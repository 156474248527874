import React, { useState } from "react";
import {
	Container,
	Image,
	Row,
	Nav,
	Col,
	Tab,
	Button,
} from "react-bootstrap";
import "./SingleItem.css";
import { Link } from "react-router-dom";
import CollectionCard from "../customComponents/CollectionCard";


const MoreFromCollection = (props) => {

	return <>
		<div className="more-form-collection-header-sec">
			<h2>More from This Collection</h2>
			<Link to={`/collection/${props.nft_collection_unique_id}`} className="section-link">
				View All
			</Link>
		</div>
		<div className="more-form-collection-wrapper">
			<div className="collection-item-card-box">
				{props.nft_collection_items
					.map((item, index) => (
						<CollectionCard
							data={item}
							useFlex={false}
							key={index}
						/>
					))}
			</div>
		</div>
	</>;
};

export default MoreFromCollection;
