import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Nav,
  Col,
  Tab,
  Button,
  Form,
} from "react-bootstrap";
import "./StaticPage.css";
import { useParams } from "react-router";
import { fetchSinglePageStart } from '../store/actions/PageAction';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StaticPageLoader from "../loaders/StaticPageLoader";


const StaticPageIndex = (props) => {

  const { static_page_unique_id } = useParams();

  useEffect(() => {
    if (static_page_unique_id) {
      props.dispatch(
        fetchSinglePageStart({ unique_id: static_page_unique_id })
      );
    }
  }, [static_page_unique_id]);


  return <>
    {props.page.loading ? (
      <StaticPageLoader />
    ) : props.page.data && (
      <div className="static-page-sec">
        <div id="circle"></div>
        <div id="circle1"></div>
        <Container>
          <Row>
            <Col md="12">
              <div className="bredcrum-sec">
                <ul className="bredcrum-list list-unstyled">
                  <li><Link to="/">Home</Link></li>
                  <li>Resources</li>
                  <li className="text-capitalize">{props.page.data.title}</li>
                </ul>
              </div>
              <div className="static-page-box">
                <div className="static-page-title">
                  <h2 className="text-capitalize">{props.page.data.title}</h2>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.page.data.description,
                  }}
                ></p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
    }
  </>;
};

const mapStateToPros = (state) => ({
  page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticPageIndex);
