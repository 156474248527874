import React, { useState } from "react";
import {
	Container,
	Image,
	Row,
	Col,
} from "react-bootstrap";
import "./Landing.css";
import { Link } from "react-router-dom";

const HomeBanner = (props) => {
	return <>
		<div className="home-banner-sec">
			<Container>
				<div className="vertical-center">
					<Row className="align-items-center">
						<Col md={12} xl={6} lg={6} className="resp-mrg-btm-md">
							<div className="home-banner-content">
								<h1>
									<span>
										Create
									</span>
									<span className="text-theme">
										Your Own Social NFT
									</span>
									<span>
										Fast
									</span>
								</h1>
								<p>Marketplace brings creators/influencers, fans/followers, and brands together on the world's first and largest marketplace for dynamic NFTs and social media posts, gifs, memes, audio, and video.</p>
								<p>Join now and start creating and trading social NFTs for FREE.  No need for Crypto. Also get a free NFT wallet when you join.</p>
								<div className="banner-btn-sec">
									<Link to="#" className="default-btn">
										Create NFT
									</Link>
									<div className="video-presentation">
										<div className="video-play-button">
											<span></span>
										</div>
										<div className="play-button-title">
											<h6>How IT Works?</h6>
										</div>
									</div>
								</div>
							</div>
							<div className="horizontal-left-banner-count">
								<h2>Currently on Marketplace</h2>
								<div className="horizontal-left-banner-box">
									<div className="horizontal-left-banner-card">
										<h4>8.2K</h4>
										<p>influencers</p>
									</div>
									<div className="horizontal-left-banner-card">
										<h4>346K</h4>
										<p>NFTS</p>
									</div>
								</div>
							</div>
						</Col>
						<Col md={12} xl={6} lg={6}>
							<div className="home-banner-img-sec">
								<Image
									className="home-banner-img"
									src={window.location.origin + "/images/landing-page/home-banner-img.png"}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	</>;
};

export default HomeBanner;
