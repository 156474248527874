import React, { useState } from "react";
import {
  Nav,
  Row,
  Col,
  Tab,
  Container,
  Form,
  InputGroup,
  Image,
  Button,
} from "react-bootstrap";
import "./Settings.css";
import { Link, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import SettingsProfile from "./SettingsProfile";
import SettingsSecurity from "./SettingsSecurity";
import SettingsWallet from "./SettingsWallet";

const SettingsIndex = (props) => {
  const location = useLocation();
  const { getRootProps, getInputProps } = useDropzone();

  return (
    <>
      <div className="settings-sec">
        <Container>
          <div className="settings-tab-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="justify-content-center">
                <Col sm={6} md={12} xl={6}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Link
                        to="/profile-setting"
                        className={`nav-link ${
                          location.pathname === "/profile-setting"
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="setting-header">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.537"
                            height="19.309"
                            viewBox="0 0 20.537 19.309"
                            fill="none"
                            stroke="none"
                          >
                            <g
                              id="Group_45274"
                              data-name="Group 45274"
                              transform="translate(1.025 0.75)"
                            >
                              <path
                                id="Path_56224"
                                data-name="Path 56224"
                                d="M17.849,18.073a6.1,6.1,0,1,0-6.1-6.1A6.1,6.1,0,0,0,17.849,18.073Z"
                                transform="translate(-8.605 -5.875)"
                                stroke-miterlimit="10"
                                stroke-width="1.5"
                              />
                              <path
                                id="Path_56225"
                                data-name="Path 56225"
                                d="M5.691,34.711a10.673,10.673,0,0,1,18.488,0"
                                transform="translate(-5.691 -17.177)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg>
                          <span>Profile</span>
                        </div>
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link
                        to="/authentication"
                        className={`nav-link ${
                          location.pathname === "/authentication"
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="setting-header">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.5"
                            height="18.166"
                            viewBox="0 0 16.5 18.166"
                            fill="none"
                            stroke="none"
                          >
                            <g id="lock" transform="translate(-1.75 -0.917)">
                              <path
                                id="Path_56428"
                                data-name="Path 56428"
                                d="M15.833,9.167H4.167A1.667,1.667,0,0,0,2.5,10.833v5.833a1.667,1.667,0,0,0,1.667,1.667H15.833A1.667,1.667,0,0,0,17.5,16.667V10.833A1.667,1.667,0,0,0,15.833,9.167Z"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <path
                                id="Path_56429"
                                data-name="Path 56429"
                                d="M5.833,9.167V5.833a4.167,4.167,0,0,1,8.333,0V9.167"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg>
                          <span>Security</span>
                        </div>
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link
                        to="/wallet"
                        className={`nav-link ${
                          location.pathname === "/wallet" ? "active" : ""
                        }`}
                      >
                        <div className="setting-header">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.823"
                            height="18.303"
                            viewBox="0 0 20.823 18.303"
                            fill="none"
                            stroke="none"
                          >
                            <g
                              id="Group_45276"
                              data-name="Group 45276"
                              transform="translate(0.75 0.75)"
                            >
                              <path
                                id="Path_56222"
                                data-name="Path 56222"
                                d="M7.344,10.493V23.935a1.68,1.68,0,0,0,1.68,1.68h16.8a.84.84,0,0,0,.84-.84V13.013a.84.84,0,0,0-.84-.84H9.024a1.68,1.68,0,0,1-1.68-1.68Zm0,0a1.68,1.68,0,0,1,1.68-1.68H23.307"
                                transform="translate(-7.344 -8.813)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <path
                                id="Path_56223"
                                data-name="Path 56223"
                                d="M32.1,26.755a1.26,1.26,0,1,0-1.26-1.26A1.26,1.26,0,0,0,32.1,26.755Z"
                                transform="translate(-17.401 -15.413)"
                              />
                            </g>
                          </svg>

                          <span>Deposit</span>
                        </div>
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link
                        to="/withdraw"
                        className={`nav-link ${
                          location.pathname === "/withdraw" ? "active" : ""
                        }`}
                      >
                        <div className="setting-header">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.537"
                            height="19.309"
                            viewBox="0 0 20.537 19.309"
                            fill="none"
                            stroke="none"
                          >
                            <g
                              id="Group_45274"
                              data-name="Group 45274"
                              transform="translate(1.025 0.75)"
                            >
                              <path
                                id="Path_56224"
                                data-name="Path 56224"
                                d="M17.849,18.073a6.1,6.1,0,1,0-6.1-6.1A6.1,6.1,0,0,0,17.849,18.073Z"
                                transform="translate(-8.605 -5.875)"
                                stroke-miterlimit="10"
                                stroke-width="1.5"
                              />
                              <path
                                id="Path_56225"
                                data-name="Path 56225"
                                d="M5.691,34.711a10.673,10.673,0,0,1,18.488,0"
                                transform="translate(-5.691 -17.177)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-credit-card"
                          >
                            <rect
                              x="1"
                              y="4"
                              width="22"
                              height="16"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                          </svg>
                          <span>Withdraw</span>
                        </div>
                      </Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  {props.children}
                  {/* <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <SettingsProfile />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <SettingsSecurity />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <SettingsWallet />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth"></Tab.Pane>
                  </Tab.Content> */}
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SettingsIndex;
