import {
  ADD_USER_CARD_FAILURE,
  ADD_USER_CARD_START,
  ADD_USER_CARD_SUCCESS,
  FETCH_USER_CARD_LIST_FAILURE,
  FETCH_USER_CARD_LIST_START,
  FETCH_USER_CARD_LIST_SUCCESS,
  SET_DEFAULT_USER_CARD_FAILURE,
  SET_DEFAULT_USER_CARD_START,
  SET_DEFAULT_USER_CARD_SUCCESS,
  DELETE_USER_CARD_FAILURE,
  DELETE_USER_CARD_START,
  DELETE_USER_CARD_SUCCESS,
  ADD_USER_BILLING_ACCOUNTS_FAILURE,
  ADD_USER_BILLING_ACCOUNTS_START,
  ADD_USER_BILLING_ACCOUNTS_SUCCESS,
  FETCH_USER_BILLING_ACCOUNTS_FAILURE,
  FETCH_USER_BILLING_ACCOUNTS_START,
  FETCH_USER_BILLING_ACCOUNTS_SUCCESS,
  SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE,
  SET_DEFAULT_USER_BILLING_ACCOUNTS_START,
  SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS,
  DELETE_USER_BILLING_ACCOUNTS_FAILURE,
  DELETE_USER_BILLING_ACCOUNTS_START,
  DELETE_USER_BILLING_ACCOUNTS_SUCCESS,
  FETCH_WALLET_DETAILS_FAILURE,
  FETCH_WALLET_DETAILS_START,
  FETCH_WALLET_DETAILS_SUCCESS,
  FETCH_MORE_WALLET_DETAILS_START,
  WALLET_PAYMENT_BY_STRIPE_FAILURE,
  WALLET_PAYMENT_BY_STRIPE_START,
  WALLET_PAYMENT_BY_STRIPE_SUCCESS,
  WITHDRAWAL_REQUEST_START,
  WITHDRAWAL_REQUEST_SUCCESS,
  WITHDRAWAL_REQUEST_FAILURE,
  CANCEL_WITHDRAWAL_REQUEST_START,
  CANCEL_WITHDRAWAL_REQUEST_SUCCESS,
  CANCEL_WITHDRAWAL_REQUEST_FAILURE,
  FETCH_WITHDRAWL_REQUESTS_START,
  FETCH_WITHDRAWL_REQUESTS_SUCCESS,
  FETCH_WITHDRAWL_REQUESTS_FAILURE,
  FETCH_MORE_WITHDRAWAL_REQUESTS_START,
  VIEW_WITHDRAWAL_REQUEST_SUCCESS,
  VIEW_WITHDRAWAL_REQUEST_START,
  VIEW_WITHDRAWAL_REQUEST_FAILURE,
  RESET_WITHDRAWL_REQUEST_SUCCESS,
  GET_WALLET_BALANCE_START,
  GET_WALLET_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_FAILURE,
} from "./ActionConstant";

// Add Card
export function addUserCardStart(data) {
  return {
    type: ADD_USER_CARD_START,
    data,
  };
}

export function addUserCardSuccess(data) {
  return {
    type: ADD_USER_CARD_SUCCESS,
    data,
  };
}

export function addUserCardFailure(error) {
  return {
    type: ADD_USER_CARD_FAILURE,
    error,
  };
}

//Get User Card List
export function fetchUserCardListStart(data) {
  return {
    type: FETCH_USER_CARD_LIST_START,
    data,
  };
}

export function fetchUserCardListSuccess(data) {
  return {
    type: FETCH_USER_CARD_LIST_SUCCESS,
    data,
  };
}

export function fetchUserCardListFailure(error) {
  return {
    type: FETCH_USER_CARD_LIST_FAILURE,
    error,
  };
}

//Make Default Card
export function setDefaultUserCardStart(data) {
  return {
    type: SET_DEFAULT_USER_CARD_START,
    data,
  };
}

export function setDefaultUserCardSuccess(data) {
  return {
    type: SET_DEFAULT_USER_CARD_SUCCESS,
    data,
  };
}

export function setDefaultUserCardFailure(error) {
  return {
    type: SET_DEFAULT_USER_CARD_FAILURE,
    error,
  }
}

//Delete user Card
export function deleteUserCardStart(data) {
  return {
    type: DELETE_USER_CARD_START,
    data,
  };
}

export function deleteUserCardSuccess(data) {
  return {
    type: DELETE_USER_CARD_SUCCESS,
    data,
  };
}

export function deleteUserCardFailure(error) {
  return {
    type: DELETE_USER_CARD_FAILURE,
    error,
  };
}

// Add Billing Accounts
export function addUserBillingAccountsStart(data) {
  return {
    type: ADD_USER_BILLING_ACCOUNTS_START,
    data,
  };
}

export function addUserBillingAccountsSuccess(data) {
  return {
    type: ADD_USER_BILLING_ACCOUNTS_SUCCESS,
    data,
  };
}

export function addUserBillingAccountsFailure(error) {
  return {
    type: ADD_USER_BILLING_ACCOUNTS_FAILURE,
    error,
  };
}

export function fetchUserBillingAccountsListStart(data) {
  return {
    type: FETCH_USER_BILLING_ACCOUNTS_START,
    data,
  };
}

export function fetchUserBillingAccountsListSuccess(data) {
  return {
    type: FETCH_USER_BILLING_ACCOUNTS_SUCCESS,
    data,
  };
}

export function fetchUserBillingAccountsListFailure(error) {
  return {
    type: FETCH_USER_BILLING_ACCOUNTS_FAILURE,
    error,
  };
}

//Make Default Billing Accounts
export function setDefaultUserBillingAccountsStart(data) {
  return {
    type: SET_DEFAULT_USER_BILLING_ACCOUNTS_START,
    data,
  };
}

export function setDefaultUserBillingAccountsSuccess(data) {
  return {
    type: SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS,
    data,
  };
}

export function setDefaultUserBillingAccountsFailure(error) {
  return {
    type: SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE,
    error,
  }
}
//Delete User Billing Accounts
export function deleteUserBillingAccountsStart(data) {
  return {
    type: DELETE_USER_BILLING_ACCOUNTS_START,
    data,
  };
}

export function deleteUserBillingAccountsSuccess(data) {
  return {
    type: DELETE_USER_BILLING_ACCOUNTS_SUCCESS,
    data,
  };
}

export function deleteUserBillingAccountsFailure(error) {
  return {
    type: DELETE_USER_BILLING_ACCOUNTS_FAILURE,
    error,
  };
}

export function fetchWalletDetailsStart(data) {
  return {
    type: FETCH_WALLET_DETAILS_START,
    data,
  };
}

export function fetchWalletDetailsSuccess(data) {
  return {
    type: FETCH_WALLET_DETAILS_SUCCESS,
    data,
  };
}

export function fetchWalletDetailsFailure(error) {
  return {
    type: FETCH_WALLET_DETAILS_FAILURE,
    error,
  };
}

export function fetchMoreWalletDetailsStart(data) {
  return {
    type: FETCH_MORE_WALLET_DETAILS_START,
    data,
  }
}

export function walletPaymentByStripeStart(data) {
  return {
    type: WALLET_PAYMENT_BY_STRIPE_START,
    data,
  };
}

export function walletPaymentByStripeSuccess(data) {
  return {
    type: WALLET_PAYMENT_BY_STRIPE_SUCCESS,
    data,
  };
}

export function walletPaymentByStripeFailure(error) {
  return {
    type: WALLET_PAYMENT_BY_STRIPE_FAILURE,
    error,
  };
}

//wallet Withdraw request
export function withdrawalRequestStart(data) {
  return {
    type: WITHDRAWAL_REQUEST_START,
    data,
  };
}

export function withdrawalRequestSuccess(data) {
  return {
    type: WITHDRAWAL_REQUEST_SUCCESS,
    data,
  };
}

export function withdrawalRequestFailure(error) {
  return {
    type: WITHDRAWAL_REQUEST_FAILURE,
    error,
  };
}

//cancel wallet withdraw request
export function cancelWithdrawalRequestStart(data) {
  return {
    type: CANCEL_WITHDRAWAL_REQUEST_START,
    data,
  };
}

export function cancelWithdrawalRequestSuccess(data) {
  return {
    type: CANCEL_WITHDRAWAL_REQUEST_SUCCESS,
    data,
  };
}

export function cancelWithdrawalRequestFailure(error) {
  return {
    type: CANCEL_WITHDRAWAL_REQUEST_FAILURE,
    error,
  };
}

//fetch Withdrawl requests
export function fetchWithdrawlRequestsStart(data) {
  return {
    type: FETCH_WITHDRAWL_REQUESTS_START,
    data,
  };
}

export function fetchWithdrawlRequestSuccess(data) {
  return {
    type: FETCH_WITHDRAWL_REQUESTS_SUCCESS,
    data,
  };
}

export function resetWithdrawlRequestSuccess(data) {
  return {
    type: RESET_WITHDRAWL_REQUEST_SUCCESS,
    data,
  }
}

export function fetchWithdrawlRequestsFailure(error) {
  return {
    type: FETCH_WITHDRAWL_REQUESTS_FAILURE,
    error,
  };
}

export function fetchMoreWithdrawlRequestStart(data) {
  return {
    type: FETCH_MORE_WITHDRAWAL_REQUESTS_START,
    data,
  }
}

// View withdraw request
export function viewWithdrawalRequestStart(data) {
  return {
    type: VIEW_WITHDRAWAL_REQUEST_START,
    data,
  };
}

export function viewWithdrawalRequestSuccess(data) {
  return {
    type: VIEW_WITHDRAWAL_REQUEST_SUCCESS,
    data,
  };
}

export function viewWithdrawalRequestFailure(error) {
  return {
    type: VIEW_WITHDRAWAL_REQUEST_FAILURE,
    error,
  };
}

export function getWalletBalanceStart(data) {
  return {
    type: GET_WALLET_BALANCE_START,
    data,
  }
}

export function getWalletBalanceSuccess(data) {
  return {
    type: GET_WALLET_BALANCE_SUCCESS,
    data,
  }
}

export function getWalletBalanceFailure(error) {
  return {
    type: GET_WALLET_BALANCE_FAILURE,
    error,
  }
}
