import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchNftCollectionsListSuccess,
  fetchNftCollectionsListFailure,
  fetchSingleNftCollectionsSuccess,
  fetchSingleNftCollectionsFailure,
  nftCollectionsSaveSuccess,
  nftCollectionsSaveFailure,
  nftCollectionsDeleteSuccess,
  nftCollectionsDeleteFailure,
  fetchNftCollectionsListForOthersSuccess,
  fetchNftCollectionsListForOthersFailure,
  fetchNftItemsListSuccess,
  fetchNftItemsListFailure,
  fetchSingleNftItemsSuccess,
  fetchSingleNftItemsFailure,
  nftItemsSaveSuccess,
  nftItemsSaveFailure,
  nftItemsDeleteSuccess,
  nftItemsDeleteFailure,
  fetchNftPropertiesListSuccess,
  fetchNftPropertiesListFailure,
  fetchNftFavouritesListSuccess,
  fetchNftFavouritesListFailure,
  nftFavouritesSaveSuccess,
  nftFavouritesSaveFailure,
  fetchCategoriesListSuccess,
  fetchCategoriesListFailure,
  fetchReportItemListSuccess,
  fetchReportItemListFailure,
  reportItemsSaveSuccess,
  reportItemsSaveFailure,
  nftItemsPaymentListSuccess,
  nftItemsPaymentListFailure,
  fetchTrendingNftItemsSuccess,
  fetchTrendingNftItemsFailure,
  sellNftItemSuccess,
  sellNftItemFailure,
  cancelListNftItemSuccess,
  cancelListNftItemFailure,
  buyNftItemSuccess,
  buyNftItemFailure,
  nftCollectionNameValidationSuccess,
  nftCollectionNameValidationFailure,
  nftUserProfileItemsListSuccess,
  nftUserProfileItemsListFailure,
  nftSearchSuccess,
  nftSearchFailure,
  nftItemFavouriteUsersListSuccess,
  nftItemFavouriteUsersListFailure,
  loadMoreNftItemsFailure,
  loadMoreNftItemsSuccess,
  loadMoreUserProfileItemsSuccess,
  loadMoreUserProfileItemsFailure,
  loadMoreItemActivitySuccess,
  loadMoreItemActivityFailure,
  importContractAddressSuccess,
  importContractAddressFailure,
  transferNFTSuccess,
  transferNFTFailure,
  bidOfferNftItemSaveSuccess,
  bidOfferNftItemSaveFailure,
  bidOfferNftItemListSuccess,
  bidOfferNftItemListFailure,
  acceptOfferNftItemSuccess,
  acceptOfferNftItemFailure,
  cancelBidOfferNftItemSuccess,
  cancelBidOfferNftItemFailure,
  loadMoreOfferListSuccess,
  loadMoreOfferListFailure,
  moreFromCollectionSuccess,
  moreFromCollectionFailure,
} from "../actions/NftAction";
import api from "../../../Environment";

import {
  FETCH_COLLECTIONS_LIST_START,
  FETCH_SINGLE_COLLECTIONS_START,
  NFT_COLLECTIONS_SAVE_START,
  NFT_COLLECTIONS_DELETE_START,
  FETCH_COLLECTIONS_LIST_FOR_OTHERS_START,
  FETCH_NFT_ITEMS_LIST_START,
  FETCH_SINGLE_NFT_ITEMS_START,
  NFT_ITEMS_SAVE_START,
  NFT_ITEMS_DELETE_START,
  FETCH_NFT_PROPERTIES_LIST_START,
  FETCH_NFT_FAVOURITES_LIST_START,
  NFT_FAVOURITES_SAVE_START,
  FETCH_NFT_CATEGORIES_LIST_START,
  FETCH_TRENDING_NFT_ITEMS_START,
  FETCH_REPORT_ITEMS_LIST_START,
  SAVE_REPORT_ITEMS_START,
  FETCH_NFT_ITEMS_PAYMENT_LIST_START,
  SELL_NFT_ITEMS_START,
  CANCEL_LIST_NFT_ITEMS_START,
  BUY_NFT_ITEMS_START,
  NFT_COLLECTIONS_NAME_VALIDATION_START,
  NFT_USER_PROFILE_ITEMS_LIST_START,
  NFT_SEARCH_START,
  NFT_ITEM_FAVOURITE_USERS_LIST_START,
  LOAD_MORE_FETCH_NFT_ITEMS_LIST_START,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
  LOAD_MORE_ITEM_ACTIVITY_START,
  IMPORT_CONTRATCT_ADDRESS_START,
  TRANSFER_NFT_START,
  BID_OFFER_NFT_ITEMS_SAVE_START,
  BID_OFFER_NFT_ITEMS_LIST_START,
  ACCEPT_OFFER_NFT_ITEMS_START,
  CANCEL_BID_OFFER_NFT_ITEM_START,
  LOAD_MORE_OFFER_LIST_START,
  MORE_FROM_COLLECTION_START,
  FETCH_MORE_COLLECTIONS_LIST_START,
  FETCH_MORE_TRENDING_NFT_ITEMS_START,
  FETCH_MORE_COLLECTIONS_LIST_FOR_OTHERS_START,
  LOAD_MORE_BID_OFFER_NFT_ITEMS_LIST_START,
  MORE_NFT_ITEM_FAVOURITE_USERS_LIST_START,
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../helper/ToastNotification";

function* fetchNftCollectionsListAPI(action) {
  try {
    const response = yield api.postMethod("nft_collections_list", action.data);
    if (response.data.success) {
      yield put(fetchNftCollectionsListSuccess(response.data.data));
    } else {
      yield put(fetchNftCollectionsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchNftCollectionsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSingleNftCollectionsAPI(action) {
  try {
    const response = yield api.postMethod("nft_collections_view", action.data);
    if (response.data.success) {
      yield put(fetchSingleNftCollectionsSuccess(response.data.data));
    } else {
      yield put(fetchSingleNftCollectionsFailure(response.data));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleNftCollectionsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftCollectionsSaveAPI(action) {
  try {
    const response = yield api.postMethod("nft_collections_save", action.data);
    if (response.data.success) {
      yield put(nftCollectionsSaveSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(nftCollectionsSaveFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftCollectionsSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftCollectionsDeleteAPI(action) {
  try {
    const response = yield api.postMethod("nft_collections_delete", action.data);
    if (response.data.success) {
      yield put(nftCollectionsDeleteSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(nftCollectionsDeleteFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftCollectionsDeleteFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchNftCollectionsListForOthersAPI(action) {
  try {
    const response = yield api.postMethod("nft_collections_list_for_others", action.data);
    if (response.data.success) {
      yield put(fetchNftCollectionsListForOthersSuccess(response.data.data));
    } else {
      yield put(fetchNftCollectionsListForOthersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchNftCollectionsListForOthersFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchNftItemsListAPI(action) {
  try {
    const response = yield api.postMethod("nft_collection_items_list", action.data);
    if (response.data.success) {
      yield put(fetchNftItemsListSuccess(response.data.data));
    } else {
      yield put(fetchNftItemsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchNftItemsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSingleNftItemsAPI(action) {
  try {
    const inputData = yield select((state) => state.nfts.singleItems.inputData);
    const response = yield api.postMethod("nft_collection_items_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleNftItemsSuccess(response.data.data));
    } else {
      yield put(fetchSingleNftItemsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleNftItemsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftItemsSaveAPI(action) {
  try {
    const response = yield api.postMethod("nft_collection_items_save", action.data);
    if (response.data.success) {
      yield put(nftItemsSaveSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(nftItemsSaveFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftItemsSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftItemsDeleteAPI(action) {
  try {
    const response = yield api.postMethod("nft_collection_items_delete", action.data);
    if (response.data.success) {
      yield put(nftItemsDeleteSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(nftItemsDeleteFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftItemsDeleteFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchNftPropertiesListAPI(action) {
  try {
    const response = yield api.postMethod("nft_collection_properties_list", action.data);
    if (response.data.success) {
      yield put(fetchNftPropertiesListSuccess(response.data.data));
    } else {
      yield put(fetchNftPropertiesListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchNftPropertiesListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchNftFavouritesListAPI(action) {
  try {
    const response = yield api.postMethod("nft_favourites_list", action.data);
    if (response.data.success) {
      yield put(fetchNftFavouritesListSuccess(response.data.data));
    } else {
      yield put(fetchNftFavouritesListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchNftFavouritesListFailure(error));
    yield put(getErrorNotificationMessage(error.message));
  }
}

function* nftFavouritesSaveAPI(action) {
  try {
    const response = yield api.postMethod("nft_favourites_save", action.data);
    if (response.data.success) {
      yield put(nftFavouritesSaveSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(nftFavouritesSaveFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftFavouritesSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchCategoriesListAPI(action) {
  try {
    const response = yield api.postMethod("categories_list", action.data);
    if (response.data.success) {
      yield put(fetchCategoriesListSuccess(response.data.data));
    } else {
      yield put(fetchCategoriesListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCategoriesListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchReportItemListAPI(action) {
  try {
    const response = yield api.postMethod(
      "report_nft_collection_item_list", action.data
    );
    if (response.data.success) {
      yield put(fetchReportItemListSuccess(response.data.data));
    } else {
      yield put(fetchReportItemListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchReportItemListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* reportItemsSaveAPI(action) {
  try {
    const response = yield api.postMethod(
      "report_nft_collection_item_save", action.data
    );
    if (response.data.success) {
      yield put(reportItemsSaveSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(reportItemsSaveFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(reportItemsSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftItemsPaymentListAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_collection_item_payment_list", action.data
    );
    if (response.data.success) {
      yield put(nftItemsPaymentListSuccess(response.data.data));
    } else {
      yield put(nftItemsPaymentListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftItemsPaymentListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchTrendingNftItemsAPI(action) {
  try {
    const response = yield api.postMethod(
      "trending_collection_items_list", action.data
    );
    if (response.data.success) {
      yield put(fetchTrendingNftItemsSuccess(response.data.data));
    } else {
      yield put(fetchTrendingNftItemsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchTrendingNftItemsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* sellNftItemAPI(action) {
  try {
    const response = yield api.postMethod(
      "sell_nft_collection_item", action.data
    );
    if (response.data.success) {
      yield put(sellNftItemSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(sellNftItemFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(sellNftItemFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* cancelListNftItemAPI(action) {
  try {
    const response = yield api.postMethod(
      "cancel_list_nft_collection_item", action.data
    );
    if (response.data.success) {
      yield put(cancelListNftItemSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(cancelListNftItemFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(cancelListNftItemFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* buyNftItemAPI(action) {
  try {
    const response = yield api.postMethod(
      "buy_nft_collection_item", action.data
    );
    if (response.data.success) {
      yield put(buyNftItemSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(buyNftItemFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(buyNftItemFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftCollectionNameValidationAPI(action) {
  try {
    const response = yield api.postMethod(
      "collection_name_validation", action.data
    );
    if (response.data.success) {
      yield put(nftCollectionNameValidationSuccess(response.data));
    } else {
      yield put(nftCollectionNameValidationFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftCollectionNameValidationFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftUserProfileItemsListAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_user_profile_items_list", action.data
    );
    if (response.data.success) {
      yield put(nftUserProfileItemsListSuccess(response.data.data));
    } else {
      yield put(nftUserProfileItemsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftUserProfileItemsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftSearchAPI(action) {
  try {
    const response = yield api.postMethod(
      "nfts_search", action.data
    );
    if (response.data.success) {
      yield put(nftSearchSuccess(response.data.data));
    } else {
      yield put(nftSearchFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftSearchFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* nftItemFavouriteUsersListAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_favourite_item_users_list", action.data
    );
    if (response.data.success) {
      yield put(nftItemFavouriteUsersListSuccess(response.data.data));
    } else {
      yield put(nftItemFavouriteUsersListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(nftItemFavouriteUsersListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* loadMoreNftItemsListAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_collection_items_list", action.data
    );
    if (response.data.success) {
      yield put(loadMoreNftItemsSuccess(response.data.data));
    } else {
      yield put(loadMoreNftItemsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(loadMoreNftItemsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* loadMoreUserProfileItemAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_user_profile_items_list", action.data
    );
    if (response.data.success) {
      yield put(loadMoreUserProfileItemsSuccess(response.data.data));
    } else {
      yield put(loadMoreUserProfileItemsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(loadMoreUserProfileItemsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* loadMoreItemActivityAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_collection_item_payment_list", action.data
    );
    if (response.data.success) {
      yield put(loadMoreItemActivitySuccess(response.data.data));
    } else {
      yield put(loadMoreItemActivityFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(loadMoreItemActivityFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* importContractAddressStart(action) {
  try {
    const response = yield api.postMethod(
      "import_contract_address", action.data
    );
    if (response.data.success) {
      yield put(importContractAddressSuccess(response.data.data));
    } else {
      yield put(importContractAddressFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(importContractAddressFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* transferNftAPI(action) {
  try {
    const response = yield api.postMethod(
      "transfer_nft_collection_item", action.data
    );
    if (response.data.success) {
      yield put(transferNFTSuccess(response.data.data));
    } else {
      yield put(transferNFTFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(transferNFTFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* bidOfferNftItemSaveAPI(action) {
  try {
    const response = yield api.postMethod(
      "bid_offer_nft_item_save", action.data
    );
    if (response.data.success) {
      yield put(bidOfferNftItemSaveSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(bidOfferNftItemSaveFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(bidOfferNftItemSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* bidOfferNftItemListAPI(action) {
  try {
    const response = yield api.postMethod(
      "bid_offer_nft_item_list", action.data
    );
    if (response.data.success) {
      yield put(bidOfferNftItemListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(bidOfferNftItemListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(bidOfferNftItemListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* acceptOfferNftItemAPI(action) {
  try {
    const response = yield api.postMethod(
      "accept_offer_nft_item", action.data
    );
    if (response.data.success) {
      yield put(acceptOfferNftItemSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(acceptOfferNftItemFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(acceptOfferNftItemFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* cancelBidOfferNftItemAPI(action) {
  try {
    const response = yield api.postMethod(
      "cancel_bid_offer_nft_item", action.data
    );
    if (response.data.success) {
      yield put(cancelBidOfferNftItemSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(cancelBidOfferNftItemFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(cancelBidOfferNftItemFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* loadMoreOfferListAPI(action) {
  try {
    const response = yield api.postMethod(
      "bid_offer_nft_item_list", action.data
    );
    if (response.data.success) {
      yield put(loadMoreOfferListSuccess(response.data.data));
    } else {
      yield put(loadMoreOfferListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(loadMoreOfferListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* moreFromCollectionAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_collection_items_list", action.data
    );
    if (response.data.success) {
      yield put(moreFromCollectionSuccess(response.data.data));
    } else {
      yield put(moreFromCollectionFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(moreFromCollectionFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_COLLECTIONS_LIST_START, fetchNftCollectionsListAPI),
    yield takeLatest(FETCH_SINGLE_COLLECTIONS_START, fetchSingleNftCollectionsAPI),
    yield takeLatest(NFT_COLLECTIONS_SAVE_START, nftCollectionsSaveAPI),
    yield takeLatest(NFT_COLLECTIONS_DELETE_START, nftCollectionsDeleteAPI),
    yield takeLatest(FETCH_COLLECTIONS_LIST_FOR_OTHERS_START, fetchNftCollectionsListForOthersAPI),
    yield takeLatest(FETCH_NFT_ITEMS_LIST_START, fetchNftItemsListAPI),
    yield takeLatest(FETCH_SINGLE_NFT_ITEMS_START, fetchSingleNftItemsAPI),
    yield takeLatest(NFT_ITEMS_SAVE_START, nftItemsSaveAPI),
    yield takeLatest(NFT_ITEMS_DELETE_START, nftItemsDeleteAPI),
    yield takeLatest(FETCH_NFT_PROPERTIES_LIST_START, fetchNftPropertiesListAPI),
    yield takeLatest(FETCH_NFT_FAVOURITES_LIST_START, fetchNftFavouritesListAPI),
    yield takeLatest(NFT_FAVOURITES_SAVE_START, nftFavouritesSaveAPI),
    yield takeLatest(FETCH_NFT_CATEGORIES_LIST_START, fetchCategoriesListAPI),
    yield takeLatest(FETCH_REPORT_ITEMS_LIST_START, fetchReportItemListAPI),
    yield takeLatest(SAVE_REPORT_ITEMS_START, reportItemsSaveAPI),
    yield takeLatest(FETCH_NFT_ITEMS_PAYMENT_LIST_START, nftItemsPaymentListAPI),
    yield takeLatest(FETCH_TRENDING_NFT_ITEMS_START, fetchTrendingNftItemsAPI),
    yield takeLatest(SELL_NFT_ITEMS_START, sellNftItemAPI),
    yield takeLatest(CANCEL_LIST_NFT_ITEMS_START, cancelListNftItemAPI),
    yield takeLatest(BUY_NFT_ITEMS_START, buyNftItemAPI),
    yield takeLatest(NFT_COLLECTIONS_NAME_VALIDATION_START, nftCollectionNameValidationAPI),
    yield takeLatest(NFT_USER_PROFILE_ITEMS_LIST_START, nftUserProfileItemsListAPI),
    yield takeLatest(NFT_SEARCH_START, nftSearchAPI),
    yield takeLatest(NFT_ITEM_FAVOURITE_USERS_LIST_START, nftItemFavouriteUsersListAPI),
    yield takeLatest(MORE_NFT_ITEM_FAVOURITE_USERS_LIST_START, nftItemFavouriteUsersListAPI),
    yield takeLatest(LOAD_MORE_FETCH_NFT_ITEMS_LIST_START, loadMoreNftItemsListAPI),
    yield takeLatest(LOAD_MORE_USER_PROFILE_ITEMS_LIST_START, loadMoreUserProfileItemAPI),
    yield takeLatest(LOAD_MORE_ITEM_ACTIVITY_START, loadMoreItemActivityAPI),
    yield takeLatest(IMPORT_CONTRATCT_ADDRESS_START, importContractAddressStart),
    yield takeLatest(TRANSFER_NFT_START, transferNftAPI),
    yield takeLatest(BID_OFFER_NFT_ITEMS_SAVE_START, bidOfferNftItemSaveAPI),
    yield takeLatest(BID_OFFER_NFT_ITEMS_LIST_START, bidOfferNftItemListAPI),
    yield takeLatest(ACCEPT_OFFER_NFT_ITEMS_START, acceptOfferNftItemAPI),
    yield takeLatest(CANCEL_BID_OFFER_NFT_ITEM_START, cancelBidOfferNftItemAPI),
    yield takeLatest(LOAD_MORE_OFFER_LIST_START, loadMoreOfferListAPI),
    yield takeLatest(MORE_FROM_COLLECTION_START, moreFromCollectionAPI),
    yield takeLatest(FETCH_MORE_COLLECTIONS_LIST_START, fetchNftCollectionsListAPI),
    yield takeLatest(FETCH_MORE_TRENDING_NFT_ITEMS_START, fetchTrendingNftItemsAPI),
    yield takeLatest(FETCH_MORE_COLLECTIONS_LIST_FOR_OTHERS_START, fetchNftCollectionsListForOthersAPI),
    yield takeLatest(LOAD_MORE_BID_OFFER_NFT_ITEMS_LIST_START, bidOfferNftItemListAPI),
  ]);
}
