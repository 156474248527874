import React, { useEffect } from "react";
import api from "../../Environment";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { fetchUserDetailsFailure } from "../store/actions/UserAction";

const Logout = (props) => {

	const navigate = useNavigate();

	useEffect(() => {
		api.postMethod("logout").then((response) => {
			if (response.data.success) {
				console.log("success");
			} else {
			}
		});
		// props.dispatch(fetchUserDetailsFailure({}));
		localStorage.removeItem("userId");
		localStorage.removeItem("accessToken");
		localStorage.removeItem("userLoginStatus");
		localStorage.removeItem("user_picture");
		localStorage.removeItem("user_cover");
		localStorage.removeItem("name");
		localStorage.removeItem("username");
		localStorage.removeItem("user_unique_id");
		localStorage.removeItem("email");
		localStorage.removeItem("mobile");
		// window.location.assign("/");
		navigate('/');
	}, []);
	return "";
};

const mapStateToPros = (state) => ({
	loginInputData: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Logout);
