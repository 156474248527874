import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import configuration from "react-global-configuration";
import useUserAuthStatus from "../customHooks/useUserAuthStatus";
import { authContext } from "../authprovider/AuthProvider";
import { connect } from 'react-redux';
import { nftFavouritesSaveStart } from '../store/actions/NftAction';
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../authprovider/chains";
import CustomLazyLoad from "../customComponents/CustomLazyLoad";

const CollectionCard = (props) => {
  const { data, useFlex } = props;

  const location = useLocation();

  const navigate = useNavigate();

  const userAuthStatus = useUserAuthStatus(props.profile);

  // const theme = useTheme();

  // const priceImage = usePriceImage(theme.palette.theme);

  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  const [favouritesCount, setFavouritesCount] = useState(data.favourites_count);

  const [favouriteStatus, setFavouriteStatus] = useState(
    data.is_favourite_item ? true : false
  );

  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  useEffect(() => {
    if (
      !props.favouriteSave.loading &&
      Object.keys(props.favouriteSave.data).length > 0
    ) {
      if (
        props.favouriteSave.data.data.token_id == data.token_id &&
        props.favouriteSave.data.data.nft_collection_id ==
        data.nft_collection_id
      ) {
        if (props.favouriteSave.data.code == "114") {
          setFavouritesCount(favouritesCount + 1);
          setFavouriteStatus(true);
        } else {
          setFavouritesCount(favouritesCount - 1 < 0 ? 0 : favouritesCount - 1);
          setFavouriteStatus(false);
        }
      }
    }
  }, [props.favouriteSave.data]);

  const toggleLike = e => {
    if (userAuthStatus.userLoggedIn) {
      handleLike(
        data.nft_collection.nft_collection_unique_id,
        data.token_id,
        data.owner_of
          ? data.owner_of
          : data.user
            ? data.user.wallet_address
            : ""
      )
    } else {
      console.log(false);
      // navigate("/login")
    }
    e.stopPropagation();
  }

  const handleLike = (
    nft_collection_unique_id,
    token_id,
    recent_owner_wallet_address
  ) => {
    props.dispatch(
      nftFavouritesSaveStart({
        nft_collection_unique_id: nft_collection_unique_id,
        nft_collection_item_token_id: token_id,
        recent_owner_wallet_address: recent_owner_wallet_address,
      })
    );
  };

  useEffect(() => {
    setFavouritesCount(data.favourites_count);
  }, [location]);

  const cardRef = useRef(null);

  const [imageHeight, setImageHeight] = useState(null);

  // useLayoutEffect(() => {
  //   setImageHeight(cardRef.current.offsetWidth);
  // }, [gridView]);

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const handleResize = () => {
  //   const delayDebounceFn = setTimeout(() => {
  //     setImageHeight(cardRef.current.offsetWidth);
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // };

  return (
    <div className={useFlex ? "col-lg-4 col-xl-3 col-md-6" : ""}>
      <div className="trending-artwork-card">
        <Link to={`/assets/${data.nft_collection.blockchain_type}/${data.nft_collection.nft_collection_unique_id}/${data.nft_collection.contract_address}/${data.token_id}`}>
          <div className="trending-artwork-img-sec">
            <CustomLazyLoad
              src={
                data.picture != ""
                  ? data.picture
                  : configuration.get("configData.item_placholder")
              }
              classes={"trending-artwork-img"}
              placeholderSrc={
                window.location.origin +
                "/images/loader.svg"
              }
            />
            {/* <Image
              src={
                data.picture != ""
                  ? data.picture
                  : configuration.get("configData.item_placholder")
              }
              className="trending-artwork-img"
            /> */}
          </div>
          <div className="trending-artwork-info">
            <div className="trending-artwork-details">
              <h6>{data.nft_collection.name}</h6>
              {data.name != "" ?
                <h5>{data.name}</h5>
                : null}
            </div>
          </div>
        </Link>
        <div className="trending-artwork-footer-details">
          <h4>
            {Number(data.amount) > 0 && data.is_listed == 1 &&
              <>{data.amount_formatted}</>
            }
            <span>
              <Image
                src={CHAIN_INFO[SupportedChainIdBySymbol[data.nft_collection.blockchain_type]].logo}
                className="eth-icon"
              />
            </span>
            {/* <span className="trending-artwork-desc">
                  90.234.89
                </span> */}
          </h4>
          <Button type="button" className="heart-btn" onClick={toggleLike}>
            <Image
              src={favouriteStatus ?
                window.location.origin + "/images/landing-page/icons/heart-fill.svg"
                : window.location.origin + "/images/landing-page/icons/heart-outline.svg"
              }
              className="heart-icon"
            />
          </Button>
        </div>
      </div>
    </div >
  );
}

const mapStateToPros = (state) => ({
  favouriteSave: state.nfts.favouriteSave,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CollectionCard);