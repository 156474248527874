import React, { useState } from "react";
import CreateSellYourNFT from "./CreateSellYourNFT";
import HomeBanner from "./HomeBanner";
import TopCollection from "./TopCollection";
import TrendingArtwork from "./TrendingArtwork";

const LandingPageIndex = (props) => {
  return (
    <>
      <div className="landing-page-sec">
        <HomeBanner />
        <CreateSellYourNFT />
        <TrendingArtwork position="landing" />
        <TopCollection location="landing" />
      </div>
    </>
  );
};

export default LandingPageIndex;
