import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Container,
  Image,
  Row,
  Nav,
  Col,
  Tab,
  Button,
  Form,
} from "react-bootstrap";
import "./ListingItem.css";
import { Link } from "react-router-dom";
import CompleteListingModal from "./CompleteListingModal";

import { useParams } from "react-router";
import { fetchSingleNftItemsStart } from "../store/actions/NftAction";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import NftToken from "../../abis/NftToken.json";
import LazyNft from "../../abis/LazyNFT.json";
import MarketPlaceContract from "../../abis/MarketPlaceContract.json";
import Web3 from "web3";
import { authContext } from "../authprovider/AuthProvider";
import { sellNftItemStart } from "../store/actions/NftAction";
// import ListingItemLoader from "../loaders/ListingItemLoader";
import PageNotFound from "../404/PageNotFound";
import { useNavigate } from "react-router";
import { useTheme } from "@material-ui/core";
import { addDays } from "date-fns";
import moment from "moment";
import FixedPriceListing from "./FixedPriceListing";
import { updateSignAndApproveStart } from "../store/actions/UserAction";
import useUserAuthStatus from "../customHooks/useUserAuthStatus";
import {
  CHAIN_INFO,
  SupportedChainId,
  SupportedChainIdBySymbol,
} from "../authprovider/chains";
import { BigNumber, ethers, providers } from "ethers";
import { connect } from "react-redux";
import ListingItemLoader from "../loaders/ListingItemLoader";

const ethPrice = require("eth-price");

const ListingItemIndex = (props) => {
  const closeCompleteListingModal = () => {
    setCompleteListingModal(false);
  };

  const SIGNING_DOMAIN_NAME = "LazyNFT-Voucher";
  const SIGNING_DOMAIN_VERSION = "1";

  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  const { auth } = useContext(authContext);
  const { nft_collection_unique_id, contract_address, token_id } = useParams();

  const navigate = useNavigate();

  const theme = useTheme();

  const userAuthStatus = useUserAuthStatus(props.profile);

  const netID = configuration.get("configData.network_id")
    ? configuration.get("configData.network_id")
    : "97";

  const stable_token_price = configuration.get("configData.stable_toke_price")
    ? configuration.get("configData.stable_toke_price")
    : "0";

  const [loadingButtonContent, setLoadingButtonContent] = useState("");

  const [sellData, setSellData] = useState({
    amount: "",
    blockchain_type: "",
    nft_collection_item_unique_id: "",
    nft_collection_contract_address: contract_address,
    nft_collection_item_token_id: token_id,
    nft_collection_unique_id: nft_collection_unique_id,
  });

  const [signData, setSignData] = useState({
    signature: null,
    status: false,
    loading: false,
    loadingContent: null,
  });

  const currentdate = new Date();

  const [priceDate, setPriceDate] = useState({
    status: false,
    DateRange: [{ name: "1 day", id: 1 }],
    DefaultRange: [
      { name: "1 day", id: 1 },
      { name: "3 days", id: 3 },
      { name: "7 days", id: 7 },
      { name: "30 days", id: 30 },
      { name: "90 days", id: 90 },
    ],
    isUserRangeSelected: false,
    previewStartDate: moment(new Date()).format("YYYY-MM-DD"),
    previewEndDate: moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
    startTime: `${
      currentdate.getHours() < 10
        ? `0${currentdate.getHours()}`
        : currentdate.getHours()
    }:${
      currentdate.getMinutes() < 10
        ? `0${currentdate.getMinutes()}`
        : currentdate.getMinutes()
    }`,
    endTime: `${
      currentdate.getHours() < 10
        ? `0${currentdate.getHours()}`
        : currentdate.getHours()
    }:${
      currentdate.getMinutes() < 10
        ? `0${currentdate.getMinutes()}`
        : currentdate.getMinutes()
    }`,
    isStartTimeError: false,
    isEndTimeError: false,
    sameDay: false,
    days_difference: null,
  });

  const [priceDateRange, setPriceDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const [termsAgreed, setTermsAgreed] = useState(false);

  useEffect(() => {
    // if (!auth.loading && auth.accounts != "" && auth.authStatus) {
    props.dispatch(
      fetchSingleNftItemsStart({
        nft_collection_unique_id: nft_collection_unique_id,
        nft_collection_item_token_id: token_id,
        wallet_address: auth.accounts,
      })
    );
    // }
  }, [auth.accounts]);

  const [priceSelect, setPriceSelect] = useState([]);

  const [nftContractData, setNftContractData] = useState("");

  const [marketPlaceContractAbi, setMarketPlaceContractAbi] = useState("");

  const [itemListed, setItemListed] = useState(false);

  const [activeListingAccordion, setActiveListingAccordion] = useState("1");

  const cardRef = useRef(null);

  const [imageWidth, setImageWidth] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const delayDebounceFn = setTimeout(() => {
      setImageWidth(cardRef.current.offsetWidth);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  };

  const handlePriceSelect = (event) => {
    const {
      target: { value },
    } = event;
    setPriceSelect(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (signData.signature) {
      setSellData({
        ...sellData,
        transaction_hash: signData.signature,
      });
    }
  }, [signData.signature]);

  const [completeListingModal, setCompleteListingModal] = useState(false);

  const handleCompleteListingClose = () => setCompleteListingModal(false);

  const handleSellDataChanges = (event) => {
    setSellData({
      ...sellData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCompleteListingModalOpen = () => {
    if (!auth.authStatus) {
      const notificationMessage = getErrorNotificationMessage(
        "Please connect metamask."
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }

    if (
      CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
      props.singleNftImage.data.nft_collection.blockchain_type
    ) {
      const notificationMessage = getErrorNotificationMessage(
        `Collection is not deployed in the detected network. Please switch to ${props.singleNftImage.data.nft_collection.blockchain_type}`
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }

    if (
      auth.accounts !=
      props.singleNftImage.data.nft_collection_item.owner_wallet_address
    ) {
      const notificationMessage = getErrorNotificationMessage(
        `The connected wallet is not the owner of this item. Please switch to ${String(
          props.singleNftImage.data.nft_collection_item.owner_wallet_address
        ).substring(0, 5)}
        ...
        ${String(
          props.singleNftImage.data.nft_collection_item.owner_wallet_address
        ).substring(
          props.singleNftImage.data.nft_collection_item.owner_wallet_address
            .length - 4
        )}`
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }

    setCompleteListingModal(true);
  };

  useEffect(() => {
    if (
      !props.singleNftImage.loading &&
      props.singleNftImage.data.nft_collection_item
    ) {
      setSellData({
        ...sellData,
        blockchain_type:
          props.singleNftImage.data.nft_collection_item.blockchain_type,
        nft_collection_item_unique_id:
          props.singleNftImage.data.nft_collection_item
            .nft_collection_item_unique_id,
      });
    }
  }, [props.singleNftImage.data]);

  // Blockchain code.

  // We need to get approval for the NFT. NFT contract should get the approval to sell the NFT.
  const getApprovalToSale = async () => {
    // NFT contract.
    setLoadingButtonContent("Initiated...");
    const web3 = window.web3;
    let nftContractData;
    let marketPlaceContractData;

    const NFTData = NftToken.networks[auth.chainId];
    if (NFTData) {
      try {
        setLoadingButtonContent((prevState) => "Connecting to Blockchain");
        console.log("Test true");
        console.log("sellerContractAddress", auth.sellerContractAddress);
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          contract_address
        );
        marketPlaceContractData = await new web3.eth.Contract(
          MarketPlaceContract.abi,
          auth.sellerContractAddress
        );
        setMarketPlaceContractAbi(marketPlaceContractData);
        console.log("Test next");
        let sellingAmount = window.web3.utils.toWei(sellData.amount, "Ether");
        let tokenID = token_id;

        nftContractData.methods
          .setApprovalForAll(auth.sellerContractAddress, true)
          .send({ from: auth.accounts })
          .on("receipt", async (receipt) => {
            setLoadingButtonContent("");
            props.dispatch(
              updateSignAndApproveStart({
                is_approved: 1,
                nft_collection_unique_id: nft_collection_unique_id,
                wallet_address: auth.accounts,
              })
            );
            toggleActiveAccordion((prev) => String(Number(prev) + 1));
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage =
                "Unexpected error occuried, Please try again...";
            } else {
              notificationMessage = error.message;
            }
            setLoadingButtonContent("");
            // Send error notification.
          });
      } catch (error) {
        setLoadingButtonContent("");
        // Send fail notification.
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log(error);
      }
    } else {
      setLoadingButtonContent("");
      window.alert("NFT contract not deployed to detected network.");
    }
  };

  const makeOfferToSale = async () => {
    // set the seller offer

    setLoadingButtonContent("Initiated...");
    console.log("sellData", sellData);
    let sellingAmount = window.web3.utils.toWei(
      String(sellData.amount),
      "Ether"
    );

    let tokenID = token_id;

    const list_start_at = `${priceDate.previewStartDate} ${priceDate.startTime}:00 `;

    const list_expire_at = `${priceDate.previewEndDate} ${priceDate.endTime}:00`;

    const list_expire_at_tostr = Date.parse(list_expire_at) / 1000;

    let royaltyAddress =
      props.singleNftImage.data.nft_collection.deployed_wallet_address;
    let royaltyPercentage = "0";

    try {
      if (
        props.singleNftImage.data.nft_collection.collection_type == 2 &&
        props.singleNftImage.data.nft_collection_item.is_minted == 0
      ) {
        if (
          props.singleNftImage.data.nft_collection.royalty_type == 1 &&
          props.singleNftImage.data.nft_collection.royalty_percentage > 0
        ) {
          royaltyAddress =
            props.singleNftImage.data.nft_collection.royalty_wallet_address;
          royaltyPercentage = String(
            Number(
              props.singleNftImage.data.nft_collection.royalty_percentage
            ) * 10
          );
        } else if (
          props.singleNftImage.data.nft_collection_item.royalty_percentage > 0
        ) {
          royaltyAddress =
            props.singleNftImage.data.nft_collection_item
              .royalty_wallet_address;
          royaltyPercentage = String(
            Number(
              props.singleNftImage.data.nft_collection_item.royalty_percentage
            ) * 10
          );
        }
        console.log("voucher data", royaltyAddress, royaltyPercentage);
        const voucher = await createVoucher(
          tokenID,
          props.singleNftImage.data.nft_collection_item.token_uri,
          sellingAmount,
          royaltyAddress,
          royaltyPercentage
        );
        console.log("voucher", voucher);
        if (voucher && voucher.signature) {
          props.dispatch(
            sellNftItemStart({
              ...sellData,
              list_start_at: list_start_at,
              list_expire_at: list_expire_at,
              price_type: priceSelect,
              sign_transaction_hash: voucher.signature,
            })
          );
          setItemListed(true);
          setLoadingButtonContent("");
        } else {
          setLoadingButtonContent("");
          // Send fail notification.
          const notificationMessage = getErrorNotificationMessage(
            "Something went wrong. Please refresh the page and try again."
          );
          props.dispatch(createNotification(notificationMessage));
        }
      } else {
        const web3 = window.web3;
        let marketPlaceContractData;

        marketPlaceContractData = await new web3.eth.Contract(
          MarketPlaceContract.abi,
          auth.sellerContractAddress
        );

        marketPlaceContractData.methods
          .makeSellOffer(
            tokenID,
            sellingAmount,
            contract_address,
            list_expire_at_tostr
          )
          .send({ from: auth.accounts })
          .on("receipt", (receipt) => {
            props.dispatch(
              sellNftItemStart({
                ...sellData,
                list_start_at: list_start_at,
                list_expire_at: list_expire_at,
                price_type: priceSelect,
              })
            );
            setItemListed(true);
            setLoadingButtonContent("");
            // Send success notification.
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage =
                "Unexpected error occuried, Please try again...";
            } else {
              notificationMessage = error.message;
            }
            setLoadingButtonContent("");
            // Send error notification.
          });
      }
    } catch (error) {
      console.log("errr", error);
      const notificationMessage = getErrorNotificationMessage(error.message);
      props.dispatch(createNotification(notificationMessage));
      setLoadingButtonContent("");
    }
  };

  //prevent reload

  useEffect(() => {
    if (loadingButtonContent != "") {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingButtonContent]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const redirect = (blockchain_type) => {
    navigate(
      `/assets/${blockchain_type}/${nft_collection_unique_id}/${contract_address}/${token_id}`
    );
  };

  const toggleActiveAccordion = useCallback((id) => {
    setActiveListingAccordion(id);
  }, []);

  const handlePriceDateOpen = useCallback(() => {
    setPriceDate({
      ...priceDate,
      status: true,
    });
  }, [priceDate]);

  const handlePriceDateClose = useCallback(() => {
    setPriceDate({
      ...priceDate,
      status: false,
      isUserRangeSelected: false,
    });
  }, [priceDate, priceDateRange]);

  const handlePriceRangeDropdown = useCallback(
    (value) => {
      setPriceDate({
        ...priceDate,
        DateRange: [value],
        DefaultRange: [
          { name: "1 day", id: 1 },
          { name: "3 days", id: 3 },
          { name: "1 week", id: 7 },
          { name: "1 month", id: 30 },
          { name: "3 months", id: 90 },
        ],
        days_difference: value.id,
        isUserRangeSelected: false,
        previewStartDate: moment(new Date()).format("YYYY-MM-DD"),
        previewEndDate: moment(
          addDays(new Date(), value.id == 1 ? value.id : value.id - 1)
        ).format("YYYY-MM-DD"),
        startTime: `${
          currentdate.getHours() < 10
            ? `0${currentdate.getHours()}`
            : currentdate.getHours()
        }:${
          currentdate.getMinutes() < 10
            ? `0${currentdate.getMinutes()}`
            : currentdate.getMinutes()
        }`,
        sameDay: false,
        endTime: `${
          currentdate.getHours() < 10
            ? `0${currentdate.getHours()}`
            : currentdate.getHours()
        }:${
          currentdate.getMinutes() < 10
            ? `0${currentdate.getMinutes()}`
            : currentdate.getMinutes()
        }`,
      });
      const newPriceDateRange = priceDateRange[0];
      newPriceDateRange.startDate = new Date();
      newPriceDateRange.endDate = addDays(
        new Date(),
        value.id == 1 ? value.id : value.id - 1
      );
      setPriceDateRange([newPriceDateRange]);
    },
    [priceDate, priceDateRange]
  );

  const getUniquerange = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  const handleRangeSelection = useCallback(
    (item) => {
      setPriceDateRange([item.selection]);

      const oneDay = 24 * 60 * 60 * 1000;

      const startDate = new Date(item.selection.startDate);

      const secondDate = new Date(item.selection.endDate);

      var time_difference = secondDate.getTime() - startDate.getTime();
      var days_difference = time_difference / (1000 * 60 * 60 * 24);

      const diffDays =
        Math.round(Math.abs((startDate - secondDate) / oneDay)) + 1;

      const formString = diffDays == 1 ? "1 day" : `${diffDays} days`;

      const ignoreDuplicate = priceDate.DefaultRange;

      ignoreDuplicate.push({ name: formString, id: diffDays });

      setPriceDate({
        ...priceDate,
        DateRange: [{ name: formString, id: diffDays }],
        DefaultRange: getUniquerange(ignoreDuplicate, "id").sort(
          (a, b) => parseFloat(a.id) - parseFloat(b.id)
        ),
        status: true,
        isUserRangeSelected: true,
        previewStartDate: moment(item.selection.startDate).format("YYYY-MM-DD"),
        previewEndDate: moment(item.selection.endDate).format("YYYY-MM-DD"),
        days_difference: days_difference,
        sameDay: days_difference <= 0 && new Date() > item.selection.startDate,
        endTime:
          days_difference <= 0
            ? constructEndTimeFormat()
            : `${
                currentdate.getHours() < 10
                  ? `0${currentdate.getHours()}`
                  : currentdate.getHours()
              }:${
                currentdate.getMinutes() < 10
                  ? `0${currentdate.getMinutes()}`
                  : currentdate.getMinutes()
              }`,

        startTime: `${
          currentdate.getHours() < 10
            ? `0${currentdate.getHours()}`
            : currentdate.getHours()
        }:${
          currentdate.getMinutes() < 10
            ? `0${currentdate.getMinutes()}`
            : currentdate.getMinutes()
        }`,
      });
    },
    [priceDateRange, priceDate]
  );

  const constructEndTimeFormat = () => {
    const defaultMinute = 15;

    let timeFormat = "";

    if (currentdate.getMinutes() + defaultMinute > 59) {
      if (currentdate.getHours() + 1 > 23) {
        timeFormat += `${
          currentdate.getHours() < 10
            ? `0${currentdate.getHours()}`
            : currentdate.getHours()
        }:${
          currentdate.getMinutes() < 10
            ? `0${currentdate.getMinutes()}`
            : currentdate.getMinutes()
        }`;
      } else {
        timeFormat += `${
          currentdate.getHours() + 1 < 10
            ? `0${currentdate.getHours() + 1}`
            : currentdate.getHours() + 1
        }:${
          currentdate.getMinutes() < 10
            ? `0${currentdate.getMinutes()}`
            : currentdate.getMinutes()
        }`;
      }
    } else {
      timeFormat += `${
        currentdate.getHours() < 10
          ? `0${currentdate.getHours()}`
          : currentdate.getHours()
      }:${currentdate.getMinutes() + 15}`;
    }

    return timeFormat;
  };

  const handleStartTimeChange = useCallback(
    (value) => {
      if (value == "") {
        setPriceDate({
          ...priceDate,
          isStartTimeError: true,
          startTime: value,
        });
      } else {
        setPriceDate({
          ...priceDate,
          startTime: value,
        });
      }
    },
    [priceDate]
  );

  const handleEndTimeChange = useCallback(
    (value) => {
      if (value == "") {
        setPriceDate({
          ...priceDate,
          isEndTimeError: true,
          endTime: value,
        });
      } else {
        setPriceDate({
          ...priceDate,
          endTime: value,
        });
      }
    },
    [priceDate]
  );

  useEffect(() => {
    const startTime =
      priceDate.startTime != "" ? String(priceDate.startTime).split(":") : "";
    const startHour =
      priceDate.startTime != "" ? startTime[0].replace(/^0/, "") : "";
    const startMinute =
      priceDate.startTime != "" ? startTime[1].replace(/^0/, "") : "";

    if (priceDate.sameDay && priceDate.isUserRangeSelected) {
      if (Number(startHour) < currentdate.getHours()) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: true,
        });
      } else if (
        Number(startHour) == currentdate.getHours() &&
        Number(startMinute) < currentdate.getMinutes()
      ) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: true,
        });
      } else if (
        Number(startHour) == currentdate.getHours() &&
        Number(startMinute) > currentdate.getMinutes()
      ) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: false,
        });
      } else if (Number(startHour) > currentdate.getHours()) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: false,
        });
      }
    } else if (
      !priceDate.isUserRangeSelected ||
      (priceDate.days_difference > 0 && priceDate.days_difference != null)
    ) {
      if (Number(startHour) < currentdate.getHours()) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: true,
        });
      } else if (
        Number(startHour) == currentdate.getHours() &&
        Number(startMinute) < currentdate.getMinutes()
      ) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: true,
        });
      } else if (
        Number(startHour) == currentdate.getHours() &&
        Number(startMinute) > currentdate.getMinutes()
      ) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: false,
        });
      } else if (Number(startHour) > currentdate.getHours()) {
        setPriceDate({
          ...priceDate,
          isStartTimeError: false,
        });
      }
    } else {
      setPriceDate({
        ...priceDate,
        isStartTimeError: false,
      });
    }
  }, [priceDate.sameDay, priceDate.startTime]);

  useEffect(() => {
    const endTime =
      priceDate.endTime != "" ? String(priceDate.endTime).split(":") : "";
    const endHour = priceDate.endTime != "" ? endTime[0].replace(/^0/, "") : "";
    const endMinute =
      priceDate.endTime != "" ? endTime[1].replace(/^0/, "") : "";

    if (priceDate.days_difference === 0 && priceDate.days_difference != null) {
      if (Number(endHour) < currentdate.getHours()) {
        setPriceDate({
          ...priceDate,
          isEndTimeError: true,
        });
      } else if (
        Number(endHour) == currentdate.getHours() &&
        Number(endMinute) < currentdate.getMinutes() + 15
      ) {
        setPriceDate({
          ...priceDate,
          isEndTimeError: true,
        });
      } else if (
        Number(endHour) == currentdate.getHours() &&
        Number(endMinute) > currentdate.getMinutes() + 15
      ) {
        setPriceDate({
          ...priceDate,
          isEndTimeError: false,
        });
      } else if (Number(endHour) > currentdate.getHours()) {
        setPriceDate({
          ...priceDate,
          isEndTimeError: false,
        });
      }
    } else {
      setPriceDate({
        ...priceDate,
        isEndTimeError: false,
      });
    }
  }, [priceDate.sameDay, priceDate.endTime]);

  const handleTermsChange = () => {
    setTermsAgreed((prev) => !prev);
  };

  const createVoucher = async (
    tokenId,
    uri,
    minPrice = 0,
    royaltyAddress = "",
    royaltyPercentage = ""
  ) => {
    console.log(
      "Voucher data",
      tokenId,
      uri,
      minPrice,
      royaltyAddress,
      royaltyPercentage
    );
    const providerWeb3 = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );
    // Prompt user for account connections
    await providerWeb3.send("eth_requestAccounts", []);
    const minterAddress = providerWeb3.getSigner();
    console.log("minterAddress", minterAddress);
    let lazy_contract_address = contract_address;
    const lazyMinter = new ethers.Contract(
      lazy_contract_address,
      LazyNft.abi,
      minterAddress
    );

    const voucher = {
      tokenId,
      uri,
      minPrice,
      royaltyAddress,
      royaltyPercentage,
    };

    const chainId = await lazyMinter.getChainID();
    console.log("chainId", chainId);

    let domain = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: lazyMinter.address,
      chainId,
    };
    const types = {
      NFTVoucher: [
        { name: "tokenId", type: "uint256" },
        { name: "minPrice", type: "uint256" },
        { name: "uri", type: "string" },
        { name: "royaltyAddress", type: "address" },
        { name: "royaltyPercentage", type: "uint256" },
      ],
    };
    console.log("types", types);
    const signature = await minterAddress._signTypedData(
      domain,
      types,
      voucher
    );
    console.log("signature", signature);
    return {
      ...voucher,
      signature,
    };
  };

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    getSuccessNotificationMessage("Wallet address copied");
  };

  return (
    <>
      {props.singleNftImage.loading ? (
        <ListingItemLoader />
      ) : props.singleNftImage.data.nft_collection_item &&
        props.singleNftImage.data.nft_collection_item.is_listed == 1 ? (
        redirect(props.singleNftImage.data.nft_collection.blockchain_type)
      ) : props.singleNftImage.data.nft_collection_item.recent_buyer_id ==
        userAuthStatus.userId ? (
        <>
          <div className="listing-item-sec">
            <div className="listing-item-header-bg">
              <Container>
                <div className="listing-item-header-sec">
                  <div className="item-list-main-back-btn">
                    <Link
                      to={`/assets/${props.singleNftImage.data.nft_collection.blockchain_type}/${nft_collection_unique_id}/${props.singleNftImage.data.nft_collection.contract_address}/${props.singleNftImage.data.nft_collection_item.token_id}`}
                    >
                      <Image
                        onClick={() => navigate(-1)}
                        src={
                          window.location.origin +
                          "/images/landing-page/icons/back-arrow.svg"
                        }
                        className="back-arrow-icon"
                      />
                    </Link>
                  </div>
                  <div className="listing-item-title-sec">
                    <Image
                      src={
                        props.singleNftImage.data.nft_collection.picture != ""
                          ? props.singleNftImage.data.nft_collection.picture
                          : configuration.get("configData.item_placholder")
                      }
                      className="listing-item-title-img"
                    />
                    <div className="listing-item-title-info">
                      <h5>{props.singleNftImage.data.nft_collection.name}</h5>
                      <h3>
                        {props.singleNftImage.data.nft_collection_item.name}
                      </h3>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container>
              <Row>
                <Col md={6}>
                  <div className="listing-item-form-sec">
                    <h2>List item for sale</h2>
                    <Form className="listing-item-form">
                      <div className="listing-item-form-box">
                        <FixedPriceListing
                          priceSelect={priceSelect}
                          handlePriceSelect={handlePriceSelect}
                          priceDate={priceDate}
                          handlePriceDateOpen={handlePriceDateOpen}
                          setPriceDate={setPriceDate}
                          handlePriceRangeDropdown={handlePriceRangeDropdown}
                          priceDateRange={priceDateRange}
                          setPriceDateRange={setPriceDateRange}
                          handleStartTimeChange={handleStartTimeChange}
                          handleEndTimeChange={handleEndTimeChange}
                          handlePriceDateClose={handlePriceDateClose}
                          handleSellDataChanges={handleSellDataChanges}
                          handleRangeSelection={handleRangeSelection}
                          sellData={sellData}
                          nft_collection={
                            props.singleNftImage.data.nft_collection
                          }
                        />
                        <div className="listing-item-fees-sec">
                          <div className="listing-item-fees-header">
                            <Form.Label>Fees</Form.Label>
                            <Image
                              src={
                                window.location.origin +
                                "/images/listing-item/icons/info-icon.svg"
                              }
                              className="info-icon"
                            />
                          </div>
                          <div className="listing-item-fees-footer">
                            <h5>Service Fee</h5>
                            <p>
                              {configuration.get("configData.admin_commission")}
                              %
                            </p>
                          </div>
                        </div>
                        {props.singleNftImage.data.nft_collection
                          .royalty_percentage > 0 ||
                        props.singleNftImage.data.nft_collection_item
                          .royalty_percentage > 0 ? (
                          <div className="listing-item-fees-sec">
                            <div className="listing-item-fees-header">
                              <Form.Label>Royalty</Form.Label>
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/listing-item/icons/info-icon.svg"
                                }
                                className="info-icon"
                              />
                            </div>
                            <div className="listing-item-fees-footer">
                              <h5>Royalty Percentag</h5>
                              <p>
                                {props.singleNftImage.data.nft_collection
                                  .royalty_type == 1
                                  ? props.singleNftImage.data.nft_collection
                                      .royalty_percentage
                                  : props.singleNftImage.data
                                      .nft_collection_item
                                      .royalty_percentage}{" "}
                                %
                              </p>
                            </div>
                            <div className="listing-item-fees-footer">
                              <h5>Royalty Wallet</h5>
                              {props.singleNftImage.data.nft_collection
                                .royalty_type == 1 ? (
                                <p
                                  onClick={() =>
                                    copyToClipboard(
                                      props.singleNftImage.data.nft_collection
                                        .royalty_wallet_address
                                    )
                                  }
                                >
                                  {props.singleNftImage.data.nft_collection.royalty_wallet_address.substr(
                                    0,
                                    5
                                  )}
                                  ...
                                  {props.singleNftImage.data.nft_collection.royalty_wallet_address.substr(
                                    props.singleNftImage.data.nft_collection
                                      .royalty_wallet_address.length - 4
                                  )}
                                </p>
                              ) : (
                                <p
                                  onClick={() =>
                                    copyToClipboard(
                                      props.singleNftImage.data
                                        .nft_collection_item
                                        .royalty_wallet_address
                                    )
                                  }
                                >
                                  {props.singleNftImage.data.nft_collection_item.royalty_wallet_address.substr(
                                    0,
                                    5
                                  )}
                                  ...
                                  {props.singleNftImage.data.nft_collection_item.royalty_wallet_address.substr(
                                    props.singleNftImage.data
                                      .nft_collection_item
                                      .royalty_wallet_address.length - 4
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : null}
                        <Form.Group>
                          <div className="agree-terms-sec">
                            <Form.Check
                              inline
                              name="group1"
                              id="my-check"
                              type="checkbox"
                              checked={termsAgreed}
                              onChange={() => handleTermsChange()}
                            />
                            <p>
                              I agree to the following{" "}
                              <Link to="/page/terms">terms & conditions</Link>
                            </p>
                          </div>
                        </Form.Group>
                        <div className="listing-item-complete-btn-sec">
                          <Button
                            className="default-btn"
                            disabled={
                              props.sellNftItem.buttonDisable ||
                              sellData.amount == 0 ||
                              priceDate.isStartTimeError ||
                              priceDate.isEndTimeError ||
                              !termsAgreed
                            }
                            onClick={handleCompleteListingModalOpen}
                          >
                            {props.sellNftItem.loadingButtonContent == ""
                              ? "Complete Listing"
                              : props.sellNftItem.loadingButtonContent}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="listing-item-preview-card">
                    <div className="listing-item-card">
                      <div className="listing-item-img-sec">
                        <Image
                          src={
                            props.singleNftImage.data.nft_collection_item
                              .picture != ""
                              ? props.singleNftImage.data.nft_collection_item
                                  .picture
                              : configuration.get("configData.item_placholder")
                          }
                          className="listing-item-img"
                        />
                      </div>
                      <div className="listing-item-info">
                        <div className="listing-item-details">
                          <h6>
                            {props.singleNftImage.data.nft_collection_item.name}
                          </h6>
                          <h5>
                            {props.singleNftImage.data.nft_collection.name}
                          </h5>
                        </div>
                        <div className="listing-item-footer-details">
                          <h4>
                            <span>
                              {sellData.amount == ""
                                ? 0
                                : parseFloat(sellData.amount, 10).toFixed(
                                    3
                                  )}{" "}
                              {priceSelect[0]}{" "}
                              {
                                props.singleNftImage.data.nft_collection_item
                                  .blockchain_type
                              }
                            </span>
                            <span>
                              <Image
                                src={
                                  CHAIN_INFO[
                                    SupportedChainIdBySymbol[
                                      props.singleNftImage.data.nft_collection
                                        .blockchain_type
                                    ]
                                  ].logo
                                }
                                className="eth-icon"
                              />
                            </span>
                          </h4>
                          <Button type="button" className="heart-btn">
                            <Image
                              src={
                                CHAIN_INFO[
                                  SupportedChainIdBySymbol[
                                    props.singleNftImage.data.nft_collection
                                      .blockchain_type
                                  ]
                                ].logo
                              }
                              className="heart-icon"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {completeListingModal ? (
            <CompleteListingModal
              completeListingModal={completeListingModal}
              closeCompleteListingModal={closeCompleteListingModal}
              setCompleteListingModal={setCompleteListingModal}
              handleCompleteListingClose={handleCompleteListingClose}
              singleNftImage={props.singleNftImage}
              sellData={sellData}
              getApprovalToSale={getApprovalToSale}
              itemListed={itemListed}
              setItemListed={setItemListed}
              signData={signData}
              setSignData={setSignData}
              loadingButtonContent={loadingButtonContent}
              makeOfferToSale={makeOfferToSale}
              activeAccordion={activeListingAccordion}
              toggleAccordion={toggleActiveAccordion}
              priceSelect={priceSelect}
              userAuthStatus={userAuthStatus}
            />
          ) : null}
        </>
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  singleNftImage: state.nfts.singleItems,
  sellNftItem: state.nfts.sellNftItem,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ListingItemIndex);
