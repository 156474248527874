import React, { useState, useContext, useEffect, useRef } from "react";
import {
  FormCheck,
  Row,
  Col,
  FormGroup,
  FormControl,
  InputGroup,
  Image,
  Button,
} from "react-bootstrap";
import "./Settings.css";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import SettingsIndex from "./SettingsIndex";
import EditProfileLoader from "../loaders/EditProfileLoader";
import { authContext } from "../authprovider/AuthProvider";
import NoDataFound from "../NoDataFound/NoDataFound";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import {
  fetchUserDetailsStart,
  updateUserDetailsStart,
} from "../store/actions/UserAction";
import Dropzone from "react-dropzone";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";

const SettingsProfile = (props) => {
  //  const { auth } = useContext(authContext);

  const formRef = useRef(null);

  const [profileData, setProfileData] = useState({
    name: "",
    about: "",
    email: "",
    mobile: "",
    country_code: "",
    website_link: "",
    discord_link: "",
    twitter_link: "",
    instagram_link: "",
    medium_link: "",
    telegram_link: "",
    wallet_address: "",
  });

  const [logoImage, setLogoImage] = useState({
    file: "",
    preview_image: "",
  });

  const [coverImage, setCoverImage] = useState({
    file: "",
    preview_image: "",
  });

  const [termsAgreed, setTermsAgreed] = useState(false);

  const editProfileSchema = Yup.object().shape({
    picture: Yup.mixed(),
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setLogoImage({
        ...logoImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddCoverImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setCoverImage({
        ...coverImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");

      const newData = {
        ...values,
        cover: coverImage.file,
        mobile: mobile,
      };
      props.dispatch(
        updateUserDetailsStart({
          ...newData,
          user_unique_id: props.profile.data.user.user_unique_id,
        })
      );
    }
  };

  const handleProfileFieldChanges = (event) => {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (
      !props.profile.loading &&
      Object.keys(props.profile.data).length > 0 &&
      props.profile.data.user
    ) {
      setProfileData({
        ...profileData,
        name: props.profile.data.user.name,
        about:
          props.profile.data.user.about != null
            ? props.profile.data.user.about
            : "",
        email: props.profile.data.user.email,
        mobile: "+" + props.profile.data.user.country_code + props.profile.data.user.mobile,
        country_code: props.profile.data.user.country_code ? props.profile.data.user.country_code : "US",
        website_link: props.profile.data.user.website_link,
        discord_link: props.profile.data.user.discord_link,
        twitter_link: props.profile.data.user.twitter_link,
        instagram_link: props.profile.data.user.instagram_link,
        medium_link: props.profile.data.user.medium_link,
        telegram_link: props.profile.data.user.telegram_link,
        wallet_address: props.profile.data.user.wallet_address,
      });
      setLogoImage({
        ...logoImage,
        preview_image: props.profile.data.user.picture,
      });
      setCoverImage({
        ...coverImage,
        preview_image: props.profile.data.user.cover,
      });
    }
  }, [props.profile.data]);

  useEffect(() => {
    if (
      !props.updateUserDetails.loading &&
      Object.keys(props.updateUserDetails.data).length > 0
    ) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, [props.updateUserDetails.loading]);

  const handleTermsChange = () => {
    setTermsAgreed((prev) => !prev);
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Incorrect format");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  const onCopy = () =>{
    getSuccessNotificationMessage("Wallet address copied");
  }

  return (
    <>
      <SettingsIndex>
        <div className="settings-box">
          {props.profile.loading ? (
            <div className="profile-sec">
              <Row>
                <Col md={6}>
                  <Skeleton height={500} />
                </Col>
                <Col md={6}>
                  <Skeleton height={250} />
                </Col>
              </Row>
            </div>
          ) : props.profile.data.user ? (
            <div className="profile-sec">
              <Formik
                initialValues={{
                  ...profileData,
                  picture: logoImage.file,
                  cover: coverImage.file,
                }}
                className="w-100"
                validationSchema={editProfileSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
                innerRef={formRef}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Form className="create-collection-form">
                    <Row>
                      <Col md={6}>
                        <h4 className="setting-title">Edit Profile</h4>

                        <div class="form-group mb-3">
                          <h6>
                            Name <span class="text-danger">*</span>
                          </h6>
                          <div class="controls">
                            <Field
                              type="text"
                              name="name"
                              placeholder="Display name"
                              className={`form-control ${touched.name && errors.name ? "is-invalid" : ""
                                }`}
                              value={profileData.name}
                              onChange={(event) =>
                                handleProfileFieldChanges(event)
                              }
                            />
                            <ErrorMessage
                              component="div"
                              name="name"
                              className="invalid-feedback mt-3"
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <h6>Email <span class="text-danger">*</span></h6>
                          <Field
                            type="email"
                            name="email"
                            className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                              }`}
                            placeholder="Email address"
                            value={profileData.email}
                            onChange={(event) =>
                              handleProfileFieldChanges(event)
                            }
                          />
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                        <div className="form-group mb-3">
                          <h6>Mobile <span class="text-danger">*</span> </h6>
                          <FormGroup>
                            <PhoneInput
                              defaultCountry={values.country_code}
                              placeholder="Enter phone number"
                              value={values.mobile}
                              onChange={(value) =>
                                setProfileData({
                                  ...profileData,
                                  mobile: value
                                })
                              }
                              onBlur={() => validateMobileNumber(values.mobile)}
                              international
                              countryCallingCodeEditable={false}
                              className={`${touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                                }`}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="mobile"
                              className="text-danger small"
                            />
                          </FormGroup>
                        </div>
                        <div class="form-group mb-3">
                          <h6>Description</h6>
                          <div class="controls">
                            <textarea
                              type="text"
                              name="about"
                              placeholder="Description"
                              className={`form-control`}
                              value={profileData.description}
                              onChange={(event) =>
                                handleProfileFieldChanges(event)
                              }
                            />
                          </div>
                        </div>
                        <div className="profile-link">
                          <h4 className="setting-title">Links</h4>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2">
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/icons/globe.svg"
                                }
                              />
                            </InputGroup.Text>
                            <FormControl
                              placeholder="Website URL"
                              name="website_link"
                              value={profileData.website_link}
                              onChange={(event) =>
                                handleProfileFieldChanges(event)
                              }
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2">
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/icons/twitter.svg"
                                }
                              />
                            </InputGroup.Text>
                            <FormControl
                              placeholder="Twitter account URL"
                              name="twitter_link"
                              value={profileData.twitter_link}
                              onChange={(event) =>
                                handleProfileFieldChanges(event)
                              }
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2">
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/icons/instagram.svg"
                                }
                              />
                            </InputGroup.Text>
                            <FormControl
                              placeholder="Instagram account URL"
                              name="instagram_link"
                              value={profileData.instagram_link}
                              onChange={(event) =>
                                handleProfileFieldChanges(event)
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="private-key-content mt-4">
                          <h6>Wallet Address</h6>
                          <div className="private-key-box">
                            <p>{profileData.wallet_address}</p>
                            <div className="private-key-btn">
                              <CopyToClipboard text={profileData.wallet_address} onCopy={() => onCopy()}>
                                <Button>
                                  <Image
                                    src={window.location.origin + "/images/auth/copy.svg"}
                                  />
                                  <span>Copy</span>
                                </Button>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                        <FormGroup
                          className="mb-3 cutom-checkbox"
                          controlId="formBasicCheckbox"
                        >
                          <FormCheck
                            type="checkbox"
                            checked={termsAgreed}
                            onChange={() => handleTermsChange()}
                          />
                          <div className="checkbox-text">
                            I agree to{" "}
                            <Link to="/page/terms" target="_blank">
                              terms & conditions
                            </Link>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <div className="profile-right-sec">
                          <div className="">
                            <div className="create-collection-logo-img">
                              <h4 className="setting-title">Profile Image*</h4>

                              <div className="create-collection-logo-item">
                                <Dropzone onDrop={(acceptedFiles) => {
                                  setLogoImage(Object.assign(acceptedFiles[0], {
                                    preview_image: URL.createObjectURL(acceptedFiles[0]),
                                    file: acceptedFiles[0]
                                  }));
                                }}
                                  accept={{ 'image/jpg': ['.jpeg', '.jpg', '.png'] }}
                                  name="logoImage" multiple={false}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                      <input
                                        {...getInputProps()}
                                        accept="image/*"
                                        id="picture"
                                      />

                                      <div className="create-collection-logo-icon-card">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/images/create-collection/icons/plus-icon.svg"
                                          }
                                          className="create-collection-logo-icon"
                                        />
                                      </div>
                                      {logoImage.preview_image != "" && (
                                        <div className="preview-image">
                                          <img
                                            src={logoImage.preview_image}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Dropzone>

                                <div className="create-collection-external-link">
                                  <div className="create-collection-note">
                                    <p>
                                      Upload your profile image ( minimum 350 x 350 pixels recommended)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="create-collection-banner-img mt-4">
                              <h4 className="setting-title">Banner Image*</h4>
                              <div className="create-collection-external-link">
                                <div className="create-collection-note">
                                  <p>
                                    Your profile’s cover image (minimum 1920 × 142 pixels recommended)
                                  </p>
                                </div>
                              </div>
                              <div className="create-collection-banner-item">
                                <Dropzone onDrop={(acceptedFiles) => {
                                  setCoverImage(Object.assign(acceptedFiles[0], {
                                    preview_image: URL.createObjectURL(acceptedFiles[0]),
                                    file: acceptedFiles[0]
                                  }));
                                }}
                                  accept={{ 'image/jpg': ['.jpeg', '.jpg', '.png'] }}
                                  name="coverImage" multiple={false}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                      <input
                                        {...getInputProps()}
                                        accept="image/*"
                                        id="coverImage"
                                      />

                                      <div className="create-collection-banner-icon-card">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/images/create-collection/icons/plus-icon.svg"
                                          }
                                          className="create-collection-banner-icon"
                                        />
                                      </div>
                                      {coverImage.preview_image != "" && (
                                        <div className="preview-image-banner">
                                          <img
                                            src={coverImage.preview_image}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="profile-btn text-center">
                      <button
                        type="submit"
                        className="default-btn border-radius-5 mt-4"
                        disabled={
                          props.updateUserDetails.buttonDisable || !termsAgreed
                        }
                      >
                        {props.updateUserDetails.loadingButtonContent != ""
                          ? props.updateUserDetails.loadingButtonContent
                          : "SAVE CHANGES"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
      </SettingsIndex>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  updateUserDetails: state.users.updateUserDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SettingsProfile);
