import {
  IMPORT_SOCIAL_POSTS_START,
  IMPORT_SOCIAL_POSTS_SUCCESS,
  IMPORT_SOCIAL_POSTS_FAILURE,
  IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
  IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_SUCCESS,
  IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_FAILURE,
  MORE_IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
  IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_START,
  IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_SUCCESS,
  IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_FAILURE,
  POST_FILES_VIEW_BASE64_START,
  POST_FILES_VIEW_BASE64_SUCCESS,
  POST_FILES_VIEW_BASE64_FAILURE,
  CREATE_IMPORTED_POST_TO_NFT_START,
  CREATE_IMPORTED_POST_TO_NFT_SUCCESS,
  CREATE_IMPORTED_POST_TO_NFT_FAILURE,
} from './ActionConstant';

export function importSocialPostsStart(data) {
  return {
    type: IMPORT_SOCIAL_POSTS_START,
    data,
  };
}

export function importSocialPostsSuccess(data) {
  return {
    type: IMPORT_SOCIAL_POSTS_SUCCESS,
    data,
  };
}

export function importSocialPostsFailure(error) {
  return {
    type: IMPORT_SOCIAL_POSTS_FAILURE,
    error,
  };
}

export function importedSocialPostsListForOwnerStart(data) {
  return {
    type: IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
    data,
  };
}

export function importedSocialPostsListForOwnerSuccess(data) {
  return {
    type: IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_SUCCESS,
    data,
  };
}

export function importedSocialPostsListForOwnerFailure(error) {
  return {
    type: IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_FAILURE,
    error,
  };
}

export function moreImportedSocialPostsListForOwnerStart(data) {
  return {
    type: MORE_IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
    data,
  }
}

// Social post single view
export function importedSocialPostViewStart(data) {
  return {
    type: IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_START,
    data,
  }
}

export function importedSocialPostViewSuccess(data) {
  return {
    type: IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_SUCCESS,
    data,
  }
}

export function importedSocialPostViewFailure(error) {
  return {
    type: IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_FAILURE,
    error,
  }
}

// post files base64 conver
export function postFilesViewBase64Start(data) {
  return {
    type: POST_FILES_VIEW_BASE64_START,
    data,
  }
}

export function postFilesViewBase64Success(data) {
  return {
    type: POST_FILES_VIEW_BASE64_SUCCESS,
    data,
  }
}

export function postFilesViewBase64Failure(error) {
  return {
    type: POST_FILES_VIEW_BASE64_FAILURE,
    error,
  }
}

//Save post to nft
export function createImportedPostToNftStart(data) {
  return {
    type: CREATE_IMPORTED_POST_TO_NFT_START,
    data,
  }
}

export function createImportedPostToNftSuccess(data) {
  return {
    type: CREATE_IMPORTED_POST_TO_NFT_SUCCESS,
    data,
  }
}

export function createImportedPostToNftFailure(error) {
  return {
    type: CREATE_IMPORTED_POST_TO_NFT_FAILURE,
    error,
  }
}