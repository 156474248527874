import React, { useState, useContext, useEffect } from "react";
import {
  Nav,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form,
  InputGroup,
  Image,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SettingsIndex from "./SettingsIndex";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { connect } from "react-redux";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/ToastNotification";
import {
  fetchWalletDetailsStart,
  fetchMoreWalletDetailsStart,
  fetchUserCardListStart,
  walletPaymentByStripeStart,
} from "../store/actions/WalletAction";
import InfiniteScroll from "react-infinite-scroll-component";
import configuration from "react-global-configuration";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import CopyToClipboard from "react-copy-to-clipboard";
import SingleCard from "./SingleCard";
import AddCardModal from "./AddCardModal";
import NoWalletDetailsFound from "../NoDataFound/NoWalletDetailsFound";

const SettingsWallet = (props) => {
  // const { auth } = useContext(authContext);

  const [showAddMoney, setShowAddMoney] = useState(0);

  const [addCard, setAddCard] = useState(false);
  const [skipFirst, setSkipFirst] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [amount, setAmount] = useState("");

  const closeAddCardModal = () => {
    setAddCard(false);
  };

  useEffect(() => {
    props.dispatch(
      fetchWalletDetailsStart({
        skip: 0,
        take: 12,
      })
    );
    props.dispatch(fetchUserCardListStart());
  }, []);

  const fetchMoreWalletDetails = () => {
    props.dispatch(
      fetchMoreWalletDetailsStart({
        skip: props.walletDetails.data.wallet_payments.length,
        take: 12,
      })
    );
  };

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage("Transaction Id Copied"));
  };

  useEffect(() => {
    if (
      !skipFirst &&
      !props.userCardList.loading &&
      Object.keys(props.userCardList.data).length > 0
    ) {
      if (
        props.userCardList.data.user_cards &&
        props.userCardList.data.user_cards.length > 0
      ) {
        props.userCardList.data.user_cards.map((card, i) => {
          if (card.is_default === 1) setSelectedCard(card.user_card_id);
        });
      } else {
        setSelectedCard(null);
      }
    }
  }, [props.userCardList]);

  useEffect(() => {
    if (
      !skipFirst &&
      !props.addUserCard.loading &&
      Object.keys(props.addUserCard.data).length > 0
    ) {
      closeAddCardModal();
      props.dispatch(fetchUserCardListStart());
    }
  }, [props.addUserCard]);

  const addMoney = (e) => {
    e.preventDefault();
    if (amount && selectedCard) {
      props.dispatch(
        walletPaymentByStripeStart({
          amount: amount,
          card_id: selectedCard,
        })
      );
    } else if (!amount) {
      props.dispatch(getErrorNotificationMessage("Please enter the amount"));
    } else if (!selectedCard) {
      props.dispatch(
        getErrorNotificationMessage("Please add or select a card")
      );
    }
  };

  useEffect(() => {
    if (
      !skipFirst &&
      !props.walletPaymentByStripe.loading &&
      Object.keys(props.walletPaymentByStripe.data).length > 0
    ) {
      props.dispatch(
        fetchWalletDetailsStart({
          skip: 0,
          take: 12,
        })
      );
      setShowAddMoney(0);
      setAmount("");
      // alert('success');
    }
    setSkipFirst(false);
  }, [props.walletPaymentByStripe]);

  return (
    <>
      <SettingsIndex>
        <div className="settings-box">
          <Row>
            <Col md={12} xl={6} lg={6} className="resp-mrg-btm-md">
              <div className="setting-wallet-left-sec">
                <h4 className="setting-title">Current Wallet Balance</h4>
                <Form className="create-collection-form">
                  <div className="wallet-box">
                    <h4>
                      {!props.walletDetails.loading &&
                      props.walletDetails.data.wallet
                        ? props.walletDetails.data.wallet.remaining_formatted
                        : configuration.get("configData.currency") + " 0.00"}
                    </h4>
                    <div className="wallet-add-btn mt-2">
                      <Link
                        onClick={() => setShowAddMoney(!showAddMoney)}
                        className="default-outline-btn"
                      >
                        <span>
                          <Image
                            className="add-img"
                            src={
                              window.location.origin + "/images/icons/add.svg"
                            }
                          />
                        </span>
                        Add Money
                      </Link>
                    </div>
                  </div>
                  {showAddMoney ? (
                    <>
                      <div className="select-card-box mt-5">
                        <h4 className="setting-title">Select Card*</h4>
                        {props.userCardList.loading ? (
                          <CommonCenterLoader />
                        ) : (
                          <>
                            <Link
                              onClick={() => setAddCard(true)}
                              className="default-outline-btn"
                            >
                              <span>
                                <Image
                                  className="add-img"
                                  src={
                                    window.location.origin +
                                    "/images/icons/add.svg"
                                  }
                                />
                              </span>
                              Add New Card
                            </Link>
                            <div className="select-radio-box">
                              {props.userCardList.data.user_cards &&
                                props.userCardList.data.user_cards.map(
                                  (card, i) => (
                                    <SingleCard
                                      card={card}
                                      key={i}
                                      selectedCard={selectedCard}
                                      setSelectedCard={setSelectedCard}
                                    />
                                  )
                                )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="add-money-box profile-link mt-5">
                        <h4 className="setting-title">Add Money*</h4>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon2">
                            <Image
                              src={
                                window.location.origin +
                                "/images/icons/dollar.svg"
                              }
                            />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Enter amount"
                            type="number"
                            name="amount"
                            min="0.00"
                            step="any"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className="text-center">
                        <Button
                          type="submit"
                          className="default-btn"
                          onClick={addMoney}
                          disabled={props.walletPaymentByStripe.buttonDisable}
                        >
                          {props.walletPaymentByStripe.loadingButtonContent
                            ? props.walletPaymentByStripe.loadingButtonContent
                            : "Add Money"}
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Form>
              </div>
            </Col>
            <Col md={12} xl={6} lg={6}>
              <div className="settings-wallet-history-sec">
                <h4 className="setting-title">Wallet History</h4>
                {props.walletDetails.loading ? (
                  <CommonCenterLoader />
                ) : props.walletDetails.data.wallet_payments &&
                  props.walletDetails.data.wallet_payments.length > 0 ? (
                  <div className="wallet-history-table">
                    <InfiniteScroll
                      dataLength={
                        props.walletDetails.data.wallet_payments.length
                      }
                      next={fetchMoreWalletDetails}
                      hasMore={
                        props.walletDetails.data.wallet_payments.length <
                        props.walletDetails.data.total
                      }
                      loader={<CommonCenterLoader />}
                    >
                      <Table size="md" responsive>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.walletDetails.data.wallet_payments.map(
                            (payment, i) => (
                              <tr key={i}>
                                <td>{payment.paid_date_formatted}</td>
                                <td className="wallet-history-id">
                                  <p>
                                    {payment.payment_id
                                      ? payment.payment_id
                                      : "-"}
                                  </p>{" "}
                                  <Button>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>{payment.payment_id}</Tooltip>
                                      }
                                    >
                                      <div className="transaction-id-sec">
                                        <CopyToClipboard
                                          text={payment.payment_id}
                                          onCopy={() => onCopy()}
                                        >
                                          <span>
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/images/icons/copy.svg"
                                              }
                                            />
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </OverlayTrigger>
                                  </Button>
                                </td>
                                <td>
                                  {payment.amount_type === "add" ? "+ " : "- "}
                                  {payment.requested_amount_formatted}
                                </td>
                                <td className="paid-color">
                                  {payment.message}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  </div>
                ) : (
                  <NoWalletDetailsFound />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </SettingsIndex>
      {addCard ? (
        <AddCardModal
          addCard={addCard}
          closeAddCardModal={closeAddCardModal}
          setAddCard={setAddCard}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  walletDetails: state.wallet.walletDetails,
  addUserCard: state.wallet.addUserCard,
  userCardList: state.wallet.userCardList,
  walletPaymentByStripe: state.wallet.walletPaymentByStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SettingsWallet);
