import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { twoStepAuthLoginStart, twoStepAuthResendCodeStart } from "../store/actions/UserAction";
import Countdown from "react-countdown";

const TwoStepAuthModal = (props) => {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(props.mobile);
  const [skipRender, setSkipRender] = useState(true);
  const [otp, setOTP] = useState("");
  const [time, setTime] = useState(Date.now());
  const [isTimer, setIsTimer] = useState(true);

  const handleChange = (otp) => setOTP(otp);

  const resendMobileVerificationCode = () => {
    props.dispatch(twoStepAuthResendCodeStart({
      email: props.email,
      mobile: mobile,
    }));
  }

  const verifyMobileCode = e => {
    e.preventDefault();
    props.dispatch(twoStepAuthLoginStart({
      email: props.email,
      mobile: mobile,
      mobile_verification_code: otp,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.twoStepAuthLogin.loading && Object.keys(props.twoStepAuthLogin.data).length > 0) {
      if (props.onboarding_step < 6) {
        navigate("/onboarding");
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.twoStepAuthLogin]);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsTimer(false);
      return <></>;
    } else {
      return (
        <div>
          Resend code in <span style={{ color: "#008fff" }}>{seconds}</span> s
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-center phone-number-verification"
        size="md"
        centered
        show={props.numberVerification}
        onHide={props.closeNumberVerificationModal}
      >
        {/* <Modal.Header closeButton>
            <Modal.Title>Add Properties</Modal.Title>
          </Modal.Header> */}
        <Modal.Body>
          <h4>Verfication You Phone Number</h4>
          <p>
            Enter the verification code sent to your phone to complete the login
          </p>
          <Button
            className="modal-close"
            onClick={() => props.closeNumberVerificationModal()}
          >
            <Image
              className="close-icon"
              src={
                window.location.origin +
                "/images/create-item/icons/modal-close.svg"
              }
            />
          </Button>
          <div className="phone-number-verification-sec">
            <Form onSubmit={verifyMobileCode}>
              <div className="verification-item">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  separator={<span>-</span>}
                />
              </div>
              {props.twoStepAuthLogin.error ?
                <div className="text-danger text-center mb-3">
                  {props.twoStepAuthLogin.error}
                </div>
                : null
              }
              <div className="verification-btn-sec">
                {isTimer ? (
                  <Countdown date={time + 60000} renderer={renderer} />
                ) : (
                  <Link
                    to="#"
                    className="resend-link"
                    onClick={e => {
                      if (props.resendTwoStepAuthCode.buttonDisable)
                        e.preventDefault()
                      else {
                        resendMobileVerificationCode()
                        setTime(Date.now());
                        setIsTimer(true);
                      }
                    }
                    }
                  >
                    {props.resendTwoStepAuthCode.loadingButtonContent ?
                      props.resendTwoStepAuthCode.loadingButtonContent
                      : "Resend"
                    }
                  </Link>
                )}
                <Button
                  type="submit"
                  className="default-btn"
                  disabled={!otp || otp.length < 6 || props.twoStepAuthLogin.buttonDisable}
                >
                  {props.twoStepAuthLogin.loadingButtonContent ?
                    props.twoStepAuthLogin.loadingButtonContent
                    : "Verify"
                  }
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  resendTwoStepAuthCode: state.users.resendTwoStepAuthCode,
  twoStepAuthLogin: state.users.twoStepAuthLogin,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TwoStepAuthModal);
