import React, { useState, useEffect } from "react";
import { Container, Image, Row, Nav, Col, Tab } from "react-bootstrap";
import "./SingleItem.css";
import { Link } from "react-router-dom";
import CollectionActivityCard from "./CollectionActivityCard";
import CollectionOffersCard from "./CollectionOffersCard";
import { connect } from "react-redux";
import { bidOfferNftItemListStart } from "../store/actions/NftAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import CollectionActivityTable from "./CollectionActivityTable";
import ActivityOfferLoader from "../loaders/ActivityOfferLoader";
import NoItemActivityFound from "../NoDataFound/NoItemActivityFound";

const ActivityOffersIndex = (props) => {
  useEffect(() => {
    props.dispatch(
      bidOfferNftItemListStart({
        nft_collection_unique_id: props.nft_collection_unique_id,
        nft_collection_item_token_id: props.nft_collection_item_token_id,
        skip: 0,
        take: 6,
      })
    );
  }, []);

  return (
    <>
      <div className="activity-offers-sec">
        <div className="activity-offers-tab-sec">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="justify-content-center">
              <Col sm={12}>
                <div className="activity-offers-tab-header-sec">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <div className="setting-header">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 27.176 22.391"
                          >
                            <path
                              id="Path_57934"
                              data-name="Path 57934"
                              d="M1.875,11.649H5.7L10.486,1.125l7.654,20.091,4.784-9.567H26.75"
                              transform="translate(-0.725 0.025)"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2.3"
                            />
                          </svg>
                          <span>Activity</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <div className="setting-header">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 37.235 21.386"
                          >
                            <g id="arrow" transform="translate(-2.285 -12.566)">
                              <path
                                id="Path_56747"
                                data-name="Path 56747"
                                d="M29.375,14.687H40.8v11.42"
                                transform="translate(-3.397 0)"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                              />
                              <path
                                id="Path_56748"
                                data-name="Path 56748"
                                d="M4.406,17.225,19.633,32.453,37.4,14.687"
                                transform="translate(0 0)"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                              />
                            </g>
                          </svg>
                          <span>Offers</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {/* <Link to="#" className="section-link">
                View All
              </Link> */}
                </div>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {props.nftItemsPaymentList.loading ? (
                      <ActivityOfferLoader />
                    ) : props.nftItemsPaymentList.data.nft_item_payments &&
                      props.nftItemsPaymentList.data.nft_item_payments.length >
                        0 ? (
                      <>
                        {props.nftItemsPaymentList.data.total > 6 && (
                          <div className="text-end">
                            <Link
                              to={`/activity/${props.nft_collection_unique_id}/${props.nft_collection_item_token_id}`}
                              className="section-link"
                            >
                              View All
                            </Link>
                          </div>
                        )}
                        <CollectionActivityTable
                          payments={
                            props.nftItemsPaymentList.data.nft_item_payments
                          }
                        />
                      </>
                    ) : (
                      <NoItemActivityFound />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {props.bidOfferNftItemList.data.nftItems.length > 0 ? (
                      <>
                        {props.bidOfferNftItemList.data.total > 6 && (
                          <div className="text-end">
                            <Link
                              to={`/offers/${props.nft_collection_unique_id}/${props.nft_collection_item_token_id}`}
                              className="section-link"
                            >
                              View All
                            </Link>
                          </div>
                        )}
                        <CollectionOffersCard
                          bidOfferNftItemLists={
                            props.bidOfferNftItemList.data.nftItems
                          }
                          singleNftImage={props.singleNftImage.data}
                          getCurrentOwner={props.getCurrentOwner}
                          cancelOfferSave={props.cancelOfferSave}
                          cancelOfferBtnContent={props.cancelOfferBtnContent}
                          setCancelOfferBtnContent={
                            props.setCancelOfferBtnContent
                          }
                          setAcceptOfferBtnContent={
                            props.setAcceptOfferBtnContent
                          }
                          acceptOfferBtnContent={props.acceptOfferBtnContent}
                          currentOwner={props.currentOwner}
                          approveOfferSave={props.approveOfferSave}
                        />
                      </>
                    ) : (
                      <NoDataFound />
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bidOfferNftItemList: state.nfts.bidOfferNftItemList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ActivityOffersIndex);
