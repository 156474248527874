import { combineReducers } from "redux";
import NftReducer from "./NftReducer";
import UserReducer from "./UserReducer";
import WalletReducer from "./WalletReducer";
import WalletAppReducer from "./WalletAppReducer";
import PageReducer from "./PageReducer";
import SocialPostReduce from './SocialPostReducer';

export default combineReducers({
  users: UserReducer,
  nfts: NftReducer,
  wallet: WalletReducer,
  walletApp: WalletAppReducer,
  page: PageReducer,
  socialPost: SocialPostReduce,
});
