import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSignAndApproveStart } from "../store/actions/UserAction";
import CustomAccordion from "../customComponents/CustomAccordion";
import { authContext } from "../authprovider/AuthProvider";
import { useTheme } from "@mui/material/styles";
import configuration from "react-global-configuration";

const CompleteListingModal = (props) => {

  const theme = useTheme();

  const { auth, library, getProviderSigner, price } = useContext(authContext);

  const handleApprove = () => {
    props.makeOfferToSale();
  };

  useEffect(() => {
    if (!props.sellNftItem.loading && props.sellNftItem.data.code == 119) {
      props.setItemListed(true);
    }
  }, [props.sellNftItem.data]);

  const [accordionData, setAccordionData] = useState(null);

  const getSign = async () => {

    props.setSignData({
      ...props.signData,
      loading: true,
      loadingContent: "Signing...",
    });
    if (props.userAuthStatus.userLoggedIn) {
      const txMessage = `
      Welcome to ${configuration.get("configData.site_name")}!
  
      Click to sign in and accept the ${configuration.get(
        "configData.site_name"
      )} Terms of Service: ${configuration.get("configData.frontend_url")}
  
        This request will not trigger a blockchain transaction or cost any gas fees.
  
        Your authentication status will reset after 24 hours.
  
        Wallet address:
        ${auth.accounts}`;
      let result = await getProviderSigner(txMessage);
      if (result) {
        props.dispatch(
          updateSignAndApproveStart({
            is_signed: 1,
            sign_signature: result,
          })
        );

        props.setSignData({
          ...props.signData,
          signature: result,
          status: true,
          loading: false,
          loadingContent: null,
        });
        props.toggleAccordion((prev) => String(Number(prev) + 1));
      } else {
        props.setSignData({
          ...props.signData,
          loading: false,
          loadingContent: null,
        });
      }
    } else {
      props.setSignData({
        ...props.signData,
        loading: false,
        loadingContent: null,
      });
    }
  };

  useEffect(() => {
    setAccordionData({
      activeAccordion: props.activeAccordion,
      accordions: [
        {
          name: "sign",
          heading: "Sign message",
          accordionContent:
            "Submit a transaction with your wallet to trade with this currency. This only needs to be done once.",
          showAccordionButton: true,
          onClickFunction: () => getSign(),
          accordionButtonDisable:
            props.updateSignApprove.loading ||
            props.signData.loading,
          accordionButtonContent:
            props.signData.loadingContent
              ? props.signData.loadingContent
              : "Sign",
          spinner: true,
          spinnerLoading: props.signData.loadingContent != null,
          isVisible:
            props.singleNftImage.data.nft_collection_item.is_user_signed == 0,
        },
        {
          name: "approve",
          heading: "Approve",
          accordionContent: "Approve Transaction.",
          showAccordionButton: true,
          onClickFunction: props.getApprovalToSale,
          accordionButtonDisable:
            props.sellNftItem.buttonDisable || props.loadingButtonContent != "",
          accordionButtonContent:
            props.sellNftItem.loadingButtonContent != "" ||
              props.loadingButtonContent != ""
              ? "Approving..."
              : "Approve",
          spinner: true,
          spinnerLoading: props.loadingButtonContent ? true : false,
          isVisible:
            props.singleNftImage.data.nft_collection_item
              .user_approved_status == 0,
        },
        {
          name: "offer",
          heading: "Complete Listing",
          accordionContent: "Complete Listing to sell the NFT.",
          showAccordionButton: true,
          onClickFunction: () => handleApprove(),
          accordionButtonDisable:
            props.sellNftItem.buttonDisable || props.loadingButtonContent != "",
          accordionButtonContent:
            props.sellNftItem.loadingButtonContent != "" ||
              props.loadingButtonContent != ""
              ? "Listing is under progress..."
              : "Complete Listing",
          spinner: true,
          spinnerLoading: props.loadingButtonContent != "",
          isVisible: true,
        },
      ],
    });
  }, [
    props.loadingButtonContent,
    props.activeAccordion,
    props.signData.loadingContent,
    props.sellNftItem,
  ]);

  return (
    <>
      <Modal
        className="modal-dialog-center complete-listing-modal"
        size="md"
        centered
        show={props.completeListingModal}
        onHide={props.closeCompleteListingModal}
      >
        {props.itemListed ? (
          props.singleNftImage.data.nft_collection_item && (
            <>
              <Modal.Body>
                <div className="accept-offer-content">
                  <h4>Congratulations your item is now listed for sale!</h4>
                  <div className="accept-offer-complete-preview-img-sec">
                    <Image
                      className="accept-offer-complete-preview-img"
                      src={
                        props.singleNftImage.data.nft_collection_item.picture !=
                          ""
                          ? props.singleNftImage.data.nft_collection_item.picture
                          : props.singleNftImage.data.nft_collection_item.picture
                      }
                    />
                  </div>
                  {props.singleNftImage.data.nft_collection.twitter_link !=
                    "" ||
                    props.singleNftImage.data.nft_collection.facebook_link !=
                    "" ||
                    props.singleNftImage.data.nft_collection.telegram_link !=
                    "" ||
                    props.singleNftImage.data.nft_collection.external_link !=
                    "" ? (
                    <div className="buy-nft-social-links-sec">
                      <h5>Links</h5>
                      <ul className="list-unstyled buy-nft-social-links">
                        {props.singleNftImage.data.nft_collection.twitter_link ?
                          <li>
                            <a href={props.singleNftImage.data.nft_collection.twitter_link} target="_blank">
                              <Image
                                className="buy-nft-social-icon"
                                src={
                                  window.location.origin + "/images/twitter.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                        {props.singleNftImage.data.nft_collection.facebook_link ?
                          <li>
                            <a href={props.singleNftImage.data.nft_collection.facebook_link} target="_blank">
                              <Image
                                className="buy-nft-social-icon"
                                src={
                                  window.location.origin + "/images/facebook.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                        {props.singleNftImage.data.nft_collection.telegram_link ?
                          <li>
                            <a href={props.singleNftImage.data.nft_collection.telegram_link} target="_blank">
                              <Image
                                className="buy-nft-social-icon"
                                src={
                                  window.location.origin + "/images/telegram.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                        {props.singleNftImage.data.nft_collection.external_link ?
                          <li>
                            <a href={props.singleNftImage.data.nft_collection.external_link} target="_blank">
                              <Image
                                className="buy-nft-social-icon"
                                src={
                                  window.location.origin + "/images/external_link.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                      </ul>
                    </div>
                  ) : null
                  }
                  <div className="accept-offer-btn-sec">
                    <Link
                      to={`/assets/${props.singleNftImage.data.nft_collection.blockchain_type}/${props.singleNftImage.data.nft_collection.nft_collection_unique_id}/${props.singleNftImage.data.nft_collection.contract_address}/${props.singleNftImage.data.nft_collection_item.token_id}`}
                      className="default-btn border-radius-5"
                    >
                      View Item
                    </Link>
                  </div>
                </div>
              </Modal.Body>
            </>
          )
        ) : (
          <>
            <Modal.Body>
              <Button className="modal-close" onClick={() => props.closeCompleteListingModal()}>
                <Image
                  className="close-icon"
                  src={
                    window.location.origin + "/images/create-item/icons/modal-close.svg"
                  }
                />
              </Button>
              <div className="complete-listing-content">
                <h4>Complete your listing</h4>
                <div className="complete-listing-header-sec">
                  <div className="complete-listing-left-header-sec">
                    <Image
                      src={
                        props.singleNftImage.data.nft_collection_item.picture !=
                          ""
                          ? props.singleNftImage.data.nft_collection_item.picture
                          : configuration.get("configData.item_placholder")
                      }
                      className="complete-listing-title-img"
                    />
                    <div className="complete-listing-title-info">
                      <h5>{props.singleNftImage.data.nft_collection.name}</h5>
                      <h3>{props.singleNftImage.data.nft_collection_item.name}</h3>
                    </div>
                  </div>
                  <div className="complete-listing-right-header-sec">
                    <h5>Price</h5>
                    <h5>{props.sellData.amount}</h5>
                    <div className="complete-listing-price-amount">
                      <Image
                        src={
                          window.location.origin + "/images/single-item/icons/dollar.svg"
                        }
                        className="complete-listing-price-icon"
                      />
                      {price.usdConvertedPrice != null &&
                        props.sellData.amount != "" &&
                        Number(props.sellData.amount) > 0 && (
                          <span>
                            {Number(
                              props.sellData.amount * price.usdConvertedPrice
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            {price.denomination}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div className="complete-listing-right-accordion-sec">
                  <p className="mb-3">To complete your listing, follow these steps:</p>
                  <div className="custom-accordion">
                    {accordionData != null &&
                      accordionData.accordions
                        .filter((accordions) => accordions.isVisible == true)
                        .map((accordion, index) => (
                          // props.singleNftImage.data.nft_collection.collection_type != 2 &&
                          props.singleNftImage.data.nft_collection_item.is_minted == 1 ?
                            <>
                              <CustomAccordion
                                activeId={accordionData.activeAccordion}
                                accordionHeading={accordion.heading}
                                accordionKey={String(index + 1)}
                                accordionContent={accordion.accordionContent}
                                accordionButton={true}
                                OnClick={
                                  accordion.name != "approve"
                                    ? () => accordion.onClickFunction()
                                    : accordion.onClickFunction
                                }
                                accordionButtonDisable={
                                  accordion.accordionButtonDisable
                                }
                                accordionButtonContent={
                                  accordion.accordionButtonContent
                                }
                                spinner
                                spinnerLoading={
                                  accordion.spinnerLoading &&
                                  props.activeAccordion === String(index + 1)
                                }
                                activeAccordion={accordionData.activeAccordion}
                                loadingComplete={
                                  Number(accordionData.activeAccordion) > index + 1
                                }
                              />
                            </> : accordion.name == "offer" ?
                              <>
                                <CustomAccordion
                                  activeId={accordionData.activeAccordion}
                                  accordionHeading={accordion.heading}
                                  accordionKey={String(1)}
                                  accordionContent={accordion.accordionContent}
                                  accordionButton={true}
                                  OnClick={
                                    accordion.name != "approve"
                                      ? () => accordion.onClickFunction()
                                      : accordion.onClickFunction
                                  }
                                  accordionButtonDisable={
                                    accordion.accordionButtonDisable
                                  }
                                  accordionButtonContent={
                                    accordion.accordionButtonContent
                                  }
                                  spinner
                                  spinnerLoading={
                                    accordion.spinnerLoading &&
                                    props.activeAccordion === String(index + 1)
                                  }
                                  activeAccordion={accordionData.activeAccordion}
                                  loadingComplete={
                                    Number(accordionData.activeAccordion) > index + 1
                                  }
                                />
                              </> : null
                        ))}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  sellNftItem: state.nfts.sellNftItem,
  profile: state.users.profile,
  updateSignApprove: state.users.updateSignApprove,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

function areEqual(prevProps, nextProps) {
  return prevProps.signData === nextProps.signData &&
    prevProps.sellNftItem === nextProps.sellNftItem &&
    prevProps.loadingButtonContent === nextProps.loadingButtonContent &&
    prevProps.activeAccordion === nextProps.activeAccordion;
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(React.memo(CompleteListingModal, areEqual));