import React, { useState, useEffect } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Button,
	Dropdown
} from "react-bootstrap";
import "./CollectionList.css";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
	fetchNftCollectionsListStart,
	clearCollectionDeleteData,
	fetchMoreNftCollectionsListStart,
} from "../store/actions/NftAction";
import ExploreCardLoader from "../loaders/ExploreCardLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import NoCollectionsFound from "../NoDataFound/NoCollectionsFound";

const MyCollection = (props) => {
	const navigate = useNavigate();

	useEffect(() => {
		props.dispatch(clearCollectionDeleteData());
		props.dispatch(fetchNftCollectionsListStart({
			skip: 0,
			take: 12
		}));
	}, []);

	const fetchMoreCollections = () => {
		props.dispatch(fetchMoreNftCollectionsListStart({
			skip: props.collectionList.data.collections.length,
			take: 12,
		}))
	}

	return <>
		<div className="collection-list-sec">
			<Container>
				<Row>
					<Col md={12}>
						<div className="collection-list-btn-sec">
							<p>Create, curate, and manage collections of unique NFTs to sell and share with friends</p>
							<Link to="/create-collection" className="default-btn">
								Create A Collection
							</Link>
						</div>
					</Col>
				</Row>
				<div className="collection-list-box">
					{props.collectionList.loading ? (
						<>
							<div className="explore-contents create-card-collection mt-4">
								<Row className="g-0">
									<ExploreCardLoader count={6} />
								</Row>
							</div>
						</>
					) : props.collectionList.data.collections &&
						props.collectionList.data.collections.length > 0 ? (
						<InfiniteScroll
							dataLength={props.collectionList.data.collections.length}
							next={fetchMoreCollections}
							hasMore={props.collectionList.data.collections.length < props.collectionList.data.total}
							loader={<ExploreCardLoader count={3} />}
							style={{ height: 'auto', overflow: 'hidden' }}
						>
							<Row>
								{props.collectionList.data.collections.map(
									(collection, index) => (
										<Col md={6} xl={4} lg={4} key={index}>
											<div onClick={e => navigate(`/collection/${collection.nft_collection_unique_id}`)} className="cursor-pointer">
												<div className="collection-list-card">
													<div className="collection-list-img-sec">
														<Image
															src={collection.cover}
															className="collection-list-img"
														/>
														<div className="collection-list-three-dots" onClick={e => e.stopPropagation()}>
															<Dropdown>
																<Dropdown.Toggle variant="success" id="dropdown-basic">
																	<Image
																		src={
																			window.location.origin + "/images/icons/three-dots.svg"
																		}
																		className="collection-list-three-dots-icon"
																	/>
																</Dropdown.Toggle>

																<Dropdown.Menu>
																	<Dropdown.Item>
																		<Link to={`/my-collection/${collection.nft_collection_unique_id}`}>
																			Edit
																		</Link>
																	</Dropdown.Item>
																	<Dropdown.Item>
																		<Link to={`/collection/${collection.nft_collection_unique_id}`}>
																			View
																		</Link>
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</div>
													</div>
													<div className="collection-list-info">
														<div className="collection-list-user-img-sec">
															<Image
																src={collection.picture}
																className="collection-list-user-img"
															/>
														</div>
														<h4>{collection.name}</h4>
														<h6>
															<span>
																by
															</span>
															<span className="collection-owner">
																<Link to="/profile">
																	you
																</Link>
															</span>
														</h6>
														<p>{collection.total_items} items</p>
													</div>
												</div>
											</div>
										</Col>
									)
								)}
							</Row>
						</InfiniteScroll>
					) : (
						<NoCollectionsFound />
					)}

				</div>
			</Container>
		</div>
	</>;
};

const mapStateToPros = (state) => ({
	singleItems: state.nfts.singleItems,
	collectionList: state.nfts.collectionList,
	itemSave: state.nfts.itemSave,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyCollection);
