import React, { useState } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { addUserCardStart } from "../store/actions/WalletAction";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import configuration from "react-global-configuration";

const CardSection = (props) => {
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const submitCard = async () => {
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (payload.error) {
      props.dispatch(getErrorNotificationMessage(payload.error));
    } else {
      console.log(payload);
      props.dispatch(
        addUserCardStart({
          card_token: payload.paymentMethod.id,
          card_holder_name: cardHolderName,
        })
      );
    }
  };

  return (
    <>
      <div className="sign-in-sec new-add-card">
        <Form className="add-card-form new-input">
          <Form.Group className="mb-3">
            <Form.Label>Card Number</Form.Label>
            <CardNumberElement
              options={{ showIcon: true }}
              className="form-control card-input"
              onChange={(e) => {
                // setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </Form.Group>
          <Row>
            <Col md={6} xl={6} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Expiration Date</Form.Label>
                <CardExpiryElement
                  className="form-control card-input"
                  onChange={(e) => {
                    // setError(e.error);
                    setCardComplete(e.complete);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6} xl={6} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>CVC/CVV</Form.Label>
                <CardCvcElement
                  className="form-control card-input"
                  onChange={(e) => {
                    // setError(e.error);
                    setCardComplete(e.complete);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Card Holder's Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ener Card Holder's Full Name"
              value={cardHolderName}
              onChange={(e) => setCardHolderName(e.target.value)}
            />
          </Form.Group>
          <div className="add-card-btn-sec text-center mt-4">
            <Button
              className="default-btn "
              disabled={!cardComplete || props.addUserCard.buttonDisable}
              onClick={() => submitCard()}
            >
              {props.addUserCard.loadingButtonContent
                ? props.addUserCard.loadingButtonContent
                : "Add Card"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
const AddCardModal = (props) => {
  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  return (
    <>
      <Modal
        className="custom-modal modal-dialog-center add-card-modal add-billing-sec"
        size="md"
        centered
        show={props.addCard}
        onHide={props.closeAddCardModal}
      >
        <Modal.Body>
          <h4>Add Card</h4>

          <Button
            className="modal-close"
            onClick={() => props.closeAddCardModal()}
          >
            <Image
              className="close-icon"
              src={
                window.location.origin +
                "/images/create-item/icons/modal-close.svg"
              }
            />
          </Button>
          <Elements stripe={stripePromise}>
            <CardSection
              dispatch={props.dispatch}
              addUserCard={props.addUserCard}
            />
          </Elements>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  addUserCard: state.wallet.addUserCard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddCardModal);
