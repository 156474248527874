import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { connect } from "react-redux";
import { apiConstants } from "../../Constant/constants";
import { updateOnboardingStart } from "../../store/actions/WalletAppAction";

const LocationSec = (props) => {
  const [skipRender, setSkipRender] = useState(true);
  const [mapPosition, setMapPosition] = useState('');
  const [locationData, setLocationData] = useState({
    latitude: props.profile.data.user.latitude ? props.profile.data.user.latitude : 37,
    longitude: props.profile.data.user.longitude ? props.profile.data.user.longitude : -95,
    address: props.profile.data.user.address ? props.profile.data.user.address : "",
  });

  let autocomplete;

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"]
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place);
      if (!place || !place.geometry) return;

      setMapPosition(place.geometry.location);


      let full_address = "";
      place.address_components.map(
        (address) =>
        (full_address =
          full_address == ""
            ? address.long_name
            : full_address + "," + address.long_name)
      );

      setLocationData({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: full_address,
      });
    });
  };

  const handleSubmit = () => {
    props.dispatch(updateOnboardingStart(locationData));
  }

  useEffect(() => {
    if (!skipRender && !props.onboardingUpdate.loading && Object.keys(props.onboardingUpdate.data).length > 0) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.prevStep()}>
          <Image
            src={window.location.origin + "/images/onboarding-icons/back.svg"}
          />
        </Button>
      </div>
      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <Form>
            <div className="forgot-password-content">
              <h4>Location</h4>

              <div className="mt-4">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <Image
                      src={
                        window.location.origin +
                        "/images/landing-page/icons/search-icon.svg"
                      }
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder={props.profile.data.user.address ? props.profile.data.user.address : "Enter your location"}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onFocus={renderAutoComplete}
                    ref={ref => (autocomplete = ref)}
                  />
                </InputGroup>
                <Map
                  className="new-map"
                  google={props.google}
                  visible={true}
                  initialCenter={{
                    lat: locationData.latitude,
                    lng: locationData.longitude,
                  }}
                  center={mapPosition}
                  zoom={8}
                >
                  <Marker position={mapPosition} />
                </Map>
              </div>
            </div>

            <div className="login-btn mt-4">
              <Button
                disabled={!locationData.address || props.onboardingUpdate.buttonDisable}
                onClick={() => handleSubmit()}
                className="default-btn">
                {props.onboardingUpdate.loadingButtonContent
                  ? props.onboardingUpdate.loadingButtonContent
                  : "Continue"}
              </Button>
            </div>
          </Form>
        </div>
      </div >
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const connector = connect(mapStateToPros, mapDispatchToProps)(LocationSec);

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(connector);
