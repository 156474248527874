import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import SettingsIndex from "./SettingsIndex";
import { Formik, Form as FORM, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import {
  changePasswordStart,
  updateTwoStepAuthStart,
} from "../store/actions/UserAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from "@material-ui/core/Switch";

const SettingsSecurity = (props) => {
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [skipRender, setSkipRender] = useState(true);
  const [initialTwoStep, setInitialTwoStep] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Required"),
    password: Yup.string()
      .required("Required")
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          "Can not be same as current password"
        ),
      }),
    password_confirmation: Yup.string()
      .required("Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !props.changePassword.loading &&
      Object.keys(props.changePassword.data).length > 0
    ) {
      setTimeout(() => {
        navigate("/logout");
      }, 2000);
    }
    setSkipRender(false);
  }, [props.changePassword]);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setInitialTwoStep(
        props.profile.data.user.is_two_step_auth_enabled === 0 ? false : true
      );
      setTwoStepAuth(
        props.profile.data.user.is_two_step_auth_enabled === 0 ? false : true
      );
    }
  }, [props.profile]);

  const changePassword = (values) => {
    props.dispatch(changePasswordStart(values));
  };

  const changeTwoStepAuth = (values) => {
    props.dispatch(updateTwoStepAuthStart(values));
  };

  return (
    <>
      <SettingsIndex>
        <div className="settings-box">
          <Row className="justify-content-center">
            <Col md={7} xl={6} lg={6}>
              <div className="create-collection-form">
                <div className="security-sec">
                  <div className="security-title mb-4">
                    <h4>Two-factor Authetication</h4>
                    <div className=" security-custom-switch">
                      {/* <Form.Check type="switch" id="custom-switch" /> */}
                      <Switch
                        checked={twoStepAuth}
                        onClick={() => setTwoStepAuth(!twoStepAuth)}
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </div>
                  </div>
                  <div className="two-factor-form">
                    {initialTwoStep != twoStepAuth ? (
                      <Formik
                        initialValues={{
                          password: "",
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string().required("Required"),
                        })}
                        innerRef={formRef}
                        onSubmit={(values) => changeTwoStepAuth(values)}
                      >
                        {({ errors, touched }) => (
                          <FORM className="collection-form">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Form.Label className="confirm-password">Confirm Password*</Form.Label>
                              <InputGroup>
                                <Field
                                  className="form-control"
                                  type="password"
                                  placeholder="Current password"
                                  name="password"
                                />
                              </InputGroup>

                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                            <div className="security-btn text-center">
                              <Button
                                type="submit"
                                className="default-btn border-radius-5 mt-4"
                                disabled={props.updateTwoStepAuth.buttonDisable}
                              >
                                {props.updateTwoStepAuth.loadingButtonContent
                                  ? props.updateTwoStepAuth.loadingButtonContent
                                  : "Submit"}
                              </Button>
                            </div>
                          </FORM>
                        )}
                      </Formik>
                    ) : null}
                  </div>
                  <div className="two-factor-authentication mt-5">
                    <div className="security-title mb-3">
                      <h4>Change Password</h4>
                    </div>
                    <Formik
                      initialValues={{
                        old_password: "",
                        password: "",
                        password_confirmation: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => changePassword(values)}
                    >
                      {({ errors, touched }) => (
                        <FORM className="collection-form">
                          <div className="col-lg-12 col-md-12 mb-4">
                            <div class="form-group ">
                              <div class="controls">
                                <Field
                                  type="password"
                                  className="form-control"
                                  placeholder="Current password"
                                  name="old_password"
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="old_password"
                                  className="text-danger text-right"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 mb-4">
                            <div class="form-group ">
                              <div class="controls">
                                <Field
                                  type="password"
                                  className="form-control"
                                  placeholder="New password"
                                  name="password"
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="password"
                                  className="text-danger text-right"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div class="form-group ">
                              <div class="controls">
                                <Field
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirm new password"
                                  name="password_confirmation"
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="password_confirmation"
                                  className="text-danger text-right"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="security-btn text-center">
                            <Button
                              type="submit"
                              className="default-btn border-radius-5 mt-4"
                              disabled={props.changePassword.buttonDisable}
                            >
                              {props.changePassword.loadingButtonContent
                                ? props.changePassword.loadingButtonContent
                                : "Submit"}
                            </Button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </SettingsIndex>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.users.changePassword,
  profile: state.users.profile,
  updateTwoStepAuth: state.users.updateTwoStepAuth,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SettingsSecurity);
