import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Image, Container, Row } from "react-bootstrap";
import LoginSlider from "../LoginSlider";
import CreateLinkSec from "./CreateLinkSec";
import CategorySelectorSec from "./CategorySelectorSec";
import "./OnBoarding.css";
import "./Responsive.css";
import AdditionalInfoSec from "./AdditionalInfoSec";
import LocationSec from "./LocationSec";
import AccountCompletion from "./AccountCompletion";
import LinkYourAccount from "./LinkYourAccount";
import InviteFriends from "./InviteFriends";
import { connect } from "react-redux";
import ConfettiIndex from "../../Confetti/ConfettiIndex";

const OnBoardIndex = (props) => {
  const [step, setStep] = useState(0);
  const [skipRender, setSkipRender] = useState(false);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      if (!skipRender) {
        setStep(Number(props.profile.data.user.onboarding_step));
        setSkipRender(true);
      }
    }
  }, [props.profile]);

  const changeStep = (stepNo) => {
    if (stepNo <= props.profile.data.user.onboarding_step || stepNo > 6) {
      setStep(stepNo);
    }
  };

  const nextStep = () => {
    changeStep(step + 1);
  };

  const prevStep = () => {
    changeStep(step - 1);
  };

  return (
    <div>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row>
              <Col md={12} xl={6} lg={6} className="border-right resp-order">
                <LoginSlider />
              </Col>
              {props.profile.data.user ? (
                <Col md={12} xl={6} lg={6} className="border-left">
                  {step === 2 ? (
                    <CreateLinkSec
                      nextStep={nextStep}
                      profile={props.profile}
                    />
                  ) : step === 3 ? (
                    <CategorySelectorSec
                      prevStep={prevStep}
                      nextStep={nextStep}
                      profile={props.profile}
                    />
                  ) : step === 4 ? (
                    <AdditionalInfoSec
                      prevStep={prevStep}
                      nextStep={nextStep}
                      profile={props.profile}
                    />
                  ) : step === 5 ? (
                    <LocationSec
                      prevStep={prevStep}
                      nextStep={nextStep}
                      profile={props.profile}
                    />
                  // ) : step === 6 ? (
                  //   <AccountCompletion
                  //     prevStep={prevStep}
                  //     setStep={changeStep}
                  //     profile={props.profile}
                  //   />
                  ) : (step === 6) ? (
                    <LinkYourAccount
                      setStep={changeStep}
                      profile={props.profile}
                      nextStep={nextStep}
                    />
                  ) : (step === 7) ? (
                    <ConfettiIndex />
                  ) : step === 8 ? (
                    <InviteFriends
                      setStep={changeStep}
                      profile={props.profile}
                    />
                  ) : null}
                </Col>
              ) : null}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(OnBoardIndex);
