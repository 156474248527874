import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import { nftSearchStart, clearSearchData } from "../store/actions/NftAction";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { authContext } from "../authprovider/AuthProvider";
import configuration from "react-global-configuration";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import useUserAuthStatus from "../customHooks/useUserAuthStatus";

const styles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: (props) =>
      props.classes == "mobile-search" ? "relative" : "absolute",
    // top: 28,
    right: (props) => (props.classes == "mobile-search" ? "0" : 0),
    left: (props) => (props.classes == "mobile-search" ? "0" : 0),
    zIndex: 1,
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    backgroundColor: theme.palette.background.paper,
    padding: "0.5rem 0",
    boxShadow: (props) =>
      props.classes == "mobile-search"
        ? "none"
        : "rgb(0 0 0 / 16%) 0px 4px 16px",
    maxHeight: "calc(100vh - 70px - 1rem)",
  },
}));

const CustomSearch = (props) => {
  const location = useLocation();

  const { auth } = useContext(authContext);

  const userAuthStatus = useUserAuthStatus(props.profile);

  const [searchKey, setSearchKey] = useState("");

  const onChange = (value) => {
    setSearchKey(value);
  };

  useEffect(() => {
    if (searchKey.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        props.dispatch(nftSearchStart({ search_key: searchKey }));
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      props.dispatch(clearSearchData());
    }
  }, [searchKey]);

  const classes = styles({ classes: props.classes });

  const handleClickAway = () => {
    setSearchKey("");
  };

  useEffect(() => {
    setSearchKey("");
  }, [location]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`nav-widget-form ${props.classes}`}>
          <form className="search-form">
            {/* <div className="search-icon">
							<SearchIcon />
						</div>
						<input
							value={searchKey}
							type="input"
							className="form-control"
							placeholder="Search"
							onChange={(event) => onChange(event.target.value)}
						/>
						{searchKey != "" && (
							<div className="search-clear">
								<i className="fas fa-times" onClick={() => onChange("")}></i>
							</div>
						)} */}
            <div className="header-search">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <Image
                    src={
                      window.location.origin +
                      "/images/landing-page/icons/search-icon.svg"
                    }
                  />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchKey}
                  onChange={(event) => onChange(event.target.value)}
                />
                {searchKey.length > 0 && (
                  <InputGroup.Text id="basic-addon1" className="top-header-close">
                    <Button onClick={() => setSearchKey("")}>
                      <Image
                        src={window.location.origin + "/images/icons/close.svg"}
                      />
                    </Button>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </div>
          </form>
          {searchKey != "" &&
            (props.nftSearch.loading ? (
              <>
                <div className={`${classes.dropdown} search-sec`}>
                  <ul className="header-search">
                    <li className="search-lists">
                      <div className="text-center py-3">
                        <Spinner
                          animation="border"
                          className="custom-spinner"
                          size="sm"
                          variant="primary"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            ) : props.nftSearch.data.total > 0 ? (
              <div className={`${classes.dropdown} search-sec`}>
                <ul className="header-search">
                  {props.nftSearch.data.nft_collections.length > 0 ? (
                    <>
                      <li className="search-list search-title">Collections</li>
                      {props.nftSearch.data.nft_collections.map(
                        (nft_collection) => (
                          <li className="search-list">
                            {nft_collection.user_id ===
                              userAuthStatus.userId ? (
                              <Link
                                to={`/assets/collection/${nft_collection.nft_collection_unique_id}`}
                              >
                                <div className="search-text">
                                  <img src={nft_collection.picture} />
                                  <div className="verified-icon-sec justify-center">
                                    <p className="mb-0">
                                      {nft_collection.name}
                                    </p>
                                    {nft_collection.is_verified == 1 && (
                                      <img
                                        className="verified-icon"
                                        src={
                                          window.location.origin +
                                          "/assets/images/icons/verified.png"
                                        }
                                        alt="Verified Icon"
                                      />
                                    )}
                                  </div>
                                  <p className="collection-item-count">
                                    {nft_collection.total_items} Items
                                  </p>
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={`/collection/${nft_collection.nft_collection_unique_id}`}
                              >
                                <div className="search-text">
                                  <img src={nft_collection.picture} />
                                  <div className="verified-icon-sec justify-center">
                                    <p className="mb-0">
                                      {nft_collection.name}
                                    </p>
                                    {nft_collection.is_verified == 1 && (
                                      <img
                                        className="verified-icon"
                                        src={
                                          window.location.origin +
                                          "/assets/images/icons/verified.png"
                                        }
                                        alt="Verified Icon"
                                      />
                                    )}
                                  </div>
                                  <p className="collection-item-count">
                                    {nft_collection.total_items} Items
                                  </p>
                                </div>
                              </Link>
                            )}
                          </li>
                        )
                      )}
                    </>
                  ) : null}
                  {props.nftSearch.data.nft_collection_items.length > 0 ? (
                    <>
                      <li className="search-list search-title">Items</li>
                      {props.nftSearch.data.nft_collection_items.map((item) => (
                        <li className="search-list">
                          <Link
                            to={`/assets/${item.nft_collection.blockchain_type}/${item.nft_collection.nft_collection_unique_id}/${item.nft_collection.contract_address}/${item.token_id}`}
                          >
                            <div className="search-text">
                              <img src={item.picture} />
                              <p>{item.name}</p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </>
                  ) : null}
                  {props.nftSearch.data.users.length > 0 ? (
                    <>
                      <li className="search-list search-title">Accounts</li>
                      {props.nftSearch.data.users.map((user) => (
                        <li className="search-list">
                          {user.user_unique_id ==
                            userAuthStatus.userUniqueId ? (
                            <Link to={`/profile`}>
                              <div className="search-text">
                                <img src={user.picture} />
                                <p>{user.name}</p>
                              </div>
                            </Link>
                          ) : (
                            <Link to={`/account/${user.user_unique_id}`}>
                              <div className="search-text">
                                <img src={user.picture} />
                                <p>{user.name}</p>
                              </div>
                            </Link>
                          )}
                        </li>
                      ))}
                    </>
                  ) : null}
                </ul>
              </div>
            ) : (
              <div className={`${classes.dropdown} search-sec`}>
                <ul className="header-search">
                  <li className="search-list">
                    <div className="search-text p-3 text-center">
                      <p className="w-100">No data found</p>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
        </div>
      </ClickAwayListener>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  nftSearch: state.nfts.nftSearch,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CustomSearch);
