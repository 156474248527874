import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import {
  fetchOnboardingCategoriesStart,
  updateOnboardingCategoriesStart,
} from "../../store/actions/WalletAppAction";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

const CategorySelectorSec = (props) => {
  const [selectedCategories, setSeletedCategories] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    props.dispatch(fetchOnboardingCategoriesStart());
    setSeletedCategories(
      props.profile.data.user.user_categories.map(
        (category, i) => category.onboarding_category_id
      )
    );
  }, [props.profile]);

  const addCategory = (categoryId) => {
    setSeletedCategories([...selectedCategories, categoryId]);
  };

  const removeCategory = (categoryId) => {
    setSeletedCategories(
      selectedCategories.filter((cat) => cat !== categoryId)
    );
  };

  const handleSubmit = () => {
    if (selectedCategories.length > 0) {
      const data = JSON.stringify(selectedCategories);
      props.dispatch(
        updateOnboardingCategoriesStart({
          onboarding_category_id: data.substring(1, data.length - 1),
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.updateOnboardingCategories.loading &&
      Object.keys(props.updateOnboardingCategories.data).length > 0
    ) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.updateOnboardingCategories]);

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.prevStep()}>
          <Image
            src={window.location.origin + "/images/onboarding-icons/back.svg"}
          />
        </Button>
      </div>

      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <Form>
            <div className="forgot-password-content">
              <h4>Interests</h4>
              {selectedCategories.length > 0 ? (
                <p>You choosen {selectedCategories.length} items</p>
              ) : (
                <>
                  <p>We need to know more about you to make sure we deliver the right opportunities to you.</p>
                  <p>You may select more than one.</p>
                </>
              )}
            </div>
            <div className="category-list">
              {props.onboardingCategories.loading ? (
                <ul>
                  {[...Array(9)].map((i, j) => (
                    <Skeleton
                      className="category-outline-btn"
                      width={j % 3 === 0 ? 250 : 100}
                    />
                  ))}
                </ul>
              ) : props.onboardingCategories.data.onboarding_categories &&
                props.onboardingCategories.data.onboarding_categories.length >
                  0 ? (
                <ul>
                  {props.onboardingCategories.data.onboarding_categories.map(
                    (category, i) => (
                      <>
                        {selectedCategories.includes(
                          category.onboarding_category_id
                        ) ? (
                          <li
                            className="category-outline-btn active"
                            onClick={() =>
                              removeCategory(category.onboarding_category_id)
                            }
                          >
                            <span>
                              {" "}
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/onboarding-icons/close.svg"
                                }
                              />
                            </span>
                            {category.name}
                          </li>
                        ) : (
                          <li
                            className="category-outline-btn"
                            onClick={() =>
                              addCategory(category.onboarding_category_id)
                            }
                          >
                            {category.name}
                          </li>
                        )}
                      </>
                    )
                  )}
                </ul>
              ) : null}
            </div>
            <div className="login-btn mt-4">
              <Button
                disabled={
                  selectedCategories.length === 0 ||
                  props.updateOnboardingCategories.buttonDisable
                }
                onClick={() => handleSubmit()}
                className="default-btn"
              >
                {props.updateOnboardingCategories.loadingButtonContent
                  ? props.updateOnboardingCategories.loadingButtonContent
                  : "Continue"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingCategories: state.walletApp.onboardingCategories,
  updateOnboardingCategories: state.walletApp.updateOnboardingCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CategorySelectorSec);
