import React, { useState, useEffect, useMemo } from "react";
import logo from './logo.svg';
import './App.css';
import Base from "./components/Base";
import 'bootstrap/dist/css/bootstrap.min.css';
import ThemeChanger from "./components/theme/ThemeChanger";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import store from "./components/store";
import configuration from "react-global-configuration";
import { apiConstants } from "./components/Constant/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./components/authprovider/AuthProvider";
import ScrollToTop from "../src/components/helper/ScrollToTop";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import TooManyRequest from "./components/404/TooManyRequest";
import { BrowserRouter, Routes } from "react-router-dom";

function App() {

  const [configLoading, setConfigLoading] = useState(true);

  const [configError, setConfigError] = useState(null);

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") != null
      ? localStorage.getItem("theme")
      : "light"
  );

  useEffect(() => {
    localStorage.setItem("theme", theme);
    if (theme === "dark") {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [theme]);

  const themetoggler = useMemo(
    () => ({
      toggleTheme: () => {
        setTheme((theme) => (theme === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);

      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
      setConfigError(false)
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
      setConfigError(true)
    }
  };

  const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  };

  return (
    <ThemeChanger theme={theme} themetoggler={themetoggler}>
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <BrowserRouter>
            <ToastContainer />

            {configLoading ? null : configError == null ? null : configError ? (
              <TooManyRequest />
            ) : (
              <AuthProvider>
                <ScrollToTop />
                <Base />
              </AuthProvider>
            )}
          </BrowserRouter>
        </Web3ReactProvider>
      </Provider>
    </ThemeChanger>
  );
}

export default App;
