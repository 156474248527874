import React, { useContext } from "react";
import {
	Container,
	Image,
	Row,
	Nav,
	Col,
	Tab,
	Button,
	Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomRangerCalender from "../customComponents/CustomRangerCalender";
import configuration from "react-global-configuration";
import { authContext } from "../authprovider/AuthProvider";
import {
  CHAIN_INFO,
  SupportedChainId,
  SupportedChainIdBySymbol,
} from "../authprovider/chains";

const FixedPriceListing = (props) => {
	const {
		priceSelect,
		handlePriceSelect,
		priceDate,
		handlePriceDateOpen,
		setPriceDate,
		handlePriceRangeDropdown,
		priceDateRange,
		setPriceDateRange,
		handleStartTimeChange,
		handleEndTimeChange,
		handlePriceDateClose,
		handleSellDataChanges,
		handleRangeSelection,
		sellData,
	} = props;

	const { price } = useContext(authContext);

	return (
		<>
			<div className="listing-item-form-card">
				<div className="listing-item-form-header">
					<Form.Label>Price</Form.Label>
					<Image
						src={
							window.location.origin + "/images/listing-item/icons/info-icon.svg"
						}
						className="info-icon"
					/>
				</div>
				<div className="listing-item-form-body">
					<Row>
						<Col md={3}>
							<div className="listing-item-form-type">
								<span>{props.nft_collection.blockchain_type}</span>
								<Image
									src={
										CHAIN_INFO[
											SupportedChainIdBySymbol[
												props.nft_collection.blockchain_type
											]
										].logo
									}
									className="eth-icon pad-l-5"
								/>
							</div>
						</Col>
						<Col md={9}>
							<Form.Control
								type="number"
								placeholder="0.001"
								name="amount"
								min="0.00"
								step="any"
								onChange={(event) => handleSellDataChanges(event)}
							/>
						</Col>
					</Row>
				</div>
				<div className="listing-item-form-footer">
					<div className="listing-item-form-price-amount">
						<Image
							src={
								window.location.origin + "/images/single-item/icons/dollar.svg"
							}
							className="listing-item-form-price-icon"
						/>

						{price.usdConvertedPrice != null &&
							sellData.amount != "" &&
							Number(sellData.amount) > 0 ? (
							<span>
								≉ <i className="fa fa-usd" aria-hidden="true"></i>{" "}
								{Number(
									sellData.amount * price.usdConvertedPrice
								).toLocaleString(undefined, {
									maximumFractionDigits: 2,
								})}{" "}
								{price.denomination}
							</span>
						) : <span>0 USD</span>}

					</div>
				</div>
			</div>
			<div className="listing-item-form-card">
				{/* <div className="listing-item-form-header">
					<Form.Label>Duration</Form.Label>
					<Image
						src={
							window.location.origin + "/images/listing-item/icons/info-icon.svg"
						}
						className="info-icon"
					/>
				</div> */}
				<div className="listing-item-form-body">
					<Row>
						<Col md={12}>
							<CustomRangerCalender
								priceDate={priceDate}
								handlePriceDateOpen={handlePriceDateOpen}
								setPriceDate={setPriceDate}
								handlePriceRangeDropdown={handlePriceRangeDropdown}
								popup
								priceDateRange={priceDateRange}
								setPriceDateRange={setPriceDateRange}
								handleRangeSelection={handleRangeSelection}
								handleStartTimeChange={handleStartTimeChange}
								handleEndTimeChange={handleEndTimeChange}
								handlePriceDateClose={handlePriceDateClose}
								months={2}
								placeHolder={"Duration"}
								tooltip
								tooltipContent={"Time duration for listing"}
							/>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default React.memo(FixedPriceListing);
