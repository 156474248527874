import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Button,
	Table,
	Form,
	InputGroup,
	Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	buyNftItemStart,
	fetchSingleNftItemsStart,
	clearNftBuyData,
} from "../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import { useTheme } from "@material-ui/core";
import configuration from "react-global-configuration";
import { CHAIN_INFO, SupportedChainId, SupportedChainIdBySymbol } from "../authprovider/chains";
import ReactCanvasConfetti from "react-canvas-confetti";
import "../Confetti/Confetti.css";

const BuyNFTModal = (props) => {

	const { buttonContent, sendMoneyToCreator } = props;

	const { auth, price } = useContext(authContext);

	const theme = useTheme();

	const [buyItemStatus, setBuyItemStatus] = useState(
		props.singleNftImage.data.nft_collection_item.buyer_unique_id ==
			auth.userUniqueId
			? "listed"
			: "unreviewed"
	);

	useEffect(() => {
		if (buyItemStatus === "listed") {
			fire();
		}
	}, [buyItemStatus]);

	useEffect(() => {
		if (
			!props.buyNftItem.loading &&
			props.buyNftItem.data.code == 121 &&
			props.singleNftImage.data.nft_collection_item
		) {
			setBuyItemStatus("listed");
			// props.dispatch(
			// 	fetchSingleNftItemsStart({
			// 		nft_collection_unique_id:
			// 			props.singleNftImage.data.nft_collection.nft_collection_unique_id,
			// 		nft_collection_item_token_id:
			// 			props.singleNftImage.data.nft_collection_item.token_id,
			// 	})
			// );
			props.dispatch(clearNftBuyData());
		}
	}, [props.buyNftItem.data]);

	const handlePriceSelect = (event) => {
		const {
			target: { value },
		} = event;
		props.setPriceSelect(
			// On autofill we get a the stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	const canvasStyles = {
		position: "fixed",
		pointerEvents: "none",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0
	};

	const refAnimationInstance = useRef(null);

	const getInstance = useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const makeShot = useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio)
			});
	}, []);

	const fire = useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55
		});

		makeShot(0.2, {
			spread: 60
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45
		});
	}, [makeShot]);

	return (
		<>
			{props.singleNftImage.data.nft_collection ? (
				<Modal
					className="modal-dialog-center buy-nft-modal"
					size="md"
					centered
					show={props.open}
					onHide={props.handleUnReviewedClose}
				>
					<Modal.Body>
						<Button className="modal-close" onClick={() => props.handleUnReviewedClose()}>
							<Image
								className="close-icon"
								src={
									window.location.origin + "/images/create-item/icons/modal-close.svg"
								}
							/>
						</Button>
						{/* <Button className="back-arrow-btn">
							<Image
								className="back-icon"
								src={
									window.location.origin + "/images/landing-page/icons/back-arrow.svg"
								}
							/>
						</Button> */}
						{buyItemStatus == "unreviewed" && (
							<div className="buy-nft-content">
								<h4>Buy NFT Image</h4>
								<div className="buy-nft-header-img-sec">
									<Image
										className="buy-nft-header-img"
										src={
											window.location.origin + "/images/single-item/icons/buy-nft-search.svg"
										}
									/>
								</div>
								<p>Review this information to ensure its what you want to buy</p>
								<div className="buy-nft-info-box">
									<div className="buy-nft-info-card">
										<h5>Collection name - <Link to={`/collection/${props.singleNftImage.data.nft_collection.nft_collection_unique_id}`}>{props.singleNftImage.data.nft_collection.name}</Link></h5>
										<h5>Item Name - <span>{props.singleNftImage.data.nft_collection_item.name}</span></h5>
										<h5>Creator - <Link to={`/account/${props.singleNftImage.data.nft_collection_item.user_unique_id}`}>{
											props.singleNftImage.data.nft_collection_item
												.user_wallet_address
										}</Link></h5>
									</div>
								</div>
								{props.singleNftImage.data.nft_collection.twitter_link ||
									props.singleNftImage.data.nft_collection.facebook_link ||
									props.singleNftImage.data.nft_collection.telegram_link ||
									props.singleNftImage.data.nft_collection.external_link
									? <div className="buy-nft-social-links-sec">
										<h5>Links</h5>
										<ul className="list-unstyled buy-nft-social-links">
											{props.singleNftImage.data.nft_collection
												.twitter_link ? (
												<li>
													<a
														href={props.singleNftImage.data.nft_collection
															.twitter_link}
														target="_blank"
													>
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/twitter.svg"
															}
														/>
													</a>
												</li>
											) : null}
											{props.singleNftImage.data.nft_collection
												.facebook_link ? (
												<li>
													<a
														href={props.singleNftImage.data.nft_collection
															.facebook_link}
														target="_blank">
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/facebook.svg"
															}
														/>
													</a>
												</li>
											) : null}
											{props.singleNftImage.data.nft_collection
												.telegram_link ? (
												<li>
													<a
														href={props.singleNftImage.data.nft_collection
															.telegram_link}
														target="_blank">
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/telegram.svg"
															}
														/>
													</a>
												</li>
											) : null}
											{props.singleNftImage.data.nft_collection
												.external_link ? (
												<li>
													<a href={props.singleNftImage.data.nft_collection
														.external_link}
														target="_blank">
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/external_link.svg"
															}
														/>
													</a>
												</li>
											) : null}
										</ul>
									</div>
									: null
								}
								<div className="buy-nft-agree-terms-sec">
									<div className="agree-terms-sec">
										<Form.Check
											inline
											name="group1"
											onChange={() => setBuyItemStatus("checkout")}
										/>
										<p>I agree to the following <Link to="/page/terms">terms & conditions</Link></p>
									</div>
								</div>
							</div>
						)}

						{buyItemStatus == "checkout" && (
							<>
								<Button className="back-arrow-btn" onClick={() => setBuyItemStatus("unreviewed")}>
									<Image
										className="back-icon"
										src={
											window.location.origin + "/images/landing-page/icons/back-arrow.svg"
										}
									/>
								</Button>
								<div className="complete-purchase-content">
									<h4>Complete Purchase</h4>
									<div className="complete-purchase-item-details">
										<div className="complete-purchase-item-header-sec">
											<h5>Item</h5>
											<h5>Subtotal</h5>
										</div>
										<div className="complete-purchase-item-body-sec">
											<div className="complete-purchase-header-sec">
												<div className="complete-purchase-left-header-sec">
													<Image
														src={
															props.singleNftImage.data.nft_collection_item
																.picture
														}
														className="complete-purchase-title-img"
													/>
													<div className="complete-purchase-title-info">
														<h5>{props.singleNftImage.data.nft_collection.name}</h5>
														<h3>{props.singleNftImage.data.nft_collection_item.name}</h3>
													</div>
												</div>
												<div className="complete-purchase-right-header-sec">
													<div className="complete-purchase-price">
														<Image
															src={CHAIN_INFO[SupportedChainIdBySymbol[props.singleNftImage.data.nft_collection.blockchain_type]].logo}
															className="complete-purchase-price-icon"
														/>
														<span>{props.singleNftImage.data.nft_collection_item.amount_formatted}</span>
													</div>
													<div className="complete-purchase-price-amount">
														<Image
															src={
																window.location.origin + "/images/single-item/icons/dollar.svg"
															}
															className="complete-purchase-price-icon"
														/>
														{props.singleNftImage.data.nft_collection_item
															.amount > 0 &&
															price.usdConvertedPrice != null ? (
															<span>
																≉{" "}
																<i className="fa fa-usd" aria-hidden="true"></i>
																{Number(
																	props.singleNftImage.data.nft_collection_item
																		.amount * price.usdConvertedPrice
																).toLocaleString(undefined, {
																	maximumFractionDigits: 2,
																})}{" "}
																{price.denomination}
															</span>
														) : "0 USD"}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="approve-this-collection-btn-sec">
										<Button
											className="default-btn"
											disabled={
												props.singleNftImage.data.nft_collection_item.is_sold ==
													1 || buttonContent != ""
													? true
													: false
											}
											onClick={() => sendMoneyToCreator()}
										>
											{buttonContent != "" ? buttonContent : "Buy now"}
										</Button>
									</div>
								</div>
							</>
						)}

						{buyItemStatus == "listed" && (
							<>
								<div className="accept-offer-content">
									<h4>Congratulations you just bought this NFT!</h4>
									<div className="accept-offer-complete-preview-img-sec">
										<Image
											className="accept-offer-complete-preview-img"
											src={props.singleNftImage.data.nft_collection_item.picture}
										/>
									</div>
									{props.singleNftImage.data.nft_collection.twitter_link ||
										props.singleNftImage.data.nft_collection.facebook_link ||
										props.singleNftImage.data.nft_collection.telegram_link ||
										props.singleNftImage.data.nft_collection.external_link
										? <div className="buy-nft-social-links-sec">
											<h5>Links</h5>
											<ul className="list-unstyled buy-nft-social-links">
												{props.singleNftImage.data.nft_collection
													.twitter_link ? (
													<li>
														<a
															href={props.singleNftImage.data.nft_collection
																.twitter_link}
															target="_blank"
														>
															<Image
																className="buy-nft-social-icon"
																src={
																	window.location.origin + "/images/twitter.svg"
																}
															/>
														</a>
													</li>
												) : null}
												{props.singleNftImage.data.nft_collection
													.facebook_link ? (
													<li>
														<a
															href={props.singleNftImage.data.nft_collection
																.facebook_link}
															target="_blank">
															<Image
																className="buy-nft-social-icon"
																src={
																	window.location.origin + "/images/facebook.svg"
																}
															/>
														</a>
													</li>
												) : null}
												{props.singleNftImage.data.nft_collection
													.telegram_link ? (
													<li>
														<a
															href={props.singleNftImage.data.nft_collection
																.telegram_link}
															target="_blank">
															<Image
																className="buy-nft-social-icon"
																src={
																	window.location.origin + "/images/telegram.svg"
																}
															/>
														</a>
													</li>
												) : null}
												{props.singleNftImage.data.nft_collection
													.external_link ? (
													<li>
														<a href={props.singleNftImage.data.nft_collection
															.external_link}
															target="_blank">
															<Image
																className="buy-nft-social-icon"
																src={
																	window.location.origin + "/images/external_link.svg"
																}
															/>
														</a>
													</li>
												) : null}
											</ul>
										</div>
										: null
									}
									<div className="accept-offer-btn-sec">
										<Button className="default-btn" onClick={() => props.handleUnReviewedClose()}>
											View Item
										</Button>
									</div>
								</div>
								<ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
							</>
						)}
					</Modal.Body>
				</Modal>
			) : null}
		</>
	);
};

const mapStateToPros = (state) => ({
	buyNftItem: state.nfts.buyNftItem,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BuyNFTModal);