import React from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
  Modal
} from "react-bootstrap";

const AddPropertiesModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center add-properties-modal"
        size="md"
        centered
        show={props.show}
        onHide={props.onHide}
      >
        {/* <Modal.Header closeButton>
            <Modal.Title>Add Properties</Modal.Title>
          </Modal.Header> */}
        <Modal.Body>
          <Button className="modal-close" onClick={() => props.onHide()}>
            <Image
              className="close-icon"
              src={
                window.location.origin + "/images/create-item/icons/modal-close.svg"
              }
            />
          </Button>
          <div className="add-properties-content">
            <h4>Add Properties</h4>
            <p>Properties show up underneath your nft, are clickable, and can be filtered in your collection's sidebar.</p>
            <div className="add-properties-form">
              {props.properties.length > 0 &&
                <>
                  {props.properties.map((properties, index) =>
                    <Row>
                      <Col md={6}>
                        <Form.Label>Property</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <Button
                              className="btn-remove"
                              onClick={() =>
                                props.handlePropertyDelete(properties)
                              }>
                              <Image
                                className="close-icon"
                                src={
                                  window.location.origin + "/images/create-item/icons/modal-close.svg"
                                }
                              />
                            </Button>
                          </InputGroup.Text>
                          <Form.Control
                            value={properties.name}
                            onChange={(event) =>
                              props.handlePropertyNameChange(
                                index,
                                event.currentTarget.value
                              )
                            } />
                        </InputGroup>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Value</Form.Label>
                          <Form.Control
                            value={properties.value}
                            onChange={(event) =>
                              props.handlePropertyValueChange(
                                index,
                                event.currentTarget.value
                              )
                            } />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </>
              }
              <div className="add-more-btn-sec">
                <Button
                  className="default-outline-btn"
                  onClick={() => props.handleNewProperty()}
                >Add More</Button>
              </div>
              <div className="create-item-btn-sec">
                <Button
                  className="default-btn"
                  onClick={() => props.handlePropertySave()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPropertiesModal;