import ethLogo from "./chainLogos/eth.png"
import BinanceLogo from "./chainLogos/binance.png"
import PolygonLogo from "./chainLogos/ploygon.png"
import VelasLogo from "./chainLogos/velas.png"

export let SupportedChainId = {
  BINANCE: 97,
  ETHEREUM: 4,
  POLYGON: 80001,
  // VELAS: 111,
};

export const All_supported_ChainIds = Object.values(SupportedChainId).filter(
  (chains) => typeof chains === "number"
);

export const SUPPORTED_RPC_URLS = {
  [SupportedChainId.ETHEREUM]: "https://rinkeby.infura.io/v3/",
  [SupportedChainId.POLYGON]: `https://rpc-mumbai.maticvigil.com/`,
  [SupportedChainId.BINANCE]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  [SupportedChainId.VELAS]: `https://evmexplorer.testnet.velas.com/rpc`,
};

export const CHAIN_INFO = {
  [SupportedChainId.ETHEREUM]: {
    explorer: "https://rinkeby.etherscan.io",
    label: "Rinkeby Testnet",
    nativeCurrency: { name: "Rinkeby", symbol: "RinkebyETH", decimals: 18 },
    logo : ethLogo,
    apiId: "ethereum"
  },
  [SupportedChainId.POLYGON]: {
    explorer: "https://mumbai.polygonscan.com/",
    label: "Polygon Testnet",
    nativeCurrency: { name: "Polygon Matic", symbol: "MATIC", decimals: 18 },
    logo : PolygonLogo,
    apiId: "matic-network"
  },
  [SupportedChainId.BINANCE]: {
    explorer: "https://testnet.bscscan.com",
    label: "Binance Testnet",
    nativeCurrency: { name: "Binance", symbol: "BNB", decimals: 18 },
    logo : BinanceLogo,
    apiId: "binancecoin"
  },
  [SupportedChainId.VELAS]: {
    explorer: "https://evmexplorer.testnet.velas.com",
    label: "Velas Testnet",
    nativeCurrency: { name: "Velas", symbol: "VLX", decimals: 18 },
    logo : VelasLogo,
    apiId: "velas"
  },
};

export let SupportedChainIdBySymbol = {
  RinkebyETH: 4,
  MATIC: 80001,
  BNB: 97,
};

export const SELECTED_CHAIN_INFO = (chainId) => CHAIN_INFO[chainId];
