import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const ListingItemLoader = () => {
  return (
    <>
      <div className="listing-item-sec">
        <Container>
          <div className="listing-item-header-sec-loader">
            <Skeleton count={1} />
          </div>
          <Row>
            <Col md={6}>
              <div className="listing-item-form-sec-loader">
                <Skeleton count={1} />
              </div>
            </Col>
            <Col md={6}>
              <div className="listing-item-preview-card-loader">
                <Skeleton count={1} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListingItemLoader;
