import React, { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { Link } from "react-router-dom";
import "./Confetti.css";


const ConfettiIndex = () => {

	const canvasStyles = {
		position: "fixed",
		pointerEvents: "none",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0
	};

	const refAnimationInstance = useRef(null);

	const getInstance = useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const makeShot = useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio)
			});
	}, []);

	const fire = useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55
		});

		makeShot(0.2, {
			spread: 60
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45
		});
	}, [makeShot]);

	useEffect(() => {
		fire()
	}, [])
	return (
		<>
			<div className="confetti-sec">
				<div className="confetti-card">
					<h4>Congratulations! You did it!</h4>
					<p>Welcome to the new world of Web3 where you can mint all of your social media posts into NFTs and engage with an unlimited number of creators/influencers, fans/followers, and brands.</p>
					<p>You are minutes away from accessing the world of #.bio (Hashtag.Bio)</p>
				</div>
				<div className="home-page-link mt-3 text-center confetti-btn-sec">
					<Link to="/my-collection">Hey, just one more thing. Now, it’s your turn to create and share an NFT.</Link>
					<Link to="/">Or you can browse other collections</Link>
				</div>
				<ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
			</div>
		</>
	);
};

export default ConfettiIndex;
