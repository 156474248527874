import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@mui/material/styles";
import CollectionCardLoader from "./CollectionCardLoader";

const ProfilerLoader = (props) => {
	const { classes, collection } = props;

	const theme = useTheme();

	return (
		<>
			<div className="item-list-sec">
				<div className="item-list-header-sec">
					<Skeleton height={100} />
				</div>
				<div className="item-list-container">
					<div className="item-list-sidebar">
						<div className="item-list-sidebar-header-sec">
							<Skeleton circle={true} width={112} height={112} />
							<Skeleton />
							<ul className="list-unstyled item-list-sidebar-list">
								<li>
									<Skeleton height={10} width={30} />
								</li>
								<li>
									<Skeleton height={10} width={30} />
								</li>
							</ul>
							<Skeleton count={4} />
						</div>
					</div>
					<div className="item-list-main-wrapper mt-4">
						<div className="item-list-tab-sec">
							<div className="item-list-tab-content-sec">
								<div className={`collection-card-loader-grid no-margin`}>
									<CollectionCardLoader
										count={12}
										classes={`mt-3`}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfilerLoader;
