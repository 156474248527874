import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, InputGroup, FormControl } from "react-bootstrap";
import "./SocialMediaImport.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import AddPropertyModal from "./AddPropertyModal";
import { connect } from 'react-redux';
import { createImportedPostToNftStart, importedSocialPostViewStart, postFilesViewBase64Start } from "../store/actions/SocialPostAction";
import { fetchNftCollectionsListStart } from "../store/actions/NftAction";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Skeleton from 'react-loading-skeleton';
import { apiConstants } from "../Constant/constants";

//Declare IPFS
const auth =
  "Basic " +
  Buffer.from(
    apiConstants.ipfs_project_id + ":" + apiConstants.ipfs_project_secret
  ).toString("base64");
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient.create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});
// const ipfs = ipfsClient({
//   host: "ipfs.infura.io",
//   port: 5001,
//   protocol: "https",
//   // apiPath: '/api/v0',
//   headers: {
//     authorization: auth,
//   },
// }); // leaving out the arguments will default to these values

const CreateNewItemIndex = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);
  const [post, setPost] = useState();
  const [addProperties, setAddProperties] = useState(false);
  const [propertiesSwapper, setPropertiesSwapper] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionList, setCollectionList] = useState([]);
  const [termsAgreed, setTermsAgreed] = useState(true);
  const [ipfsStatus, setIpfsStatus] = useState(false);

  const handleOpenAddPropertiesModal = () => {
    setPropertiesSwapper(properties);
    if (properties.length === 0) {
      handleNewProperty();
    }
    setAddProperties(true);
  };

  const closeAddPropertiesModal = () => {
    setPropertiesSwapper([]);
    setAddProperties(false);
  };

  const options = [
    { value: 'collection1', label: 'Collection1' },
    { value: 'collection2', label: 'Collection2' },
  ]

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: '#F5F5F5!important',
      border: '0!important',
      boxShadow: 'none',
      height: '45px',
      minWidth: "250px",

    }),
    singleValue: provided => ({
      ...provided, color: '#989CA1', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "1.1em", fontWeight: "600"
    }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#4b4b4b!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
  }

  const createNftItemschema = Yup.object().shape({
    name: Yup.string().required("NFT name is required"),
    external_link: Yup.string().required("Link is required"),
    royalty_wallet_address: Yup.string().when("royalty_percentage", {
      is: (royalty_percentage) => Number(royalty_percentage) > 0,
      then: Yup.string().required("Royalty Wallet address is required"),
      otherwise: Yup.string(),
    }),
  });

  const handleNewProperty = () => {
    let newPropety = {
      name: "",
      value: "",
      nft_value_id: "",
    };
    setPropertiesSwapper([...propertiesSwapper, newPropety]);
  };

  const handlePropertyNameChange = (index, value) => {
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            name: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyValueChange = (index, value) => {
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            value: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyDelete = (removeCollection) => {
    setPropertiesSwapper(
      propertiesSwapper.filter((collection) => collection != removeCollection)
    );
  };

  const handlePropertySave = () => {
    const saveProperties = propertiesSwapper.filter(
      (collection) =>
        collection.name.trim() != "" && collection.value.trim() != ""
    );

    setProperties(saveProperties);
    closeAddPropertiesModal();
  };

  const handleSubmit = (values) => {
    setIpfsStatus(true);
    makeIpfs(values);
  };

  const makeIpfs = async (values) => {
    // const baseData = post.postFile;
    let ipfsData = [];
    // baseData.map(async (file, i) => {
    let base64 = await convertDataURIToBinaryFF(post.postFile);
    const imageData = Buffer(base64);
    const result = await ipfs.add(imageData);
    if (!result || !result.path) {
      console.error("Ipfs add failed");
      setIpfsStatus(false);
      return;
    }
    ipfsData = {
      post_file: "https://nftmeta.infura-ipfs.io/ipfs/" + result.path,
      // file_type: file.file_type,
    };
    console.log(ipfsData);
    // if (ipfsData.length === baseData.length) {
    // generateNFTHtml(values, ipfsData);
    // }
    // });
    completed(values, ipfsData, post.postFile)
  };

  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };

  const completed = async (values, imageIpfs, picture) => {
    let allWords = "";
    let attributes = [];
    properties.map((property) => {
      attributes.push({ trait_type: property.name, value: property.value });
      allWords = allWords + " " + property.value;
    });
    let metadata = JSON.stringify({
      description: values.description,
      external_url: values.external_link,
      image: imageIpfs.post_file,
      name: values.name,
      text: allWords,
      attributes,
    });
    console.log(metadata);

    metadata = Buffer(metadata);
    const cidArr = await ipfs.add(metadata);
    if (!cidArr || !cidArr.path) {
      console.error("Ipfs add failed");
      setIpfsStatus(false);
      return;
    }
    const cid = cidArr.path;
    // const tokenURIHash = await ipfsJson.cat(cid);
    props.dispatch(
      createImportedPostToNftStart({
        ...values,
        post_unique_id: params.post_unique_id,
        token_uri: "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
        picture: picture,
        file_type: 1,
        item_type: 2,
        is_minted: 0,
        properties: JSON.stringify(properties),
        nft_collection_id: selectedCollection.nft_collection_id,
        wallet_address: selectedCollection.deployed_wallet_address,
      })
    );
  };


  useEffect(() => {
    props.dispatch(
      postFilesViewBase64Start({ post_unique_id: params.post_unique_id })
    );
    props.dispatch(
      importedSocialPostViewStart({ post_unique_id: params.post_unique_id })
    );
    props.dispatch(fetchNftCollectionsListStart());
  }, [params.post_unique_id]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.postsFilesViewBase64.loading &&
      !props.singlePost.loading
    ) {
      if (Object.keys(props.postsFilesViewBase64.data).length > 0 &&
        Object.keys(props.singlePost.data).length > 0) {

        let postData = {
          ...props.singlePost.data.social_imported_post,
          postFile: props.postsFilesViewBase64.data.post_file_data,
        };
        // setTemplateDoc(nftTemlate.getNftTemplate(postData));
        setPost(postData);
      } else {
        navigate("/social-media-select-post");
      }
    }
  }, [props.postsFilesViewBase64, props.singlePost]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.collectionList.loading &&
      Object.keys(props.collectionList.data).length === 0
    ) {
      navigate("/profile");
    } else if (!props.collectionList.loading &&
      Object.keys(props.collectionList.data).length > 0 && props.collectionList.data.collections.length > 0) {
      let collections = props.collectionList.data.collections
        .filter(
          (collection) =>
            collection.collection_type === 2
        )
        .map((collection, index) => {
          return { value: index, label: collection.name }
        })
      setCollectionList(collections)
    }
  }, [props.collectionList]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveNftCollectionsItems.loading
    ) {
      if (Object.keys(props.saveNftCollectionsItems.data).length > 0) {
        navigate(`/assets/${props.saveNftCollectionsItems.data.nft_collection.blockchain_type}/
        ${props.saveNftCollectionsItems.data.nft_collection.nft_collection_unique_id}/
        ${props.saveNftCollectionsItems.data.nft_collection.contract_address}/
        ${props.saveNftCollectionsItems.data.nft_collection_item.token_id}`);
        // props.dispatch(
        //   postNftStatusUpdateStart({
        //     post_unique_id: params.post_unique_id,
        //   })
        // );
        setIpfsStatus(false);
      } else {
        setIpfsStatus(false);
      }
    }
    setSkipRender(false);
  }, [props.saveNftCollectionsItems]);

  const validateFields = (value) => {
    let formattedValue = value.replaceAll(" ", "");
    let msg = "";
    if (formattedValue.length <= 0) msg = "Invalid formate.";
    return msg;
  };


  return <>
    <div className="social-create-new-item-sec">
      <Container>

        <Row>
          <Col md={12}>
            <div className="social-create-new-item-header-sec">
              <h3>Create New Item</h3>
            </div>
          </Col>
        </Row>
        {props.singlePost.loading || !props.singlePost.data.social_imported_post ?
          <Row>
            <Col md={6}>
              <div className="nft-preview-img-sec">
                <Skeleton className="nft-preview-img" />
              </div>
            </Col>
            <Col md={6}>
              <div className="nft-preview-img-sec">
                <Skeleton className="nft-preview-img" />
              </div>
            </Col>
          </Row>
          :
          <Formik
            initialValues={{
              name: "",
              description: props.singlePost.data.social_imported_post.text,
              external_link: "",
              royalty_percentage: 0,
              royalty_wallet_address: "",
            }}
            className="w-100"
            validationSchema={createNftItemschema}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize
          >
            {({ errors, touched, setFieldValue, values, isSubmitting }) =>
              <FORM className="social-create-new-item-form edit-profile-form">
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        <div className="nft-preview-img-sec">
                          <Image
                            className="nft-preview-img"
                            src={props.singlePost.data.social_imported_post.imageUrl}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name*</Form.Label>
                      <Field
                        className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                        placeholder="NFT name"
                        name="name"
                        validate={validateFields}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback mt-3"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder="Description"
                        as="textarea"
                        name="description"
                        rows={3}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>External Link*</Form.Label>
                      <p>
                        Marketplace will include a link to this URL on this
                        item's detail page, so that users can click to learn
                        more about it. You are welcome to link to your own
                        webpage with more details.
                      </p>
                      <Field
                        name="external_link"
                        placeholder="https://yoursite.io/item/123"
                        className={`form-control 
                        ${touched.external_link && errors.external_link
                            ? "is-invalid"
                            : ""
                          }`}
                        validate={validateFields}
                      />
                      <ErrorMessage
                        component="div"
                        name="external_link"
                        className="invalid-feedback mt-3"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Collection*</Form.Label>
                      <p>
                        If you want to add new collection, you can create
                        collection in{" "}
                        <a
                          href="#"
                        >
                          NFT Social Marketplace
                        </a>
                      </p>
                      {props.collectionList.loading ? null : props
                        .collectionList.data ? (
                        props.collectionList.data.collections.length >
                          0 ? (
                          <div className="new-select-dropdown mb-3">
                            <Select options={collectionList} styles={customStyles}
                              isSearchable={false}
                              onChange={(collection) => setSelectedCollection(
                                props.collectionList.data.collections[
                                collection.value
                                ]
                              )}
                            />
                          </div>
                        ) : null
                      ) : (
                        <div className="text-danger">
                          Error fetching collection details, please try again.
                        </div>
                      )}
                      {/* <div className="new-select-dropdown mb-3">
                        <Select
                          options={options}
                          styles={
                            customStyles}
                          // menuIsOpen={true}
                          isSearchable={false}
                        />
                      </div> */}
                    </Form.Group>
                    {selectedCollection &&
                      selectedCollection.royalty_type == 2 ?
                      <div className="royality-social-create-new-item">
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Royalties</Form.Label>
                              <p>
                                Collect a fee when a user re-sells an Item you
                                originally created. This is deducted from the
                                final sale price and paid monthly to a payout
                                address of your choosing.
                              </p>
                              <Field
                                className="form-control"
                                name="royalty_percentage"
                                type="number"
                                placeholder="1"
                              />
                            </Form.Group>
                          </Col>
                          {Number(values.royalty_percentage) > 0 && (
                            <Col md={12}>
                              <Form.Group className="mb-3" >
                                <Form.Label>Royalty Wallet Address*</Form.Label>
                                <p>
                                  Royalty percentage will be sent to the provided
                                  wallet address. Ensure the wallet address is
                                  correct, you will not be able to change the
                                  wallet address once set.
                                </p>
                                <Field
                                  className="form-control"
                                  name="royalty_wallet_address"
                                  type="text"
                                  placeholder="Wallet address"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="royalty_wallet_address"
                                  className="invalid-feedback mt-3"
                                />
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                      </div>
                      : null
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <div className="properties-collapse-sec">
                        <div className="properties-collapse-info">
                          <h4>Properties</h4>
                          <p>textual traits that show up as rectangles</p>
                        </div>
                        <div className="properties-collapse-btn-sec">
                          <Button type="button" onClick={() => handleOpenAddPropertiesModal()}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
                            </svg>
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                    <div className="properties-item-box">
                      {properties.map((property, i) => (
                        <>
                          {property.name != "" && property.value != "" && (
                            <div className="properties-item-card">
                              <p>{property.name}</p>
                              <h4>{property.value}</h4>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className="mt-4 save-btn-sec">
                  <Button
                    type="submit"
                    className="btn btn-success"
                    disabled={
                      !termsAgreed ||
                      !selectedCollection ||
                      ipfsStatus
                    }
                  >
                    {ipfsStatus ? "Loading" : "Save"}
                  </Button>
                </div>
              </FORM>
            }
          </Formik>
        }
      </Container>
    </div>
    {addProperties ?
      <AddPropertyModal
        addProperty={addProperties}
        closeAddPropertyModal={closeAddPropertiesModal}
        properties={propertiesSwapper}
        handleNewProperty={handleNewProperty}
        handlePropertyNameChange={handlePropertyNameChange}
        handlePropertyValueChange={handlePropertyValueChange}
        handlePropertyDelete={handlePropertyDelete}
        handlePropertySave={handlePropertySave}
      />
      : null
    }
  </>;
};

const mapStateToPros = (state) => ({
  singlePost: state.socialPost.singleSocialPostImport,
  postsFilesViewBase64: state.socialPost.postsFilesViewBase64,
  collectionList: state.nfts.collectionList,
  saveNftCollectionsItems: state.socialPost.saveSocialNftItem,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateNewItemIndex);
