import React from "react";
import Slider from "react-slick";
import { Image } from "react-bootstrap";

const LoginSlider = () => {
  var slider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };
  return (
    <div>
      <div className="auth-slider">
        <Slider {...slider}>
          <div className="login-slider">
            <Image src={window.location.origin + "/images/auth/img-1.png"} />
            <div className="auth-slider-content">
              <h3>Start making money from your social media today!</h3>
              <p>
                Just fill in the form on the right and you will get FREE access to amazing tools to monetize, manage, market and measure your social media in ways you never would imagine.
              </p>
            </div>
          </div>
          <div className="login-slider">
            <Image src={window.location.origin + "/images/auth/img-2.png"} />
            <div className="auth-slider-content">
              <h3>Yes, we will turn your social media post into NFT's for FREE.How? By allowing you to turn your social media posts into NFTs for FREE and sell them</h3>
              <p>
                You will be able to mint any of your content without the need of a wallet or crypto account. Why? We give you a FREE NFT wallet and allow you to trade NFTs with a credit or debit card.
              </p>
            </div>
          </div>
          <div className="login-slider">
            <Image src={window.location.origin + "/images/auth/img-3.png"} />
            <div className="auth-slider-content">
              <h3>Finally, you can start making money off your social media and get rewarded for doing what you love</h3>
              <p>
                We made this so simple that anyone can participate and start minting social NFTs within minutes, whether you’re a creator/influencer, a fan/follower, a brand, or even just you.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default LoginSlider;
