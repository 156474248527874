import React, { useContext } from 'react';
import {
  Image,
  Button,
  Table
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import configuration from "react-global-configuration";
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../authprovider/chains";
import { connect } from 'react-redux';
import NoDataFound from '../NoDataFound/NoDataFound';
import TooManyRequest from '../404/TooManyRequest';


const CollectionActivityTable = (props) => {

  return (
    <div className="collection-activity-card-sec">
      {props.payments ?
        props.payments.length > 0 ?
          <div className="collection-activity-table-sec">
            <Table size="sm" responsive>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Item</th>
                  <th>Price</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {props.payments.map((payment, i) =>
                  <tr>
                    <td>
                      <div className="activity-status">
                        {payment.payment_type == "1"
                          ?
                          <Image
                            src={
                              window.location.origin + "/images/item-list/icons/activity-sale.svg"
                            }
                            className="activity-status-icon"
                          />
                          : payment.payment_type == "2" ?
                            <Image
                              src={
                                window.location.origin + "/images/item-list/icons/activity-transfer.svg"
                              }
                              className="activity-status-icon"
                            />
                            : payment.payment_type == "3" ?
                              <Image
                                src={
                                  window.location.origin + "/images/item-list/icons/activity-list.svg"
                                }
                                className="activity-status-icon"
                              />
                              : <Image
                                src={
                                  window.location.origin + "/images/item-list/icons/activity-cancel.svg"
                                }
                                className="activity-status-icon"
                              />
                        }
                        <span>{payment.payment_type_formatted}</span>
                      </div>
                    </td>
                    <td>
                      <div className="activity-item-img-sec">
                        <Image
                          src={payment.nft_collection_item_picture}
                          className="activity-item-img"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="activity-price">
                        <Image
                          src={CHAIN_INFO[SupportedChainIdBySymbol[payment.nft_collection_item_blockchain_type]].logo}
                          className="activity-price-icon"
                        />
                        <span>{Number(
                          payment.amount
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}{" "}
                          {payment.nft_collection_item_blockchain_type}
                        </span>
                      </div>
                    </td>
                    <td>
                      {payment.from_user ?
                        <Link
                          to={
                            payment.from_user.user_unique_id ===
                              props.profile.data?.user?.user_unique_id
                              ? `/profile`
                              : `/account/${payment.from_user.user_unique_id}`
                          }
                          className="activity-user-link">
                          {payment.from_formatted}
                        </Link>
                        : "-"
                      }
                    </td>
                    <td>
                      {payment.to_user != null ? (
                        <Link
                          to={
                            payment.to_user.user_unique_id ===
                              props.profile.data?.user?.user_unique_id
                              ? `/profile`
                              : `/account/${payment.to_user.user_unique_id}`
                          }
                          className="activity-user-link"
                        >
                          {payment.to_formatted}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {payment.created ? <>
                        {configuration.get("configData.rpc_url") != "" &&
                          payment.transaction_hash != "" ? (
                          <a
                            href={`${CHAIN_INFO[SupportedChainIdBySymbol[payment.nft_collection_item_blockchain_type]].explorer}/tx/${payment.transaction_hash}`}
                            target="_blank"
                            className="activity-user-link"
                          >
                            {payment.created}
                          </a>
                        ) : (
                          <>{payment.created}</>
                        )}
                      </> : null
                      }
                    </td>
                  </tr>
                )
                }
              </tbody>
            </Table>
          </div>
          : <NoDataFound />
        : <TooManyRequest />
      }
    </div>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CollectionActivityTable);