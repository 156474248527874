import React, { useEffect, useState } from "react";
import "./Profile.css";
import {
  Nav,
  Row,
  Col,
  Tab,
  Container,
  Form,
  InputGroup,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ProfileItemsSec from "./ProfileItemsSec";
import {
  fetchNftPropertiesListStart,
  nftUserProfileItemsListStart,
  loadMoreUserProfileItemsStart,
} from "../store/actions/NftAction";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";

const ProfileIndex = (props) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("collected");
  const [skipRender, setSkipRender] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    let tab = "collected";
    if (location.pathname === "/profile-favorited") {
      tab = "favorites";
    } else if (location.pathname === "/profile-created") {
      tab = "created";
    }
    setActiveTab(tab);
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setSearch("");
      fetchInitialDetails(tab, "");
    }
  }, [location.pathname, props.profile]);

  const fetchInitialDetails = (tab = activeTab, searchValue) => {
    props.dispatch(
      nftUserProfileItemsListStart({
        type: tab,
        user_unique_id: props.profile.data.user.user_unique_id,
        skip: 0,
        take: 12,
        search_key: searchValue,
      })
    );
  }

  const fetchMoreItems = () => {
    props.dispatch(loadMoreUserProfileItemsStart({
      type: activeTab,
      user_unique_id: props.profile.data.user.user_unique_id,
      skip: props.userProfileItems.data.nft_collection_items.length,
      take: 12,
      search_key: search,
    }));
  }

  const searchItem = (value) => {
    setSearch(value);
    if (value === "" || value.length > 2) {
      fetchInitialDetails(activeTab, value);
    }
  }

  const onCopy = () =>{
    getSuccessNotificationMessage("Wallet address copied");
  }

  return (
    <>
      {props.profile.loading ?
        <>
          <div className="profile-sec mb-3">
            <Skeleton height={148} />
          </div>
          <div className="row d-flex justify-content-center">
            {[...Array(3)].map((i, j) => <Skeleton key={j} containerClassName="col-auto" className="tab-loader" />)}
          </div>
          <Container>
            <Row>
              <div className="collection-item-card-sec">
                <div className="collection-item-card-box">
                  {[...Array(4)].map((val, i) => (
                    <Skeleton height={380} key={i} />
                  ))}
                </div>
              </div>
            </Row>
          </Container>
        </>
        : props.profile.data.user
          ? <div className="profile-sec">
            <div className="profile-banner-sec new" style={{background: `url(${props.profile.data.user.cover})`}}>
              <Container>
                <div className="profile-banner-content">
                  <div className="profile-banner-img-box">
                    <Image
                      src={props.profile.data.user.picture}
                      className="profile-img"
                    />
                    <div className="profile-img-content">
                      <h4>{props.profile.data.user.name}</h4>
                      <p>
                        {/* 0x38c…ce86 */}
                        {props.profile.data.user.wallet_address.substring(0, 5)}
                        {"..."}
                        {props.profile.data.user.wallet_address.substring(props.profile.data.user.wallet_address.length - 4, props.profile.data.user.wallet_address.length)}
                        <CopyToClipboard text={props.profile.data.user.wallet_address} onCopy={() => onCopy()}>
                          <Button>
                            <Image
                              src={window.location.origin + "/images/icons/copy.svg"}
                              className="copy-icon"
                            />
                          </Button>
                        </CopyToClipboard>
                      </p>
                    </div>
                  </div>
                  <div className="edit-profile-link">
                    <Link to="/profile-setting">
                      <span>
                        <Image
                          src={window.location.origin + "/images/profile/edit.svg"}
                        />
                      </span>
                      Edit Profile
                    </Link>
                  </div>
                </div>
              </Container>
            </div>

            <div className="settings-sec">
              <Container>
                <div className="settings-tab-sec">
                  <Tab.Container id="left-tabs-example" >
                    <Row className=" align-items-center">
                      <Col md={8} xl={5} lg={8} className="ms-auto resp-marg-btm-xs">
                        <Nav variant="pills">
                          <Nav.Item>
                            <Link to="/profile" className={`nav-link ${activeTab === "collected" ? "active" : ""}`}>
                              <div className="setting-header">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25.926"
                                  height="25.914"
                                  viewBox="0 0 25.926 25.914"
                                  fill="none"
                                  stroke="none"
                                >
                                  <g
                                    id="dots-white"
                                    transform="translate(-3.992 -4.007)"
                                  >
                                    <path
                                      id="Path_56669"
                                      data-name="Path 56669"
                                      d="M20.439,5.3,17.032,8.7a.949.949,0,0,0,0,1.343l3.4,3.4a.949.949,0,0,0,1.343,0l3.407-3.407a.949.949,0,0,0,0-1.343l-3.4-3.4A.949.949,0,0,0,20.439,5.3Z"
                                      transform="translate(-4.155 0)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56670"
                                      data-name="Path 56670"
                                      d="M32.191,17.047l-3.407,3.407a.949.949,0,0,0,0,1.343l3.4,3.4a.949.949,0,0,0,1.343,0l3.407-3.407a.949.949,0,0,0,0-1.343l-3.4-3.4A.949.949,0,0,0,32.191,17.047Z"
                                      transform="translate(-8.311 -4.155)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56671"
                                      data-name="Path 56671"
                                      d="M8.689,17.03,5.282,20.437a.949.949,0,0,0,0,1.343l3.4,3.4a.949.949,0,0,0,1.343,0l3.407-3.407a.949.949,0,0,0,0-1.343l-3.4-3.4A.949.949,0,0,0,8.689,17.03Z"
                                      transform="translate(0 -4.149)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56672"
                                      data-name="Path 56672"
                                      d="M20.441,28.78l-3.407,3.407a.949.949,0,0,0,0,1.343l3.4,3.4a.949.949,0,0,0,1.343,0l3.407-3.407a.949.949,0,0,0,0-1.343l-3.4-3.4A.949.949,0,0,0,20.441,28.78Z"
                                      transform="translate(-4.156 -8.304)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                  </g>
                                </svg>
                                <span>Collected</span>
                              </div>
                            </Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Link to="/profile-created" className={`nav-link ${activeTab === "created" ? "active" : ""}`}>
                              <div className="setting-header">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23.909"
                                  height="21.947"
                                  viewBox="0 0 23.909 21.947"
                                  fill="none"
                                  stroke="none"
                                >
                                  <g id="wand" transform="translate(-5.489 -5.472)">
                                    <path
                                      id="Path_56674"
                                      data-name="Path 56674"
                                      d="M39.656,23.5v5.161"
                                      transform="translate(-13.74 -7.052)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56675"
                                      data-name="Path 56675"
                                      d="M35.25,27.906h5.161"
                                      transform="translate(-11.914 -8.878)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56676"
                                      data-name="Path 56676"
                                      d="M15.422,7.344v4.3"
                                      transform="translate(-3.699 -0.358)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56677"
                                      data-name="Path 56677"
                                      d="M11.75,11.016h4.3"
                                      transform="translate(-2.177 -1.88)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56678"
                                      data-name="Path 56678"
                                      d="M30.844,33.781v3.441"
                                      transform="translate(-10.088 -11.312)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56679"
                                      data-name="Path 56679"
                                      d="M27.906,36.719h3.441"
                                      transform="translate(-8.871 -12.529)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56680"
                                      data-name="Path 56680"
                                      d="M22.737,6.731,6.747,22.72a.86.86,0,0,0,0,1.216l2.224,2.224a.86.86,0,0,0,1.216,0l15.99-15.99a.86.86,0,0,0,0-1.216L23.953,6.731A.86.86,0,0,0,22.737,6.731Z"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                    <path
                                      id="Path_56681"
                                      data-name="Path 56681"
                                      d="M26.438,14.688l3.441,3.441"
                                      transform="translate(-8.263 -3.401)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.8"
                                    />
                                  </g>
                                </svg>
                                <span>Created</span>
                              </div>
                            </Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Link to="/profile-favorited" className={`nav-link ${activeTab === "favorites" ? "active" : ""}`}>
                              <div className="setting-header">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26.464"
                                  height="23.537"
                                  viewBox="0 0 26.464 23.537"
                                  fill="none"
                                  stroke="none"
                                >
                                  <path
                                    id="heart"
                                    d="M17.372,28.872S5.141,22.022,5.141,13.7a6.361,6.361,0,0,1,12.232-2.446h0A6.361,6.361,0,0,1,29.6,13.7C29.6,22.022,17.372,28.872,17.372,28.872Z"
                                    transform="translate(-4.141 -6.335)"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                  />
                                </svg>

                                <span>Favorited</span>
                              </div>
                            </Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col md={4} xl={3} lg={4}>
                        <div className="header-search profile-search">
                          <Form onSubmit={e => e.preventDefault()}>
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/landing-page/icons/search-icon.svg"
                                  }
                                />
                              </InputGroup.Text>
                              <Form.Control
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={search}
                                onChange={e => searchItem(e.target.value)}
                              />
                              {search
                                ? <InputGroup.Text
                                  id="basic-addon1"
                                  className="search-close-icon"
                                >
                                  <Button onClick={e => searchItem("")}>
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/images/icons/close.svg"
                                      }
                                    />
                                  </Button>
                                </InputGroup.Text>
                                : null
                              }
                            </InputGroup>
                          </Form>
                        </div>
                      </Col>
                      {/* <Col sm={1}>
                    <div className="sort-by-icons">
                      <div className="sort-img">
                        <Button>
                          <Image
                            src={
                              window.location.origin +
                              "/images/profile/arrow-1.svg"
                            }
                          />
                        </Button>
                      </div>
                      <div className="sort-img">
                        <Button>
                          <Image
                            src={
                              window.location.origin +
                              "/images/profile/arrow-2.svg"
                            }
                          />
                        </Button>
                      </div>
                    </div>
                  </Col> */}
                    </Row>
                    <Row>
                      {props.userProfileItems.loading
                        ? <div className="collection-item-card-sec">
                          <div className="collection-item-card-box">
                            {[...Array(4)].map((val, i) => (
                              <Skeleton height={380} key={i} />
                            ))}
                          </div>
                        </div>
                        : <Col sm={12}>
                          <InfiniteScroll
                            dataLength={props.userProfileItems.data.nft_collection_items.length}
                            next={fetchMoreItems}
                            hasMore={props.userProfileItems.data.nft_collection_items.length < props.userProfileItems.data.filter_total}
                            loader={<div className="collection-item-card-sec">
                              <div className="collection-item-card-box">
                                {[...Array(4)].map((val, i) => (
                                  <Skeleton height={380} key={i} />
                                ))}
                              </div>
                            </div>}
                            style={{ height: 'auto', overflow: 'hidden' }}
                          >
                            <ProfileItemsSec items={props.userProfileItems.data.nft_collection_items} />
                          </InfiniteScroll>
                        </Col>
                      }
                    </Row>
                  </Tab.Container>
                </div>
              </Container>
            </div>
          </div>
          : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  userProfileItems: state.nfts.userProfileItems,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
