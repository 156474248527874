import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./CreateCollection.css";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Formik, Form as FORM, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  nftCollectionsSaveStart,
  nftCollectionNameValidationStart,
} from "../store/actions/NftAction";
import { useNavigate } from "react-router-dom";
import { authContext } from "../authprovider/AuthProvider";
import NftToken from "../../abis/NftToken.json";
import Web3 from "web3";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import { CHAIN_INFO, SupportedChainId } from "../authprovider/chains";
import configuration from "react-global-configuration";
import ImageIcon from "@mui/icons-material/Image";

const CreateCollection = (props) => {
  const { categories } = props;

  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const { auth, connectWallet, getProviderSigner } = useContext(authContext);

  const [networkSelect, setNetworkSelect] = useState([
    { name: "Polygon", id: 1 },
  ]);

  const [skipInitalRender, setSkipInitalRender] = useState(false);

  const [validCollectionName, setValidCollectionName] = useState();

  const [termsAgreed, setTermsAgreed] = useState(false);

  const [collectionData, setCollectionData] = useState({
    name: "",
    symbol: "",
    external_link: "",
    category_id: "",
    website_link: "",
    discord_link: "",
    twitter_link: "",
    instagram_link: "",
    medium_link: "",
    telegram_link: "",
    description: "",
    contract_address: "",
    royalty_percentage: "",
    collection_type: "1",
    royalty_wallet_address: "",
    deployed_wallet_address: "",
    royalty_type: "",
  });

  const [inputData, setInputData] = useState({
    name: "",
    symbol: "",
  });

  const [buttonContent, setButtonContent] = useState("");

  const [logoImage, setLogoImage] = useState({
    file: "",
    preview_image: "",
  });

  const [coverImage, setCoverImage] = useState({
    file: "",
    preview_image: "",
  });

  const [royaltyType, setRoyaltyType] = useState(1);

  const createNftCollectionchema = Yup.object().shape({
    picture: Yup.mixed().required("Logo is required"),
    name: Yup.string().required("Collection name is required"),
    symbol: Yup.string().required("Symbol is required"),
    royalty_wallet_address: Yup.string().when("royalty_percentage", {
      is: (royalty_percentage) => Number(royalty_percentage) > 0,
      then: Yup.string().required("Royalty Wallet address is required"),
      otherwise: Yup.string(),
    }),
  });

  const handleNetworkSelect = (value) => {
    setNetworkSelect([value]);
  };

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setLogoImage({
        ...logoImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddCoverImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setCoverImage({
        ...coverImage,
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (values) => {
    const newData = {
      ...values,
      cover: coverImage.file,
      royalty_percentage:
        collectionData.royalty_percentage != ""
          ? collectionData.royalty_percentage
          : "0",
    };

    if (collectionData.collection_type == "1") {
      deployContract(newData);
    } else {
      deployLazyMintingContract(newData);
    }
  };

  const handleSocialLinkChanges = (event) => {
    setCollectionData({
      ...collectionData,
      [event.target.name]: event.target.value,
    });
  };

  const handleRoyaltyChange = (event) => {
    setCollectionData({
      ...collectionData,
      royalty_percentage: isInt(Number(event.target.value))
        ? event.target.value
        : Number(event.target.value).toFixed(1),
    });
  };

  const handleCategorySelect = (value) => {
    setCollectionData({
      ...collectionData,
      category_id: value,
    });
  };

  useEffect(() => {
    if (
      !props.collectionSave.loading &&
      Object.keys(props.collectionSave.data).length > 0
    ) {
      navigate(
        `/collection/${props.collectionSave.data.nft_collection_unique_id}`
      );
    }
  }, [props.collectionSave.data]);

  useEffect(() => {
    if (skipInitalRender) {
      if (collectionData.name.length == 0) {
        setValidCollectionName(null);
      }
      const delayDebounceFn = setTimeout(() => {
        if (collectionData.name.length > 2) {
          props.dispatch(
            nftCollectionNameValidationStart({ name: collectionData.name })
          );
        }
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    setSkipInitalRender(true);
  }, [collectionData.name]);

  useEffect(() => {
    if (skipInitalRender && !props.collectionNameValidation.loading) {
      if (props.collectionNameValidation.data.code == 122) {
        setValidCollectionName(true);
      } else {
        setValidCollectionName(false);
      }
    }
  }, [props.collectionNameValidation.data]);

  // blockchain part
  const deployContract = async (newData) => {
    if (!auth.authStatus) {
      const notificationMessage = getErrorNotificationMessage(
        "Please connect metamask."
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
    setButtonContent("Your project is being created...");

    let transaction_hash = "";

    const web3 = window.web3;
    const ecr721Token = new web3.eth.Contract(NftToken.abi);
    try {
      const res = await ecr721Token
        .deploy({
          data: NftToken.bytecode,
          arguments: [
            collectionData.name,
            collectionData.symbol,
            auth.accounts,
            collectionData.royalty_wallet_address == ""
              ? auth.accounts
              : collectionData.royalty_wallet_address,
            collectionData.royalty_percentage == ""
              ? "0"
              : String(Number(collectionData.royalty_percentage) * 10),
            royaltyType == 1 ? true : false,
          ],
        })
        .send(
          {
            from: auth.accounts,
          },
          function (error, transactionHash) {
            // API call....
            console.log("Txt", transactionHash);
            transaction_hash = transactionHash;
          }
        )
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("con", confirmationNumber);
        })
        .then(async (newContractInstance) => {
          console.log("New token created.", newContractInstance.address);
          console.log(
            "name",
            await newContractInstance.methods.name.call().toString()
          );
          // Todo - Send notification.
          console.log("Contract Address", newContractInstance.options.address); // instance with the new contract address
          // saving contract address

          const updatedData = {
            ...newData,
            contract_address: newContractInstance.options.address,
            deployed_wallet_address: auth.accounts,
            blockchain_type: CHAIN_INFO[auth.chainId].nativeCurrency.symbol,
            transaction_hash: transaction_hash,
            royalty_type: royaltyType,
          };
          props.dispatch(nftCollectionsSaveStart(updatedData));
          // props.dispatch(
          //   settingsUpdateStart({
          //     contract_address: newContractInstance.options.address,
          //   })
          // );
          setButtonContent("");
          // Save the token contract address.
        });
    } catch (error) {
      console.log("errr", error);
      const notificationMessage = getErrorNotificationMessage(error.message);
      props.dispatch(createNotification(notificationMessage));
      setButtonContent("");
    }
  };

  //prevent reload

  useEffect(() => {
    if (buttonContent != "") {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [buttonContent]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (collectionData.royalty_percentage == "") {
      setCollectionData({
        ...collectionData,
        royalty_percentage: "",
        royalty_wallet_address: "",
      });
    }
  }, [collectionData.royalty_percentage]);

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  const handleTermsChange = () => {
    setTermsAgreed((prev) => !prev);
  };

  // blockchain part
  const deployLazyMintingContract = async (newData) => {
    if (!auth.authStatus) {
      const notificationMessage = getErrorNotificationMessage(
        "Please connect metamask."
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
    setButtonContent("Deploying contract...");

    const txMessage = `
      Welcome to ${configuration.get("configData.site_name")}!
  
      Click to sign in and accept the ${configuration.get(
        "configData.site_name"
      )} Terms of Service: ${configuration.get("configData.frontend_url")}
  
        This request will not trigger a blockchain transaction or cost any gas fees.
  
        Wallet address:
        ${auth.accounts}`;

    let result = await getProviderSigner(txMessage);
    if (result) {
      let lazy_contract_address =
        auth.chainId == configuration.get("configData.binance_network_id")
          ? configuration.get(
              "configData.binance_lazy_minting_contract_address"
            )
          : auth.chainId == configuration.get("configData.ethereum_network_id")
          ? configuration.get(
              "configData.ethereum_lazy_minting_contract_address"
            )
          : configuration.get(
              "polygon_lazy_minting_contract_address.ethereum_network_id"
            );

      const updatedData = {
        ...newData,
        contract_address: lazy_contract_address,
        deployed_wallet_address: auth.accounts,
        blockchain_type: CHAIN_INFO[auth.chainId].nativeCurrency.symbol,
        sign_transaction_hash: result,
        royalty_type: royaltyType,
      };
      props.dispatch(nftCollectionsSaveStart(updatedData));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Sign transaction failed!"
      );
      props.dispatch(createNotification(notificationMessage));
      setButtonContent("");
    }
  };

  return (
    <>
      <div className="create-new-collection-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="create-new-collection-header">
                <Image
                  src={
                    window.location.origin +
                    "/images/landing-page/icons/back-arrow.svg"
                  }
                  className="back-arrow-icon"
                />
                <div className="create-new-collection-title">
                  <h2>Create New Collection</h2>
                </div>
              </div>
              <div className="create-new-collection-box">
                <div className="create-new-collection-sub-title">
                  <Image
                    src={
                      window.location.origin +
                      "/images/create-collection/icons/create-collection-icon.svg"
                    }
                    className="create-new-collection-icon"
                  />
                  <span>New Collection</span>
                </div>
                <Row className="justify-content-md-center">
                  <Col md={10} xl={6} lg={7}>
                    <Formik
                      initialValues={{
                        ...collectionData,
                        picture: logoImage.file,
                        cover: coverImage.file,
                      }}
                      className="w-100"
                      validationSchema={createNftCollectionchema}
                      onSubmit={(values) => handleSubmit(values)}
                      enableReinitialize
                    >
                      {({ errors, touched, setFieldValue, values }) => (
                        <>
                          <FORM className="create-collection-form">
                            <Field
                              type="text"
                              name="name"
                              placeholder="Collection name*"
                              className={`form-control mb-3 ${
                                touched.name && errors.name ? "is-invalid" : ""
                              }`}
                              value={collectionData.name}
                              onChange={(event) =>
                                handleSocialLinkChanges(event)
                              }
                            />
                            <ErrorMessage
                              component="div"
                              name="name"
                              className="invalid-feedback mt-3"
                            />
                            {validCollectionName != undefined &&
                              validCollectionName && (
                                <p className="text-success mt-3">
                                  Valid collection name
                                </p>
                              )}
                            {validCollectionName != undefined &&
                              !validCollectionName && (
                                <p className="invalid-feedback mt-3">
                                  InValid collection name
                                </p>
                              )}
                            <Field
                              type="text"
                              name="symbol"
                              placeholder="Collection symbol*"
                              className={`form-control mb-3 ${
                                touched.symbol && errors.symbol
                                  ? "is-invalid"
                                  : ""
                              }`}
                              value={collectionData.symbol}
                              onChange={(event) =>
                                handleSocialLinkChanges(event)
                              }
                            />
                            <ErrorMessage
                              component="div"
                              name="symbol"
                              className="invalid-feedback mt-3"
                            />
                            <textarea
                              type="text"
                              name="description"
                              placeholder="Description"
                              className={`form-control mb-3`}
                              value={collectionData.description}
                              onChange={(event) =>
                                handleSocialLinkChanges(event)
                              }
                            />
                            <div className="create-collection-logo-img">
                              <Form.Label>Logo Image*</Form.Label>
                              <div className="create-collection-logo-item">
                                {/* <div {...getRootProps()}>
																<input {...getInputProps()} />
																<div className="create-collection-logo-icon-card">
																	<Image
																		src={
																			window.location.origin + "/images/create-collection/icons/plus-icon.svg"
																		}
																		className="create-collection-logo-icon"
																	/>
																</div>
															</div> */}
                                <div className="profileButton custom-input">
                                  {logoImage.preview_image != "" && (
                                    <div className="preview-image">
                                      <img
                                        src={logoImage.preview_image}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <input
                                    className="profileButton-input "
                                    type="file"
                                    name="logo"
                                    accept="image/*"
                                    id="upload"
                                    onChange={(event) =>
                                      handleAddImageChange(event)
                                    }
                                  />
                                  <label
                                    className="profileButton-button ripple-effect"
                                    htmlFor="upload"
                                  >
                                    <ImageIcon />
                                  </label>
                                </div>
                                <div className="create-collection-external-link">
                                  <div className="create-collection-note">
                                    <p>
                                      Upload a Logo Image for navigation.{" "}
                                      <strong>350x350 pixels</strong> works
                                      best.
                                    </p>
                                    <ErrorMessage
                                      component="div"
                                      name="picture"
                                      className="invalid-feedback mt-3"
                                    />
                                  </div>
                                  <Form.Group
                                    className="mb-0"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>
                                      External Link
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/images/create-collection/icons/info-icon.svg"
                                        }
                                        className="info-icon"
                                      />
                                    </Form.Label>
                                    <input
                                      type="text"
                                      name="external_link"
                                      className="form-control"
                                      placeholder="http://yoursite.io/item/123"
                                      value={collectionData.external_link}
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                    {/* <Form.Control type="email" placeholder="http://yoursite.io/youritem/123" /> */}
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                            <div className="create-collection-banner-img">
                              <Form.Label>Banner Image*</Form.Label>
                              <div className="create-collection-banner-item preview-box">
                                {/* <div {...getRootProps()}>
																<input {...getInputProps()} />
																<div className="create-collection-banner-icon-card">
																	<Image
																		src={
																			window.location.origin + "/images/create-collection/icons/plus-icon.svg"
																		}
																		className="create-collection-banner-icon"
																	/>
																</div>
															</div> */}
                                <div className="previewButton custom-input-banner">
                                  {coverImage.preview_image != "" && (
                                    <div className="preview-image">
                                      <img
                                        src={coverImage.preview_image}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <input
                                    className="previewButton-input"
                                    type="file"
                                    name="cover"
                                    accept="image/*"
                                    id="uploadCover"
                                    onChange={(event) =>
                                      handleAddCoverImageChange(event)
                                    }
                                  />
                                  <label
                                    className="previewButton-button ripple-effect"
                                    htmlFor="uploadCover"
                                  >
                                    <ImageIcon />
                                  </label>
                                </div>
                                <div className="create-collection-external-link">
                                  <div className="create-collection-note">
                                    <p>
                                      This image will appear at the top of your
                                      collection page.{" "}
                                      <strong>1400x400 pixels</strong> works
                                      best.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {categories.loading ? null : categories.data
                                .categories.length > 0 ? (
                              <Form.Group className="select-category">
                                <Form.Label>Category</Form.Label>
                                <div className="create-collection-note">
                                  <p>
                                    Adding a category will help make your item
                                    discoverable on Marketplace.
                                  </p>
                                </div>
                                <div className="create-collection-category-box">
                                  {categories.data.categories
                                    .filter((cat) => cat.status === 1)
                                    .map((category) => (
                                      <label
                                        for="collection-category-1"
                                        onClick={() =>
                                          handleCategorySelect(
                                            category.category_id
                                          )
                                        }
                                      >
                                        <input
                                          type="radio"
                                          value={category.category_id}
                                          name="collection-category"
                                          id={category.category_id}
                                          checked={
                                            category.category_id ===
                                            collectionData.category_id
                                              ? true
                                              : false
                                          }
                                        />
                                        <h4>{category.name}</h4>
                                      </label>
                                    ))}
                                </div>
                              </Form.Group>
                            ) : null}
                            <Form.Group>
                              <div className="lazy-minting-sec">
                                <div className="create-item-properties-card">
                                  <div className="create-item-properties-item">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/images/create-item/icons/lazy-miniting.svg"
                                      }
                                      className="create-item-logo-icon"
                                    />
                                    <div className="create-item-properties-info">
                                      <Form.Label>Lazy Miniting</Form.Label>
                                      <p>
                                        Buyer will pay gas fees for minting.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="create-item-properties-icon-sec">
                                    <Switch
                                      name="collection_type"
                                      value={
                                        collectionData.collection_type == "2"
                                          ? "1"
                                          : "2"
                                      }
                                      checked={
                                        collectionData.collection_type == "2"
                                      }
                                      onChange={(event) =>
                                        handleSocialLinkChanges(event)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group className="royalites-sec">
                              <Form.Label>Roayalties</Form.Label>
                              <div className="create-collection-note">
                                <p>
                                  Collect a fee when a user re-sells an item you
                                  originally created. This is deducted from the
                                  final sale price and paid monthly to a payout
                                  address of your choosing.
                                </p>
                              </div>
                              <div className="royality-collection-sec">
                                <div className="mb-3">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        name="royalty-type"
                                        id="type-image"
                                        value="1"
                                        onChange={() => setRoyaltyType(1)}
                                        control={<Radio />}
                                        label="Royalty For Collection"
                                        checked={royaltyType === 1}
                                      />
                                      <FormControlLabel
                                        id="type-dynamic"
                                        name="royalty-type"
                                        value="2"
                                        onChange={() => setRoyaltyType(2)}
                                        control={<Radio />}
                                        label="Royalty For Items"
                                        checked={royaltyType === 2}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </Form.Group>
                            {royaltyType == 1 && (
                              <div className="royalties-form">
                                <Form.Group className="mb-3">
                                  <Form.Label>Royalty Percentage</Form.Label>
                                  {/* <Form.Control type="text" placeholder="" /> */}
                                  <input
                                    type="number"
                                    name="royalty_percentage"
                                    className="form-control"
                                    placeholder="E.g 4.5"
                                    min="0"
                                    step="any"
                                    value={collectionData.royalty_percentage}
                                    onChange={(event) =>
                                      handleRoyaltyChange(event)
                                    }
                                  />
                                </Form.Group>
                                {royaltyType == 1 &&
                                  Number(collectionData.royalty_percentage) >
                                    0 && (
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Royalty Wallet Address *
                                      </Form.Label>
                                      <div className="create-collection-note">
                                        <p>
                                          Royalty percentage will be sent to the
                                          provided wallet address
                                        </p>
                                      </div>
                                      {/* <Form.Control type="text" placeholder="Wallet address" /> */}
                                      <input
                                        type="text"
                                        name="royalty_wallet_address"
                                        className="form-control"
                                        placeholder="Wallet address"
                                        value={
                                          collectionData.royalty_wallet_address
                                        }
                                        onChange={(event) =>
                                          handleSocialLinkChanges(event)
                                        }
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="royalty_wallet_address"
                                        className="invalid-feedback mt-3"
                                      />
                                    </Form.Group>
                                  )}
                              </div>
                            )}
                            <div className="profile-link create-collection-profile-link">
                              <h4 className="setting-title">Links</h4>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/icons/globe.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Yoursite.io"
                                  name="website_link"
                                  value={collectionData.website_link}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                  className="border-top-right-radius"
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/icons/globe.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="https://discord.gg/abcdef"
                                  name="discord_link"
                                  value={collectionData.discord_link}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/icons/twitter.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="https://twitter.com/YourTwitterHandle"
                                  name="twitter_link"
                                  value={collectionData.twitter_link}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/icons/instagram.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="https://www.instagram.com/YourInstagramHandle"
                                  name="instagram_link"
                                  value={collectionData.instagram_link}
                                  onChange={(event) =>
                                    handleSocialLinkChanges(event)
                                  }
                                />
                              </InputGroup>
                            </div>
                            <Form.Group>
                              <div className="agree-terms-sec">
                                <Form.Check
                                  inline
                                  name="group1"
                                  checked={termsAgreed}
                                  onChange={() => handleTermsChange()}
                                />
                                <p>
                                  I agree to the following{" "}
                                  <Link to="/page/terms">
                                    terms & conditions
                                  </Link>
                                </p>
                              </div>
                            </Form.Group>
                            <div className="create-collection-btn-sec">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={
                                  !validCollectionName ||
                                  props.collectionSave.buttonDisable ||
                                  buttonContent != "" ||
                                  !termsAgreed
                                }
                              >
                                {buttonContent == ""
                                  ? "Create Collection"
                                  : buttonContent}
                              </Button>
                            </div>
                          </FORM>
                        </>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  collectionSave: state.nfts.collectionSave,
  collectionNameValidation: state.nfts.collectionNameValidation,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateCollection);
