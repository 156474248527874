import React, { useState, useEffect, useContext } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Button,
	Table,
	Form,
	InputGroup,
	Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { transferNFTStart } from "../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import NftToken from "../../abis/NftToken.json";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import CustomAccordion from "../customComponents/CustomAccordion";
import { updateSignAndApproveStart } from "../store/actions/UserAction";
import {
	CHAIN_INFO,
	SupportedChainId,
	SupportedChainIdBySymbol,
} from "../authprovider/chains";
import { connect } from "react-redux";

const TransferNFTModal = (props) => {

	const { auth, getProviderSigner } = useContext(authContext);

	const [loadingButtonContent, setLoadingButtonContent] = useState("");

	const netID = configuration.get("configData.network_id")
		? configuration.get("configData.network_id")
		: "97";

	const transferSchema = Yup.object().shape({
		address: Yup.string().required("Wallet address is required"),
	});

	const handleSubmit = (values) => {
		if (auth.authStatus) {
			transferNft(values.address);
		}
	};

	const getSign = async () => {
		if (!auth.authStatus) {
			const notificationMessage = getErrorNotificationMessage(
				"Please connect metamask."
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}

		if (
			CHAIN_INFO[auth.chainId].nativeCurrency.symbol !=
			props.singleNftImage.data.nft_collection.blockchain_type
		) {
			const notificationMessage = getErrorNotificationMessage(
				`Collection is not deployed in the detected network. Please switch to ${props.singleNftImage.data.nft_collection.blockchain_type}`
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}

		if (
			auth.accounts !=
			props.singleNftImage.data.nft_collection_item.owner_wallet_address
		) {
			const notificationMessage = getErrorNotificationMessage(
				`The connected wallet is not the owner of this item. Please switch to ${String(
					props.singleNftImage.data.nft_collection_item.owner_wallet_address
				).substring(0, 5)}
      ...
      ${String(
					props.singleNftImage.data.nft_collection_item.owner_wallet_address
				).substring(
					props.singleNftImage.data.nft_collection_item.owner_wallet_address
						.length - 4
				)}`
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}
		props.setSignData({
			...props.signData,
			loading: true,
			loadingContent: "Signing...",
		});
		if (auth.authStatus) {
			const txMessage = `
        Welcome to ${configuration.get("configData.site_name")}!
  
        Click to sign in and accept the ${configuration.get(
				"configData.site_name"
			)} Terms of Service: ${configuration.get("configData.frontend_url")}
  
        This request will not trigger a blockchain transaction or cost any gas fees.
  
        Your authentication status will reset after 24 hours.
  
        Wallet address:
        ${auth.accounts}`;
			let result = await getProviderSigner(txMessage);
			if (result) {
				props.dispatch(
					updateSignAndApproveStart({
						is_signed: 1,
						sign_signature: result,
					})
				);
				props.setSignData({
					...props.signData,
					signature: result,
					status: true,
					loading: false,
					loadingContent: null,
				});

				props.toggleAccordion("2");
			} else {
				props.setSignData({
					...props.signData,
					loading: false,
					loadingContent: null,
				});
			}
		} else {
			props.setSignData({
				...props.signData,
				loading: false,
				loadingContent: null,
			});
		}
	};

	const transferNft = async (to_address) => {
		// NFT contract.
		setLoadingButtonContent("Initiated...");
		const web3 = window.web3;
		let nftContractData;
		const NFTData = NftToken.networks[auth.chainId];
		if (NFTData && auth.accounts) {
			try {
				setLoadingButtonContent((prevState) => "Transfering...");
				nftContractData = await new web3.eth.Contract(
					NftToken.abi,
					props.singleNftImage.data.nft_collection.contract_address
				);
				let tokenID = Number(
					props.singleNftImage.data.nft_collection_item.token_id
				);
				nftContractData.methods
					.transferFrom(auth.accounts, to_address, tokenID)
					.send({ from: auth.accounts })
					.on("receipt", (receipt) => {
						props.dispatch(
							transferNFTStart({
								nft_collection_unique_id:
									props.singleNftImage.data.nft_collection.nft_collection_unique_id,
								nft_collection_item_token_id:
									props.singleNftImage.data.nft_collection_item.token_id,
								transaction_hash:
									receipt && receipt.transactionHash
										? receipt.transactionHash
										: "",
								is_minted: 1,
								buyer_wallet_address: to_address,
							})
						);
						setLoadingButtonContent("");
						// Send success notification.
					})
					.on("error", (error) => {
						let notificationMessage;
						if (error.message == undefined) {
							notificationMessage =
								"Unexpected error occuried, Please try again...";
						} else {
							notificationMessage = error.message;
						}
						setLoadingButtonContent("");
						props.dispatch(createNotification(notificationMessage));
					});
			} catch (error) {
				setLoadingButtonContent("");
				// Send fail notification.
				const notificationMessage = getErrorNotificationMessage(
					"Something went wrong. Please refresh the page and try again."
				);
				props.dispatch(createNotification(notificationMessage));
				console.log(error);
			}
		} else {
			setLoadingButtonContent("");
			window.alert("NFT contract not deployed to detected network.");
		}
	};

	return (
		<>
			<Modal
				className="modal-dialog-center transfer-nft-modal"
				size="md"
				centered
				show={props.open}
				onHide={props.handleClose}
			>
				<Modal.Body>
					<Button className="modal-close" onClick={() => props.handleClose()}>
						<Image
							className="close-icon"
							src={
								window.location.origin + "/images/create-item/icons/modal-close.svg"
							}
						/>
					</Button>
					<div className="transfer-nft-content">
						<h4>Transfer NFT</h4>
						{props.singleNftImage.data.nft_collection_item.is_user_signed ==
							0 ? (
							<>
								{props.signData.status ? (
									<Formik
										initialValues={{
											address: "",
										}}
										className="w-100"
										validationSchema={transferSchema}
										onSubmit={(values) => handleSubmit(values)}
										enableReinitialize
									>
										{({ errors, touched }) => (
											<FORM className="transfer-nft-form">
												<Form.Group className="mb-3" controlId="formBasicEmail">
													<Form.Label>Address</Form.Label>
													{/* <Form.Control type="email" placeholder="Select Reason" /> */}
													<Field
														type="text"
														name="address"
														placeholder="Wallet Address"
														className={`form-control ${touched.address && errors.address
															? "is-invalid"
															: ""
															}`}
													/>
													<ErrorMessage
														component="div"
														name="address"
														className="invalid-feedback mt-3"
													/>
												</Form.Group>
												<div className="transfer-nft-btn-sec">
													<Button
														type="submit"
														className="default-btn"
														disabled={loadingButtonContent != ""}
													>
														{loadingButtonContent == ""
															? "Transfer"
															: loadingButtonContent}
													</Button>
												</div>
											</FORM>
										)}
									</Formik>
								) : (
									<CustomAccordion
										activeId={props.activeAccordion}
										accordionHeading={"Sign message"}
										accordionKey={"1"}
										accordionContent={
											"Submit a transaction with your wallet to trade with this currency. This only needs to be done once."
										}
										accordionButton={true}
										OnClick={() => getSign()}
										accordionButtonDisable={
											props.signData.loading ||
											props.signData.loadingContent != null
										}
										accordionButtonContent={
											props.signData.loadingContent != null
												? props.signData.loadingContent
												: "Sign"
										}
										spinner
										spinnerLoading={
											props.signData.loadingContent != null &&
											props.activeAccordion === "1"
										}
										activeAccordion={props.activeAccordion}
										loadingComplete={
											Number(props.activeAccordion) > Number("1")
										}
									/>
								)}
							</>
						) : (
							<Formik
								initialValues={{
									address: "",
								}}
								className="w-100"
								validationSchema={transferSchema}
								onSubmit={(values) => handleSubmit(values)}
								enableReinitialize
							>
								{({ errors, touched }) => (
									<FORM className="transfer-nft-form">
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<Form.Label>Address</Form.Label>
											{/* <Form.Control type="email" placeholder="Select Reason" /> */}
											<Field
												type="text"
												name="address"
												placeholder="Wallet Address"
												className={`form-control ${touched.address && errors.address
													? "is-invalid"
													: ""
													}`}
											/>
											<ErrorMessage
												component="div"
												name="address"
												className="invalid-feedback mt-3"
											/>
										</Form.Group>
										<div className="transfer-nft-btn-sec">
											<Button
												type="submit"
												className="default-btn"
												disabled={loadingButtonContent != ""}
											>
												{loadingButtonContent == ""
													? "Transfer"
													: loadingButtonContent}
											</Button>
										</div>
									</FORM>
								)}
							</Formik>
						)}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	transferNft: state.nfts.transferNft,
	profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(React.memo(TransferNFTModal));