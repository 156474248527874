import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Tab,
  Nav,
} from "react-bootstrap";
import "./ItemList.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CollectionItemCard from "./CollectionItemCard";
import CollectionActivityCard from "./CollectionActivityCard";
import { connect } from "react-redux";
import {
  fetchSingleNftCollectionsStart,
  fetchNftItemsListStart,
  fetchNftPropertiesListStart,
  clearNftCollectionData,
  loadMoreNftItemsStart,
  nftItemsPaymentListStart,
  loadMoreItemActivityStart,
  importContractAddressStart,
} from "../store/actions/NftAction";
import useValidation from "../customHooks/useValidation";
import ProfilerLoader from "../loaders/ProfilerLoader";
import PageNotFound from "../404/PageNotFound";
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../authprovider/chains";
import NoDataFound from "../NoDataFound/NoDataFound";
import CollectionCardLoader from "../loaders/CollectionCardLoader";
import CustomInfiniteScroll from "../customComponents/CustomInfiniteScroll";
import CollectionCard from "../customComponents/CollectionCard";
import Skeleton from "react-loading-skeleton";

const queryString = require("query-string");

const Collection = (props) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { nft_collection_unique_id } = useParams();

  const { validation } = useValidation(props.singleCollection);

  const parsedTab = queryString.parse(location.search);

  const [activeTab, setActiveTab] = useState(
    parsedTab.tab ? parsedTab.tab : "items"
  );

  const [skipInitalRender, setSkipIntialRender] = useState(false);

  const [priceSort, setPriceSort] = useState([]);

  const [searchKey, setSearchKey] = useState("");

  //get filter propetries

  const [filterDataContents, setFilterDataContents] = useState({
    sort_by: [],
    min_amount: "",
    max_amount: "",
    categories: [],
    properties: [],
    propertyRemoved: {
      count: 0,
      data: [],
    },
    categoryRemoved: {
      count: 0,
      data: [],
    },
    clearAll: false,
    search_key: "",
  });

  useEffect(() => {
    const parsedTab = queryString.parse(location.search, {
      arrayFormat: "bracket-separator",
      arrayFormatSeparator: "|",
    });
    if (
      Object.keys(parsedTab).length > 0 &&
      parsedTab.property &&
      parsedTab.property.length > 0
    ) {
      setFilterDataContents({
        ...filterDataContents,
        properties: [JSON.parse(parsedTab.property)],
        queryParsed: true,
      });
    }

    if (
      props.singleCollection.data.nft_collection &&
      props.singleCollection.data.nft_collection.nft_collection_unique_id ==
      nft_collection_unique_id
    ) {
    } else {
      props.dispatch(
        fetchSingleNftCollectionsStart({
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
      props.dispatch(clearNftCollectionData());
    }

    return () => {
      // set scroll position for non browser scroll postion event
    };
  }, [location.pathname]);

  useEffect(() => {
    if (!validation.loading && !validation.status) {
      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
        })
      );
      props.dispatch(
        fetchNftPropertiesListStart({
          type: activeTab,
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
      // props.dispatch(
      //   nftItemsPaymentListStart({
      //     nft_collection_unique_id: nft_collection_unique_id,
      //   })
      // );
    }
  }, [validation, location]);

  useEffect(() => {
    if (skipInitalRender) {
      const query = queryString.stringify(
        { tab: activeTab },
        { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
      );

      navigate({
        pathname: location.pathname,
        search: query,
      });

      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
    }
    setSkipIntialRender(true);
  }, [activeTab]);

  useEffect(() => {
    if (searchKey.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        props.dispatch(
          fetchNftItemsListStart({
            nft_collection_unique_id: nft_collection_unique_id,
            search_key: searchKey,
          })
        );
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      props.dispatch(
        fetchNftItemsListStart({
          nft_collection_unique_id: nft_collection_unique_id,
        })
      );
    }
  }, [searchKey]);

  return (
    <>
      {validation.loading ? (
        <>
          <ProfilerLoader />
        </>
      ) : validation.status ? (
        <>
          <PageNotFound />
        </>
      ) : (
        <>
          {props.singleCollection.data.nft_collection ? (
            <div className="item-list-sec">
              <div className="item-list-header-sec">
                <Image
                  src={props.singleCollection.data.nft_collection.cover}
                  className="item-list-header-bg-img"
                />
              </div>
              <div className="item-list-container">
                <div className="item-list-sidebar">
                  <div className="item-list-sidebar-header-sec">
                    <Image
                      src={props.singleCollection.data.nft_collection.picture}
                      className="item-list-profile-img"
                    />
                  </div>
                  <h4>{props.singleCollection.data.nft_collection.name}</h4>
                  <ul className="list-unstyled item-list-sidebar-list">
                    <li>
                      {props.singleCollection.data.nft_collection.total_items}{" "}
                      items
                    </li>
                    <li>
                      {props.singleCollection.data.nft_collection.owners_count}{" "}
                      owners
                    </li>
                  </ul>
                  <div className="item-list-price-details">
                    <div className="item-list-price-card">
                      <h5>Floor price</h5>
                      <div className="item-list-price-info">
                        <Image
                          src={
                            CHAIN_INFO[
                              SupportedChainIdBySymbol[
                              props.singleCollection.data.nft_collection
                                .blockchain_type
                              ]
                            ].logo
                          }
                          className="trending-icon"
                        />
                        <span>
                          {Number(
                            props.singleCollection.data.nft_collection
                              .floor_price
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{" "}
                          {
                            props.singleCollection.data.nft_collection
                              .blockchain_type
                          }
                        </span>
                      </div>
                    </div>
                    <div className="item-list-price-card">
                      <h5>Value traded</h5>
                      <div className="item-list-price-info">
                        <Image
                          src={
                            CHAIN_INFO[
                              SupportedChainIdBySymbol[
                              props.singleCollection.data.nft_collection
                                .blockchain_type
                              ]
                            ].logo
                          }
                          className="trending-icon"
                        />
                        <span>
                          {
                            props.singleCollection.data.nft_collection
                              .volume_traded
                          }{" "}
                          {
                            props.singleCollection.data.nft_collection
                              .blockchain_type
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-list-main-wrapper">
                  <div className="item-list-main-header-box">
                    <div className="item-list-main-back-btn">
                      <Image
                        onClick={() => navigate(-1)}
                        src={
                          window.location.origin +
                          "/images/landing-page/icons/back-arrow.svg"
                        }
                        className="back-arrow-icon"
                      />
                    </div>
                    {!props.profile.loading &&
                      Object.keys(props.profile.data).length > 0 &&
                      props.singleCollection.data.nft_collection.user_id ==
                      props.profile.data.user.user_id ? (
                      <div className="item-list-main-btn-sec">
                        <Link to={`/my-collection/${props.singleCollection.data.nft_collection.nft_collection_unique_id}`} className="default-btn">
                          Edit Collection
                        </Link>
                        {!props.singleCollection.loading &&
                          props.singleCollection.data.nft_collection &&
                          props.singleCollection.data.nft_collection
                            .collection_type != 3 ? (
                          <Link
                            to={`/create-item?nft_collection_unique_id=${props.singleCollection.data.nft_collection.nft_collection_id}`}
                            className="default-outline-btn"
                          >
                            Add Item
                          </Link>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="item-list-tab-sec">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={activeTab}
                      onSelect={(k) => setActiveTab(k)}
                    >
                      <div className="item-list-tab-header-sec">
                        <div className="item-list-tab-menu">
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey={"items"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 26.076 25.938"
                                >
                                  <g
                                    id="Group_46289"
                                    data-name="Group 46289"
                                    transform="translate(0.147 0.141)"
                                  >
                                    <path
                                      id="Path_56684"
                                      data-name="Path 56684"
                                      d="M9.756,1.409,6.4,4.762a.934.934,0,0,0,0,1.321L9.753,9.434a.934.934,0,0,0,1.321,0l3.353-3.353a.934.934,0,0,0,0-1.321L11.077,1.409A.934.934,0,0,0,9.756,1.409Z"
                                      transform="translate(2.475)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2.3"
                                    />
                                    <path
                                      id="Path_56685"
                                      data-name="Path 56685"
                                      d="M14.757,6.409,11.4,9.762a.935.935,0,0,0,0,1.321l3.351,3.351a.934.934,0,0,0,1.321,0l3.353-3.353a.935.935,0,0,0,0-1.321L16.078,6.409A.934.934,0,0,0,14.757,6.409Z"
                                      transform="translate(4.95 2.475)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2.3"
                                    />
                                    <path
                                      id="Path_56686"
                                      data-name="Path 56686"
                                      d="M4.756,6.4,1.4,9.755a.934.934,0,0,0,0,1.321l3.351,3.351a.934.934,0,0,0,1.321,0l3.353-3.353a.934.934,0,0,0,0-1.321L6.077,6.4A.934.934,0,0,0,4.756,6.4Z"
                                      transform="translate(0 2.471)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2.3"
                                    />
                                    <path
                                      id="Path_56687"
                                      data-name="Path 56687"
                                      d="M9.757,11.4,6.4,14.755a.934.934,0,0,0,0,1.321l3.351,3.351a.934.934,0,0,0,1.321,0l3.353-3.353a.935.935,0,0,0,0-1.321L11.078,11.4A.934.934,0,0,0,9.757,11.4Z"
                                      transform="translate(2.475 4.946)"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2.3"
                                    />
                                  </g>
                                </svg>

                                <span>Items</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey={"activity"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 27.176 22.391"
                                >
                                  <path
                                    id="Path_57934"
                                    data-name="Path 57934"
                                    d="M1.875,11.649H5.7L10.486,1.125l7.654,20.091,4.784-9.567H26.75"
                                    transform="translate(-0.725 0.025)"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2.3"
                                  />
                                </svg>

                                <span>Activity</span>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                        <div className="item-list-tab-search">
                          <div className="header-search profile-search">
                            <Form>
                              <InputGroup>
                                <InputGroup.Text id="basic-addon1">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/landing-page/icons/search-icon.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Search"
                                  aria-label="Search"
                                  aria-describedby="basic-addon1"
                                  onChange={(event) =>
                                    setSearchKey(event.target.value)
                                  }
                                  value={searchKey}
                                />
                                {searchKey.length > 0 && (
                                  <InputGroup.Text
                                    id="basic-addon1"
                                    className="search-close-icon"
                                  >
                                    <Button onClick={() => setSearchKey("")}>
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/images/icons/close.svg"
                                        }
                                      />
                                    </Button>
                                  </InputGroup.Text>
                                )}
                              </InputGroup>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <div className="item-list-tab-content-sec">
                        {activeTab === "items" ? (
                          <>
                            {props.itemsList.loading ? (
                              <>
                                <div
                                  className={`collection-card-loader-grid no-margin`}
                                >
                                  <CollectionCardLoader
                                    count={12}
                                    classes={`mt-3`}
                                  />
                                </div>
                              </>
                            ) : props.itemsList.data.nft_collection_items
                              .length > 0 ? (
                              <div>
                                <CustomInfiniteScroll
                                  fetchAction={loadMoreNftItemsStart}
                                  fetchData={props.itemsList}
                                  fetchedData={
                                    props.itemsList.data.nft_collection_items
                                  }
                                  skip={
                                    props.itemsList.data.nft_collection_items
                                      .length
                                  }
                                  take={12}
                                  skippedFetch={true}
                                  total={props.itemsList.data.total}
                                  dispatchData={{
                                    min_amount: filterDataContents.min_amount,
                                    max_amount: filterDataContents.max_amount,
                                    categories:
                                      filterDataContents.categories.length > 0
                                        ? JSON.stringify(
                                          filterDataContents.categories
                                        )
                                        : "",
                                    properties:
                                      filterDataContents.properties.length > 0
                                        ? JSON.stringify(
                                          filterDataContents.properties
                                        )
                                        : "",
                                    search_key: filterDataContents.search_key,
                                    sort_by:
                                      priceSort.length > 0
                                        ? priceSort[0].id
                                        : "",
                                    nft_collection_unique_id:
                                      nft_collection_unique_id,
                                  }}
                                  useLoader={true}
                                  loaderComponent={
                                    <div
                                      className={`collection-card-loader-grid no-margin`}
                                    >
                                      <CollectionCardLoader
                                        count={12}
                                        classes={`mt-3`}
                                      />
                                    </div>
                                  }
                                  loaderclasses={`collection-grid no-margin`}
                                >
                                  <div className={`collection-grid no-margin`}>
                                    <div className="collection-item-card-sec">
                                      <div className="collection-item-card-box">
                                        {props.itemsList.data.nft_collection_items.map(
                                          (item, index) => (
                                            <CollectionCard
                                              data={item}
                                              key={index}
                                            />
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </CustomInfiniteScroll>
                              </div>
                            ) : (
                              <NoDataFound />
                            )}
                          </>
                        ) : (
                          <>
                            <CollectionActivityCard
                              nftCollectionUniqueId={nft_collection_unique_id}
                            />
                          </>
                        )}
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  singleCollection: state.nfts.singleCollection,
  itemsList: state.nfts.itemsList,
  propertiesList: state.nfts.propertiesList,
  nftItemsPaymentList: state.nfts.nftItemsPaymentList,
  importContract: state.nfts.importContract,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Collection);
