import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSuccessNotificationMessage } from '../helper/ToastNotification';
import { fetchUserDetailsStart } from '../store/actions/UserAction';
import Modal from "react-modal";
import { CHAIN_INFO, SupportedChainId } from '../authprovider/chains';
import { getWalletBalanceStart } from '../store/actions/WalletAction';

Modal.setAppElement("#root");


const BuyIndex = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.users.profile);
  const walletBalance = useSelector(state => state.wallet.walletBalance);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(getWalletBalanceStart());
    }
  }, [profile]);

  const onCopy = () => {
    getSuccessNotificationMessage("Wallet address copied");
  }
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  }

  return (
    profile.loading ?
      "loading"
      : profile.data.user ?
        <>
          <div className="profile-sec">
            <div className="profile-banner-sec new" style={{ background: `url(${profile.data.user.cover})` }}>
              <Container>
                <div className="profile-banner-content">
                  <div className="profile-banner-img-box">
                    <Image
                      src={profile.data.user.picture}
                      className="profile-img"
                    />
                    <div className="profile-img-content">
                      <h4>{profile.data.user.name}</h4>
                      <p>
                        {/* 0x38c…ce86 */}
                        {profile.data.user.wallet_address.substring(0, 5)}
                        {"..."}
                        {profile.data.user.wallet_address.substring(profile.data.user.wallet_address.length - 4, profile.data.user.wallet_address.length)}
                        <CopyToClipboard text={profile.data.user.wallet_address} onCopy={() => onCopy()}>
                          <Button>
                            <Image
                              src={window.location.origin + "/images/icons/copy.svg"}
                              className="copy-icon"
                            />
                          </Button>
                        </CopyToClipboard>
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="social-media-import-box">
                    {Object.entries(SupportedChainId).map((chain, index) => (
                      <div className="social-media-import-card">
                        <div className="social-media-import-icon-sec">
                          {console.log()}
                          <Image
                            src={
                              CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                                .logo
                            }
                            style={{ width: "3em" }}
                          />
                        </div>
                        <div className="social-media-import-info-sec">
                          <h4>
                            {
                              CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                                .nativeCurrency.name
                            }
                          </h4>
                          {Object.keys(walletBalance.data).length > 0 ?
                            <p>
                              {CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                                .nativeCurrency.symbol === "BNB" ? Number(walletBalance.data.wallet_balance.bnb_balance_formatted).toFixed(4) + " BNB"
                                : CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                                  .nativeCurrency.symbol === "MATIC" ? Number(walletBalance.data.wallet_balance.polygon_balance_formatted).toFixed(4) + " MATIC"
                                  : Number(walletBalance.data.wallet_balance.ethereum_balance_formatted).toFixed(4) + " ETH"
                              }
                            </p>
                            : null
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="d-flex justify-center mt-3">
              <button
                id="buyWithMoonPay"
                onClick={openModal}
                className="default-btn btn btn-primary"
              >
                On-ramp with MoonPay
              </button>
            </div>
          </div>
          {modalIsOpen && (
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              className="on-ramp-modal"
              overlayClassName="modal-overlay"
            >
              <div id="modalContent" className="modal-content">
                <iframe
                  id="moonpayBuyIframe"
                  src="https://buy-sandbox.moonpay.com/?apiKey=pk_test_s6KThhYMu3fEfuut9Qf4HgR9ag8MyKD"
                  title="MoonPay"
                ></iframe>
              </div>
            </Modal>
          )}
        </>
        : null
  );
}

export default BuyIndex;