import React from 'react';
import TooManyRequest from '../404/TooManyRequest';
import CollectionCard from '../customComponents/CollectionCard';
import NoDataFound from '../NoDataFound/NoDataFound';

const ProfileItemsSec = (props) => {

  return (
    <>
      {props.items
        ? props.items.length > 0
          ? <div className="collection-item-card-sec">
            <div className="collection-item-card-box">
              {props.items.map((item, i) =>
                <CollectionCard data={item} useFlex={false} key={i} />
              )}
            </div>
          </div>
          : <NoDataFound
            message="No items"
          />
        : <TooManyRequest />
      }
    </>
  );

}

export default ProfileItemsSec;