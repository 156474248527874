import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./Landing.css";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import {
  fetchNftCollectionsListForOthersStart,
  fetchMoreNftCollectionsListForOthersStart,
} from "../store/actions/NftAction";
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../authprovider/chains";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../NoDataFound/NoDataFound";

const TopCollection = (props) => {
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);
  const [showTopCollection, setShowTopCollection] = useState(true);
  const [sortByDays, setSortByDays] = useState(7);

  useEffect(() => {
    props.dispatch(
      fetchNftCollectionsListForOthersStart({
        sort_by_days: sortByDays,
        search_key: searchKey,
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreNftCollectionsListForOthers = () => {
    props.dispatch(
      fetchMoreNftCollectionsListForOthersStart({
        sort_by_days: sortByDays,
        skip: props.collections.data.collections.length,
        take: 12,
        search_key: searchKey,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.collections.loading &&
      (Object.keys(props.collections.data).length === 0 ||
        !props.collections.data.collections ||
        props.collections.data.collections.length === 0)
    ) {
      setShowTopCollection(false);
    }
    setSkipRender(false);
  }, []);

  const [searchKey, setSearchKey] = useState("");

  const onChange = (value) => {
    setSearchKey(value);
  };

  useEffect(() => {
    if (!skipRender) {
      if (searchKey.length > 0) {
        const delayDebounceFn = setTimeout(() => {
          props.dispatch(
            fetchNftCollectionsListForOthersStart({
              sort_by_days: sortByDays,
              skip: 0,
              take: 12,
              search_key: searchKey,
            })
          );
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      } else {
        props.dispatch(
          fetchNftCollectionsListForOthersStart({
            skip: 0,
            take: 12,
          })
        );
      }
    }
    setSkipRender(false);
  }, [searchKey]);

  return (
    <>
      {props.location == "landing" ? (
        showTopCollection ? (
          <div className="top-collection-sec sm-padding">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="top-collection-header-sec">
                    <h2>
                      <span>
                        <Image
                          src={
                            window.location.origin +
                            "/images/landing-page/icons/trending.svg"
                          }
                          className="trending-icon"
                        />
                      </span>
                      Top Collections Over Last 7 Days
                    </h2>
                    <Link to="/top-collection" className="section-link">
                      View All
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="top-collection-table-sec">
                    {props.collections.loading ? (
                      <div>
                        {[...Array(4)].map((val, i) => (
                          <Skeleton height={50} />
                        ))}
                      </div>
                    ) : (
                      <Table size="sm" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Items</th>
                            <th>Owners</th>
                            <th>Floor Price</th>
                            <th>Value Traded</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.collections.data.collections.map(
                            (collection, i) => (
                              <tr
                                key={i}
                                className="cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/collection/${collection.nft_collection_unique_id}`
                                  )
                                }
                              >
                                <td>{i + 1}</td>
                                <td>
                                  <div className="top-collection-user-details">
                                    <Image
                                      src={collection.picture}
                                      className="top-collection-user-img"
                                    />
                                    <span>{collection.name}</span>
                                  </div>
                                </td>
                                <td>{collection.total_items}</td>
                                <td>{collection.owners_count}</td>
                                <td>
                                  <div className="top-collection-floor-price">
                                    <Image
                                      src={
                                        CHAIN_INFO[
                                          SupportedChainIdBySymbol[
                                            collection.blockchain_type
                                          ]
                                        ].logo
                                      }
                                      className="trending-icon"
                                    />
                                    <span>
                                      {collection.floor_price}{" "}
                                      {collection.blockchain_type}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="top-collection-value-traded">
                                    <Image
                                      src={
                                        CHAIN_INFO[
                                          SupportedChainIdBySymbol[
                                            collection.blockchain_type
                                          ]
                                        ].logo
                                      }
                                      className="trending-icon"
                                    />
                                    <span>
                                      {collection.volume_traded}{" "}
                                      {collection.blockchain_type}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <Link to="#">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/images/landing-page/icons/right-arrow.svg"
                                      }
                                      className="right-arrow-icon"
                                    />
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null
      ) : (
        <div className="top-collection-sec single-trending-page">
          <Container>
            <div className="header-back-btn mb-4">
              <Button>
                <Image
                  onClick={() => navigate(-1)}
                  src={
                    window.location.origin + "/images/onboarding-icons/back.svg"
                  }
                />
              </Button>
            </div>
            <Row>
              <Col md={12}>
                <div className="top-collection-header-sec">
                  <h2>
                    <span>
                      <Image
                        src={
                          window.location.origin +
                          "/images/landing-page/icons/trending.svg"
                        }
                        className="trending-icon"
                      />
                    </span>
                    Top Collections Over Last 7 Days
                  </h2>
                  <div className="header-search single-page-header-search">
                    <Form>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          <Image
                            src={
                              window.location.origin +
                              "/images/landing-page/icons/search-icon.svg"
                            }
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Search"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="search_key"
                          name="search_key"
                          value={searchKey}
                          onChange={(event) => onChange(event.target.value)}
                        />
                        {searchKey != "" && (
                          <InputGroup.Text
                            id="basic-addon1"
                            className="search-close-icon"
                          >
                            <Button onClick={() => onChange("")}>
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/icons/close.svg"
                                }
                              />
                            </Button>
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="top-collection-table-sec">
                  {props.collections.loading ? (
                    <div>
                      {[...Array(4)].map((val, i) => (
                        <Skeleton height={50} />
                      ))}
                    </div>
                  ) : props.collections.data.collections &&
                    props.collections.data.collections.length > 0 ? (
                    <InfiniteScroll
                      dataLength={props.collections.data.collections.length}
                      next={fetchMoreNftCollectionsListForOthers}
                      hasMore={
                        props.collections.data.collections.length <
                        props.collections.data.total
                      }
                      loader={""}
                    >
                      <Table size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Items</th>
                            <th>Owners</th>
                            <th>Floor Price</th>
                            <th>Value Traded</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.collections.data.collections.map(
                            (collection, i) => (
                              <tr
                                key={i}
                                className="cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/collection/${collection.nft_collection_unique_id}`
                                  )
                                }
                              >
                                <td>{i + 1}</td>
                                <td>
                                  <div className="top-collection-user-details">
                                    <Image
                                      src={collection.picture}
                                      className="top-collection-user-img"
                                    />
                                    <span>{collection.name}</span>
                                  </div>
                                </td>
                                <td>{collection.total_items}</td>
                                <td>{collection.owners_count}</td>
                                <td>
                                  <div className="top-collection-floor-price">
                                    <Image
                                      src={
                                        CHAIN_INFO[
                                          SupportedChainIdBySymbol[
                                            collection.blockchain_type
                                          ]
                                        ].logo
                                      }
                                      className="trending-icon"
                                    />
                                    <span>
                                      {collection.floor_price}{" "}
                                      {collection.blockchain_type}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="top-collection-value-traded">
                                    <Image
                                      src={
                                        CHAIN_INFO[
                                          SupportedChainIdBySymbol[
                                            collection.blockchain_type
                                          ]
                                        ].logo
                                      }
                                      className="trending-icon"
                                    />
                                    <span>
                                      {collection.volume_traded}{" "}
                                      {collection.blockchain_type}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <Link to="#">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/images/landing-page/icons/right-arrow.svg"
                                      }
                                      className="right-arrow-icon"
                                    />
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  collections: state.nfts.collectionListForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TopCollection);
