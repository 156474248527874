
import { takeLatest, all, put, call, select } from "redux-saga/effects";
import api, { walletAppBaseUrl } from "../../../Environment";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../helper/ToastNotification";
import { FETCH_ONBOARDING_CATEGORIES_START, REGENERATE_MOBILE_VERIFICATION_CODE_START, SEND_EMAIL_INVITAION_START, UPDATE_ONBOARDING_CATEGORIES_START, UPDATE_ONBOARDING_START, USERNAME_VALIDATION_START, VERIFY_MOBILE_START } from "../actions/ActionConstant";
import { fetchUserDetailsSuccess } from "../actions/UserAction";

import {
  fetchOnboardingCategoriesFailure,
  fetchOnboardingCategoriesSuccess,
  regenerateMobileVerificationCodeFailure,
  regenerateMobileVerificationCodeSuccess,
  sendEmailInvitationFailure,
  sendEmailInvitationSuccess,
  updateOnboardingCategoriesFailure,
  updateOnboardingCategoriesSuccess,
  updateOnboardingFailure,
  updateOnboardingSuccess,
  usernameValidationFailure,
  usernameValidationSuccess,
  verifyMobileFailure,
  verifyMobileSuccess,
} from "../actions/WalletAppAction";

function* verifyMobileAPI(action) {
  try {
    const response = yield api.postMethod("verify_mobile", action.data, walletAppBaseUrl);
    if (response.data.success) {
      yield put(verifyMobileSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      localStorage.setItem("userId", response.data.data.user.user_id);
      localStorage.setItem("accessToken", response.data.data.user.token);
      localStorage.setItem("wallet_address", action.data.wallet_address)
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.user.picture);
      localStorage.setItem("name", response.data.data.user.name);
      localStorage.setItem("user_unique_id", response.data.data.user.user_unique_id);
      localStorage.removeItem("email");
      localStorage.removeItem("mobile");
      yield put(fetchUserDetailsSuccess(response.data));
    } else {
      yield put(verifyMobileFailure(response.data.error));
      // yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(verifyMobileFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* regenerateMobileVerificationCodeAPI(action) {
  try {
    const response = yield api.postMethod("regenerate_mobile_verification_code", action.data, walletAppBaseUrl);
    if (response.data.success) {
      yield put(regenerateMobileVerificationCodeSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(regenerateMobileVerificationCodeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(regenerateMobileVerificationCodeFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateOnboardingAPI(action) {
  try {
    const response = yield api.postMethod("update_onboarding", action.data, walletAppBaseUrl);
    if (response.data.success) {
      const profileData = yield select((state) => state.users.profile.data);
      yield put(fetchUserDetailsSuccess({
        data: {
          ...profileData,
          user: response.data.data,
        }
      }))
      yield put(updateOnboardingSuccess(response.data.data));
      // yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(updateOnboardingFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateOnboardingFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* usernameValidationAPI(action) {
  try {
    const response = yield api.postMethod("username_validation", action.data, walletAppBaseUrl);
    if (response.data.success) {
      yield put(usernameValidationSuccess(response.data));
    } else {
      yield put(usernameValidationFailure(response));
    }
  } catch (error) {
    yield put(usernameValidationFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchOnboardingCategoriesAPI(action) {
  try {
    const response = yield api.postMethod("onboarding_categories", action.data, walletAppBaseUrl);
    if (response.data.success) {
      yield put(fetchOnboardingCategoriesSuccess(response.data.data));
    } else {
      yield put(fetchOnboardingCategoriesFailure(response));
    }
  } catch (error) {
    yield put(fetchOnboardingCategoriesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateOnboardingCategoriesAPI(action) {
  try {
    const response = yield api.postMethod("onboarding_categories_update", action.data, walletAppBaseUrl);
    if (response.data.success) {
      const profileData = yield select((state) => state.users.profile.data);
      yield put(fetchUserDetailsSuccess({
        data: {
          ...profileData,
          user: response.data.data,
        }
      }))
      yield put(updateOnboardingCategoriesSuccess(response.data.data));
    } else {
      yield put(updateOnboardingCategoriesFailure(response));
    }
  } catch (error) {
    yield put(updateOnboardingCategoriesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* sendEmailInvitaionAPI(action) {
  try {
    const response = yield api.postMethod("send_email_invitation", action.data, walletAppBaseUrl);
    if (response.data.success) {
      yield put(sendEmailInvitationSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(sendEmailInvitationFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(sendEmailInvitationFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* WalletAppSaga() {
  yield all([
    yield takeLatest(VERIFY_MOBILE_START, verifyMobileAPI),
    yield takeLatest(REGENERATE_MOBILE_VERIFICATION_CODE_START, regenerateMobileVerificationCodeAPI),
    yield takeLatest(UPDATE_ONBOARDING_START, updateOnboardingAPI),
    yield takeLatest(USERNAME_VALIDATION_START, usernameValidationAPI),
    yield takeLatest(FETCH_ONBOARDING_CATEGORIES_START, fetchOnboardingCategoriesAPI),
    yield takeLatest(UPDATE_ONBOARDING_CATEGORIES_START, updateOnboardingCategoriesAPI),
    yield takeLatest(SEND_EMAIL_INVITAION_START, sendEmailInvitaionAPI),
  ]);
}