import React from "react";
import HeaderIndex from "./Header/HeaderIndex";

const AuthenticationLayout = (props) => {
  return (
    <>
      <HeaderIndex />
      <div className="main-wrapper">{props.children}</div>
    </>
  );
};

export default AuthenticationLayout;
