import { useTheme } from "@mui/material/styles";
import React from "react";

const AccordionLoadingSvg = (props) => {

  const theme = useTheme();

  return (
    <svg
      className={props.classes}
      viewBox="0 0 100 100"
      width="100px"
      height="100px"
    >
      <defs>
        <linearGradient id="cs-grad-1" x1="0.5" y1="0" x2="0.5" y2="1">
          <stop offset="0%" stop-color="hsl(0,0%,100%)" />
          <stop offset="100%" stop-color="hsl(0,0%,80%)" />
        </linearGradient>
        <linearGradient
          id="cs-grad-2a"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          gradientTransform="rotate(90,0.5,0.5)"
        >
          <stop offset="0%" stop-color={theme.themeColor.primary} />
          <stop offset="100%" stop-color={theme.themeColor.primary} />
        </linearGradient>
        <linearGradient id="cs-grad-2b" x1="0.5" y1="0" x2="0.5" y2="1">
          <stop offset="0%" stop-color={theme.themeColor.primary} />
          <stop offset="100%" stop-color={theme.themeColor.primary} />
        </linearGradient>
      </defs>
      <circle
        class="check-spinner__circle"
        cx="50"
        cy="50"
        r="0"
        fill="url(#cs-grad-1)"
      />
      <circle
        class="check-spinner__worm-a"
        cx="50"
        cy="50"
        r="46"
        fill="none"
        stroke="url(#cs-grad-2a)"
        stroke-width="8"
        stroke-linecap="round"
        stroke-dasharray="72.2 216.8"
        stroke-dashoffset="36.1"
        transform="rotate(-90,50,50)"
      />
      <path
        class="check-spinner__worm-b"
        d="M 17.473 17.473 C 25.797 9.15 37.297 4 50 4 C 75.4 4 96 24.6 96 50 C 96 75.4 75.4 96 50 96 C 24.6 96 4 75.4 4 50 C 4 44.253 6.909 36.33 12.5 35 C 21.269 32.913 35 50 35 50 L 45 60 L 65 40"
        fill="none"
        stroke="url(#cs-grad-2b)"
        stroke-width="8"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="0 0 72.2 341.3"
      />
    </svg>
  );
};

export default AccordionLoadingSvg;
