import {
  FETCH_ONBOARDING_CATEGORIES_FAILURE,
  FETCH_ONBOARDING_CATEGORIES_START,
  FETCH_ONBOARDING_CATEGORIES_SUCCESS,
  REGENERATE_MOBILE_VERIFICATION_CODE_FAILURE,
  REGENERATE_MOBILE_VERIFICATION_CODE_START,
  REGENERATE_MOBILE_VERIFICATION_CODE_SUCCESS,
  SEND_EMAIL_INVITAION_FAILURE,
  SEND_EMAIL_INVITAION_START,
  SEND_EMAIL_INVITAION_SUCCESS,
  UPDATE_ONBOARDING_CATEGORIES_FAILURE,
  UPDATE_ONBOARDING_CATEGORIES_START,
  UPDATE_ONBOARDING_CATEGORIES_SUCCESS,
  UPDATE_ONBOARDING_FAILURE,
  UPDATE_ONBOARDING_START,
  UPDATE_ONBOARDING_SUCCESS,
  USERNAME_VALIDATION_FAILURE,
  USERNAME_VALIDATION_START,
  USERNAME_VALIDATION_SUCCESS,
  VERIFY_MOBILE_FAILURE,
  VERIFY_MOBILE_START,
  VERIFY_MOBILE_SUCCESS
} from "./ActionConstant";

//Mobile Verification
export function verifyMobileStart(data) {
  return {
    type: VERIFY_MOBILE_START,
    data
  };
}

export function verifyMobileSuccess(data) {
  return {
    type: VERIFY_MOBILE_SUCCESS,
    data,
  };
}

export function verifyMobileFailure(error) {
  return {
    type: VERIFY_MOBILE_FAILURE,
    error,
  };
}

//Regenerate Mobile Verification
export function regenerateMobileVerificationCodeStart(data) {
  return {
    type: REGENERATE_MOBILE_VERIFICATION_CODE_START,
    data,
  };
}

export function regenerateMobileVerificationCodeSuccess(data) {
  return {
    type: REGENERATE_MOBILE_VERIFICATION_CODE_SUCCESS,
    data,
  };
}

export function regenerateMobileVerificationCodeFailure(error) {
  return {
    type: REGENERATE_MOBILE_VERIFICATION_CODE_FAILURE,
    error,
  };
}

//Onboarding Categories List
export function fetchOnboardingCategoriesStart(data) {
  return {
    type: FETCH_ONBOARDING_CATEGORIES_START,
    data,
  };
}

export function fetchOnboardingCategoriesSuccess(data) {
  return {
    type: FETCH_ONBOARDING_CATEGORIES_SUCCESS,
    data,
  };
}

export function fetchOnboardingCategoriesFailure(error) {
  return {
    type: FETCH_ONBOARDING_CATEGORIES_FAILURE,
    error,
  }
}

//Update Onboarding Categories
export function updateOnboardingCategoriesStart(data) {
  return {
    type: UPDATE_ONBOARDING_CATEGORIES_START,
    data,
  };
}

export function updateOnboardingCategoriesSuccess(data) {
  return {
    type: UPDATE_ONBOARDING_CATEGORIES_SUCCESS,
    data,
  };
}

export function updateOnboardingCategoriesFailure(error) {
  return {
    type: UPDATE_ONBOARDING_CATEGORIES_FAILURE,
    error,
  };
}

//Update Onboarding
export function updateOnboardingStart(data) {
  return {
    type: UPDATE_ONBOARDING_START,
    data,
  };
}

export function updateOnboardingSuccess(data) {
  return {
    type: UPDATE_ONBOARDING_SUCCESS,
    data,
  };
}

export function updateOnboardingFailure(error) {
  return {
    type: UPDATE_ONBOARDING_FAILURE,
    error,
  };
}

//Send Email Invitation
export function sendEmailInvitationStart(data) {
  return {
    type: SEND_EMAIL_INVITAION_START,
    data,
  };
}

export function sendEmailInvitationSuccess(data) {
  return {
    type: SEND_EMAIL_INVITAION_SUCCESS,
    data,
  };
}

export function sendEmailInvitationFailure(error) {
  return {
    type: SEND_EMAIL_INVITAION_FAILURE,
    error,
  };
}

  // usernname validation
  export function usernameValidationStart(data) {
    return {
      type: USERNAME_VALIDATION_START,
      data,
    };
  }
  
  export function usernameValidationSuccess(data) {
    return {
      type: USERNAME_VALIDATION_SUCCESS,
      data,
    };
  }
  
  export function usernameValidationFailure(error) {
    return {
      type: USERNAME_VALIDATION_FAILURE,
      error,
    };
  }