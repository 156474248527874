import React from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginSlider from "./LoginSlider";

const PrivateKey = () => {
  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row>
              <Col md={6} className="border-right">
                <LoginSlider />
              </Col>
              <Col md={6} className="border-left">
                <div className="login-auth-sec">
                  <Form>
                    <div className="sign-in-sec mt-4">
                      <div className="forgot-password-content private-key-sec">
                        <h3>Hi! Zeynep,</h3>
                        <h4>Welcome to Marketplace</h4>
                        <p>
                          <span>Warning: </span> Never disclose this key. Anyone
                          with your private keys can steal any assets held in
                          your account. Kindly store it in a secure location.
                        </p>
                      </div>
                      <div className="private-key-content">
                        <h5>Your Key:</h5>
                        <div className="private-key-box">
                          <p>
                            0XA8765ASDF876890876A66AS
                            ASDADADSASDASDASDASDA781726
                          </p>
                          <div className="private-key-btn">
                            <Button>
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/auth/copy.svg"
                                }
                              />
                              <span>Copy</span>
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="login-btn">
                        <Button className="default-btn" type="submit">
                          Continue
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PrivateKey;
