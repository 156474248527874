import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  FETCH_OTHER_USER_DETAILS_START,
  FETCH_OTHER_USER_DETAILS_SUCCESS,
  FETCH_OTHER_USER_DETAILS_FAILURE,
  UPDATE_SIGN_APPROVE_START,
  UPDATE_SIGN_APPROVE_SUCCESS,
  UPDATE_SIGN_APPROVE_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_FORGOT_PASSWORD_START,
  VERIFY_FORGOT_PASSWORD_SUCCESS,
  VERIFY_FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESEND_EMAIL_VERIFICATION_CODE_START,
  RESEND_EMAIL_VERIFICATION_CODE_SUCCESS,
  RESEND_EMAIL_VERIFICATION_CODE_FAILURE,
  TWO_STEP_AUTH_LOGIN_START,
  TWO_STEP_AUTH_LOGIN_SUCCESS,
  TWO_STEP_AUTH_LOGIN_FAILURE,
  TWO_STEP_AUTH_RESEND_CODE_SUCCESS,
  TWO_STEP_AUTH_RESEND_CODE_FAILURE,
  TWO_STEP_AUTH_RESEND_CODE_START,
  UPDATE_TWO_STEP_AUTH_START,
  UPDATE_TWO_STEP_AUTH_SUCCESS,
  UPDATE_TWO_STEP_AUTH_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE
} from "./ActionConstant";

// User login actions.

export function userLoginStart(data) {
  return {
    type: LOGIN_START,
    data,
  };
}

export function userLoginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
}

export function userLoginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

//User Registration
export function registerStart(data) {
  return {
    type: REGISTER_START,
    data,
  };
}

export function registerSuccess(data) {
  return {
    type: REGISTER_SUCCESS,
    data,
  };
}

export function registerFailure(error) {
  return {
    type: REGISTER_FAILURE,
    error,
  }
}

// Get user details actions.

export function fetchUserDetailsStart(data) {
  return {
    type: FETCH_USER_DETAILS_START,
    data,
  };
}

export function fetchUserDetailsSuccess(data) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    data,
  };
}

export function fetchUserDetailsFailure(error) {
  return {
    type: FETCH_USER_DETAILS_FAILURE,
    error,
  };
}

export function updateUserDetailsStart(data) {
  return {
    type: UPDATE_USER_DETAILS_START,
    data,
  };
}

export function updateUserDetailsSuccess(data) {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    data,
  };
}

export function updateUserDetailsFailure(error) {
  return {
    type: UPDATE_USER_DETAILS_FAILURE,
    error,
  };
}

export function fetchOtherUserDetailsStart(data) {
  return {
    type: FETCH_OTHER_USER_DETAILS_START,
    data,
  };
}

export function fetchOtherUserDetailsSuccess(data) {
  return {
    type: FETCH_OTHER_USER_DETAILS_SUCCESS,
    data,
  };
}

export function fetchOtherUserDetailsFailure(error) {
  return {
    type: FETCH_OTHER_USER_DETAILS_FAILURE,
    error,
  };
}

export function updateSignAndApproveStart(data) {
  return {
    type: UPDATE_SIGN_APPROVE_START,
    data,
  };
}

export function updateSignAndApproveSuccess(data) {
  return {
    type: UPDATE_SIGN_APPROVE_SUCCESS,
    data,
  };
}

export function updateSignAndApproveFailure(error) {
  return {
    type: UPDATE_SIGN_APPROVE_FAILURE,
    error,
  };
}

//User Forgot password
export function forgotPasswordStart(data) {
  return {
    type: FORGOT_PASSWORD_START,
    data,
  };
}

export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    data,
  };
}

export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    error,
  };
}

export function verifyForgotPasswordStart(data) {
  return {
    type: VERIFY_FORGOT_PASSWORD_START,
    data,
  };
}

export function verifyForgotPasswordSuccess(data) {
  return {
    type: VERIFY_FORGOT_PASSWORD_SUCCESS,
    data,
  };
}

export function verifyForgotPasswordFailure(error) {
  return {
    type: VERIFY_FORGOT_PASSWORD_FAILURE,
    error,
  };
}

//Reset User password
export function resetPasswordStart(data) {
  return {
    type: RESET_PASSWORD_START,
    data,
  };
}

export function resetPasswordSuccess(data) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    data,
  };
}

export function resetPassswordFailure(error) {
  return {
    type: RESET_PASSWORD_FAILURE,
    error,
  };
}

//user Email Verification
export function verifyEmailStart(data) {
  return {
    type: VERIFY_EMAIL_START,
    data,
  };
}

export function verifyEmailSuccess(data) {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    data,
  };
}

export function verifyEmailFailure(error) {
  return {
    type: VERIFY_EMAIL_FAILURE,
    error,
  };
}

//Resend Email Verification Code
export function resendEmailVerificationCodeStart(data) {
  return {
    type: RESEND_EMAIL_VERIFICATION_CODE_START,
    data,
  };
}

export function resendEmailVerificationCodeSuccess(data) {
  return {
    type: RESEND_EMAIL_VERIFICATION_CODE_SUCCESS,
    data,
  };
}

export function resendEmailVerificationCodeFailure(error) {
  return {
    type: RESEND_EMAIL_VERIFICATION_CODE_FAILURE,
    error,
  };
}

//Two step auth Login
export function twoStepAuthLoginStart(data) {
  return {
    type: TWO_STEP_AUTH_LOGIN_START,
    data,
  };
}

export function twoStepAuthLoginSuccess(data) {
  return {
    type: TWO_STEP_AUTH_LOGIN_SUCCESS,
    data,
  };
}

export function twoStepAuthLoginFailure(error) {
  return {
    type: TWO_STEP_AUTH_LOGIN_FAILURE,
    error,
  };
}

//Two step auth  resend code
export function twoStepAuthResendCodeStart(data) {
  return {
    type: TWO_STEP_AUTH_RESEND_CODE_START,
    data,
  };
}

export function twoStepAuthResendCodeSuccess(data) {
  return {
    type: TWO_STEP_AUTH_RESEND_CODE_SUCCESS,
    data,
  };
}

export function twoStepAuthResendCodeFailure(error) {
  return {
    type: TWO_STEP_AUTH_RESEND_CODE_FAILURE,
    error,
  };
}

//update two step auth status
export function updateTwoStepAuthStart(data) {
  return {
    type: UPDATE_TWO_STEP_AUTH_START,
    data,
  };
}

export function updateTwoStepAuthSuccess(data) {
  return {
    type: UPDATE_TWO_STEP_AUTH_SUCCESS,
    data,
  };
}

export function updateTwoStepAuthFailure(error) {
  return {
    type: UPDATE_TWO_STEP_AUTH_FAILURE,
    error,
  }
}


//Change Password
export function changePasswordStart(data) {
  return {
    type: CHANGE_PASSWORD_START,
    data,
  };
}

export function changePasswordSuccess(data) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    data,
  };
}

export function changePasswordFailure(error) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    error,
  };
}