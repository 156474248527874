import React from "react";

const NoCollectionsFound = () => {
	return (
		<>
			<div className="error-area no-data-found">
				<div className="d-table m-auto">
					<div className="d-table-cell">
						<div className="error-content text-center">
							<div className="">
								<img
									src={
										window.location.origin + "/images/no-data/no-collections.png"
									}
									alt="img"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NoCollectionsFound;
