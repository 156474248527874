import React from "react";
import HeaderIndex from "./Header/HeaderIndex";
import FooterIndex from "./Footer/FooterIndex";

const MainLayout = (props) => {
  return (
    <>
      <HeaderIndex />
      <div className="main-wrapper">{props.children}</div>
      <FooterIndex />
    </>
  );
};

export default MainLayout;
