import React, { useState, useEffect } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Button,
	Table,
	Form,
	InputGroup,
	Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { reportItemsSaveStart } from "../store/actions/NftAction";
import { connect } from "react-redux";

const ReportCollectionModal = (props) => {

	const education = [
		{ value: "Fake collection or possible scam", label: "Fake collection or possible scam" },
		{ value: "Explicit or sensitive content", label: "Explicit or sensitive content" },
		{ value: "Spam", label: "Spam" },
		{ value: "In the wrong category", label: "In the wrong category" },
		{ value: "Other", label: "Other" },
	];

	const [reasonInput, setReasonInput] = useState("");

	const [selectedOption, setSelectedOption] = useState(null);

	const handleChange = (selectedOption) => {
    setReasonInput(selectedOption.value);
    setSelectedOption(selectedOption);
  };

	const handleSubmit = (event) => {
		event.preventDefault();
		props.dispatch(
			reportItemsSaveStart({
				nft_collection_contract_address: props.contract_address,
				nft_collection_item_token_id: props.token_id,
				reason: reasonInput,
			})
		);
	};

	useEffect(() => {
		if (!props.reportItemsSave.loading && Object.keys(props.reportItemsSave.data).length > 0) {
			props.closeReportCollectionModal();
		}
	}, [props.reportItemsSave.data]);

	return (
		<>
			<Modal
				className="modal-dialog-center report-collection-modal"
				size="md"
				centered
				show={props.reportCollection}
				onHide={props.closeReportCollectionModal}
			>
				<Modal.Body>
					<Button className="modal-close" onClick={() => props.closeReportCollectionModal()}>
						<Image
							className="close-icon"
							src={
								window.location.origin + "/images/create-item/icons/modal-close.svg"
							}
						/>
					</Button>
					<div className="report-collection-content">
						<h4>Report this collection</h4>
						<Form className="report-collection-form">
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>I think this collection is:</Form.Label>
								<Select 
									options={education} 
									value={selectedOption}
        					onChange={handleChange}
									placeholder="Select a reason"
								/>
							</Form.Group>
							<div className="report-collection-btn-sec">
								<Button
									to=""
									className="default-btn"
									onClick={handleSubmit}
									disabled={props.reportItemsSave.buttonDisable}
								>
									{props.reportItemsSave.loadingButtonContent
										? props.reportItemsSave.loadingButtonContent : "Send"}
								</Button>
							</div>
						</Form>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	reportItemsSave: state.nfts.reportItemsSave,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ReportCollectionModal);