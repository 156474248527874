import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./Landing.css";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  fetchTrendingNftItemsStart,
  fetchMoreTrendingNftItemsStart,
} from "../store/actions/NftAction";
import { connect } from "react-redux";
import CollectionCard from "../customComponents/CollectionCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../NoDataFound/NoDataFound";

const TrendingArtwork = (props) => {
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);
  const [showTrending, setShowTrending] = useState(true);

  useEffect(() => {
    props.dispatch(fetchTrendingNftItemsStart({ skip: 0, take: 12 }));
  }, []);

  const fetchMoreTrendingNftItems = () => {
    props.dispatch(
      fetchMoreTrendingNftItemsStart({
        skip: props.trendingItems.data.nftItems.length,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.trendingItems.loading &&
      (Object.keys(props.trendingItems.data).length === 0 ||
        !props.trendingItems.data.nftItems ||
        props.trendingItems.data.nftItems.length === 0)
    ) {
      setShowTrending(false);
    }
    setSkipRender(false);
  }, [props.trendingItems]);

  const [searchKey, setSearchKey] = useState("");

  const onChange = (value) => {
    setSearchKey(value);
  };

  useEffect(() => {
    if (searchKey.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        props.dispatch(
          fetchTrendingNftItemsStart({
            skip: 0,
            take: 12,
            search_key: searchKey,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      props.dispatch(
        fetchTrendingNftItemsStart({
          skip: 0,
          take: 12,
        })
      );
    }
  }, [searchKey]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {props.position == "landing" ? (
        showTrending ? (
          <div className="trending-artwork-sec sm-padding">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="trending-artwork-header-sec">
                    <h2>
                      <span>
                        <Image
                          src={
                            window.location.origin +
                            "/images/landing-page/icons/trending.svg"
                          }
                          className="trending-icon"
                        />
                      </span>
                      Trending Artwork
                    </h2>
                    <Link to="/trending-artwork" className="section-link">
                      View All
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="trending-artwork-box">
                    {props.trendingItems.loading ? (
                      <Slider {...settings}>
                        {[...Array(4)].map((val, i) => (
                          <Skeleton height={380} key={i} />
                        ))}
                      </Slider>
                    ) : props.trendingItems.data.nftItems &&
                      props.trendingItems.data.nftItems.length > 0 ? (
                      <Slider {...settings}>
                        {props.trendingItems.data.nftItems
                          .sort(
                            (prev, next) =>
                              parseFloat(next.favourites_count) -
                              parseFloat(prev.favourites_count)
                          )
                          .map((item, i) => (
                            <CollectionCard
                              data={item}
                              useFlex={false}
                              key={i}
                            />
                          ))}
                      </Slider>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null
      ) : (
        <>
          <div className="trending-artwork-sec  single-trending-page">
            <Container>
              <div className="header-back-btn mb-4">
                <Button>
                  <Image
                    onClick={() => navigate(-1)}
                    src={
                      window.location.origin +
                      "/images/onboarding-icons/back.svg"
                    }
                  />
                </Button>
              </div>
              <Row>
                <Col md={12}>
                  <div className="trending-artwork-header-sec">
                    <h2>
                      <span>
                        <Image
                          src={
                            window.location.origin +
                            "/images/landing-page/icons/trending.svg"
                          }
                          className="trending-icon"
                        />
                      </span>
                      Trending Artwork
                    </h2>
                    <div className="header-search single-page-header-search">
                      <Form>
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            <Image
                              src={
                                window.location.origin +
                                "/images/landing-page/icons/search-icon.svg"
                              }
                            />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Search"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            id="search_key"
                            name="search_key"
                            value={searchKey}
                            onChange={(event) => onChange(event.target.value)}
                          />
                          {searchKey != "" && (
                            <InputGroup.Text
                              id="basic-addon1"
                              className="search-close-icon"
                            >
                              <Button onClick={() => onChange("")}>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/icons/close.svg"
                                  }
                                />
                              </Button>
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {props.trendingItems.loading ? (
                    <Slider {...settings}>
                      {[...Array(4)].map((val, i) => (
                        <Skeleton height={380} key={i} />
                      ))}
                    </Slider>
                  ) : props.trendingItems.data.nftItems &&
                    props.trendingItems.data.nftItems.length > 0 ? (
                    <InfiniteScroll
                      dataLength={props.trendingItems.data.nftItems.length}
                      next={fetchMoreTrendingNftItems}
                      hasMore={
                        props.trendingItems.data.nftItems.length <
                        props.trendingItems.data.total
                      }
                      loader={""}
                    >
                      <div className="trending-artwork-box">
                        {props.trendingItems.data.nftItems
                          .sort(
                            (prev, next) =>
                              parseFloat(next.favourites_count) -
                              parseFloat(prev.favourites_count)
                          )
                          .map((item, i) => (
                            <CollectionCard
                              data={item}
                              useFlex={false}
                              key={i}
                            />
                          ))}
                      </div>
                    </InfiniteScroll>
                  ) : (
                    <NoDataFound />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  trendingItems: state.nfts.trendingItems,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TrendingArtwork);
