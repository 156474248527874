import React, { useState } from "react";
import {
    Container,
    Image,
    Row,
    Nav,
    Col,
    Tab,
    Button,
    Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Mint.css";


const SuccessMint = (props) => {


    return <>
        <div className="mint-page-sec">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="mint-page-vertical-center">
                            <div className="mint-page-success-img-sec">
                                <Image
                                    src={
                                        window.location.origin + "/images/mint/success.svg"
                                    }
                                    className="mint-page-success-img"
                                />
                            </div>
                            <div className="mint-info-sec">
                                <h2>Your NFT Has Minted!</h2>
                            </div>
                            <div className="mint-btn-sec">
                                <Link to="#" className="mint-btn">
                                    <Image
                                        src={
                                            window.location.origin + "/images/mint/diamond.svg"
                                        }
                                        className="min-btn-icon"
                                    />
                                    MarketPlace
                                </Link>
                                <Button className="mint-btn">
                                    <Image
                                        src={
                                            window.location.origin + "/images/mint/copy-link.svg"
                                        }
                                        className="min-btn-icon"
                                    />
                                    Copy link
                                </Button>
                                <Button className="mint-btn">
                                    <Image
                                        src={
                                            window.location.origin + "/images/mint/share.svg"
                                        }
                                        className="min-btn-icon"
                                    />
                                    Share With
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>;
};

export default SuccessMint;
