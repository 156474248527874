import React, { useState, useEffect } from "react";
import "./Verification.css";
import OtpInput from "react-otp-input";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { resendEmailVerificationCodeStart, verifyEmailStart } from "../store/actions/UserAction";
import { connect } from "react-redux";

const EmailVerificationIndex = (props) => {
  const navigate = useNavigate();

  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const handleChange = (otp) => setOTP(otp);

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    } else {
      let userId = localStorage.getItem("userId");
      let accessToken = localStorage.getItem("accessToken");
      let userLoginStatus = localStorage.getItem("userLoginStatus");
      let authentication = userId && accessToken && userLoginStatus == "true" ? true : false;
      if (authentication) {
        navigate("/");
      } else {
        navigate("/login");
      }
    }
  }, []);

  const verify = e => {
    e.preventDefault();
    props.dispatch(verifyEmailStart({
      email: email,
      verification_code: otp,
    }));
  };

  const resend = () => {
    props.dispatch(resendEmailVerificationCodeStart({
      email: email,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.verifyEmail.loading && Object.keys(props.verifyEmail.data).length > 0) {
      setTimeout(() => {
        navigate("/privatekey");
      }, 2000);
    }
    setSkipRender(false);
  }, [props.verifyEmail]);

  return (
    <>
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={5}>
              <Link to="/login">
                <div className="verification-logo-sec">
                  <Image src={window.location.origin + "/images/logo.png"} />
                </div>
              </Link>
              <div className="verification-box mt-4">
                <h4>
                  Please enter the one time password to verify your account
                </h4>

                <Form onSubmit={verify}>
                  <div className="verification-item">
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="verification-btn-sec">
                    <Button
                      className="default-outline-btn"
                      onClick={() => resend()}
                      disabled={props.resendVerificationCode.buttonDisable}
                    >
                      {props.resendVerificationCode.loadingButtonContent ?
                        props.resendVerificationCode.loadingButtonContent
                        : "Resend"
                      }
                    </Button>
                    <Button
                      type="submit"
                      className="default-btn"
                      disabled={!otp || otp.length < 6 || props.verifyEmail.buttonDisable}
                    >
                      {props.verifyEmail.loadingButtonContent ?
                        props.verifyEmail.loadingButtonContent
                        : "Validate"
                      }
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  resendVerificationCode: state.users.resendVerificationCode,
  verifyEmail: state.users.verifyEmail,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(EmailVerificationIndex);
