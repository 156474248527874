import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Container,
  Button,
  Image,
  Row,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import CollectionActivityCard from "../CollectionItem/CollectionActivityCard";
import "./SingleLanding.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import Web3 from "web3";
import NftToken from "../../abis/NftToken.json";
import { authContext } from "../authprovider/AuthProvider";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  nftItemsPaymentListStart,
  moreNftItemsPaymentListStart,
} from "../store/actions/NftAction";
import { useHistory, useLocation, useParams } from "react-router";

const ActivityIndex = (props) => {
  const navigate = useNavigate();

  const {
    nft_collection_unique_id,
    nft_collection_item_token_id,
    contract_address,
  } = useParams();

  return (
    <div>
      <div className="single-activity-sec">
        <Container>
          <div className="header-back-btn mb-4">
            <Button>
              <Image
                onClick={() => navigate(-1)}
                src={
                  window.location.origin + "/images/onboarding-icons/back.svg"
                }
              />
            </Button>
          </div>
          <Row>
            <Col md={12}>
              <div className="top-collection-header-sec">
                <h2>
                  <span>
                    <Image
                      src={
                        window.location.origin + "/images/icons/activity.svg"
                      }
                      className="trending-icon"
                    />
                  </span>
                  Activity
                </h2>
              </div>
            </Col>
          </Row>
          <CollectionActivityCard
            nftCollectionUniqueId={nft_collection_unique_id}
          />
        </Container>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ActivityIndex);
