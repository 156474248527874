import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Form,
  InputGroup,
  Button,
  FormGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  updateOnboardingStart,
  usernameValidationStart,
} from "../../store/actions/WalletAppAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const CreateLinkSec = (props) => {
  const [isvalidUserName, setIsValidUserName] = useState(false);
  const [userName, setUserName] = useState(props.profile.data.user.username);
  const [skipRender, setSkipRender] = useState(true);
  const [formattedLink, setFormattedLink] = useState(
    configuration.get("configData.frontend_url").replace("https://", "")
  );

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required *"),
  });

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.replace(" ", "") === username) {
        if (username !== userName) {
          setUserName(username);
          setIsValidUserName(true);
          props.dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        setIsValidUserName(false);
        return "No white space allowed";
      }
    } else {
      setIsValidUserName(false);
      return "Must Contain 4 Characters";
    }
  };

  const handleSubmit = (values) => {
    props.dispatch(updateOnboardingStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.onboardingUpdate.loading &&
      Object.keys(props.onboardingUpdate.data).length > 0
    ) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  return (
    <>
      <div className="create-link-sec login-auth-sec mt-5 ">
        <div className="sign-in-sec mt-4">
          <Formik
            initialValues={{
              username: props.profile.data.user.username
                ? props.profile.data.user.username
                : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <FORM>
                <div className="forgot-password-content">
                  <h4>Choose your Custom URL</h4>
                  <p>
                    See if your custom URL is available
                  </p>
                </div>

                <FormGroup className="mb-3 create-link-input">
                  <InputGroup
                    className={`${
                      touched.username && errors.username ? "is-invalid" : ""
                    }`}
                  >
                    <InputGroup.Text className="input-group-icon">
                      <Image
                        src={
                          window.location.origin +
                          "/images/onboarding-icons/link.svg"
                        }
                      />
                    </InputGroup.Text>
                    <InputGroup.Text className="input-group-icon username-link">
                      {formattedLink}
                    </InputGroup.Text>
                    <Field
                      className={`form-control username-field 
                      ${
                        touched.username && errors.username ? "is-invalid" : ""
                      }`}
                      name="username"
                      validate={handleUsernameValidation}
                      // placeholder="hashtag.bio"
                      autoComplete="off"
                    />
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="username"
                    className="text-danger text-right"
                  />
                  {props.validation.isInValid && isvalidUserName ? (
                    <div class="text-danger text-right">
                      Name is already used
                    </div>
                  ) : (
                    ""
                  )}
                  {props.validation.isValid && isvalidUserName ? (
                    <div class="username-success text-right">
                      Name is available
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <div className="login-btn">
                  <Button
                    type="submit"
                    disabled={
                      !(
                        (props.validation.isValid && isvalidUserName) ||
                        props.profile.data.user.username === userName
                      ) || props.onboardingUpdate.buttonDisable
                    }
                    className="default-btn"
                  >
                    {props.onboardingUpdate.loadingButtonContent
                      ? props.onboardingUpdate.loadingButtonContent
                      : "Continue"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  validation: state.walletApp.validationInputData,
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateLinkSec);
