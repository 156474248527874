import React, { useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarPickerSkeleton } from "@mui/x-date-pickers/CalendarPickerSkeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateOnboardingStart } from "../../store/actions/WalletAppAction";
import { connect } from "react-redux";

const AdditionalInfoSec = (props) => {
  const [skipRender, setSkipRender] = React.useState(true);

  function getRandomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  const validationSchema = Yup.object().shape({
    // education: Yup.string().required("Education is required *"),
  });

  /**
   * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
   * ⚠️ No IE11 support
   */
  function fakeFetch(date, { signal }) {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        const daysInMonth = date.daysInMonth();
        const daysToHighlight = [1, 2, 3].map(() =>
          getRandomNumber(1, daysInMonth)
        );

        resolve({ daysToHighlight });
      }, 500);

      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException("aborted", "AbortError"));
      };
    });
  }

  const initialValue = props.profile.data.user.dob
    ? dayjs(props.profile.data.user.dob, "YYYY-MM-DD")
    : dayjs("2000/01/01", "YYYY-MM-DD");

  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [value, setValue] = React.useState(initialValue);
  const [maxDate, setMaxDate] = React.useState("08/17/2000")

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        // setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    const d = new Date();
    d.setFullYear(d.getFullYear()-10);
    setMaxDate(d)
    console.log(d, d.toDateString());
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Other" },
    { value: "rather-not-select", label: "Rather not say" },
  ];

  const [selectedGender, setSelectedGender] = React.useState();

  useEffect(() => {
    setSelectedGender(
      genders.filter(
        (gender) => gender.value === props.profile.data.user.gender
      )[0]
    );
  }, []);

  const handleSubmit = (values) => {
    props.dispatch(
      updateOnboardingStart({
        gender: selectedGender.value,
        dob: dayjs(value).format("YYYY-MM-DD"),
        ...values,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.onboardingUpdate.loading &&
      Object.keys(props.onboardingUpdate.data).length > 0
    ) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.prevStep()}>
          <Image
            src={window.location.origin + "/images/onboarding-icons/back.svg"}
          />
        </Button>
      </div>

      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <Formik
            initialValues={{
              education: props.profile.data.user.education
                ? props.profile.data.user.education
                : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <FORM>
                <div className="forgot-password-content">
                  <h4>Additional Information</h4>
                </div>
                <div className="additional-info">
                  <div className="mt-4">
                    <Form.Group>
                      <Form.Label>Gender*</Form.Label>
                      <Select
                        options={genders}
                        initialValue={selectedGender}
                        value={selectedGender}
                        onChange={(value) => setSelectedGender(value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="mt-4">
                    <Form.Label>Date of Birth*</Form.Label>
                    <div className=" additional-date-picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={value}
                          loading={isLoading}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                          onMonthChange={handleMonthChange}
                          renderInput={(params) => <TextField {...params} />}
                          renderLoading={() => <CalendarPickerSkeleton />}
                          maxDate={maxDate}
                          renderDay={(day, _value, DayComponentProps) => {
                            const isSelected =
                              !DayComponentProps.outsideCurrentMonth &&
                              highlightedDays.indexOf(day.date()) > 0;

                            return (
                              <Badge
                                key={day.toString()}
                                overlap="circular"
                                badgeContent={isSelected ? "🌚" : undefined}
                              >
                                <PickersDay {...DayComponentProps} />
                              </Badge>
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  {/* <div className="mt-4 additional-education-input is-invalid">
                    <Form.Group className={`${
                      touched.education && errors.education ? "is-invalid" : ""
                    }`}>
                      <Form.Label>Education*</Form.Label>
                      <Field 
                        className={`form-control ${
                          touched.education && errors.education ? "is-invalid" : ""
                        }`}
                        name="education" 
                      />
                      <ErrorMessage
                        component={"div"}
                        name="education"
                        className="text-danger text-right"
                      />
                    </Form.Group>
                  </div> */}
                </div>

                <div className="login-btn mt-4">
                  <Button
                    disabled={!value || props.onboardingUpdate.buttonDisable}
                    type="submit"
                    className="default-btn"
                  >
                    {props.onboardingUpdate.loadingButtonContent
                      ? props.onboardingUpdate.loadingButtonContent
                      : "Continue"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AdditionalInfoSec);
