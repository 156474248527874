import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Container, Row, Col } from "react-bootstrap";
import "./Loader.css";

const SingleNftImageLoader = () => {
  return (
    <>
      <div className="single-item-view-sec">
        <Container>
          <Row>
            <Col md={6}>
              <div className="single-item-preview-img-card-loader">
                <Skeleton count={1} />
              </div>
            </Col>
            <Col md={6}>
              <div className="single-item-info-box-loader">
                <Skeleton count={1} />
              </div>
            </Col>
          </Row>
          <div className="single-item-properties-sec-loader">
            <Skeleton count={1} />
          </div>

          <Row className="justify-content-center">
            <Col sm={12}>
              <div className="activity-offers-tab-header-sec-loader">
                <div className="nav-pills">
                  <Skeleton count={2} />
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="tab-content-loader">
                <Skeleton count={1} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SingleNftImageLoader;
