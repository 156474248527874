import React, { useContext, useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { authContext } from "./AuthProvider";
import useUserAuthStatus from "../customHooks/useUserAuthStatus";
import { CHAIN_INFO, SupportedChainId } from "../authprovider/chains";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";

const AuthWallet = (props) => {
  const {
    auth,
    connectWallet,
    hanldeLogout,
    supportedChains,
    context,
    price,
    setAuth,
    handleConnector,
    loginConnectors,
    activatingConnector,
  } = useContext(authContext);

  const userAuthStatus = useUserAuthStatus(props.profile);

  const [showNetworks, setShowNetworks] = useState(false);

  const styles = {
    position: "absolute",
    width: 305,
    top: 60,
    right: 0,
    left: -265,
    zIndex: 9999,
    border: "1px solid transparent",
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    bgcolor: "background.paper",
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  };

  const onCopy = () => {
    getSuccessNotificationMessage("Wallet address copied");
  };

  const handleNetworkChange = (chain) => {
    setAuth({
      ...auth,
      chainId: chain,
    });
    setShowNetworks(false);
  };

  return (
    <Box sx={styles}>
      {showNetworks ? (
        <ul className="nav-dropdown-link">
          <li className="nav-dropdown-close">
            <Link to="#" onClick={() => setShowNetworks(false)}>
              {" "}
              <Image src={window.location.origin + "/images/icons/close.svg"} />
            </Link>
          </li>
          <li className="wallet-select-network">
            <h5>Select Network</h5>
          </li>
          {Object.entries(SupportedChainId).map((chain, index) => (
            <li
              className="wallet-link-list"
              onClick={() => handleNetworkChange(chain[1])}
              key={index}
            >
              <Link to="">
                <span>
                  {" "}
                  <Image
                    src={
                      CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                        .logo
                    }
                  />
                </span>
                {
                  CHAIN_INFO[Object.entries(SupportedChainId)[index][1]]
                    .nativeCurrency.name
                }
              </Link>
            </li>
          ))}
        </ul>
      ) : auth.authStatus ? (
        <div>
          <div className="wallet-sec-select">
            <Link
              onClick={() => setShowNetworks(true)}
              className="wallet-link-list"
            >
              <span>
                {" "}
                <Image src={CHAIN_INFO[auth.chainId].logo} />
              </span>
              {CHAIN_INFO[auth.chainId].label}
            </Link>
            <div className="wallet-address-copy-btn">
              <CopyToClipboard text={auth.accounts} onCopy={() => onCopy()}>
                <Button>
                  {auth.accounts.substr(0, 5)}...
                  {auth.accounts.substr(auth.accounts.length - 4)}
                </Button>
              </CopyToClipboard>
            </div>

            <div className="connect-wallet-btn">
              <Button onClick={() => hanldeLogout()}>Logout</Button>
            </div>
            <div className="wallet-account-balance-box">
              <div className="wallet-account-balance">
                <Image src={CHAIN_INFO[auth.chainId].logo} />
                <div className=" wallet-balnce-content">
                  <h4>{CHAIN_INFO[auth.chainId].nativeCurrency.symbol}</h4>
                  <p>
                    1 {CHAIN_INFO[auth.chainId].nativeCurrency.symbol} ≉ /${" "}
                    {price.usdConvertedPrice}
                  </p>
                </div>
              </div>
              <div className=" wallet-balnce-content text-end">
                <h4>
                  {Number(auth.ethBalance).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </h4>
                <p>
                  {price.totalUsdbalaneFormatted} {price.denomination}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="wallet-sec-select">
          <Link
            onClick={() => setShowNetworks(true)}
            className="wallet-link-list"
          >
            <span>
              {" "}
              <Image src={CHAIN_INFO[auth.chainId].logo} />
            </span>
            {CHAIN_INFO[auth.chainId].label}
          </Link>
          <div className="wallet-info-sec wallet-link-list">
            <h4>
              <span>
                {" "}
                <Image
                  src={window.location.origin + "/images/icons/info.svg"}
                />
              </span>
              Wallet
            </h4>
            <p>
              Connect with one of our available providers or create a new one
            </p>
          </div>
          <div className="connect-wallet-btn">
            <div className="metamask-img">
              <Image
                src={window.location.origin + "/images/icons/metamask.svg"}
              />
              <p>Metamask</p>
            </div>

            <Button
              disabled={activatingConnector != undefined ? true : false}
              onClick={() =>
                handleConnector(loginConnectors[0].connectorFunction)
              }
            >
              {JSON.stringify(activatingConnector) ===
              JSON.stringify(loginConnectors[0].connectorFunction)
                ? "Loading..."
                : "Connect"}
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AuthWallet);
