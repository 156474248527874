import React, { useState, useEffect, useContext, useCallback } from "react";
import CollectionOffersCard from "../CollectionItem/CollectionOffersCard";
import {
  Container,
  Button,
  Image,
  Row,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import {
  bidOfferNftItemListStart,
  fetchMoreBidOfferNftItemListStart,
  fetchSingleNftItemsStart,
} from "../store/actions/NftAction";
import { useHistory, useLocation, useParams } from "react-router";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import Web3 from "web3";
import NftToken from "../../abis/NftToken.json";
import { authContext } from "../authprovider/AuthProvider";
import { Link, useNavigate } from "react-router-dom";

const OfferIndex = (props) => {
  const navigate = useNavigate();

  const {
    nft_collection_unique_id,
    nft_collection_item_token_id,
    contract_address,
  } = useParams();
  const [currentOwner, setCurrentOwner] = useState(null);
  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  useEffect(() => {
    props.dispatch(
      bidOfferNftItemListStart({
        nft_collection_unique_id: nft_collection_unique_id,
        nft_collection_item_token_id: nft_collection_item_token_id,
        skip: 0,
        take: 12,
      })
    );
    getCurrentOwner();
  }, []);

  const getCurrentOwner = async () => {
    let currentowneraddress = await getOwner(
      contract_address,
      nft_collection_item_token_id
    ).then((res) => res);
    console.log("currentowneraddress", currentowneraddress);
    setCurrentOwner(currentowneraddress);
    props.dispatch(
      fetchSingleNftItemsStart({
        nft_collection_unique_id: nft_collection_unique_id,
        nft_collection_item_token_id: nft_collection_item_token_id,
        owner_wallet_address:
          currentowneraddress == null || currentowneraddress == false
            ? ""
            : currentowneraddress,
        current_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
  };

  const getOwner = async (contract_address, token_id) => {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:3000");
    let nftContractData;
    let ownerAddress;

    const NFTData = NftToken.networks[auth.chainId];
    if (NFTData) {
      try {
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          contract_address
        );
        ownerAddress = await nftContractData.methods.ownerOf(token_id).call();
        return ownerAddress;
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  };

  const fetchMoreBidOfferNftItemList = () => {
    props.dispatch(
      fetchMoreBidOfferNftItemListStart({
        skip: props.bidOfferNftItemList.data.nftItems.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="single-activity-sec">
        <Container>
          <div className="header-back-btn mb-4">
            <Button>
              <Image
                onClick={() => navigate(-1)}
                src={
                  window.location.origin + "/images/onboarding-icons/back.svg"
                }
              />
            </Button>
          </div>
          <Row>
            <Col md={12}>
              <div className="top-collection-header-sec">
                <h2>
                  <span>
                    <Image
                      src={window.location.origin + "/images/icons/offer.svg"}
                      className="trending-icon"
                    />
                  </span>
                  Offers
                </h2>
              </div>
            </Col>
          </Row>
          {props.bidOfferNftItemList.loading || props.singleItems.loading ? (
            <div>
              {[...Array(4)].map((val, i) => (
                <Skeleton height={50} />
              ))}
            </div>
          ) : (
            <InfiniteScroll
              dataLength={props.bidOfferNftItemList.data.nftItems.length}
              next={fetchMoreBidOfferNftItemList}
              hasMore={
                props.bidOfferNftItemList.data.nftItems.length <
                props.bidOfferNftItemList.data.total
              }
              loader={""}
            >
              <CollectionOffersCard
                bidOfferNftItemLists={props.bidOfferNftItemList.data.nftItems}
                singleNftImage={props.singleItems.data}
              />
            </InfiniteScroll>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bidOfferNftItemList: state.nfts.bidOfferNftItemList,
  singleItems: state.nfts.singleItems,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(OfferIndex);
