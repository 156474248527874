import React, { useState } from "react";
import {
    Container,
    Image,
    Row,
    Nav,
    Col,
    Tab,
    Button,
    Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";


const FailedMint = (props) => {


    return <>
        <div className="mint-page-sec">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="mint-page-vertical-center">
                            <div className="mint-page-success-img-sec">
                                <Image
                                    src={
                                        window.location.origin + "/images/mint/failed.svg"
                                    }
                                    className="mint-page-success-img"
                                />
                            </div>
                            <div className="mint-info-sec">
                                <h2>Lorem ipsum dolar si amet!</h2>
                            </div>
                            <div className="mint-failed-btn-sec">
                                <Button className="mint-btn-outline">
                                    Back
                                </Button>
                                <Button className="mint-btn">
                                    Try Again
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>;
};

export default FailedMint;
