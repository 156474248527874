import React, { useState } from "react";
import {
    Container,
    Image,
    Row,
    Col,
  } from "react-bootstrap";
import "./Landing.css";
import { Link } from "react-router-dom";

const CreateSellYourNFT = (props) => {
  return <>
  <div className="create-sell-your-nft-sec sm-padding">
    <Container>
        <Row className="justify-content-md-center">
            <Col md={9}>
                <div className="section-title">
                    <h2>
                      <span>
                        <Image
                          src={
                            window.location.origin + "/images/landing-page/icons/text-side-icon.svg"
                          }
                        />
                      </span>
                        Create and Sell your Social NFTs in 4 Easy Steps
                      <span>
                        <Image
                          src={
                            window.location.origin + "/images/landing-page/icons/text-side-icon.svg"
                          }
                        />
                      </span>
                    </h2>
                </div>
            </Col>
        </Row>
        <Row className="margin-top-lg">
          <Col md={6}>
              <div className="create-sell-your-nft-card">
                <div className="create-sell-your-nft-icon-sec">
                  <Image
                    src={
                      window.location.origin + "/images/landing-page/icons/wallet-with-bg.svg"
                    }
                    className="create-sell-your-nft-icon"
                  />
                </div>
                <div className="create-sell-your-nft-info">
                  <h4>Set Up Wallet</h4>
                  <p>Connect wallet by clicking the wallet icon in the top right corner. <Link to="#">Learn more</Link> about the wallets we support.</p>
                </div>
              </div>
          </Col>
          <Col md={6}>
            <div className="create-sell-your-nft-card">
              <div className="create-sell-your-nft-icon-sec">
                <Image
                  src={
                    window.location.origin + "/images/landing-page/icons/circle-with-bg.svg"
                  }
                  className="create-sell-your-nft-icon"
                />
              </div>
              <div className="create-sell-your-nft-info">
                <h4>Create Collection</h4>
                <p>Click create and add social links, a description, profile & banner images, and set a royalties.</p>
              </div>
            </div>
          </Col>
          <Col md={6}>
              <div className="create-sell-your-nft-card">
                <div className="create-sell-your-nft-icon-sec">
                  <Image
                    src={
                      window.location.origin + "/images/landing-page/icons/diamond-with-bg.svg"
                    }
                    className="create-sell-your-nft-icon"
                  />
                </div>
                <div className="create-sell-your-nft-info">
                  <h4>Add NFT</h4>
                  <p>Upload your post in any format, add a title, a description and customize your NFT.</p>
                </div>
              </div>
          </Col>
          <Col md={6}>
            <div className="create-sell-your-nft-card">
              <div className="create-sell-your-nft-icon-sec">
                <Image
                  src={
                    window.location.origin + "/images/landing-page/icons/coins-with-bg.svg"
                  }
                  className="create-sell-your-nft-icon"
                />
              </div>
              <div className="create-sell-your-nft-info">
                <h4>List For Sale</h4>
                <p>Choose between auctions, fixed-price listings, and declining-price listings.</p>
              </div>
            </div>
          </Col>
        </Row>
    </Container>
  </div>
  </>;
};

export default CreateSellYourNFT;
