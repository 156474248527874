import {
  IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_FAILURE,
  IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
  IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_SUCCESS,
  IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_FAILURE,
  IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_START,
  IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_SUCCESS,
  IMPORT_SOCIAL_POSTS_FAILURE,
  IMPORT_SOCIAL_POSTS_START,
  IMPORT_SOCIAL_POSTS_SUCCESS,
  MORE_IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
  POST_FILES_VIEW_BASE64_FAILURE,
  POST_FILES_VIEW_BASE64_START,
  POST_FILES_VIEW_BASE64_SUCCESS,
  CREATE_IMPORTED_POST_TO_NFT_START,
  CREATE_IMPORTED_POST_TO_NFT_SUCCESS,
  CREATE_IMPORTED_POST_TO_NFT_FAILURE,

} from "../actions/ActionConstant";

const initialState = {
  importSocialPosts: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  socialPostsListForOwner: {
    data: {
      posts: [],
      total_post: 0,
    },
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  singleSocialPostImport: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  postsFilesViewBase64: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  saveSocialNftItem: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  }
};

const SocialPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_SOCIAL_POSTS_START:
      return {
        ...state,
        importSocialPosts: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case IMPORT_SOCIAL_POSTS_SUCCESS:
      return {
        ...state,
        importSocialPosts: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case IMPORT_SOCIAL_POSTS_FAILURE:
      return {
        ...state,
        importSocialPosts: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START:
      return {
        ...state,
        socialPostsListForOwner: {
          data: {
            posts: [],
            total_post: 0,
          },
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case MORE_IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START:
      return state;
    case IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_SUCCESS:
      return {
        ...state,
        socialPostsListForOwner: {
          data: {
            posts: [...state.socialPostsListForOwner.data.posts, ...action.data.social_imported_posts],
            total_post: action.data.total,
          },
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_FAILURE:
      return {
        ...state,
        socialPostsListForOwner: {
          data: state.socialPostsListForOwner.data,
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_START:
      return {
        ...state,
        singleSocialPostImport: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true,
        }
      }
    case IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_SUCCESS:
      return {
        ...state,
        singleSocialPostImport: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }
    case IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_FAILURE:
      return {
        ...state,
        singleSocialPostImport: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }
    case POST_FILES_VIEW_BASE64_START:
      return {
        ...state,
        postsFilesViewBase64: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true,
        }
      }
    case POST_FILES_VIEW_BASE64_SUCCESS:
      return {
        ...state,
        postsFilesViewBase64: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }
    case POST_FILES_VIEW_BASE64_FAILURE:
      return {
        ...state,
        postsFilesViewBase64: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }
    case CREATE_IMPORTED_POST_TO_NFT_START:
      return {
        ...state,
        saveSocialNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true
        }
      }
    case CREATE_IMPORTED_POST_TO_NFT_SUCCESS:
      return {
        ...state,
        saveSocialNftItem: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }
    case CREATE_IMPORTED_POST_TO_NFT_FAILURE:
      return {
        ...state,
        saveSocialNftItem: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }

    default:
      return state;
  }
};

export default SocialPostReducer;
