import React, { useState, useEffect } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Button,
	Table,
	Form,
	InputGroup,
	Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const CancelOfferModal = (props) => {

	const { cancelOfferBtnContent, setCancelOfferBtnContent } = props;

	const [termsAgreed, setTermsAgreed] = useState(false)

	useEffect(() => {
		if (
			!props.cancelOffer.loading &&
			props.cancelOffer.data.code == 128 &&
			props.singleNftImage.nft_collection_item
		) {
			setCancelOfferBtnContent("");
			props.getCurrentOwner();
			props.handleCancelOfferClose();
		}
	}, [props.cancelOffer.data]);

	const handleTermsChange = () => {
		setTermsAgreed(prev => !prev)
	}

	return (
		<>
			<Modal
				className="modal-dialog-center cancel-offer-modal"
				size="md"
				centered
				show={props.open}
				onHide={props.handleCancelOfferClose}
			>
				<Modal.Body>
					<Button className="modal-close" onClick={() => props.handleCancelOfferClose()}>
						<Image
							className="close-icon"
							src={
								window.location.origin + "/images/create-item/icons/modal-close.svg"
							}
						/>
					</Button>
					<div className="favourite-content">
						<h4>Are You sure you want to cancel your offer</h4>
						<div className="accept-offer-agree-terms-sec">
							<div className="agree-terms-sec">
								<Form.Check
									inline
									name="group1"
									checked={termsAgreed}
                onChange={() => handleTermsChange()}
								/>
								<p>I agree to the following <Link to="/page/terms">terms & conditions</Link></p>
							</div>
						</div>
						<div className="accept-offer-btn-sec">
							<Button 
							className="default-btn"
							disabled={!termsAgreed || cancelOfferBtnContent}
              onClick={() => props.cancelOfferSave(props.cancelOfferInput)}
							>
								{cancelOfferBtnContent ? cancelOfferBtnContent : "Confirm"}
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	cancelOffer: state.nfts.cancelBidOfferNftItem,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CancelOfferModal);