import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import { clearNftBuyData } from "../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import { useTheme } from "@material-ui/core";
import configuration from "react-global-configuration";
import CustomCalander from "../customComponents/CustomCalander";
import moment from "moment";
import { addDays } from "date-fns";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const MakeofferModal = (props) => {

  const [priceSelect, setPriceSelect] = useState([]);

  const [minBid, setMinBid] = useState(0);

  const { makeOfferButtonContent } = props;

  const { auth, price } = useContext(authContext);

  const theme = useTheme();

  const nativeCurrencySymbol = configuration.get(
    "configData.native_currency_symbol"
  )
    ? configuration.get("configData.native_currency_symbol")
    : "VLX";

  const makeOfferSchema = Yup.object().shape({
    amount: Yup.number()
      .min()
      .required("* Amount is required.")
      .test(
        "min",
        `${minBid == 0
          ? "* amount must be grater than 0."
          : `* amount must be grater than last bid ${minBid} ${nativeCurrencySymbol}.`
        }`,
        (value) => Number(value) > minBid
      ),
  });

  const currentdate = new Date();

  const [makeOfferStatus, setMakeOfferStatus] = useState(
    props.singleNftImage.data.nft_collection_item.buyer_unique_id ==
      auth.userUniqueId
      ? "listed"
      : "unreviewed"
  );

  const constructEndTimeFormat = () => {
    let timeFormat = "";

    if (currentdate.getHours() + 1 > 23) {
      timeFormat += `${currentdate.getHours() < 10
        ? `0${currentdate.getHours()}`
        : currentdate.getHours()
        }:${currentdate.getMinutes() < 10
          ? `0${currentdate.getMinutes()}`
          : currentdate.getMinutes()
        }`;
    } else {
      timeFormat += `${currentdate.getHours() + 1 < 10
        ? `0${currentdate.getHours() + 1}`
        : currentdate.getHours() + 1
        }:${currentdate.getMinutes() < 10
          ? `0${currentdate.getMinutes()}`
          : currentdate.getMinutes()
        }`;
    }

    return timeFormat;
  };

  const [offerData, setOfferData] = useState({
    amount: "0",
    bid_expire_at:
      currentdate.getHours() + 1 > 23
        ? moment(addDays(new Date(), 1)).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    endTime: constructEndTimeFormat(),
    isEndTimeError: false,
    sameDay: currentdate.getHours() + 1 > 23 ? false : true,
  });

  const [termsAgreed, setTermsAgreed] = useState(false);

  const handlePriceSelect = (event) => {
    const {
      target: { value },
    } = event;
    setPriceSelect(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleDateSelect = useCallback(
    (value) => {
      console.log(value);
      setOfferData({
        ...offerData,
        bid_expire_at: value,
        sameDay: new Date() > value,
        endTime:
          new Date() > value
            ? constructEndTimeFormat()
            : `${currentdate.getHours() < 10
              ? `0${currentdate.getHours()}`
              : currentdate.getHours()
            }:${currentdate.getMinutes() < 10
              ? `0${currentdate.getMinutes()}`
              : currentdate.getMinutes()
            }`,
      });
    },
    [offerData]
  );

  const handleEndTimeChange = useCallback((value) => {
    if (value == "") {
      setOfferData({
        ...offerData,
        isEndTimeError: true,
        endTime: value,
      });
    } else {
      setOfferData({
        ...offerData,
        endTime: value,
      });
    }
  });

  useEffect(() => {
    if (
      !props.offersave.loading &&
      props.offersave.data.code == 124 &&
      props.singleNftImage.data.nft_collection_item
    ) {
      setMakeOfferStatus("listed");
    }
    props.setMakeOfferButtonContent("");
  }, [props.offersave.data]);

  const closeMakeOffer = async () => {
    setMakeOfferStatus("unreviewed");
    props.handleMakeOfferClose();
    props.dispatch(clearNftBuyData());
    // props.getCurrentOwner()
  };

  useEffect(() => {
    const endTime =
      offerData.endTime != "" ? String(offerData.endTime).split(":") : "";
    const endHour = offerData.endTime != "" ? endTime[0].replace(/^0/, "") : "";
    const endMinute =
      offerData.endTime != "" ? endTime[1].replace(/^0/, "") : "";

    if (offerData.sameDay) {
      if (Number(endHour) < currentdate.getHours()) {
        setOfferData({
          ...offerData,
          isEndTimeError: true,
        });
      } else if (Number(endHour) == currentdate.getHours()) {
        setOfferData({
          ...offerData,
          isEndTimeError: true,
        });
      } else if (Number(endHour) > currentdate.getHours()) {
        setOfferData({
          ...offerData,
          isEndTimeError: false,
        });
      }
    } else {
      setOfferData({
        ...offerData,
        isEndTimeError: false,
      });
    }
  }, [offerData.endTime, offerData.sameDay]);

  useEffect(() => {
    if (
      !props.bidOfferNftItemList.loading &&
      props.bidOfferNftItemList.data.nft_item_offers &&
      props.bidOfferNftItemList.data.nft_item_offers.length > 0
    ) {
      setMinBid(
        props.bidOfferNftItemList.data.nft_item_offers[0].bid_status != 3 ?
          Number(props.bidOfferNftItemList.data.nft_item_offers[0].amount) : 0
      );
      setOfferData({
        ...offerData,
        amount: props.bidOfferNftItemList.data.nft_item_offers[0].bid_status != 3 ? props.bidOfferNftItemList.data.nft_item_offers[0].amount : "0",
      });
    }
  }, [props.bidOfferNftItemList.data]);

  const handleSubmit = () => {
    props.makeOfferSave(offerData);
  };

  const handleTermsChange = () => {
    setTermsAgreed((prev) => !prev);
  };


  return (
    <div>
      <Modal
        className="modal-dialog-center add-properties-modal"
        size="md"
        centered
        show={props.open}
        onHide={props.handleMakeOfferClose}
      >
        {makeOfferStatus == "unreviewed" && (
          <Modal.Body>
            <Button
              className="modal-close"
              onClick={() => props.handleMakeOfferClose()}
            >
              <Image
                className="close-icon"
                src={
                  window.location.origin +
                  "/images/create-item/icons/modal-close.svg"
                }
              />
            </Button>
            <Button className="back-arrow-btn" onClick={() => props.handleMakeOfferClose()}>
              <Image
                className="back-icon"
                src={
                  window.location.origin +
                  "/images/landing-page/icons/back-arrow.svg"
                }
              />
            </Button>
            <div className="make-offer-sec new-form-input ">
              <h4>Yes, you can make an offer!</h4>
              <p>
                Tell us what you want to pay and it’s up to the seller if they accept it.  Do you feel lucky?
              </p>
              <Formik
                initialValues={{
                  amount: offerData.amount,
                }}
                className="w-100"
                validationSchema={makeOfferSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
              >
                {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                  <FORM>
                    <div className="price-box">
                      <div className="price-label">
                        <h5>Price</h5>
                      </div>
                      <div className="price-input">
                        <div className="price-value">{props.singleNftImage.data.nft_collection_item.blockchain_type}</div>

                        <Field
                          type="number"
                          name="amount"
                          placeholder="Amount"
                          className={`form-control ${touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          value={offerData.amount}
                          onChange={(event) =>
                            setOfferData({
                              ...offerData,
                              amount: event.target.value,
                            })
                          }
                        />
                      </div>
                      {offerData.amount > 0 &&
                        price.usdConvertedPrice != null && (
                          <p className="converted_price right m-0">
                            ≉{" "}
                            <i
                              className="fa fa-usd"
                              aria-hidden="true"
                            ></i>{" "}
                            {Number(
                              offerData.amount * price.usdConvertedPrice
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            {price.denomination}
                          </p>
                        )}
                    </div>
                    <h4 className="mt-3">Offer Expiration</h4>
                    <div className="offer-date">
                      <CustomCalander
                        months={1}
                        value={offerData.bid_expire_at}
                        handleDateSelect={handleDateSelect}
                        handleEndTimeChange={handleEndTimeChange}
                        withTime
                        useTimeValue
                        timeValue={offerData.endTime}
                        isTimeError={offerData.isEndTimeError}
                      />
                    </div>
                    <div className="agree-terms-sec make-offer-agree-terms">
                      <Form.Check
                        inline
                        name="group1"
                        id="my-check"
                        type="checkbox"
                        checked={termsAgreed}
                        onChange={() => handleTermsChange()}
                      />
                      <p> I agree to the following <Link to="/page/terms">terms & conditions</Link></p>
                    </div>
                    <div className="Price-btn text-center mt-3">
                      <Button
                        className="default-btn"
                        disabled={
                          makeOfferButtonContent != ""
                            ? true
                            : false || offerData.isEndTimeError || !termsAgreed
                        }
                        type="submit"
                      >
                        {makeOfferButtonContent != ""
                          ? makeOfferButtonContent
                          : "Make An Offer"}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </Modal.Body>
        )}
        {makeOfferStatus == "listed" && (
          <Modal.Body>
            <Button
              className="modal-close"
              onClick={() => props.handleMakeOfferClose()}
            >
              <Image
                className="close-icon"
                src={
                  window.location.origin +
                  "/images/create-item/icons/modal-close.svg"
                }
              />
            </Button>
            <Button className="back-arrow-btn" onClick={() => props.handleMakeOfferClose()}>
              <Image
                className="back-icon"
                src={
                  window.location.origin +
                  "/images/landing-page/icons/back-arrow.svg"
                }
              />
            </Button>
            <div className="accept-offer-content">
              <h4>Your offer is completed successfully</h4>
              <div className="accept-offer-complete-preview-img-sec">
                <Image
                  className="accept-offer-complete-preview-img"
                  src={props.singleNftImage.data.nft_collection_item.picture}
                />
              </div>
              <div className="buy-nft-social-links-sec">
                <h5>Links</h5>
                <ul className="list-unstyled buy-nft-social-links">
                  {props.singleNftImage.data.nft_collection
                    .twitter_link ? (
                    <li>
                      <a
                        href={props.singleNftImage.data.nft_collection
                          .twitter_link}
                        target="_blank"
                      >
                        <Image
                          className="buy-nft-social-icon"
                          src={
                            window.location.origin + "/images/twitter.svg"
                          }
                        />
                      </a>
                    </li>
                  ) : null}
                  {props.singleNftImage.data.nft_collection
                    .facebook_link ? (
                    <li>
                      <a
                        href={props.singleNftImage.data.nft_collection
                          .facebook_link}
                        target="_blank">
                        <Image
                          className="buy-nft-social-icon"
                          src={
                            window.location.origin + "/images/facebook.svg"
                          }
                        />
                      </a>
                    </li>
                  ) : null}
                  {props.singleNftImage.data.nft_collection
                    .telegram_link ? (
                    <li>
                      <a
                        href={props.singleNftImage.data.nft_collection
                          .telegram_link}
                        target="_blank">
                        <Image
                          className="buy-nft-social-icon"
                          src={
                            window.location.origin + "/images/telegram.svg"
                          }
                        />
                      </a>
                    </li>
                  ) : null}
                  {props.singleNftImage.data.nft_collection
                    .external_link ? (
                    <li>
                      <a href={props.singleNftImage.data.nft_collection
                        .external_link}
                        target="_blank">
                        <Image
                          className="buy-nft-social-icon"
                          src={
                            window.location.origin + "/images/external_link.svg"
                          }
                        />
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="accept-offer-btn-sec">
                <Button className="default-btn" onClick={() => closeMakeOffer()}>
                  View Item
                </Button>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

const mapStateToPros = (state) => ({
  offersave: state.nfts.bidOfferNftItemSave,
  bidOfferNftItemList: state.nfts.bidOfferNftItemList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MakeofferModal);
