import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { updateOnboardingStart } from "../../store/actions/WalletAppAction";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";

const LinkYourAccount = (props) => {
  const [skipRender, setSkipRender] = useState(true);

  const validateLink = (value) => {
    if (value.replaceAll(" ", "") !== value) {
      return "Invalid link";
    }
    return "";
  };

  const handleSubmit = (values) => {
    props.dispatch(updateOnboardingStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.onboardingUpdate.loading &&
      Object.keys(props.onboardingUpdate.data).length > 0
    ) {
      getSuccessNotificationMessage("Social Links Updated");
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.setStep(5)}>
          <Image
            src={window.location.origin + "/images/onboarding-icons/back.svg"}
          />
        </Button>
      </div>
      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <div className="invite-link-section">
            <h4 className="onboard-link-title">Link your Socials</h4>
            <p>
              Link your social media accounts to increase the strength of
              your profile
            </p>
            <div className="link-social-account-sec mt-4">
              <Formik
                initialValues={{
                  insta_username: props.profile.data.user.insta_username
                    ? props.profile.data.user.insta_username
                    : "",
                  facebook_username: props.profile.data.user.facebook_username
                    ? props.profile.data.user.facebook_username
                    : "",
                  twitter_username: props.profile.data.user.twitter_username
                    ? props.profile.data.user.twitter_username
                    : "",
                  snap_username: props.profile.data.user.snap_username
                    ? props.profile.data.user.snap_username
                    : "",
                }}
                // validationSchema={{}}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, touched }) => (
                  <FORM>
                    <ul>
                      <li>
                        <span>
                          <Image
                            src={
                              window.location.origin +
                              "/images/onboarding-icons/instagram.svg"
                            }
                          />
                        </span>
                        <Form.Group>
                          <Field
                            type="text"
                            placeholder="Enter account URL"
                            className="form-control"
                            name="insta_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="insta_username"
                            className="text-danger small"
                          />
                        </Form.Group>
                      </li>
                      <li>
                        <span>
                          <Image
                            src={
                              window.location.origin +
                              "/images/onboarding-icons/facebook.svg"
                            }
                          />
                        </span>
                        <Form.Group>
                          <Field
                            type="text"
                            placeholder="Enter account URL"
                            className="form-control"
                            name="facebook_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="facebook_username"
                            className="text-danger small"
                          />
                        </Form.Group>
                      </li>
                      <li>
                        <span>
                          <Image
                            src={
                              window.location.origin +
                              "/images/onboarding-icons/twitter.svg"
                            }
                          />
                        </span>
                        <Form.Group>
                          <Field
                            type="text"
                            placeholder="Enter account URL"
                            className="form-control"
                            name="twitter_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="twitter_username"
                            className="text-danger small"
                          />
                        </Form.Group>
                      </li>
                      <li>
                        <span>
                          <Image
                            src={
                              window.location.origin +
                              "/images/onboarding-icons/snapchat.svg"
                            }
                          />
                        </span>
                        <Form.Group>
                          <Field
                            type="text"
                            placeholder="Enter account URL"
                            className="form-control"
                            name="snap_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="snap_username"
                            className="text-danger small"
                          />
                        </Form.Group>
                      </li>
                    </ul>
                    <div className="login-btn mt-5">
                      <Button
                        disabled={props.onboardingUpdate.buttonDisable}
                        className="default-btn"
                        type="submit"
                      >
                        {props.onboardingUpdate.loadingButtonContent
                          ? props.onboardingUpdate.loadingButtonContent
                          : "Continue"}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>

          {/* <div className="home-page-link mt-4 text-center">
            <Link to="/">Go To Homepage</Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LinkYourAccount);
