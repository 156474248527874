import React, { useState, useEffect, useContext } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Button,
	Table,
	Form,
	InputGroup,
	Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";

import configuration from "react-global-configuration";
import { connect } from "react-redux";
import Web3 from "web3";
import NftToken from "../../abis/NftToken.json";
import { authContext } from "../authprovider/AuthProvider";
import CustomAccordion from "../customComponents/CustomAccordion";
import { updateSignAndApproveStart } from "../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import { CHAIN_INFO, SupportedChainId, SupportedChainIdBySymbol } from "../authprovider/chains";

const AcceptOfferModal = (props) => {

	const { price } = useContext(authContext);

	const { acceptOfferBtnContent, setAcceptOfferBtnContent } = props;

	const [termsAgreed, setTermsAgreed] = useState(false)

	const [approveData, setApproveData] = useState({
		signature: null,
		status: false,
		loading: false,
		loadingContent: null,
		activeAccordion: "1",
	});

	const { auth } = useContext(authContext);

	// We need to get approval for the NFT. NFT contract should get the approval to sell the NFT.
	const getApprovalToSale = async () => {

		if (!auth.authStatus) {
			const notificationMessage = getErrorNotificationMessage("Please connect metamask.");
			props.dispatch(createNotification(notificationMessage));
			return false;
		}

		if (CHAIN_INFO[auth.chainId].nativeCurrency.symbol != props.singleNftImage.nft_collection.blockchain_type) {
			const notificationMessage = getErrorNotificationMessage(
				`Collection is not deployed in the detected network. Please switch to ${props.singleNftImage.nft_collection.blockchain_type}`
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}

		if (auth.accounts != props.currentOwner) {
			const notificationMessage = getErrorNotificationMessage(
				`Connected wallet is not the owner of this NFT. Please switch to ${String(
					props.currentOwner
				).substring(0, 5)}
        ...
        ${String(
					props.currentOwner
				).substring(
					props.currentOwner.length -
					4
				)}`
			);
			props.dispatch(createNotification(notificationMessage));
			return false;
		}
		// NFT contract.
		setApproveData({
			...approveData,
			loading: true,
			loadingContent: "Loading...",
		});
		const web3 = window.web3;
		let nftContractData;

		const NFTData = NftToken.networks[auth.chainId];
		if (NFTData) {
			try {
				console.log("Test true");
				console.log("sellerContractAddress", auth.sellerContractAddress);
				nftContractData = await new web3.eth.Contract(
					NftToken.abi,
					props.singleNftImage.nft_collection.contract_address
				);
				console.log("Test next");

				nftContractData.methods
					.setApprovalForAll(auth.sellerContractAddress, true)
					.send({ from: auth.accounts })
					.on("receipt", async (receipt) => {
						props.dispatch(
							updateSignAndApproveStart({
								is_approved: 1,
								nft_collection_unique_id: props.singleNftImage.nft_collection.nft_collection_unique_id,
								wallet_address: auth.accounts,
							})
						);
						setApproveData({
							...approveData,
							status: true,
							loading: false,
							loadingContent: null,
							activeAccordion: "2",
						});
						props.setApproveOfferStatus("accept")
					})
					.on("error", (error) => {
						let notificationMessage;
						if (error.message == undefined) {
							notificationMessage =
								"Unexpected error occuried, Please try again...";
						} else {
							notificationMessage = error.message;
						}
						setApproveData({
							...approveData,
							status: true,
							loading: false,
							loadingContent: null,
						});
						// Send error notification.
					});
			} catch (error) {
				setApproveData({
					...approveData,
					status: true,
					loading: false,
					loadingContent: null,
				});
				// Send fail notification.
				const notificationMessage = getErrorNotificationMessage(
					"Something went wrong. Please refresh the page and try again."
				);
				props.dispatch(createNotification(notificationMessage));
				console.log(error);
			}
		} else {
			setApproveData({
				...approveData,
				status: true,
				loading: false,
				loadingContent: null,
			});
			window.alert("NFT contract not deployed to detected network.");
		}
	};

	useEffect(() => {
		if (
			!props.acceptOffer.loading &&
			props.acceptOffer.data.code == 125 &&
			props.singleNftImage.nft_collection_item
		) {
			props.setApproveOfferStatus("completed");

		}
		setAcceptOfferBtnContent("");
	}, [props.acceptOffer.data]);

	const closeAcceptOffer = async () => {
		props.handleAcceptOfferClose()
		props.getCurrentOwner()
	};

	const handleTermsChange = () => {
		setTermsAgreed(prev => !prev)
	}

	return (
		<>
			<Modal
				className="modal-dialog-center accept-offer-modal"
				size="md"
				centered
				show={props.open}
				onHide={props.handleAcceptOfferClose}
			>
				<Modal.Body>
					<>
						{props.approveOfferStatus == "confirm" ? (
							<>
								<Button className="modal-close" onClick={() => props.handleAcceptOfferClose()}>
									<Image
										className="close-icon"
										src={
											window.location.origin + "/images/create-item/icons/modal-close.svg"
										}
									/>
								</Button>
								<Button className="back-arrow-btn">
									{/* <Image
							className="back-icon"
							src={
								window.location.origin + "/images/landing-page/icons/back-arrow.svg"
							}
						/> */}
								</Button>
								<div className="step1">
									<div className="accept-offer-content">
										<h4>Are You Sure?</h4>
										<div className="accept-offer-header-img-sec">
											<Image
												className="accept-offer-header-img"
												src={
													window.location.origin + "/images/single-item/icons/warning-icon.svg"
												}
											/>
										</div>
										<p>
											Your About to accept this offer
											<strong>for {props.acceptOfferInput.amount}</strong>
											<Image
												src={
													CHAIN_INFO[
														SupportedChainIdBySymbol[
														props.singleNftImage.nft_collection.blockchain_type
														]
													].logo
												}
												className="accept-offer-price-icon"
											/>
										</p>
										<div className="accept-offer-agree-terms-sec">
											<div className="agree-terms-sec">
												<Form.Check
													inline
													name="group1"
													checked={termsAgreed}
													onChange={() => handleTermsChange()}
												/>
												<p>I agree to the following <Link to="/page/terms">terms & conditions</Link></p>
											</div>
										</div>
										<div className="accept-offer-btn-sec">
											<Button className="default-outline-btn" onClick={() => props.handleAcceptOfferClose()}>
												Cancel
											</Button>
											<Button
												className="default-btn"
												disabled={!termsAgreed}
												onClick={() =>
													props.setApproveOfferStatus(
														props.singleNftImage.nft_collection_item.user_approved_status == 1 ? "accept" : "approve"
													)
												}
											>
												Approve
											</Button>
										</div>
									</div>
								</div>
							</>
						) : props.approveOfferStatus == "approve" ? (
							<>
								<Button className="modal-close" onClick={() => props.handleAcceptOfferClose()}>
									<Image
										className="close-icon"
										src={
											window.location.origin + "/images/create-item/icons/modal-close.svg"
										}
									/>
								</Button>
								<Button className="back-arrow-btn" onClick={() => props.setApproveOfferStatus("confirm")}>
									<Image
										className="back-icon"
										src={
											window.location.origin + "/images/landing-page/icons/back-arrow.svg"
										}
									/>
								</Button>
								<div>
									<h4 className="mb-5">Approve this collection</h4>
									<CustomAccordion
										activeId="1"
										accordionHeading={"Approve"}
										accordionKey={"1"}
										accordionContent={
											"Approve the NFT Collection. This only needs to be done once."
										}
										accordionButton={true}
										OnClick={() => getApprovalToSale()}
										accordionButtonDisable={
											approveData.loading ||
											approveData.loadingContent != null
										}
										accordionButtonContent={
											approveData.loadingContent != null
												? approveData.loadingContent
												: "Approve"
										}
										spinner
										spinnerLoading={
											approveData.loadingContent != null &&
											approveData.activeAccordion === "1"
										}
										activeAccordion={approveData.activeAccordion}
										loadingComplete={
											Number(approveData.activeAccordion) > Number("1")
										}
									/>
								</div>
							</>
						) : props.approveOfferStatus == "accept" ? (
							<>
								<Button className="modal-close" onClick={() => props.handleAcceptOfferClose()}>
									<Image
										className="close-icon"
										src={
											window.location.origin + "/images/create-item/icons/modal-close.svg"
										}
									/>
								</Button>
								<Button className="back-arrow-btn" onClick={() => props.setApproveOfferStatus("confirm")}>
									<Image
										className="back-icon"
										src={
											window.location.origin + "/images/landing-page/icons/back-arrow.svg"
										}
									/>
								</Button>
								<div className="step2">
									<div className="accept-offer-content">
										<h4>Accept this offer</h4>
										<div className="accept-offer-details">
											<div className="accept-offer-header-sec">
												<h5>Item</h5>
												<h5>Subtotal</h5>
											</div>
											<div className="accept-offer-body-sec">
												<div className="accept-offer-header-sec">
													<div className="accept-offer-left-header-sec">
														<Image
															src={props.singleNftImage.nft_collection_item.picture}
															className="accept-offer-title-img"
														/>
														<div className="accept-offer-title-info">
															<h5>{props.singleNftImage.nft_collection.name}</h5>
															<h3>{props.singleNftImage.nft_collection_item.name}</h3>
														</div>
													</div>
													<div className="accept-offer-right-header-sec">
														<div className="accept-offer-price">
															<Image
																src={
																	CHAIN_INFO[
																		SupportedChainIdBySymbol[
																		props.singleNftImage.nft_collection.blockchain_type
																		]
																	].logo
																}
																className="accept-offer-price-icon"
															/>
															<span>{props.acceptOfferInput.amount} {props.singleNftImage.nft_collection.blockchain_type}</span>
														</div>
														<div className="accept-offer-price-amount">
															<Image
																src={
																	window.location.origin + "/images/single-item/icons/dollar.svg"
																}
																className="accept-offer-price-icon"
															/>
															<span>
																{price.usdConvertedPrice != null &&
																	props.acceptOfferInput.amount != "" &&
																	Number(props.acceptOfferInput.amount) > 0 ? (
																	<p className="converted_price right">
																		≉ <i className="fa fa-usd" aria-hidden="true"></i>{" "}
																		{Number(
																			props.acceptOfferInput.amount * price.usdConvertedPrice
																		).toLocaleString(undefined, {
																			maximumFractionDigits: 2,
																		})}{" "}
																		{price.denomination}
																	</p>
																) : "0 USD"}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="accept-offer-fees-detials">
											<h5>Fees</h5>
											<div className="accept-offer-service-fee-card">
												<h5>Service Fee</h5>
												<div className="border-dashed"></div>
												<h5>{configuration.get("configData.admin_commission")} %</h5>
											</div>
											{props.singleNftImage.nft_collection
												.royalty_type == 1 ? props.singleNftImage.nft_collection
													.royalty_percentage > 0 && (
												<div className="accept-offer-service-fee-card">
													<h5>Royalty Percentage</h5>
													<div className="border-dashed"></div>
													<h5>{
														props.singleNftImage.nft_collection
															.royalty_percentage
													}{" "} %</h5>
												</div>
											) : props.singleNftImage.nft_collection_item
												.royalty_percentage > 0 && (
												<div className="accept-offer-service-fee-card">
													<h5>Royalty Percentage</h5>
													<div className="border-dashed"></div>
													<h5>{
														props.singleNftImage.nft_collection_item
															.royalty_percentage
													}{" "} %</h5>
												</div>
											)}
										</div>
										<div className="accept-offer-total-earning">
											<h5>Total Earning</h5>
											<h5>{props.acceptOfferInput.total_earnings}</h5>
										</div>
										<div className="accept-offer-btn-sec">
											<Button 
											 className="default-btn" 
											 onClick={() => props.approveOfferSave(props.acceptOfferInput)}
											 disabled={acceptOfferBtnContent ? true : false}
											>
												{acceptOfferBtnContent ? acceptOfferBtnContent : "Accept Offer"}
											</Button>
										</div>
									</div>
								</div>
							</>
						) : (
							<div className="step3">
								<div className="accept-offer-content">
									<h4>Offer Accepted for the item</h4>
									<div className="accept-offer-complete-preview-img-sec">
										<Image
											className="accept-offer-complete-preview-img"
											src={props.singleNftImage.nft_collection_item.picture}
										/>
									</div>
									<div className="buy-nft-social-links-sec">
										<h5>Links</h5>
										<ul className="list-unstyled buy-nft-social-links">
											{props.singleNftImage.nft_collection
												.twitter_link ? (
												<li>
													<a
														href={props.singleNftImage.nft_collection
															.twitter_link}
														target="_blank"
													>
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/twitter.svg"
															}
														/>
													</a>
												</li>
											) : null}
											{props.singleNftImage.nft_collection
												.facebook_link ? (
												<li>
													<a
														href={props.singleNftImage.nft_collection
															.facebook_link}
														target="_blank">
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/facebook.svg"
															}
														/>
													</a>
												</li>
											) : null}
											{props.singleNftImage.nft_collection
												.telegram_link ? (
												<li>
													<a
														href={props.singleNftImage.nft_collection
															.telegram_link}
														target="_blank">
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/telegram.svg"
															}
														/>
													</a>
												</li>
											) : null}
											{props.singleNftImage.nft_collection
												.external_link ? (
												<li>
													<a href={props.singleNftImage.nft_collection
														.external_link}
														target="_blank">
														<Image
															className="buy-nft-social-icon"
															src={
																window.location.origin + "/images/external_link.svg"
															}
														/>
													</a>
												</li>
											) : null}
										</ul>
									</div>
									<div className="accept-offer-btn-sec">
										<Button className="default-btn" onClick={() => closeAcceptOffer()}>
											View Item
										</Button>
									</div>
								</div>
							</div>
						)}
					</>

				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	acceptOffer: state.nfts.acceptOfferNftItem,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AcceptOfferModal);