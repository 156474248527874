import React, { useState, useEffect, useRef } from "react";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import "./Auth.css";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginSlider from "./LoginSlider";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userLoginStart, registerStart } from "../store/actions/UserAction";
import { connect } from "react-redux";
import PhoneNumberVerificationModal from "./PhoneNumberVerificationModal";
import TwoStepAuthModal from "./TwoStepAuthModal";
import SocialButton from "../helper/SocialButton";
import { gapi } from "gapi-script";

const LoginIndex = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const formRef = useRef(null);

  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [numberVerification, setNumberVerification] = useState(null);
  const [twoStepAuth, setTwoStepAuth] = useState(null);
  const [loginInputData, setLoginInputData] = useState({
    login_by: apiConstants.LOGIN_BY,
    device_token: apiConstants.DEVICE_TYPE,
    device_type: apiConstants.DEVICE_TOKEN,
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const closeNumberVerificationModal = () => {
    setNumberVerification(null);
  };

  const closeTwoStepModal = () => {
    setTwoStepAuth(null);
  }

  const login = (values) => {
    props.dispatch(userLoginStart({ ...values, ...loginInputData }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.loginInputData.loading &&
      Object.keys(props.loginInputData.data).length > 0
    ) {
      // if (props.loginInputData.data.user.is_two_step_auth_enabled == 1) {
      //   navigate("/verification");
      // } else if (props.loginInputData.data.user.is_email_verified == 0) {
      //   navigate("/register-verification");
      // } else {
      //   navigate("/");
      // }
      formRef.current.resetForm();
      if (props.loginInputData.data.user.onboarding_step == 1) {
        setNumberVerification(props.loginInputData.data.user.mobile);
      } else if (props.loginInputData.data.user.is_two_step_auth_enabled == 1) {
        //TwoStep Auth Modal
        //Revert back for production
        // setTwoStepAuth(props.loginInputData.data.user.mobile);
        navigate("/");
      } else if (props.loginInputData.data.user.onboarding_step < 6) {
        navigate("/onboarding");
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.loginInputData]);

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get("configData.GOOGLE_CLIENT_ID"),
        plugin_name: "chat",
      });
    });
  }, []);

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };


  useEffect(() => {
    if (
      !skipRender &&
      !props.registerInputData.loading &&
      Object.keys(props.registerInputData.data).length > 0
    ) {
      formRef.current.resetForm();
      if (props.registerInputData.data.data.user.onboarding_step == 2 && props.registerInputData.data.data.private_passphrase) {
        navigate("/view-private-key");
      } else if (props.registerInputData.data.data.user.onboarding_step < 6) {
        navigate("/onboarding");
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.registerInputData]);

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row>
              <Col md={12} xl={6} lg={6} className="border-right resp-order">
                <LoginSlider />
              </Col>
              <Col md={12} xl={6} lg={6} className="border-left">
                <div className="login-auth-sec">
                  <div className="login-nav-link">
                    <ul>
                      <li
                        className={
                          location.pathname === "/login" ? "active" : ""
                        }
                      >
                        <Link to="/login">Log in</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/register" ? "active" : ""
                        }
                      >
                        <Link to="/register">Sign Up</Link>
                      </li>
                    </ul>
                  </div>
                  <Formik
                    innerRef={formRef}
                    initialValues={{
                      email: configuration.get("configData.demo_user_email")
                        ? configuration.get("configData.demo_user_email")
                        : "",
                      password: configuration.get(
                        "configData.demo_user_password"
                      )
                        ? configuration.get("configData.demo_user_password")
                        : "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => login(values)}
                  >
                    {({ errors, touched }) => (
                      <FORM>
                        <div className="sign-in-sec mt-4">
                          <FormGroup
                            className={`mb-4 ${touched.email && errors.email ? "is-invalid" : ""
                              }`}
                          >
                            <Form.Label>Email*</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/auth/mail.svg"
                                  }
                                />
                              </InputGroup.Text>

                              <Field
                                className={`form-control ${touched.mobile && errors.mobile
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                type="email"
                                // placeholder="Enter Your Email Address"
                                name="email"
                              />
                              {/* <Form.Control placeholder="Placeholder" /> */}
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <FormGroup
                            className={`mb-4 ${touched.password && errors.password
                              ? "is-invalid"
                              : ""
                              }`}
                          >
                            <div className="Forgot-password-link">
                              <Form.Label>Password*</Form.Label>
                              <Link to="/forgot-password">
                                Forgot password?
                              </Link>
                            </div>
                            <InputGroup>
                              <InputGroup.Text>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/auth/lock.svg"
                                  }
                                />
                              </InputGroup.Text>
                              <Field
                                className={`form-control ${touched.mobile && errors.mobile
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Your Password"
                                name="password"
                              />
                              {/* <Form.Control placeholder="Placeholder" /> */}
                              <InputGroup.Text
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Image
                                  src={
                                    showPassword
                                      ? window.location.origin +
                                      "/images/auth/eye_dash.svg"
                                      : window.location.origin +
                                      "/images/auth/eye.svg"
                                  }
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger small"
                            />
                          </FormGroup>
                          {/* <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check type="checkbox" label="Remember Me" />
                          </Form.Group> */}
                          <div className="login-btn">
                            <Button
                              className="default-btn"
                              type="submit"
                              disabled={props.loginInputData.buttonDisable}
                            >
                              {props.loginInputData.loadingButtonContent
                                ? props.loginInputData.loadingButtonContent
                                : "Login"}
                            </Button>
                          </div>
                          <div className="sign-up-link">
                            <h4>
                              Don’t have an account yet?
                              <Link to="/register">Sign up</Link>{" "}
                            </h4>
                          </div>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                  <div className="login-social-icon mt-3">

                    {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                      configuration.get("configData.FB_CLIENT_ID") ? (
                      <>
                        <span className="or-line">
                          <p>Alternatively, login via:</p>
                        </span>
                        <div className="login-icons">
                          {configuration.get("configData.FB_CLIENT_ID") ? (
                            <SocialButton
                              provider="facebook"
                              appId={configuration.get(
                                "configData.FB_CLIENT_ID"
                              )}
                              onLoginSuccess={handleFacebookLogin}
                              onLoginFailure={handleSocialLoginFailure}
                              className="social-button"
                              id="facebook-connect"
                            >
                              {/* <span> */}
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/social-facebook.svg"
                                  }
                                />
                              {/* </span> */}
                            </SocialButton>
                          ) : null}
                          {configuration.get(
                            "configData.GOOGLE_CLIENT_ID"
                          ) ? (
                            <SocialButton
                              provider="google"
                              key={"google"}
                              appId={configuration.get(
                                "configData.GOOGLE_CLIENT_ID"
                              )}
                              onLoginSuccess={handleGoogleLogin}
                              onLoginFailure={handleSocialLoginFailure}
                              className="social-button"
                              id="google-connect"
                            >
                              {/* <span> */}
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/google.svg"
                                  }
                                />
                              {/* </span> */}
                            </SocialButton>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {numberVerification ? (
        <PhoneNumberVerificationModal
          numberVerification={true}
          mobile={numberVerification}
          closeNumberVerificationModal={closeNumberVerificationModal}
        />
      ) : null}
      {twoStepAuth ? (
        <TwoStepAuthModal
          numberVerification={true}
          mobile={twoStepAuth}
          email={props.loginInputData.data.user.email}
          onboarding_step={props.loginInputData.data.user.onboarding_step}
          closeNumberVerificationModal={closeTwoStepModal}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  loginInputData: state.users.loginInputData,
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);
