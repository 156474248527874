import React, { useState, useEffect } from "react";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DateRange } from "react-date-range";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { Form } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import { Modal } from "react-bootstrap";
import CustomTooltip from "./CustomTooltip";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const styles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},

	calenderWrapper: {
		position: "absolute",
		top: "100%",
		left: "0",
		backgroundColor: theme.palette.background.paper,
		width: (props) => (props.width < 991 ? "90%" : "100%"),
		maxWidth: "700px",
		padding: "2rem",
		borderRadius: "5px",
		boxShadow: "rgb(0 0 0 / 10%) 0px 0px 10px",
		zIndex: 99,
		transform: "none",
	},

	timePicker: {
		width: "100%",
	},
}));

const CustomRangerCalender = (props) => {
	const {
		priceDate,
		handlePriceDateOpen,
		handlePriceRangeDropdown,
		popup,
		priceDateRange,
		handleRangeSelection,
		handleStartTimeChange,
		handleEndTimeChange,
		handlePriceDateClose,
		months,
		placeHolder,
		tooltip,
		tooltipContent,
	} = props;

	const [width, setWidth] = useState(window.innerWidth);

	const classes = styles({ classes: props.classes, width: width });

	var maxDate = new Date(
		new Date().getFullYear(),
		new Date().getMonth() + 3,
		new Date().getDate()
	);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleResize = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		if (priceDate.status && popup) {
			document.body.classList.add("noBodyOverflow");
		} else {
			document.body.classList.remove("noBodyOverflow");
		}
	}, [priceDate.status]);

	function timeDiffCalc(dateFuture, dateNow) {
		let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

		// calculate days
		const days = Math.floor(diffInMilliSeconds / 86400);
		diffInMilliSeconds -= days * 86400;

		// calculate hours
		const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
		diffInMilliSeconds -= hours * 3600;

		// calculate minutes
		const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
		diffInMilliSeconds -= minutes * 60;

		let difference = "";
		if (days > 0) {
			difference += days === 1 ? `${days} day ` : `${days} days `;
		}

		if (hours > 0) {
			difference += hours === 1 ? `${hours} hour ` : `${hours} hours `;
		}

		if (minutes > 0) {
			difference += minutes === 1 ? `${minutes} minutes` : `${minutes} minutes`;
		}


		if (minutes == 0 && days == 0 && hours == 0) {
			difference = "Select valid date"
		}

		return difference;
	}

	useEffect(() => {
		const res = timeDiffCalc(
			new Date(`${priceDate.previewStartDate} ${priceDate.startTime}:00`),
			new Date(`${priceDate.previewEndDate} ${priceDate.endTime}:00`)
		);
	}, [priceDate]);

	return (
		<>
			<Box className={classes.root}>
				<div className="rangeCalender mt-3">
					<div className="rangerCalender-heading">
						<h6>{placeHolder}</h6>
						{tooltip && (
							<CustomTooltip title={tooltipContent} placement="top">
								<p>
									<i className="fas fa-info-circle"></i>
								</p>
							</CustomTooltip>
						)}
					</div>
					<button
						type="button"
						className="toggler not-btn"
						onClick={handlePriceDateOpen}
					>
						<div className="calender-icon">
							<CalendarTodayIcon />
						</div>
						<div className="selected-range">
							{" "}
							{/* {priceDate.isUserRangeSelected
                ? `${priceDate.previewStartDate} - ${priceDate.previewEndDate} - ${priceDate.startTime} : ${priceDate.endTime}`
                : `${priceDate.DateRange[0].name} - ${priceDate.startTime} : ${priceDate.endTime}`} */}
							{timeDiffCalc(
								new Date(
									`${priceDate.previewStartDate} ${priceDate.startTime}:00`
								),
								new Date(`${priceDate.previewEndDate} ${priceDate.endTime}:00`)
							)}
						</div>
					</button>
				</div>
				{priceDate.status && (
					<>
						<Modal
							show={true}
							onHide={handlePriceDateClose}
							animation={false}
							aria-labelledby="contained-modal-title-vcenter"
							className="custom-modal cancel-listing-modal"
							centered
							size={"lg"}
						>
							<Modal.Header>
								<div className="modal-header-wrapper">
									<h3 className="mb-0 modal-heading-text">
										Select Date Range
									</h3>
									<div
										className="custom-close"
										onClick={() => handlePriceDateClose()}
									>
										<CloseIcon />
									</div>
								</div>
							</Modal.Header>
							<Modal.Body>
								<Box>
									<div
										className={`range-calender ${width < 1200 ? "mobile" : "desktop"
											}`}
									>
										<DateRange
											editableDateInputs={false}
											onChange={(item) => handleRangeSelection(item)}
											moveRangeOnFirstSelection={false}
											ranges={priceDateRange}
											months={width < 1200 ? 1 : months ? months : 1}
											direction="horizontal"
											minDate={new Date()}
											maxDate={maxDate}
											showMonthAndYearPickers={false}
											showDateDisplay={false}
										/>
										<div className="time-picker">
											<div
												className={`custom-timeinput ${priceDate.isStartTimeError ? "invalid-field" : ""
													}`}
											>
												<TextField
													id="time"
													label="Start time"
													type="time"
													value={priceDate.startTime == "" ? "--:--" : priceDate.startTime}
													className={classes.timePicker}
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														step: 300, // 5 min
													}}
													onChange={(event) =>
														handleStartTimeChange(event.target.value)
													}
												/>
											</div>
											<div
												className={`custom-timeinput ${priceDate.isEndTimeError ? "invalid-field" : ""
													}`}
											>
												<TextField
													id="time"
													label="End time"
													type="time"
													value={priceDate.endTime == "" ? "--:--" : priceDate.endTime}
													className={classes.timePicker}
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														step: 300, // 5 min
													}}
													onChange={(event) =>
														handleEndTimeChange(event.target.value)
													}
												/>
											</div>
										</div>
										{priceDate.isStartTimeError && (
											<p className="invalid-message-text mt-3">
												* Start date must be now or in the future
											</p>
										)}
										{priceDate.isEndTimeError && (
											<p className="invalid-message-text mt-3">
												* End time must be atleast 15 minutes from current
												time
											</p>
										)}
										<div className="mt-4 button-wrapper-center">
											<button
												type="button"
												className="default-btn border-radius-5"
												onClick={() => handlePriceDateClose()}
											>
												Save
											</button>
										</div>
									</div>
								</Box>
							</Modal.Body>
						</Modal>
					</>
				)}
			</Box>
		</>
	);
};

export default React.memo(CustomRangerCalender);
