import {
  FETCH_COLLECTIONS_LIST_START,
  FETCH_COLLECTIONS_LIST_SUCCESS,
  FETCH_COLLECTIONS_LIST_FAILURE,
  FETCH_MORE_COLLECTIONS_LIST_START,
  FETCH_SINGLE_COLLECTIONS_START,
  FETCH_SINGLE_COLLECTIONS_SUCCESS,
  FETCH_SINGLE_COLLECTIONS_FAILURE,
  NFT_COLLECTIONS_SAVE_START,
  NFT_COLLECTIONS_SAVE_SUCCESS,
  NFT_COLLECTIONS_SAVE_FAILURE,
  NFT_COLLECTIONS_DELETE_START,
  NFT_COLLECTIONS_DELETE_SUCCESS,
  NFT_COLLECTIONS_DELETE_FAILURE,
  FETCH_COLLECTIONS_LIST_FOR_OTHERS_START,
  FETCH_COLLECTIONS_LIST_FOR_OTHERS_SUCCESS,
  FETCH_COLLECTIONS_LIST_FOR_OTHERS_FAILURE,
  FETCH_MORE_COLLECTIONS_LIST_FOR_OTHERS_START,
  FETCH_NFT_ITEMS_LIST_START,
  FETCH_NFT_ITEMS_LIST_SUCCESS,
  FETCH_NFT_ITEMS_LIST_FAILURE,
  FETCH_SINGLE_NFT_ITEMS_START,
  FETCH_SINGLE_NFT_ITEMS_SUCCESS,
  FETCH_SINGLE_NFT_ITEMS_FAILURE,
  NFT_ITEMS_SAVE_START,
  NFT_ITEMS_SAVE_SUCCESS,
  NFT_ITEMS_SAVE_FAILURE,
  NFT_ITEMS_DELETE_START,
  NFT_ITEMS_DELETE_SUCCESS,
  NFT_ITEMS_DELETE_FAILURE,
  FETCH_NFT_PROPERTIES_LIST_START,
  FETCH_NFT_PROPERTIES_LIST_SUCCESS,
  FETCH_NFT_PROPERTIES_LIST_FAILURE,
  FETCH_NFT_FAVOURITES_LIST_START,
  FETCH_NFT_FAVOURITES_LIST_SUCCESS,
  FETCH_NFT_FAVOURITES_LIST_FAILURE,
  NFT_FAVOURITES_SAVE_START,
  NFT_FAVOURITES_SAVE_SUCCESS,
  NFT_FAVOURITES_SAVE_FAILURE,
  FETCH_NFT_CATEGORIES_LIST_START,
  FETCH_NFT_CATEGORIES_LIST_SUCCESS,
  FETCH_NFT_CATEGORIES_LIST_FAILURE,
  FETCH_REPORT_ITEMS_LIST_START,
  FETCH_REPORT_ITEMS_LIST_SUCCESS,
  FETCH_REPORT_ITEMS_LIST_FAILURE,
  SAVE_REPORT_ITEMS_START,
  SAVE_REPORT_ITEMS_SUCCESS,
  SAVE_REPORT_ITEMS_FAILURE,
  FETCH_NFT_ITEMS_PAYMENT_LIST_START,
  FETCH_NFT_ITEMS_PAYMENT_LIST_SUCCESS,
  FETCH_NFT_ITEMS_PAYMENT_LIST_FAILURE,
  FETCH_TRENDING_NFT_ITEMS_START,
  FETCH_TRENDING_NFT_ITEMS_SUCCESS,
  FETCH_TRENDING_NFT_ITEMS_FAILURE,
  SELL_NFT_ITEMS_START,
  SELL_NFT_ITEMS_SUCCESS,
  SELL_NFT_ITEMS_FAILURE,
  CANCEL_LIST_NFT_ITEMS_START,
  CANCEL_LIST_NFT_ITEMS_SUCCESS,
  CANCEL_LIST_NFT_ITEMS_FAILURE,
  BUY_NFT_ITEMS_START,
  BUY_NFT_ITEMS_SUCCESS,
  BUY_NFT_ITEMS_FAILURE,
  NFT_COLLECTIONS_NAME_VALIDATION_START,
  NFT_COLLECTIONS_NAME_VALIDATION_SUCCESS,
  NFT_COLLECTIONS_NAME_VALIDATION_FAILURE,
  NFT_USER_PROFILE_ITEMS_LIST_START,
  NFT_USER_PROFILE_ITEMS_LIST_SUCCESS,
  NFT_USER_PROFILE_ITEMS_LIST_FAILURE,
  NFT_SEARCH_START,
  NFT_SEARCH_SUCCESS,
  NFT_SEARCH_FAILURE,
  NFT_ITEM_FAVOURITE_USERS_LIST_START,
  NFT_ITEM_FAVOURITE_USERS_LIST_SUCCESS,
  NFT_ITEM_FAVOURITE_USERS_LIST_FAILURE,
  CLEAR_NFT_COLLECTIONS_SAVE,
  CLEAR_NFT_COLLECTION_ITEM_SAVE,
  LOAD_MORE_FETCH_NFT_ITEMS_LIST_START,
  LOAD_MORE_FETCH_NFT_ITEMS_LIST_SUCCESS,
  LOAD_MORE_FETCH_NFT_ITEMS_LIST_FAILURE,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE,
  LOAD_MORE_ITEM_ACTIVITY_START,
  LOAD_MORE_ITEM_ACTIVITY_SUCCESS,
  LOAD_MORE_ITEM_ACTIVITY_FAILURE,
  CLEAR_NFT_SINGLE_ITEM_SELL,
  CLEAR_CANCEL_LISTING_DATA,
  CLEAR_NFT_BUY_DATA,
  CLEAR_NFT_COLLECTION_LIST,
  CLEAR_COLLECTION_DELETE_DATA,
  IMPORT_CONTRATCT_ADDRESS_START,
  IMPORT_CONTRATCT_ADDRESS_SUCCESS,
  IMPORT_CONTRATCT_ADDRESS_FAILURE,
  CLEAR_IMPORT_CONTRACT_ADDRESS_DATA,
  CLEAR_SEARCH_DATA,
  TRANSFER_NFT_START,
  TRANSFER_NFT_SUCCESS,
  TRANSFER_NFT_FAILURE,
  CLEAR_TRANSFER_NFT_DATA,
  CLEAR_SINGLE_NFT_IMAGE_DATA,
  BID_OFFER_NFT_ITEMS_SAVE_START,
  BID_OFFER_NFT_ITEMS_SAVE_SUCCESS,
  BID_OFFER_NFT_ITEMS_SAVE_FAILURE,
  BID_OFFER_NFT_ITEMS_LIST_START,
  BID_OFFER_NFT_ITEMS_LIST_SUCCESS,
  BID_OFFER_NFT_ITEMS_LIST_FAILURE,
  LOAD_MORE_BID_OFFER_NFT_ITEMS_LIST_START,
  ACCEPT_OFFER_NFT_ITEMS_START,
  ACCEPT_OFFER_NFT_ITEMS_SUCCESS,
  ACCEPT_OFFER_NFT_ITEMS_FAILURE,
  CANCEL_BID_OFFER_NFT_ITEM_START,
  CANCEL_BID_OFFER_NFT_ITEM_SUCCESS,
  CANCEL_BID_OFFER_NFT_ITEM_FAILURE,
  LOAD_MORE_OFFER_LIST_START,
  LOAD_MORE_OFFER_LIST_SUCCESS,
  LOAD_MORE_OFFER_LIST_FAILURE,
  MORE_FROM_COLLECTION_START,
  MORE_FROM_COLLECTION_SUCCESS,
  MORE_FROM_COLLECTION_FAILURE,
  FETCH_MORE_TRENDING_NFT_ITEMS_START,
  MORE_NFT_ITEM_FAVOURITE_USERS_LIST_START,
} from "../actions/ActionConstant";

const initialState = {
  collectionList: {
    data: {
      collections: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleCollection: {
    data: {},
    loading: true,
    error: false,
  },
  collectionSave: {
    data: {},
    loading: false,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  collectionDelete: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  collectionListForOthers: {
    data: {
      collections: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  itemsList: {
    data: [],
    loading: true,
    error: false,
    total: 0,
  },
  singleItems: {
    inputData: {},
    data: {},
    loading: true,
    error: false,
  },
  itemSave: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  itemDelete: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  propertiesList: {
    data: {},
    loading: false,
    error: false,
  },
  favouritesList: {
    data: {},
    loading: false,
    error: false,
  },
  favouriteSave: {
    data: {},
    loading: true,
    error: false,
  },
  categoriesList: {
    data: {},
    loading: true,
    error: false,
  },
  reportItemsList: {
    data: {},
    loading: false,
    error: false,
  },
  reportItemsSave: {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  nftItemsPaymentList: {
    data: {},
    loading: true,
    error: false,
  },
  trendingItems: {
    data: {
      nftItems: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  sellNftItem: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  cancelListNftItem: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  buyNftItem: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  collectionNameValidation: {
    data: {},
    loading: false,
    error: false,
  },
  userProfileItems: {
    data: {},
    loading: true,
    error: false,
  },
  nftSearch: {
    data: {},
    loading: true,
    error: false,
  },
  itemFavouriteUsers: {
    data: {
      users: [],
      total: 0,
    },
    loading: false,
    error: false,
  },
  importContract: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  transferNft: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  bidOfferNftItemSave: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  bidOfferNftItemList: {
    data: {
      nftItems: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  acceptOfferNftItem: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  cancelBidOfferNftItem: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  moreFromCollection: {
    data: [],
    loading: true,
    error: false,
    total: 0,
  },
};

const nftReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COLLECTIONS_LIST_START:
      return {
        ...state,
        collectionList: {
          data: {
            collections: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_MORE_COLLECTIONS_LIST_START:
      return state;
    case FETCH_COLLECTIONS_LIST_SUCCESS:
      return {
        ...state,
        collectionList: {
          data: {
            collections: [...state.collectionList.data.collections, ...action.data.nft_collections],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_COLLECTIONS_LIST_FAILURE:
      return {
        ...state,
        collectionList: {
          data: state.collectionList.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_SINGLE_COLLECTIONS_START:
      return {
        ...state,
        singleCollection: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SINGLE_COLLECTIONS_SUCCESS:
      return {
        ...state,
        singleCollection: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SINGLE_COLLECTIONS_FAILURE:
      return {
        ...state,
        singleCollection: {
          data: {},
          loading: false,
          error: action,
        },
      };
    case NFT_COLLECTIONS_SAVE_START:
      return {
        ...state,
        collectionSave: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Your project is being created...",
          buttonDisable: true,
        },
      };
    case NFT_COLLECTIONS_SAVE_SUCCESS:
      return {
        ...state,
        collectionSave: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case NFT_COLLECTIONS_SAVE_FAILURE:
      return {
        ...state,
        collectionSave: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case NFT_COLLECTIONS_DELETE_START:
      return {
        ...state,
        collectionDelete: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case NFT_COLLECTIONS_DELETE_SUCCESS:
      return {
        ...state,
        collectionDelete: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case NFT_COLLECTIONS_DELETE_FAILURE:
      return {
        ...state,
        collectionDelete: {
          data: {},
          loading: false,
          error: true,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case FETCH_COLLECTIONS_LIST_FOR_OTHERS_START:
      return {
        ...state,
        collectionListForOthers: {
          data: {
            collections: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        },
      };
    case FETCH_MORE_COLLECTIONS_LIST_FOR_OTHERS_START:
      return state;
    case FETCH_COLLECTIONS_LIST_FOR_OTHERS_SUCCESS:
      return {
        ...state,
        collectionListForOthers: {
          data: {
            collections: [...state.collectionListForOthers.data.collections, ...action.data.nft_collections],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_COLLECTIONS_LIST_FOR_OTHERS_FAILURE:
      return {
        ...state,
        collectionListForOthers: {
          data: state.collectionListForOthers.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_NFT_ITEMS_LIST_START:
      return {
        ...state,
        itemsList: {
          data: [],
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        itemsList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_ITEMS_LIST_FAILURE:
      return {
        ...state,
        itemsList: {
          data: [],
          loading: false,
          error: true,
        },
      };
    case FETCH_SINGLE_NFT_ITEMS_START:
      return {
        ...state,
        singleItems: {
          data: {},
          inputData: action.data,
          loading: true,
          error: false,
        },
        acceptOfferNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
        cancelBidOfferNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
        moreFromCollection: {
          data: [],
          loading: true,
          error: false,
          total: 0,
        },
      };
    case FETCH_SINGLE_NFT_ITEMS_SUCCESS:
      return {
        ...state,
        singleItems: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SINGLE_NFT_ITEMS_FAILURE:
      return {
        ...state,
        singleItems: {
          ...state.singleItems,
          data: {},
          loading: false,
          error: action,
        },
      };
    case NFT_ITEMS_SAVE_START:
      return {
        ...state,
        itemSave: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Your NFT is being minted...",
          buttonDisable: true,
        },
      };
    case NFT_ITEMS_SAVE_SUCCESS:
      return {
        ...state,
        itemSave: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case NFT_ITEMS_SAVE_FAILURE:
      return {
        ...state,
        itemSave: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case NFT_ITEMS_DELETE_START:
      return {
        ...state,
        itemDelete: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case NFT_ITEMS_DELETE_SUCCESS:
      return {
        ...state,
        itemDelete: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case NFT_ITEMS_DELETE_FAILURE:
      return {
        ...state,
        itemDelete: {
          data: {},
          loading: false,
          error: true,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case FETCH_NFT_PROPERTIES_LIST_START:
      return {
        ...state,
        propertiesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_PROPERTIES_LIST_SUCCESS:
      return {
        ...state,
        propertiesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_PROPERTIES_LIST_FAILURE:
      return {
        ...state,
        propertiesList: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_FAVOURITES_LIST_START:
      return {
        ...state,
        favouritesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_FAVOURITES_LIST_SUCCESS:
      return {
        ...state,
        favouritesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_FAVOURITES_LIST_FAILURE:
      return {
        ...state,
        favouritesList: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case NFT_FAVOURITES_SAVE_START:
      return {
        ...state,
        favouriteSave: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case NFT_FAVOURITES_SAVE_SUCCESS:
      return {
        ...state,
        favouriteSave: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case NFT_FAVOURITES_SAVE_FAILURE:
      return {
        ...state,
        favouriteSave: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_CATEGORIES_LIST_START:
      return {
        ...state,
        categoriesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categoriesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        categoriesList: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case FETCH_REPORT_ITEMS_LIST_START:
      return {
        ...state,
        reportItemsList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_REPORT_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        reportItemsList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_REPORT_ITEMS_LIST_FAILURE:
      return {
        ...state,
        reportItemsList: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case SAVE_REPORT_ITEMS_START:
      return {
        ...state,
        reportItemsSave: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading..",
        },
      };
    case SAVE_REPORT_ITEMS_SUCCESS:
      return {
        ...state,
        reportItemsSave: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SAVE_REPORT_ITEMS_FAILURE:
      return {
        ...state,
        reportItemsSave: {
          data: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_NFT_ITEMS_PAYMENT_LIST_START:
      return {
        ...state,
        nftItemsPaymentList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_ITEMS_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        nftItemsPaymentList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_ITEMS_PAYMENT_LIST_FAILURE:
      return {
        ...state,
        nftItemsPaymentList: {
          data: {},
          loading: false,
          error: true,
        },
      };
    case FETCH_TRENDING_NFT_ITEMS_START:
      return {
        ...state,
        trendingItems: {
          data: {
            nftItems: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_TRENDING_NFT_ITEMS_SUCCESS:
      return {
        ...state,
        trendingItems: {
          data: {
            nftItems: [...state.trendingItems.data.nftItems, ...action.data.nft_collection_items],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_MORE_TRENDING_NFT_ITEMS_START:
      return state;
    case FETCH_TRENDING_NFT_ITEMS_FAILURE:
      return {
        ...state,
        trendingItems: {
          data: state.trendingItems.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SELL_NFT_ITEMS_START:
      return {
        ...state,
        sellNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Listing is under progress...",
          buttonDisable: true,
        },
      };
    case SELL_NFT_ITEMS_SUCCESS:
      return {
        ...state,
        sellNftItem: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case SELL_NFT_ITEMS_FAILURE:
      return {
        ...state,
        sellNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case CANCEL_LIST_NFT_ITEMS_START:
      return {
        ...state,
        cancelListNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case CANCEL_LIST_NFT_ITEMS_SUCCESS:
      return {
        ...state,
        cancelListNftItem: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case CANCEL_LIST_NFT_ITEMS_FAILURE:
      return {
        ...state,
        cancelListNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case BUY_NFT_ITEMS_START:
      return {
        ...state,
        buyNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case BUY_NFT_ITEMS_SUCCESS:
      return {
        ...state,
        buyNftItem: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case BUY_NFT_ITEMS_FAILURE:
      return {
        ...state,
        buyNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case NFT_COLLECTIONS_NAME_VALIDATION_START:
      return {
        ...state,
        collectionNameValidation: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case NFT_COLLECTIONS_NAME_VALIDATION_SUCCESS:
      return {
        ...state,
        collectionNameValidation: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case NFT_COLLECTIONS_NAME_VALIDATION_FAILURE:
      return {
        ...state,
        collectionNameValidation: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case NFT_USER_PROFILE_ITEMS_LIST_START:
      return {
        ...state,
        userProfileItems: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case NFT_USER_PROFILE_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        userProfileItems: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case NFT_USER_PROFILE_ITEMS_LIST_FAILURE:
      return {
        ...state,
        userProfileItems: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case NFT_SEARCH_START:
      return {
        ...state,
        nftSearch: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case NFT_SEARCH_SUCCESS:
      return {
        ...state,
        nftSearch: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case NFT_SEARCH_FAILURE:
      return {
        ...state,
        nftSearch: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case NFT_ITEM_FAVOURITE_USERS_LIST_START:
      return {
        ...state,
        itemFavouriteUsers: {
          data: {
            users: [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case MORE_NFT_ITEM_FAVOURITE_USERS_LIST_START:
      return state;
    case NFT_ITEM_FAVOURITE_USERS_LIST_SUCCESS:
      return {
        ...state,
        itemFavouriteUsers: {
          data: {
            users: [...state.itemFavouriteUsers.data.users, ...action.data.users],
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case NFT_ITEM_FAVOURITE_USERS_LIST_FAILURE:
      return {
        ...state,
        itemFavouriteUsers: {
          data: state.itemFavouriteUsers.data,
          loading: false,
          error: action.error,
        },
      };
    case CLEAR_NFT_COLLECTIONS_SAVE: {
      return {
        ...state,
        collectionSave: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
        itemsList: {
          data: [],
          loading: true,
          error: false,
          total: 0,
        },
      };
    }
    case CLEAR_NFT_COLLECTION_ITEM_SAVE: {
      return {
        ...state,
        itemSave: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    }

    case LOAD_MORE_FETCH_NFT_ITEMS_LIST_START:
      return {
        ...state,
        itemsList: {
          ...state.itemsList,
          inputData: action.data,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_FETCH_NFT_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        itemsList: {
          ...state.itemsList,
          data: {
            ...state.itemsList.data,
            nft_collection_items: [
              ...state.itemsList.data.nft_collection_items,
              ...action.data.nft_collection_items,
            ],
          },
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_FETCH_NFT_ITEMS_LIST_FAILURE:
      return {
        ...state,
        itemsList: {
          ...state.itemsList,
          loading: false,
          error: action.error,
        },
      };

    case LOAD_MORE_USER_PROFILE_ITEMS_LIST_START:
      return {
        ...state,
        userProfileItems: {
          ...state.userProfileItems,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        userProfileItems: {
          ...state.userProfileItems,
          data: {
            ...state.userProfileItems.data,
            nft_collection_items: [
              ...state.userProfileItems.data.nft_collection_items,
              ...action.data.nft_collection_items,
            ],
            filter_total: action.data.filter_total,
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE:
      return {
        ...state,
        userProfileItems: {
          ...state.userProfileItems,
          loading: false,
          error: action.error,
        },
      };

    case LOAD_MORE_ITEM_ACTIVITY_START:
      return {
        ...state,
        nftItemsPaymentList: {
          ...state.nftItemsPaymentList,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_ITEM_ACTIVITY_SUCCESS:
      return {
        ...state,
        nftItemsPaymentList: {
          ...state.nftItemsPaymentList,
          data: {
            ...state.nftItemsPaymentList.data,
            nft_item_payments: [
              ...state.nftItemsPaymentList.data.nft_item_payments,
              ...action.data.nft_item_payments,
            ],
          },
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_ITEM_ACTIVITY_FAILURE:
      return {
        ...state,
        nftItemsPaymentList: {
          ...state.nftItemsPaymentList,
          loading: false,
          error: action.error,
        },
      };

    case CLEAR_NFT_SINGLE_ITEM_SELL:
      return {
        ...state,
        sellNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };

    case CLEAR_CANCEL_LISTING_DATA:
      return {
        ...state,
        cancelListNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };

    case CLEAR_NFT_BUY_DATA:
      return {
        ...state,
        buyNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
        bidOfferNftItemSave: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
        cancelBidOfferNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };

    case CLEAR_COLLECTION_DELETE_DATA:
      return {
        ...state,
        collectionDelete: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case CLEAR_NFT_COLLECTION_LIST:
      return {
        ...state,
        collectionList: {
          data: {
            collections: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case IMPORT_CONTRATCT_ADDRESS_START:
      return {
        ...state,
        importContract: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case IMPORT_CONTRATCT_ADDRESS_SUCCESS:
      return {
        ...state,
        importContract: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case IMPORT_CONTRATCT_ADDRESS_FAILURE:
      return {
        ...state,
        importContract: {
          data: {},
          loading: false,
          error: true,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };

    case CLEAR_IMPORT_CONTRACT_ADDRESS_DATA:
      return {
        ...state,
        importContract: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };

    case CLEAR_SEARCH_DATA:
      return {
        ...state,
        nftSearch: {
          data: {},
          loading: true,
          error: false,
        },
      };

    case TRANSFER_NFT_START:
      return {
        ...state,
        transferNft: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case TRANSFER_NFT_SUCCESS:
      return {
        ...state,
        transferNft: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case TRANSFER_NFT_FAILURE:
      return {
        ...state,
        transferNft: {
          data: {},
          loading: false,
          error: action.data,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };

    case CLEAR_TRANSFER_NFT_DATA:
      return {
        ...state,
        transferNft: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case BID_OFFER_NFT_ITEMS_SAVE_START:
      return {
        ...state,
        bidOfferNftItemSave: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case BID_OFFER_NFT_ITEMS_SAVE_SUCCESS:
      return {
        ...state,
        bidOfferNftItemSave: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case BID_OFFER_NFT_ITEMS_SAVE_FAILURE:
      return {
        ...state,
        bidOfferNftItemSave: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };



    case FETCH_TRENDING_NFT_ITEMS_START:
      return {
        ...state,
        trendingItems: {
          data: {
            nftItems: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };


    case BID_OFFER_NFT_ITEMS_LIST_START:
      return {
        ...state,
        bidOfferNftItemList: {
          data: {
            nftItems: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case BID_OFFER_NFT_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        bidOfferNftItemList: {
          data: {
            nftItems: [...state.bidOfferNftItemList.data.nftItems, ...action.data.nft_item_offers],
            total: action.data.total,
          },
          buttonDisable: false,
          loadingButtonContent: null,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_BID_OFFER_NFT_ITEMS_LIST_START:
      return state;
    case BID_OFFER_NFT_ITEMS_LIST_FAILURE:
      return {
        ...state,
        bidOfferNftItemList: {
          data: state.bidOfferNftItemList.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case CLEAR_SINGLE_NFT_IMAGE_DATA:
      return {
        ...state,
        singleItems: {
          inputData: {},
          data: {},
          loading: true,
          error: false,
        },
        itemSave: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case ACCEPT_OFFER_NFT_ITEMS_START:
      return {
        ...state,
        acceptOfferNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case ACCEPT_OFFER_NFT_ITEMS_SUCCESS:
      return {
        ...state,
        acceptOfferNftItem: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case ACCEPT_OFFER_NFT_ITEMS_FAILURE:
      return {
        ...state,
        acceptOfferNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case CANCEL_BID_OFFER_NFT_ITEM_START:
      return {
        ...state,
        cancelBidOfferNftItem: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true,
        },
      };
    case CANCEL_BID_OFFER_NFT_ITEM_SUCCESS:
      return {
        ...state,
        cancelBidOfferNftItem: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case CANCEL_BID_OFFER_NFT_ITEM_FAILURE:
      return {
        ...state,
        cancelBidOfferNftItem: {
          data: {},
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false,
        },
      };
    case LOAD_MORE_OFFER_LIST_START:
      return {
        ...state,
        bidOfferNftItemList: {
          ...state.bidOfferNftItemList,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_OFFER_LIST_SUCCESS:
      return {
        ...state,
        bidOfferNftItemList: {
          ...state.bidOfferNftItemList,
          data: {
            ...state.bidOfferNftItemList.data,
            nft_item_offers: [
              ...state.bidOfferNftItemList.data.nft_item_offers,
              ...action.data.nft_item_offers,
            ],
          },
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_OFFER_LIST_FAILURE:
      return {
        ...state,
        bidOfferNftItemList: {
          ...state.bidOfferNftItemList,
          loading: false,
          error: action.error,
        },
      };

    case MORE_FROM_COLLECTION_START:
      return {
        ...state,
        moreFromCollection: {
          data: [],
          loading: true,
          error: false,
        },
      };
    case MORE_FROM_COLLECTION_SUCCESS:
      return {
        ...state,
        moreFromCollection: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case MORE_FROM_COLLECTION_FAILURE:
      return {
        ...state,
        moreFromCollection: {
          data: [],
          loading: false,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default nftReducer;
