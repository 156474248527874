import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginSlider from "./LoginSlider";
import { useParams, useHistory } from "react-router";
import { useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPasswordStart } from "../store/actions/UserAction";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const ResetPassword = (props) => {

  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState({
    new_password: false,
    confirm_password: false,
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Required")
      .test(
        "len",
        "password length should be minimum 6",
        (val) => val && val.length >= 6
      ),
    password_confirmation: Yup.string()
      .required("Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  const reset = (values) => {
    props.dispatch(
      resetPasswordStart({
        ...values,
        reset_token: localStorage.getItem("reset_token"),
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.resetPassword.loading &&
      props.resetPassword.data.success
    ) {
      localStorage.removeItem("reset_token");
      navigate("/login");
    }
    setSkipRender(false);
  }, [props.resetPassword]);

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row>
              <Col md={6} className="border-right">
                <LoginSlider />
              </Col>
              <Col md={6} className="border-left">
                <div className="login-auth-sec">
                  <Formik
                    initialValues={{
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => reset(values)}
                  >
                    {({ errors, touched }) => (
                      <FORM>
                        <div className="sign-in-sec mt-4">
                          <div className="forgot-password-content mb-3">
                            <h4>Reset Password</h4>
                            {/* <p>
                          Enter your email address and we’ll send you
                          instructions to reset your password.
                        </p> */}
                          </div>
                          <Form.Label>Enter New Password*</Form.Label>
                          <div className="mb-3">
                            <InputGroup>
                              <InputGroup.Text>
                                <Image
                                  src={
                                    window.location.origin + "/images/auth/lock.svg"
                                  }
                                />
                              </InputGroup.Text>
                              {/* <Form.Control placeholder="Placeholder" /> */}
                              <Field
                                className="form-control"
                                type={showPassword.new_password ? "text" : "password"}
                                placeholder="Enter Your New Password"
                                name="password"
                              />
                              <InputGroup.Text
                                onClick={() => setShowPassword({
                                  ...showPassword,
                                  new_password: !showPassword.new_password
                                })}
                              >
                                <Image
                                  src={
                                    showPassword.new_password ? window.location.origin + "/images/auth/eye_dash.svg"
                                      : window.location.origin + "/images/auth/eye.svg"
                                  }
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger small"
                            />
                          </div>
                          <Form.Label>Confirm Password*</Form.Label>
                          <div className="mb-3">
                            <InputGroup>
                              <InputGroup.Text>
                                <Image
                                  src={
                                    window.location.origin + "/images/auth/lock.svg"
                                  }
                                />
                              </InputGroup.Text>
                              {/* <Form.Control placeholder="Placeholder" /> */}
                              <Field
                                className="form-control"
                                type={showPassword.confirm_password ? "text" : "password"}
                                placeholder="Confirm Password"
                                name="password_confirmation"
                              />
                              <InputGroup.Text
                                onClick={() => setShowPassword({
                                  ...showPassword,
                                  confirm_password: !showPassword.confirm_password
                                })}
                              >
                                <Image
                                  src={
                                    showPassword.confirm_password ? window.location.origin + "/images/auth/eye_dash.svg"
                                      : window.location.origin + "/images/auth/eye.svg"
                                  }
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password_confirmation"
                              className="text-danger small"
                            />
                          </div>

                          <div className="login-btn">
                            <Button className="default-btn"
                              disabled={props.resetPassword.buttonDisable}
                              type="submit"
                            >
                              {props.resetPassword.loadingButtonContent
                                ? props.resetPassword.loadingButtonContent
                                : "Submit"}
                            </Button>
                          </div>
                          <div className="forgot-password-bottom-link">
                            <Link to="/login">Sign In</Link>
                            <Link to="/register">Sign Up</Link>
                          </div>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  resetPassword: state.users.resetPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ResetPassword);
