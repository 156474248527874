import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Image, Container, Form, InputGroup, Button, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginSlider from "./LoginSlider";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgotPasswordStart } from "../store/actions/UserAction";
import { connect } from "react-redux";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ForgetPasswordVerificationModal from "./ForgetPasswordVerificationModal";

const ForgotPasswordIndex = (props) => {

  const [step, setStep] = useState(1);

  const [skipRender, setSkipRender] = useState(true);

  const [email, setEmail] = useState("");

  const [passwordVerification, setPasswordVerification] = useState(null);

  const [forgotPasswordData, setForgotPasswordData] = useState({
    attribute: "email",
    value: ""
  });

  const formRef = useRef(null);

  const closePasswordVerificationModal = () => {
    setPasswordVerification(null);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.forgotPassword.loading &&
      Object.keys(props.forgotPassword.data).length > 0
    ) {
      setPasswordVerification(true);
    }
    setSkipRender(false);
  }, [props.forgotPassword]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const mobileValidationSchema = Yup.object().shape({
    mobile: Yup.string().required("Required"),
  });

  const handleForgotPassword = (values) => {
    setEmail(values.email);
    setForgotPasswordData({
      attribute: "email",
      value: values.email
    });
    props.dispatch(forgotPasswordStart(values));
  };

  const handleForgotPasswordByMobile = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      setForgotPasswordData({
        attribute: "mobile",
        value: mobile
      });
      props.dispatch(forgotPasswordStart({
        mobile: mobile,
      }));
    }
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Incorrect format");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row>
              <Col md={6} className="border-right">
                <LoginSlider />
              </Col>
              <Col md={6} className="border-left">
                <div className="login-auth-sec">
                  <div className="login-nav-link">
                    <ul>
                      <li
                        className={
                          step === 1 ? "active" : ""
                        }
                        onClick={() => setStep(1)}
                      >
                        <Link to="#">Email</Link>
                      </li>
                      <li
                        className={
                          step === 2 ? "active" : ""
                        }
                        onClick={() => setStep(2)}
                      >
                        <Link to="#">Mobile</Link>
                      </li>
                    </ul>
                  </div>
                  {step === 1 && (
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleForgotPassword(values)}
                    >
                      {({ errors, touched }) => (
                        <FORM>
                          <div className="sign-in-sec mt-4">
                            <div className="forgot-password-content">
                              {/* <h4>Forgot Password</h4> */}
                              <p>
                                Enter your email address and we’ll send you instructions on how to reset your password.
                              </p>
                            </div>
                            <Form.Label>Email*</Form.Label>
                            <div className="mb-3">
                              <InputGroup>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin + "/images/auth/mail.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  type="email"
                                  placeholder="Email address"
                                  name="email"
                                />
                                {/* <Form.Control placeholder="Placeholder" /> */}
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="email"
                                className="text-danger small"
                              />
                            </div>
                            <div className="login-btn">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={props.forgotPassword.buttonDisable}
                              >
                                {props.forgotPassword.loadingButtonContent
                                  ? props.forgotPassword.loadingButtonContent
                                  : "Submit"}
                              </Button>
                            </div>
                            <div className="forgot-password-bottom-link">
                              <Link to="/login">Sign in</Link>
                              <Link to="/register">Sign up</Link>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  )}
                  {step === 2 && (
                    <Formik
                      initialValues={{
                        mobile: "",
                      }}
                      validationSchema={mobileValidationSchema}
                      onSubmit={(values) => handleForgotPasswordByMobile(values)}
                      innerRef={formRef}
                    >
                      {({ errors, values, touched, setFieldValue }) => (
                        <FORM>
                          <div className="sign-in-sec mt-4">
                            <div className="forgot-password-content">
                              {/* <h4>Forgot Password</h4> */}
                              <p>
                                Enter your phone number and we’ll send you instructions on how to reset your password.
                              </p>
                            </div>
                            <Form.Label>Phone number*</Form.Label>
                            <div className="mb-3">
                              <FormGroup>
                                <PhoneInput
                                  defaultCountry="US"
                                  placeholder="Enter phone number"
                                  value={values.mobile}
                                  onChange={(value) =>
                                    setFieldValue("mobile", value)
                                  }
                                  onBlur={() => validateMobileNumber(values.mobile)}
                                  international
                                  countryCallingCodeEditable={false}
                                  className={`${touched.mobile && errors.mobile
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="mobile"
                                  className="text-danger small"
                                />
                              </FormGroup>
                            </div>
                            <div className="login-btn">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={props.forgotPassword.buttonDisable}
                              >
                                {props.forgotPassword.loadingButtonContent
                                  ? props.forgotPassword.loadingButtonContent
                                  : "Submit"}
                              </Button>
                            </div>
                            <div className="forgot-password-bottom-link">
                              <Link to="/login">Sign in</Link>
                              <Link to="/register">Sign up</Link>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  )}
                  {step == 3 && (
                    <div className="check-your-mail-card">
                      <div className="check-your-mail-icon-sec">
                        <Image
                          className="wave-hand-icon"
                          width={100}
                          src={window.location.origin + "/images/email-icon.png"}
                        />
                      </div>
                      <h5>Chek Your Mail</h5>
                      <p>
                        We emailed a reset password link to <span>{email}</span> click
                        the link to reset your password
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {passwordVerification ? (
        <ForgetPasswordVerificationModal
          passwordVerification={true}
          forgotPasswordData={forgotPasswordData}
          closePasswordVerificationModal={closePasswordVerificationModal}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPasswordIndex);