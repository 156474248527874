import React, { useState, useContext } from "react";
import { Image, Button, Table } from "react-bootstrap";
import "./ItemList.css";
import { Link } from "react-router-dom";
import useUserAuthStatus from "../customHooks/useUserAuthStatus";
import { authContext } from "../authprovider/AuthProvider";
import CancelOfferModal from "./CancelOfferModal";
import AcceptOfferModal from "./AcceptOfferModal";
import { connect } from "react-redux";

const CollectionOffersCard = (props) => {
  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  const userAuthStatus = useUserAuthStatus(props.profile);

  const [acceptOfferModal, setAcceptOfferModal] = useState(false);

  const [cancelOfferModal, setCancelOfferModal] = useState(false);

  const [approveOfferStatus, setApproveOfferStatus] = useState("confirm");

  const handleAcceptOfferClose = () => setAcceptOfferModal(false);

  const handleCancelOfferClose = () => setCancelOfferModal(false);

  const [acceptOfferInput, setAcceptOfferInput] = useState({
    nft_collection_item_unique_id: "",
    buyer_id: 0,
    amount: 0,
    total_earnings: 0,
  });

  const [cancelOfferInput, setCancelOfferInput] = useState({
    nft_item_offer_id: 0,
    wallet_address: "",
  });

  const handleAcceptOfferModalOpen = () => {
    setAcceptOfferModal(true);
  };

  const handleCancelOfferModalOpen = () => {
    setCancelOfferModal(true);
  };

  const acceptOffer = (event, offer) => {
    event.preventDefault();
    setAcceptOfferInput({
      nft_collection_item_unique_id: props.singleNftImage.nft_collection_item
        ? props.singleNftImage.nft_collection_item.nft_collection_item_unique_id
        : "",
      buyer_id: offer.user_id,
      amount: offer.amount,
      total_earnings: offer.total_earnings,
    });
    setApproveOfferStatus("confirm");
    setAcceptOfferModal(true);
  };

  const cancelOffer = (event, offer) => {
    event.preventDefault();
    setCancelOfferInput({
      nft_item_offer_id: offer.nft_item_offer_id,
      wallet_address: offer.wallet_address,
    });
    setCancelOfferModal(true);
  };

  return (
    <>
      <div className="collection-offers-card-sec">
        <div className="collection-offers-table-sec">
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>Price</th>
                <th>Price (USD)</th>
                <th>Expiration</th>
                <th>From</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {props.bidOfferNftItemLists.map((offer, index) => (
                <tr key={index}>
                  <td>
                    <div className="offers-price">
                      <Image
                        src={
                          window.location.origin +
                          "/images/landing-page/icons/text-side-icon.svg"
                        }
                        className="offers-price-icon"
                      />
                      <span>{offer.amount}</span>
                      <span>
                        {offer.bid_status == 2
                          ? "(Expired)"
                          : offer.bid_status == 3
                          ? "(Cancelled)"
                          : ""}
                      </span>
                      <span>
                        {
                          props.singleNftImage.nft_collection_item
                            .blockchain_type
                        }
                      </span>
                    </div>
                  </td>
                  <td>
                    {price.usdConvertedPrice != null &&
                      offer.amount != "" &&
                      Number(offer.amount) > 0 && (
                        <p className="converted_price right">
                          ≉ <i className="fa fa-usd" aria-hidden="true"></i>{" "}
                          {Number(
                            offer.amount * price.usdConvertedPrice
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{" "}
                          {price.denomination}
                        </p>
                      )}
                  </td>
                  <td>{offer.expired_at_formatter}</td>
                  <td>
                    <Link
                      className="offers-user-link"
                      to={
                        offer.user_id == userAuthStatus.userId
                          ? `/profile`
                          : `/account/${
                              offer.user ? offer.user.user_unique_id : ""
                            }`
                      }
                    >
                      {offer.user
                        ? offer.user_id == userAuthStatus.userId
                          ? "You"
                          : offer.user.wallet_address_formatted
                        : "N/A"}
                    </Link>
                  </td>
                  <td>
                    {props.singleNftImage.nft_collection_item.recent_buyer_id ==
                      userAuthStatus.userId &&
                    index == 0 &&
                    offer.bid_status == 1 ? (
                      <Button
                        className="default-btn two border-radius-5"
                        onClick={(event) => {
                          handleAcceptOfferModalOpen();
                          acceptOffer(event, offer);
                        }}
                      >
                        Accept
                      </Button>
                    ) : offer.is_cancel_access == 1 &&
                      index == 0 &&
                      offer.bid_status != 3 ? (
                      <Button
                        className="default-btn"
                        onClick={(event) => {
                          handleCancelOfferModalOpen();
                          cancelOffer(event, offer);
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <AcceptOfferModal
            open={acceptOfferModal}
            handleAcceptOfferClose={handleAcceptOfferClose}
            singleNftImage={props.singleNftImage}
            acceptOfferInput={acceptOfferInput}
            setApproveOfferStatus={setApproveOfferStatus}
            approveOfferStatus={approveOfferStatus}
            getCurrentOwner={props.getCurrentOwner}
            approveOfferSave={props.approveOfferSave}
            setAcceptOfferBtnContent={props.setAcceptOfferBtnContent}
            acceptOfferBtnContent={props.acceptOfferBtnContent}
            currentOwner={props.currentOwner}
          />
          <CancelOfferModal
            open={cancelOfferModal}
            handleCancelOfferClose={handleCancelOfferClose}
            singleNftImage={props.singleNftImage}
            cancelOfferInput={cancelOfferInput}
            getCurrentOwner={props.getCurrentOwner}
            cancelOfferSave={props.cancelOfferSave}
            cancelOfferBtnContent={props.cancelOfferBtnContent}
            setCancelOfferBtnContent={props.setCancelOfferBtnContent}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CollectionOffersCard);
