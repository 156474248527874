import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { updateUserDetailsStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import configuration from "react-global-configuration";

const AccountCompletion = (props) => {

  const [logoImage, setLogoImage] = useState({
    file: "",
    preview_image: props.profile.data.user.picture,
  });

  const onCopy = () => {
    getSuccessNotificationMessage("Link copied");
  }

  const handleAddImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setLogoImage({
        file: file,
        preview_image: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
      props.dispatch(updateUserDetailsStart({
        picture: file,
      }));
    }
  };

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.prevStep()}>
          <Image
            src={window.location.origin + "/images/onboarding-icons/back.svg"}
          />
        </Button>
      </div>
      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <Form>
            <div className="account-completion-sec">
              <h4>Congrats!</h4>
              <p>Now you can verify your account</p>
              <div className="profile-img">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    <Image
                      src={logoImage.preview_image ? logoImage.preview_image : window.location.origin + "/images/placeholder.jpeg"}
                      className="profile-image"
                    />
                    <div className="input-edit-img">
                      <Image
                        src={
                          window.location.origin + "/images/icons/add-icon.svg"
                        }
                        type="image/png"
                      />
                    </div>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    className="image-input"
                    onChange={(event) => handleAddImageChange(event)}
                  />
                </Form.Group>
                <p>You Can add your profile picture.</p>
                <div className="profile-name-link">
                  <span>
                    <Image
                      src={window.location.origin + "/images/icons/link.svg"}
                      type="image/png"
                    />
                  </span>
                  <div>
                    {configuration.get("configData.frontend_url")}<span>{props.profile.data.user.username}</span>
                  </div>
                  <CopyToClipboard text={`${configuration.get("configData.frontend_url")}${props.profile.data.user.username}`} onCopy={() => onCopy()}>
                    <Button>
                      <Image
                        src={window.location.origin + "/images/icons/copy.svg"}
                        type="image/png"
                      />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="login-btn mt-4">
              <div className="link-account-sec">
                <Button
                  onClick={() => props.setStep(7)}
                  className="default-btn"
                >
                  Link Your accounts
                </Button>
                <p>
                  <span>
                    <Image
                      src={window.location.origin + "/images/icons/star.svg"}
                    />
                  </span>
                  100 Point
                </p>
              </div>
              <div className="link-account-sec mt-3">
                <Button
                  onClick={() => props.setStep(8)}
                  className="default-btn invite-btn"
                >
                  Invite Friends
                </Button>
                <p>
                  <span>
                    <Image
                      src={window.location.origin + "/images/icons/star.svg"}
                    />
                  </span>
                  100 Point
                </p>
              </div>
              <div className="home-page-link mt-3 text-center">
                <Link to="/">Go To Homepage</Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AccountCompletion);
