import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const ActivityOfferLoader = () => {
  return (
    <>
      <div className="activity-offers-tab-sec">
        <Container>
          <div className="collection-activity-table-sec-loader">
            <Skeleton count={4} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default ActivityOfferLoader;
