import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Spinner } from "react-bootstrap";
import AccordionLoadingSvg from "./AccordionLoadingSvg";

const accordionStyle = makeStyles({
  accordion: {
    boxShadow: "0px 0px 3px rgb(0 0 0 / 25%)",
    marginBottom: "0 !important",

    "&.MuiAccordion-root.Mui-expanded" :{
      margin : "0",
    },

    "& .MuiAccordionSummary-root": {
      minHeight: "auto",

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    },
  },
});

const CustomAccordion = (props) => {
  const {
    activeId,
    accordionKey,
    accordionHeading,
    accordionContent,
    accordionButtonDisable,
    OnClick,
    accordionButton,
    accordionButtonContent,
    accrodionOnchange,
    accordionToggle,
    spinner,
    spinnerLoading,
    activeAccordion,
    loadingComplete
  } = props;

  const classes = accordionStyle();

  return (
    <>
      <Accordion
        expanded={activeId === accordionKey}
        onChange={accordionToggle ? () => accrodionOnchange() : null}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="custom-accordion-heading">
            {spinner && (
              <>
                <div
                  className={`custom-accordion-spinner ${
                    spinnerLoading ? "loading" : loadingComplete ? "loading-complete" : "not-loading"
                  }`}
                >
                  <AccordionLoadingSvg classes={`check-spinner ${loadingComplete ? "loading-complete" : ""}`} />
                  <h6 className="heading">{accordionKey}</h6>
                </div>
              </>
            )}

            <h6 className="mb-0">{accordionHeading}</h6>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="custom-accordion-contents">
            <p>{accordionContent}</p>
            {accordionButton && (
              <button
                className="default-btn border-radius-5"
                onClick={() => OnClick()}
                disabled={accordionButtonDisable}
              >
                {accordionButtonContent}
              </button>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default React.memo(CustomAccordion);
