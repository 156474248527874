import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../../Environment";
import {
  CREATE_IMPORTED_POST_TO_NFT_START,
  IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
  IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_START,
  IMPORT_SOCIAL_POSTS_START,
  MORE_IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START,
  POST_FILES_VIEW_BASE64_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../helper/ToastNotification";
import {
  importSocialPostsSuccess,
  importSocialPostsFailure,
  importedSocialPostsListForOwnerSuccess,
  importedSocialPostsListForOwnerFailure,
  importedSocialPostViewSuccess,
  importedSocialPostViewFailure,
  postFilesViewBase64Success,
  postFilesViewBase64Failure,
  createImportedPostToNftSuccess,
  createImportedPostToNftFailure,
} from "../actions/SocialPostAction";



function* importSocialPostsAPI(action) {
  try {
    const response = yield api.postMethod("import_social_posts", action.data);
    if (response.data.success) {
      yield put(importSocialPostsSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(importSocialPostsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(importSocialPostsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* importedSocialPostsListForOwnerAPI(action) {
  try {
    const response = yield api.postMethod("imported_social_posts_list_for_owner", action.data);
    if (response.data.success) {
      yield put(importedSocialPostsListForOwnerSuccess(response.data.data));
    } else {
      yield put(importedSocialPostsListForOwnerFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(importedSocialPostsListForOwnerFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* importedSocialPostViewForOwnerAPI(action) {
  try {
    const response = yield api.postMethod("imported_social_posts_view_for_owner", action.data);
    if (response.data.success) {
      yield put(importedSocialPostViewSuccess(response.data.data));
    } else {
      yield put(importedSocialPostViewFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(importedSocialPostViewFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* postFilesViewBase64API(action) {
  try {
    const response = yield api.postMethod("posts_files_view_base64", action.data);
    if (response.data.success) {
      yield put(postFilesViewBase64Success(response.data.data));
    } else {
      yield put(postFilesViewBase64Failure(response.data.error));
      yield call(getErrorNotificationMessage(response.data.error));
    }
  } catch (error) {
    yield put(postFilesViewBase64Failure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* createImportedPostToNftAPI(action) {
  try {
    const response = yield api.postMethod("create_imported_post_to_nft", action.data);
    if (response.data.success) {
      yield put(createImportedPostToNftSuccess(response.data.data));
    } else {
      yield put(createImportedPostToNftFailure(response.data.error));
      yield call(getErrorNotificationMessage(response.data.error));
    }
  } catch (error) {
    yield put(createImportedPostToNftFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}



export default function* SocialPostSaga() {
  yield all([
    yield takeLatest(IMPORT_SOCIAL_POSTS_START, importSocialPostsAPI),
    yield takeLatest(IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START, importedSocialPostsListForOwnerAPI),
    yield takeLatest(MORE_IMPORTED_SOCIAL_POSTS_LIST_FOR_OWNER_START, importedSocialPostsListForOwnerAPI),
    yield takeLatest(IMPORTED_SOCIAL_POST_VIEW_FOR_OWNER_START, importedSocialPostViewForOwnerAPI),
    yield takeLatest(POST_FILES_VIEW_BASE64_START, postFilesViewBase64API),
    yield takeLatest(CREATE_IMPORTED_POST_TO_NFT_START, createImportedPostToNftAPI),
  ]);
}
