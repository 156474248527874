import { all, fork } from "redux-saga/effects";

import UserSaga from './UserSaga';
import NftSaga from './NftSaga';
import WalletSaga from "./WalletSaga";
import WalletAppSaga from "./WalletAppSaga";
import PageSaga from "./PageSaga";
import SocialPostSaga from "./SocialPostSaga";

export default function* rootSaga() {
  yield all([
    fork(UserSaga),
    fork(NftSaga),
    fork(WalletSaga),
    fork(WalletAppSaga),
    fork(PageSaga),
    fork(SocialPostSaga),
  ]);

}
