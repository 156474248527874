import React, { useState, useEffect } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Button,
	Table,
	Form,
	InputGroup,
	Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { moreNftItemFavouriteUsersListStart } from "../store/actions/NftAction";
import Skeleton from "react-loading-skeleton";
import NoFavouritesFound from "../NoDataFound/NoFavouritesFound";

const FavoritedByModal = (props) => {

	const fetchMoreFavUsers = () => {
		props.dispatch(
			moreNftItemFavouriteUsersListStart({
				nft_collection_unique_id: props.nft_collection_unique_id,
				nft_collection_item_token_id: props.nft_collection_item_token_id,
				skip: props.itemFavouriteUsers.data.users.length,
				take: 12,
			})
		);
	}

	return (
		<>
			<Modal
				className="modal-dialog-center favorited-modal"
				size="md"
				centered
				show={props.favoritedBy}
				onHide={props.closeFavoritedByModal}
			>
				<Modal.Body>
					<Button className="modal-close" onClick={() => props.closeFavoritedByModal()}>
						<Image
							className="close-icon"
							src={
								window.location.origin + "/images/create-item/icons/modal-close.svg"
							}
						/>
					</Button>
					<div className="favourite-content">
						<h4>Favorited by</h4>
						{props.itemFavouriteUsers.loading ?
							<div className="favorite-box">
								<Skeleton count={6} className="favorite-card" height={50} />
							</div>
							: <div className="favorite-box">
								{props.itemFavouriteUsers.data.users ? (
									props.itemFavouriteUsers.data.users.length > 0 ? (
										<InfiniteScroll
											dataLength={props.itemFavouriteUsers.data.users.length}
											next={fetchMoreFavUsers}
											hasMore={props.itemFavouriteUsers.data.users.length < props.itemFavouriteUsers.data.total}
											loader={<Skeleton count={6} className="favorite-card" height={50} />}
											style={{ height: 'auto', overflow: 'hidden' }}
										>
											{props.itemFavouriteUsers.data.users.map((user) => (
												<Link to={user.user_id === (props.profile.data.user ? props.profile.data.user.user_id : 0) ? `/profile` :
													`/account/${user.user_unique_id}`}>
													<div className="favorite-card">
														<div className="favorite-img-sec">
															<Image
																className="favorite-img"
																src={user.picture}
															/>
														</div>
														<div className="favorite-info">
															<h5>{user.user_id === (props.profile.data.user ? props.profile.data.user.user_id : 0) ? "You" : user.name}</h5>
															<p>{user.wallet_address.substr(0, 5)}...
																{user.wallet_address.substr(user.wallet_address.length - 4)}</p>
														</div>
													</div>
												</Link>
											))}
										</InfiniteScroll>
									) : <NoFavouritesFound />
								) : <NoFavouritesFound />}
							</div>
						}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	itemFavouriteUsers: state.nfts.itemFavouriteUsers,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FavoritedByModal);