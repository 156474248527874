import React, { useEffect, useState } from "react";
import {
  Image,
  Button,
  Table
} from "react-bootstrap";
import "./ItemList.css";
import { Link } from "react-router-dom";
import CollectionActivityTable from "./CollectionActivityTable";
import { loadMoreItemActivityStart, nftItemsPaymentListStart } from "../store/actions/NftAction";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";


const CollectionActivityCard = (props) => {

  useEffect(() => {
    props.dispatch(
      nftItemsPaymentListStart({
        nft_collection_unique_id: props.nftCollectionUniqueId,
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMorePaymentList = () => {
    props.dispatch(loadMoreItemActivityStart({
      nft_collection_unique_id: props.nftCollectionUniqueId,
      skip: props.nftItemsPaymentList.data.nft_item_payments.length,
      take: 12,
    }));
  }

  return <>
    {props.nftItemsPaymentList.loading ?
      <Skeleton count={6} height={60} />
      : <InfiniteScroll
        dataLength={props.nftItemsPaymentList.data.nft_item_payments.length}
        next={fetchMorePaymentList}
        hasMore={props.nftItemsPaymentList.data.nft_item_payments.length < props.nftItemsPaymentList.data.total}
        loader={<Skeleton count={6} height={60} />}
        style={{ height: 'auto', overflow: 'hidden' }}
      >
        <CollectionActivityTable
          payments={props.nftItemsPaymentList.data.nft_item_payments}
        />
      </InfiniteScroll>
    }
  </>;
};


const mapStateToPros = (state) => ({
  nftItemsPaymentList: state.nfts.nftItemsPaymentList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CollectionActivityCard);
