import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Container } from "react-bootstrap";

const StaticPageLoader = (props) => {
  const { classes } = props;
  return (
    <>
      <div className="static-page-sec">
        <div id="circle"></div>
        <div id="circle1"></div>
        <Container>
          <div className="terms-conditions-area pt-100 pb-70">
            <div className="pt-45">
              <div className="sections mb-5">
                <Skeleton count={1} height={48} width={"25%"} />
                <Skeleton count={20} height={18} width={"100%"} />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default StaticPageLoader;
