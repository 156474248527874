import React, { useState } from "react";
import {
	Image,
	Button,
} from "react-bootstrap";
import "./ItemList.css";
import { Link } from "react-router-dom";
import CustomLazyLoad from "../customComponents/CustomLazyLoad";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import useUserAuthStatus from "../customHooks/useUserAuthStatus";
import { CHAIN_INFO, SupportedChainIdBySymbol } from "../authprovider/chains";

const CollectionItemCard = (props) => {

	const { data } = props;

	const userAuthStatus = useUserAuthStatus(props.profile);

	return (
		<>
			<div className="collection-item-card">
				<Link to={`/assets/${data.nft_collection.blockchain_type}/${data.nft_collection.nft_collection_unique_id}/${data.nft_collection.contract_address}/${data.token_id}`}>
					<div className="collection-item-img-sec">
						<CustomLazyLoad
							src={
								data.picture != ""
									? data.picture
									: configuration.get("configData.item_placholder")
							}
							classes={"image collection-item-img"}
							placeholderSrc={
								window.location.origin +
								"/assets/images/loader.svg"
							}
						/>
					</div>
					<div className="collection-item-info">
						<div className="collection-item-details">
							<h6>{data.name}</h6>
							<h5>{data.nft_collection.name}</h5>
						</div>
						<div className="collection-item-footer-details">
							<h4>
								{Number(data.amount) > 0 &&
									data.is_listed == 1 &&
									data.is_listing_started == 1 && (
										<span>
											{Number(data.amount).toLocaleString(undefined, {
												maximumFractionDigits: 5,
											})} {data.blockchain_type}
										</span>
									)}
								<span>
									<Image
										src={CHAIN_INFO[
											SupportedChainIdBySymbol[
											data.blockchain_type
											]].logo
										}
										className="eth-icon"
									/>
								</span>
								{/* <span className="trending-artwork-desc">
									90.234.89
								</span> */}
							</h4>
							<Button type="button" className="heart-btn">
								<Image
									src={
										window.location.origin + "/images/landing-page/icons/heart-outline.svg"
									}
									className="heart-icon"
								/>
							</Button>
						</div>
					</div>
				</Link>
			</div>
			{/* <div className="collection-item-card">
					<Link to="#">
						<div className="collection-item-img-sec">
							<Image
								src={
									window.location.origin + "/images/landing-page/img-artwork-2.png"
								}
								className="collection-item-img"
							/>
							<div className="collection-item-img-sub-title">
								<Image
									src={
										window.location.origin + "/images/create-item/icons/insta.svg"
									}
									className="collection-item-icon"
								/>
								<span>NFT</span>
							</div>
							<div className="collection-item-left-social-info">
								<div className="collection-item-left-social-card">
									<Image
										src={
											window.location.origin + "/images/create-item/icons/like.svg"
										}
										className="collection-item-left-social-icon"
									/>
									<p>627,19K</p>
								</div>
								<div className="collection-item-left-social-card">
									<Image
										src={
											window.location.origin + "/images/create-item/icons/message.svg"
										}
										className="collection-item-left-social-icon"
									/>
									<p>155K</p>
								</div>
								<div className="collection-item-left-social-card">
									<Image
										src={
											window.location.origin + "/images/create-item/icons/share.svg"
										}
										className="collection-item-left-social-icon"
									/>
									<p>140K</p>
								</div>
							</div>
						</div>
						<div className="collection-item-info">
							<div className="collection-item-details">
								<h6>Soldier of Love</h6>
								<h5>charletdewitte</h5>
							</div>
							<div className="collection-item-footer-details">
								<h4>
									<span>
										13 ETH
									</span>
									<span>
										<Image
											src={
												window.location.origin + "/images/landing-page/icons/text-side-icon.svg"
											}
											className="eth-icon"
										/>
									</span>
									<span className="trending-artwork-desc">
										90.234.89
									</span>
								</h4>
								<Button type="button" className="heart-btn">
									<Image
										src={
											window.location.origin + "/images/landing-page/icons/heart-outline.svg"
										}
										className="heart-icon"
									/>
								</Button>
							</div>
						</div>
					</Link>
				</div> */}
		</>
	);
};

const mapStateToPros = (state) => ({
	favouriteSave: state.nfts.favouriteSave,
	profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CollectionItemCard);
