import React from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const FooterIndex = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const join = (values) => {
    if (
      !localStorage.getItem("userId") ||
      !localStorage.getItem("accessToken")
    ) {
      localStorage.setItem("join_email", values.email);
      navigate("/register");
    }
  };

  return (
    <div>
      <footer>
        <div className="footer-sec">
          <Container>
            <Row>
              <Col md={4} className="resp-marg-btm-xs">
                <div className="footer-subscribe">
                  <h4>Don’t miss anything from us!</h4>
                  <div className="new-form-input">
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => join(values)}
                    >
                      {({ errors, touched }) => (
                        <FORM>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {/* <Form.Label>E-Mail Address</Form.Label> */}
                            <Field
                              className="form-control"
                              type="email"
                              placeholder="Enter your email address"
                              name="email"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="text-danger small"
                            />
                          </Form.Group>

                          <Button className="default-btn" type="submit">
                            SUBSCRIBE
                          </Button>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
              <Col md={3} xl={3} lg={3} xs={4} className="border-right resp-marg-btm-xs">
                <div className="footer-list">
                  <h4>Resources</h4>
                  <ul>
                    {/* {configuration
                      .get("configData.footer_pages1")
                      .map((static_page, index) => (
                        <li key={index}>
                          <Link
                            to={`/page/${static_page.static_page_unique_id}`}
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))} */}
                      <li>
                        <a
                          href="https://www.socialpayme.co/#about-us"
                          target="_blank"
                        >
                          About
                        </a>
                      </li>
                      <li>
                        <a
                          href="mailto:info@socialpayme.com?subject=NFT%20Social%20Marketplace%20enquiry"
                        >
                          Contact
                        </a>
                      </li><li>
                        <a
                          href="https://www.socialpayme.co/privacy-policy"
                          target="_blank"
                        >
                          Privacy
                        </a>
                      </li><li>
                        <a
                          href="https://www.socialpayme.co/terms-and-conditions"
                          target="_blank"
                        >
                          Terms
                        </a>
                      </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} xl={3} lg={3} xs={3} className="border-right resp-marg-btm-xs">
                <div className="footer-list">
                  <h4>Company</h4>
                  <ul>
                    {configuration
                      .get("configData.footer_pages2")
                      .map((static_page, index) => (
                        <li key={index}>
                          <Link
                            to={`/page/${static_page.static_page_unique_id}`}
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
              <Col md={2} xl={2} lg={2} xs={3}>
                <div className="footer-list">
                  <ul className="footer-social-icons">
                    {configuration.get("configData.facebook_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.facebook_link")}
                          target="_blank"
                        >
                          <span>
                            <Image
                              src={
                                window.location.origin + "/images/facebook.svg"
                              }
                            />
                          </span>
                          Facebook
                        </a>
                      </li>
                    ) : null}
                    {configuration.get("configData.instagram_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.instagram_link")}
                          target="_blank"
                        >
                          <span>
                            <Image
                              src={
                                window.location.origin + "/images/instagram.svg"
                              }
                            />
                          </span>
                          Instagram
                        </a>
                      </li>
                    ) : null}
                    {configuration.get("configData.twitter_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.twitter_link")}
                          target="_blank"
                        >
                          <span>
                            <Image
                              src={
                                window.location.origin + "/images/twitter.svg"
                              }
                            />
                          </span>
                          Twitter
                        </a>
                      </li>
                    ) : null}
                    {configuration.get("configData.linkedin_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.linkedin_link")}
                          target="_blank"
                        >
                          <span>
                            <Image
                              src={
                                window.location.origin + "/images/linkedin.svg"
                              }
                            />
                          </span>
                          LinkedIn
                        </a>
                      </li>
                    ) : null}
                    {configuration.get("configData.youtube_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.youtube_link")}
                          target="_blank"
                        >
                          <span>
                            <Image
                              src={
                                window.location.origin + "/images/youtube.svg"
                              }
                            />
                          </span>
                          YouTube
                        </a>
                      </li>
                    ) : null}
                    {configuration.get("configData.pinterest_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.pinterest_link")}
                          target="_blank"
                        >
                          <span>
                            <Image
                              src={
                                window.location.origin + "/images/pinterest.svg"
                              }
                            />
                          </span>
                          Pinterest
                        </a>
                      </li>
                    ) : null}
                    {configuration.get("configData.tiktok_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.tiktok_link")}
                          target="_blank"
                        >
                          <span>
                            <Image
                              src={
                                window.location.origin + "/images/tiktok.svg"
                              }
                            />
                          </span>
                          Tiktok
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </Col>
            </Row>
            <div className="copyright-sec">
              <p>{configuration.get("configData.copyright_content")}</p>
            </div>
          </Container>
        </div>
      </footer>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(FooterIndex);
