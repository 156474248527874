import React, { useState, useEffect } from "react";
import {
  Nav,
  Row,
  Col,
  Tab,
  Container,
  Form,
  InputGroup,
  Image,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AddNewBillingAccountModal from "./AddNewBillingAccountModal";
import SettingsIndex from "./SettingsIndex";
import {
  fetchMoreWithdrawlRequestStart,
  fetchWithdrawlRequestsStart,
  fetchUserBillingAccountsListStart,
  withdrawalRequestStart,
} from "../store/actions/WalletAction";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import configuration from "react-global-configuration";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/ToastNotification";
import { connect } from "react-redux";
import WalletWithdrawTableRow from "./WalletWithdrawTableRow";
import SingleAccount from "./SingleAccount";
import NoWithdrawFound from "../NoDataFound/NoWithdrawFound";

const SettingWithdraw = (props) => {
  const [showWithdraw, setShowWithdraw] = useState(0);

  const [addNewBillingAccount, setAddNewBillingAccount] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState("");

  const closeAddNewBillingAccountModal = () => {
    setAddNewBillingAccount(false);
  };

  useEffect(() => {
    props.dispatch(
      fetchWithdrawlRequestsStart({
        skip: 0,
        take: 12,
      })
    );
    props.dispatch(fetchUserBillingAccountsListStart());
  }, []);

  const fetchMoreWithdraw = () => {
    props.dispatch(
      fetchMoreWithdrawlRequestStart({
        skip: props.withdrawRequestList.data.withdrawals.length,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.addUserBillingAccounts.loading &&
      Object.keys(props.addUserBillingAccounts.data).length > 0
    ) {
      closeAddNewBillingAccountModal();
      props.dispatch(fetchUserBillingAccountsListStart());
    }
  }, [props.addUserBillingAccounts]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.userBillingAccounts.loading &&
      Object.keys(props.userBillingAccounts.data).length > 0
    ) {
      if (
        props.userBillingAccounts.data.user_billing_accounts &&
        props.userBillingAccounts.data.user_billing_accounts.length > 0
      ) {
        props.userBillingAccounts.data.user_billing_accounts.map(
          (account, i) => {
            if (account.is_default === 1)
              setSelectedAccount(account.user_billing_account_id);
          }
        );
      } else {
        setSelectedAccount(null);
      }
    }
  }, [props.userBillingAccounts]);

  const withdrawMoney = (e) => {
    e.preventDefault();
    if (amount && selectedAccount) {
      props.dispatch(
        withdrawalRequestStart({
          amount: amount,
          user_billing_account_id: selectedAccount,
        })
      );
    } else if (!amount) {
      props.dispatch(getErrorNotificationMessage("Please enter the amount"));
    } else if (!selectedAccount) {
      props.dispatch(
        getErrorNotificationMessage("Please add or select an account")
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.withdrawRequest.loading &&
      Object.keys(props.withdrawRequest.data).length > 0
    ) {
      props.dispatch(
        fetchWithdrawlRequestsStart({
          skip: 0,
          take: 12,
        })
      );
      setShowWithdraw(0);
      setAmount("");
    }
    setSkipRender(false);
  }, [props.withdrawRequest]);

  return (
    <>
      <SettingsIndex>
        <div className="settings-box">
          <Row>
            <Col md={12} xl={6} lg={6} className="resp-mrg-btm-md">
              <div className="setting-wallet-left-sec">
                <h4 className="setting-title">Current Wallet Balance</h4>
                <Form className="create-collection-form">
                  <div className="wallet-box">
                    <h4>
                      {!props.withdrawRequestList.loading &&
                      props.withdrawRequestList.data.wallet
                        ? props.withdrawRequestList.data.wallet
                            .remaining_formatted
                        : configuration.get("configData.currency") + " 0.00" }
                    </h4>
                    <div className="wallet-add-btn mt-2">
                      <Link
                        onClick={() => setShowWithdraw(!showWithdraw)}
                        className="default-outline-btn"
                      >
                        <span>
                          <Image
                            className="add-img"
                            src={
                              window.location.origin +
                              "/images/icons/withdraw.svg"
                            }
                          />
                        </span>
                        Withdraw
                      </Link>
                    </div>
                  </div>
                  {showWithdraw ? (
                    <>
                      <div className="select-card-box mt-5">
                        <h4 className="setting-title">
                          Billing Account*
                        </h4>
                        {props.userBillingAccounts.loading ? (
                          <CommonCenterLoader />
                        ) : (
                          <>
                            <Link
                              onClick={() => setAddNewBillingAccount(true)}
                              className="default-outline-btn"
                            >
                              <span>
                                <Image
                                  className="add-img"
                                  src={
                                    window.location.origin +
                                    "/images/icons/plus.svg"
                                  }
                                />
                              </span>
                              Add New Billing Account
                            </Link>
                            {props.userBillingAccounts.data
                              .user_billing_accounts &&
                              props.userBillingAccounts.data.user_billing_accounts.map(
                                (account, i) => (
                                  <SingleAccount
                                    account={account}
                                    selectedAccount={selectedAccount}
                                    setSelectedAccount={setSelectedAccount}
                                  />
                                )
                              )}
                          </>
                        )}
                      </div>
                      <div className="add-money-box profile-link mt-5">
                        <h4 className="setting-title">Withdraw Money*</h4>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon2">
                            <Image
                              src={
                                window.location.origin +
                                "/images/icons/dollar.svg"
                              }
                            />
                          </InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className="text-center">
                        <Button
                          className="default-btn"
                          type="submit"
                          onClick={withdrawMoney}
                          disabled={props.withdrawRequest.buttonDisable}
                        >
                          {props.withdrawRequest.loadingButtonContent
                            ? props.withdrawRequest.loadingButtonContent
                            : "Withdraw"}
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Form>
              </div>
            </Col>
            <Col md={12} xl={6} lg={6}>
              <div className="settings-wallet-history-sec wallet-scroll">
                <h4 className="setting-title">Withdrawal History</h4>
                {props.withdrawRequestList.loading ? (
                  <CommonCenterLoader />
                ) : props.withdrawRequestList.data.withdrawals &&
                  props.withdrawRequestList.data.withdrawals.length > 0 ? (
                  <div className="wallet-history-table withdraw-table">
                    <InfiniteScroll
                      dataLength={
                        props.withdrawRequestList.data.withdrawals.length
                      }
                      next={fetchMoreWithdraw}
                      hasMore={
                        props.withdrawRequestList.data.withdrawals.length <
                        props.withdrawRequestList.data.total
                      }
                      loader={<CommonCenterLoader />}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Transaction ID</th>
                            <th>Account</th>
                            <th>Requested</th>
                            <th>Paid</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.withdrawRequestList.data.withdrawals.map(
                            (withdraw, i) => (
                              <WalletWithdrawTableRow
                                withdraw={withdraw}
                                key={i}
                              />
                            )
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  </div>
                ) : (
                  <NoWithdrawFound />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </SettingsIndex>
      {addNewBillingAccount ? (
        <AddNewBillingAccountModal
          addNewBillingAccount={addNewBillingAccount}
          closeAddNewBillingAccountModal={closeAddNewBillingAccountModal}
          setAddNewBillingAccount={setAddNewBillingAccount}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  withdrawRequestList: state.wallet.withdrawRequestList,
  userBillingAccounts: state.wallet.userBillingAccounts,
  addUserBillingAccounts: state.wallet.addUserBillingAccounts,
  withdrawRequest: state.wallet.withdrawRequest,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SettingWithdraw);
